import { Image } from "../../../Image";
import { ContentTypeIcon } from "../../../../icons";
import type { Asset as SearchResultAsset } from "../../types";
import { useMemo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  grid-area: image;
  border-radius: ${(props) => props.theme.CornerRadius};
  background: ${(props) => props.theme.SearchImageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.FieldDisabledColor};
  overflow: hidden;
  box-shadow: ${(props) => props.theme.SearchImageRing};
`;

const ContentImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  align-content: center;
  text-align: center;
`;

//This should be moved out and refactored
const MEDIA_CONTENT_TYPES = ["cartoon", "clip", "photo", "getty", "gettyvideo"];

export function Asset(props: {
  asset?: SearchResultAsset | null;
  cdnHost?: string;
  contentType: string;
  modifications: { width?: number; height?: number };
  className?: string;
  assetAspectRatio?: string;
}) {
  let { asset, contentType, className, assetAspectRatio, ...forwardProps } =
    props;
  let aspectRatio = useMemo(() => {
    return assetAspectRatio
      ? assetAspectRatio
      : MEDIA_CONTENT_TYPES.includes(contentType)
      ? undefined
      : "1:1";
  }, [contentType, assetAspectRatio]);

  return (
    <Wrapper
      className={`${aspectRatio === "1:1" ? "content-asset" : "media-asset"} ${
        asset && props.cdnHost ? "has-image" : "has-placeholder"
      } ${className ?? ""}`.trim()}
      style={props.modifications}
    >
      {asset != null && props.cdnHost ? (
        <ContentImage
          aspectRatio={aspectRatio}
          asset={asset}
          {...forwardProps}
        />
      ) : (
        <ContentTypeIcon contentType={contentType} size="regular" />
      )}
    </Wrapper>
  );
}
Asset.displayName = "SearchResult__Asset";
