import { ReactNode, MouseEvent as ReactMouseEvent } from "react";
import styled from "styled-components";
import { RoutableLink } from "../../../RoutableLink";
import {
  isGroup,
  isMenuAnchor,
  isMenuAction,
  isMenuItemCheckbox,
  isMenuItemRadio,
  isMenuLink,
  isSeparator,
  isItem,
  MenuItem as Item,
} from "./types";
import { CheckIcon } from "../../../../icons";

const Separator = styled.li`
  /* Resets */
  list-style: none;

  margin: var(--spacing-xs) 0;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
`;

const Wrapper = styled.li`
  /* Resets */
  font-size: inherit;
  cursor: default;
  user-select: none;
  list-style: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Group = styled.ul`
  /* Resets */
  list-style: none;

  /* Positioning */
  position: relative;
  overflow-y: auto;
`;

const GroupItem = styled.li`
  & + & {
    margin-top: var(--spacing-xs);
    border-top: 1px solid ${(props) => props.theme.DividerColor};
    padding-top: var(--spacing-xs);
  }
`;

const GroupLabel = styled(Wrapper)`
  padding: ${(props) => props.theme.SecondaryPadding};
  font: ${(props) => props.theme.FontLabel};
`;

const ButtonItem = styled(Wrapper)<{ disabled?: boolean }>`
  padding: ${(props) => props.theme.SecondaryPadding};
  cursor: pointer;

  &:focus {
    outline: none;
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }
  ${(props) =>
    props.disabled &&
    `
  opacity: 50%;
  pointer-events: none;`};
`;

const OptionItem = styled(Wrapper)`
  position: relative;
  padding: ${(props) => props.theme.SecondaryPaddingWithLeftIcon};

  svg {
    position: absolute;
    left: var(--spacing-sm);
  }

  &:focus {
    outline: none;
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }
`;

const Anchor = styled.a`
  display: block;
  padding: ${(props) => props.theme.SecondaryPadding};
  text-decoration: none;
  color: ${(props) => props.theme.Color};

  &:focus {
    outline: none;
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }
`;

export function MenuItem<T>(props: {
  item: Item<T>;
  onMouseEnter?: (
    evt: ReactMouseEvent<HTMLLIElement | HTMLAnchorElement>
  ) => void;
  onMouseMove?: (
    evt: ReactMouseEvent<HTMLLIElement | HTMLAnchorElement>
  ) => void;
  onMouseLeave?: (
    evt: ReactMouseEvent<HTMLLIElement | HTMLAnchorElement>
  ) => void;
  focused: T | null;
  children: (value: T) => ReactNode;
  onClose?: (evt: ReactMouseEvent<Element>) => void;
  updateRef: (
    value: T,
    element: HTMLLIElement | HTMLAnchorElement | null
  ) => void;
}) {
  let { item, children, focused, updateRef, onClose, ...forwardProps } = props;
  if (isGroup(item)) {
    let items = item.items;
    return (
      <GroupItem>
        <Group role="group" aria-label={item.label}>
          {item.label && (
            <GroupLabel role="presentation">{item.label}</GroupLabel>
          )}
          {items.map((child, index) => (
            <MenuItem
              item={child}
              key={index}
              updateRef={updateRef}
              onClose={onClose}
              focused={focused}
              {...forwardProps}
            >
              {children}
            </MenuItem>
          ))}
        </Group>
      </GroupItem>
    );
  } else if (isSeparator(item)) {
    return <Separator role="separator"></Separator>;
  } else if (isMenuAction(item)) {
    let action = item;
    return (
      <ButtonItem
        role="menuitem"
        disabled={item.disabled}
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          action.onClick();
          onClose && onClose(evt);
        }}
        ref={(element) => updateRef(action.value, element)}
        tabIndex={action.value === focused ? 0 : -1}
        {...forwardProps}
      >
        {props.children(action.value)}
      </ButtonItem>
    );
  } else if (isMenuLink(item)) {
    let { value, onClick, ...linkProps } = item;
    return (
      <Wrapper role="none">
        <Anchor
          {...linkProps}
          as={RoutableLink}
          role="menuitem"
          onClick={(evt: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
            onClick && onClick(evt);
            onClose && onClose(evt);
          }}
          ref={(element: HTMLAnchorElement | null) => updateRef(value, element)}
          tabIndex={value === focused ? 0 : -1}
          {...forwardProps}
        >
          {props.children(value)}
        </Anchor>
      </Wrapper>
    );
  } else if (isMenuAnchor(item)) {
    let { value, onClick, ...anchorProps } = item;
    return (
      <Wrapper role="none">
        <Anchor
          {...anchorProps}
          role="menuitem"
          onClick={(evt: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
            onClick && onClick(evt);
            onClose && onClose(evt);
          }}
          ref={(element) => updateRef(value, element)}
          tabIndex={value === focused ? 0 : -1}
          {...forwardProps}
        >
          {props.children(value)}
        </Anchor>
      </Wrapper>
    );
  } else if (isMenuItemCheckbox(item)) {
    let { value, checked, onChange } = item;
    return (
      <OptionItem
        role="menuitemcheckbox"
        aria-checked={checked}
        onClick={(evt) => {
          onChange(!checked);
          onClose && onClose(evt);
        }}
        ref={(element) => updateRef(value, element)}
        tabIndex={value === focused ? 0 : -1}
        {...forwardProps}
      >
        {checked && <CheckIcon size="small" />}
        {props.children(value)}
      </OptionItem>
    );
  } else if (isMenuItemRadio(item)) {
    let { value, checked, onChange } = item;
    return (
      <OptionItem
        role="menuitemradio"
        aria-checked={checked}
        onClick={(evt) => {
          onChange(value);
          onClose && onClose(evt);
        }}
        ref={(element) => updateRef(value, element)}
        tabIndex={value === focused ? 0 : -1}
        {...forwardProps}
      >
        {checked && <CheckIcon size="small" />}
        {props.children(value)}
      </OptionItem>
    );
  } else if (isItem(item)) {
    return <Wrapper role="none">{props.children(item.value)}</Wrapper>;
  }
  throw new Error(`Invalid MenuItem: ${JSON.stringify(item)}`);
}

MenuItem.displayName = "ARIA.MenuItem";
