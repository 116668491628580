import { SVGAttributes } from "react";

export const HelpIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.322 14.802a1 1 0 01-1-1v-2.498c1.188-.059 2.097-.308 2.728-.748.645-.455.968-1.122.968-2.002v-.308c0-.675-.205-1.195-.616-1.562-.41-.381-.96-.572-1.65-.572-.733 0-1.32.213-1.76.638-.177.17-.33.36-.463.569-.324.514-.944.857-1.508.631l-.18-.071c-.477-.191-.74-.719-.52-1.184.076-.16.16-.317.251-.473.264-.425.594-.8.99-1.122a4.39 4.39 0 011.408-.792C10.513 4.103 11.136 4 11.84 4c.719 0 1.371.103 1.958.308a4.21 4.21 0 011.496.88c.41.367.726.821.946 1.364.22.528.33 1.115.33 1.76 0 .66-.117 1.254-.352 1.782-.22.513-.52.953-.902 1.32a4.792 4.792 0 01-1.254.902 5.77 5.77 0 01-1.452.462v1.024a1 1 0 01-1 1h-.288zm.166 5.082c-.557 0-.968-.132-1.232-.396-.25-.279-.374-.63-.374-1.056v-.374c0-.425.125-.77.374-1.034.264-.279.675-.418 1.232-.418.557 0 .96.14 1.21.418.264.264.396.609.396 1.034v.374c0 .425-.132.777-.396 1.056-.25.264-.653.396-1.21.396z"
        fill="currentColor"
      />
    </svg>
  );
};
HelpIcon.displayName = "HelpIcon";
