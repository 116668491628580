import { useContext, ReactNode } from "react";
import {
  ThemeProvider as SCThemeProvider,
  ThemeContext,
  DefaultTheme,
} from "styled-components";

import themes from "./themes";

export { ThemeContext };
export type { Theme } from "./themes/light";

type Tint = "blue" | "red" | "yellow" | "orange" | "purple" | "green" | "gray";

function tint(theme: Record<string, number | string>, color: Tint) {
  let tintedTheme = { ...theme };
  for (let key of Object.keys(theme) as (keyof DefaultTheme)[]) {
    let value = theme[key];
    if (typeof value === "string") {
      tintedTheme[key] =
        color === "gray"
          ? value.replace(/blue-(\d)0/, "gray-$1")
          : value.replace("blue", color);
    } else {
      tintedTheme[key] = value;
    }
  }
  tintedTheme._tint = color;
  return tintedTheme as unknown as DefaultTheme;
}

function tinterFor(tint: Tint) {
  return function tinter(css: string) {
    return css
      .replace(/--color-gray-([1-9])/, `--color-${tint}-$10`)
      .replace(/--color-([a-z]+)-([1-9]0)/, `--color-${tint}-$2`);
  };
}

type TransformFn = (
  theme: DefaultTheme,
  tinter: (css: string) => string
) => DefaultTheme;

type TintArgs = {
  tint: Tint;
  transform?: TransformFn;
};

type ThemeArgs = {
  theme: "dark" | "light" | "modal";
  children: ReactNode;
} & (TintArgs | { tint?: undefined });

export function ThemeProvider(
  props:
    | ThemeArgs
    | (TintArgs & {
        theme?: undefined;
        children: ReactNode;
      })
) {
  let outerTheme = useContext(ThemeContext);
  let color = props.tint ?? (outerTheme?._tint as Tint) ?? "blue";
  let parentTheme = outerTheme?._name as "dark" | "light";
  let theme = props.theme ?? parentTheme;
  let transform: TransformFn = props.tint
    ? props.transform ?? ((theme) => tint(theme, props.tint))
    : (theme) => theme;

  if (parentTheme && props.theme === "modal") {
    let innerTheme = themes[`${parentTheme}`] ?? themes[`${parentTheme}Modal`];

    if (innerTheme) {
      return (
        <SCThemeProvider theme={tint(innerTheme, color)}>
          {props.children}
        </SCThemeProvider>
      );
    }
  }
  if (theme === "modal") {
    throw new Error("A parent theme is needed before using a modal theme");
  }

  return (
    <SCThemeProvider
      theme={transform(
        themes[theme],
        props.tint
          ? tinterFor(props.tint)
          : () => {
              throw new Error("unreachable");
            }
      )}
    >
      {props.children}
    </SCThemeProvider>
  );
}
