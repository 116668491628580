import { RoutableLink } from "../RoutableLink";
import styled from "styled-components";

export const Link = styled(RoutableLink)`
  /** Reset */
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  &::-moz-focus-inner {
    border: 0;
  }

  display: inline;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.SecondaryColor};
  cursor: pointer;
  font: ${(props) => props.theme.FontInteractive};
  text-decoration: none;
  border-radius: ${(props) => props.theme.CornerRadius};

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;
Link.displayName = "Link";
