import debounce from "lodash/debounce";
import { useMemo } from "react";

export function useDebouncedCallback<T extends unknown[], R>(
  fn: (...args: T) => R,
  ms: number,
  dependencies: unknown[] = []
) {
  // eslint-disable-next-line
  let debounceFn = useMemo(() => debounce(fn, ms), [ms, ...dependencies]);

  return debounceFn;
}
