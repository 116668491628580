import { ReactNode, useEffect, useState } from "react";
import {
  IntlContext,
  IntlProvider as ReactIntlProvider,
  MessageFormatElement,
} from "react-intl";
export { IntlContext };
import MarkdownSource from "@atjson/source-commonmark";
import ReactRenderer, { ReactRendererProvider } from "@atjson/renderer-react";
import styled from "styled-components";

const Bold = styled.strong`
  font-weight: bold;
`;

const Italic = styled.em`
  font-style: italic;
`;

const Code = styled.code`
  display: inline-block;
  font: ${(props) => props.theme.FontCode};
  background: ${(props) => props.theme.SurfaceColor};
  box-shadow: ${(props) => props.theme.FieldDisabledRing};
  color: ${(props) => props.theme.Color};
  border-radius: var(--spacing-xxs);
  padding: 0 var(--spacing-xs);
  line-height: var(--spacing-md);
`;

const Link = styled.a`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  &::-moz-focus-inner {
    border: 0;
  }

  display: inline;
  align-items: center;
  background: transparent;
  color: ${(props) => props.theme.SecondaryColor};
  cursor: pointer;
  font: ${(props) => props.theme.FontInteractive};
  text-decoration: none;
  border-radius: ${(props) => props.theme.CornerRadius};

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;

const MinimalHtml = (props: { children: string }) => {
  if (props.children === "<br/>") {
    return <br />;
  }
  // Strip all other HTML
  return <></>;
};

const components = {
  strong: Bold,
  em: Italic,
  Blockquote(props: { children: ReactNode }) {
    return <blockquote>{props.children}</blockquote>;
  },
  link: Link,
  code_inline: Code,
  html_inline: MinimalHtml,
  paragraph(props: { children: ReactNode }) {
    return <>{props.children}</>;
  },
};

function markdownToReact(input: ReactNode) {
  let markdown = MarkdownSource.fromRaw(String(input));
  return (
    <ReactRendererProvider value={components}>
      {ReactRenderer.render(markdown)}
    </ReactRendererProvider>
  );
}

function isMessagePromise(
  messages:
    | Promise<Record<string, MessageFormatElement[]> | Record<string, string>>
    | Record<string, MessageFormatElement[]>
    | Record<string, string>
): messages is Promise<
  Record<string, MessageFormatElement[]> | Record<string, string>
> {
  return "then" in messages;
}

export function IntlProvider(props: {
  children: ReactNode;
  locale?: string;
  load?(
    languageCode: string,
    regionOrScriptCode: string
  ):
    | Promise<Record<string, MessageFormatElement[]> | Record<string, string>>
    | Record<string, MessageFormatElement[]>
    | Record<string, string>;
}) {
  const [messages, setMessages] = useState<
    Record<string, MessageFormatElement[]> | Record<string, string>
  >({});
  const locale = props.locale ?? "en-GB";
  const load = props.load;

  useEffect(() => {
    let [languageCode, regionOrScriptCode] = locale
      .split("-")
      .map((localePart) => localePart.toLowerCase());
    if (load) {
      const loadedMessages = load(languageCode, regionOrScriptCode);
      if (isMessagePromise(loadedMessages)) {
        loadedMessages.then((messages) => setMessages(messages));
      } else {
        setMessages(loadedMessages);
      }
    }
  }, [locale, load]);

  // We silence translation errors,
  // since they're handled in the sync process
  return (
    <ReactIntlProvider
      messages={messages}
      locale={locale}
      defaultLocale="en-GB"
      defaultRichTextElements={{
        md: (chunk) => markdownToReact(chunk),
      }}
      onError={() => {}}
    >
      {props.children}
    </ReactIntlProvider>
  );
}
