import { SVGAttributes } from "react";

export const NextIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M14 7a1 1 0 110 2H2a1 1 0 010-2h12z" fill="currentColor" />
        <path
          d="M8.293 3.707a1 1 0 011.414-1.414l5 5a1 1 0 01-1.414 1.414l-5-5z"
          fill="currentColor"
        />
        <path
          d="M9.707 13.707a1 1 0 01-1.414-1.414l5-5a1 1 0 111.414 1.414l-5 5z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M18 11a1 1 0 110 2H6a1 1 0 110-2h12z" fill="currentColor" />
        <path
          d="M12.293 7.707a1 1 0 011.414-1.414l5 5a1 1 0 01-1.414 1.414l-5-5z"
          fill="currentColor"
        />
        <path
          d="M13.707 17.707a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 1.414l-5 5z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
NextIcon.displayName = "NextIcon";
