import styled from "styled-components";
import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";
import {
  HighlightedText,
  Metadata,
  RoutableLink,
  PublishStatusIndicator,
} from "../../index";
import { Asset, ContentType, Title, Filename } from "../-private";
import type { Props } from "../types";
import { EllipsisIcon, CheckIcon } from "../../../icons";
import { getMetaData } from "../helper";

const WrappableTile = styled.div<{ $archived: boolean }>`
  height: 100%;
  background: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerRadius};
  display: flex;
  flex-direction: column;

  .media-asset.has-image {
    border-radius: 0;
    box-shadow: none;
  }
  ${({ $archived, theme }) => {
    if ($archived)
      return `
      background: ${theme.ArchivedBackground};
      border-radius: var(--spacing-xxs)
    `;
    else return;
  }}
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 156px;
`;

const AssetWrapper = styled.div`
  position: relative;
  border-radius: calc(${(props) => props.theme.CornerRadius} - 1px)
    calc(${(props) => props.theme.CornerRadius} - 1px) 0 0;
  user-select: none;
  overflow: hidden;
`;

const Slot = styled.div`
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
`;

const Container = styled.div<{ $selectionInitiated?: boolean }>`
  position: relative;
  height: 320px;
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  border: 1px solid ${(props) => props.theme.DividerColor};
  overflow: hidden;
  min-width: 240px;
  ${WrappableTile} {
    & > span {
      position: absolute;
      top: 0;
      border-radius: unset;
      border-bottom-right-radius: ${(props) => props.theme.CornerRadius};
      z-index: 1;
    }
  }
  ${({ $selectionInitiated, theme }) => {
    if ($selectionInitiated) {
      return `
      ${WrappableTile}{
        &:hover {
          background: ${theme.BorderlessHoverBackground};
          & > span {
            visibility: visible;
          }
        }
      }
      
      &[aria-selected="true"] {
        ${WrappableTile} {
          & > span {
            color: ${theme.Background};
            background: ${theme.PrimaryBackground};
            visibility: visible;
          }
          &:hover {
            background: ${theme.SecondaryActiveBackground};
            box-shadow: ${theme.FieldActiveRing};
          }
          box-shadow: ${theme.FieldActiveRing};
          background: ${theme.SecondaryActiveBackground};
        }
      }
      `;
    }
    return;
  }}
`;

const ContentDescription = styled.div`
  display: grid;
  grid-template-rows: auto 40px 32px 24px auto;
  grid-template-areas:
    ". . ."
    ". title ."
    ". modified-at ."
    ". metadata ."
    ". . .";
  gap: var(--spacing-xs) calc(var(--spacing-xxs) * 3);
  grid-template-columns: auto 1fr auto;
  align-items: center;
`;

const MediaDescription = styled.div`
  display: grid;
  grid-template-rows: auto 40px 64px;
  grid-template-areas:
    ". . ."
    ". title ."
    ". credit ."
    ". metadata ."
    ". . .";
  gap: var(--spacing-xs) calc(var(--spacing-xxs) * 3);
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 40px 32px 24px auto;
  align-items: center;
`;

const Description = styled.span<{ $isDraggable: boolean }>`
  display: ${(props) => (props.$isDraggable ? "none" : "inline-block")};
  grid-area: modified-at;
  font: ${(props) => props.theme.FontSmallStatement};
`;

const MetaData = styled.div`
  grid-area: metadata;
  margin-top: auto;
  display: flex;
  gap: var(--spacing-xs);
  flex-direction: row;
  align-items: center;

  & > svg {
    margin-right: var(--spacing-xxs);
  }
`;

const Credit = styled.div`
  grid-area: credit;
  display: flex;
  overflow: hidden;
`;

const CreditText = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  background-color: ${(props) => props.theme.ArchivedBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  height: var(--spacing-md);
  padding: 0px var(--spacing-xxs);
  color: ${(props) => props.theme.Color};
  font: ${(props) => props.theme.FontSmallStatement};
`;

const TruncatedHighlightedText = styled(HighlightedText)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledEllipsisIcon = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  color: ${(props) => props.theme.PrimaryDisabledColor};
  z-index: ${(props) => props.theme.ElevationMenu};
`;

const AssetIndex = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-left: var(--spacing-xxs);
  margin-top: var(--spacing-xxs);
  top: 0;
  left: 0;
  position: absolute;
`;

const SearchResultLink = styled(RoutableLink)`
  /** Reset */
  appearance: none;
  text-decoration: none;
  color: ${(props) => props.theme.Color};
`;

const StyledCheckIcon = styled.span`
  line-height: 0;
  display: block;
  padding: var(--spacing-xxs);
  color: ${(props) => props.theme.PlaceholderColor};
  background: ${(props) => props.theme.BorderlessHoverBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  visibility: hidden;
`;

const MEDIA_TYPES = ["cartoon", "clip", "photo", "getty", "gettyvideo"];

function SearchTile<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  const {
    result,
    query,
    cdnHost,
    assetAs,
    draggable,
    hideIndex,
    ...forwardProps
  } = props;
  const isMedia = MEDIA_TYPES.includes(props.result.contentType);
  const assetProps = assetAs ? forwardProps : {};

  return (
    <WrappableTile $archived={!!result.archived}>
      <StyledCheckIcon>
        <CheckIcon size="small" />
      </StyledCheckIcon>
      <AssetWrapper {...assetProps} as={assetAs}>
        {!hideIndex && props.assetindex && (
          <AssetIndex>{("0" + props.assetindex).slice(-2)}</AssetIndex>
        )}
        <Asset
          asset={result.asset}
          cdnHost={props.cdnHost}
          contentType={result.contentType}
          modifications={{ height: 188 }}
        />
      </AssetWrapper>
      {result.publishInfo && result.revisionInfo ? (
        <Wrapper>
          <PublishStatusIndicator
            publishInfo={result.publishInfo}
            latestRevision={result.revisionInfo.version}
            publishQueue={result.publishQueue}
            size="small"
          />
        </Wrapper>
      ) : (
        <></>
      )}
      {isMedia ? (
        <MediaDescription>
          <Filename
            title={result.title}
            clamp={2}
            query={query}
            fidelity="compact"
          />
          {result?.credit?.content && (
            <Credit>
              <CreditText>
                <TruncatedHighlightedText document={result.credit} />
              </CreditText>
            </Credit>
          )}
          <MetaData>
            <ContentType
              contentTypeLabel={result.contentTypeLabel}
              fidelity="full"
            />
            {result.metadata && (
              <Metadata metadata={getMetaData(result.metadata)} />
            )}
          </MetaData>
        </MediaDescription>
      ) : (
        <ContentDescription>
          <Title title={result.title} clamp={2} query={query} fidelity="full" />
          <Description $isDraggable={!!draggable}>
            {result.description && (
              <FormattedMessage
                defaultMessage={
                  "Updated {lastModified, date, medium} by {contributor}"
                }
                description="shows the date on which a piece of content was last edited and by who"
                values={{
                  lastModified: new Date(result.description),
                  contributor: result.revisionInfo?.authorName,
                }}
              />
            )}
          </Description>
          <MetaData>
            <ContentType
              contentTypeLabel={result.contentTypeLabel}
              fidelity="full"
            />
            {result.metadata && (
              <Metadata metadata={getMetaData(result.metadata)} />
            )}
          </MetaData>
        </ContentDescription>
      )}
    </WrappableTile>
  );
}

export function AssetTile<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  const { children, assetAs, draggable, hideIndex, result, ...forwardProps } =
    props;
  const containerProps = assetAs ? {} : forwardProps;

  return (
    <Container
      {...containerProps}
      $selectionInitiated={props.selectionInitiated}
    >
      {draggable && (
        <StyledEllipsisIcon>
          <EllipsisIcon size="regular" />
        </StyledEllipsisIcon>
      )}
      {props.to && !props.selectionInitiated ? (
        <SearchResultLink to={props.to}>
          <SearchTile {...props} />
        </SearchResultLink>
      ) : (
        <SearchTile {...props} />
      )}
      <Slot>{children}</Slot>
    </Container>
  );
}
AssetTile.displayName = "AssetTile";
