import { SVGAttributes } from "react";

export const MontroseIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.379 4.565c.217.429.544 1.109.726 1.511.323.715 1.127 2.45 1.89 4.081.21.448.382.827.382.843 0 .015.216.483.479 1.038.263.556.579 1.23.701 1.499.123.268.5 1.08.836 1.804.338.724.714 1.536.836 1.804.123.268.5 1.08.837 1.804.337.724.708 1.525.825 1.78.117.255.271.463.344.463.072 0 .253-.274.402-.61l.812-1.828.715-1.609c.268-.608 3.23-7.301 4.597-10.386.677-1.529 1.276-2.835 1.33-2.903.077-.096.854-.117 3.504-.097L30 3.784v.293c0 .283-.018.294-.541.348-1.225.125-1.88.465-2.195 1.142l-.215.46v9.216c0 10.05-.007 9.914.55 10.38.383.319 1.004.537 1.762.62.629.068.639.074.639.362v.292l-4.847.026c-4.422.023-4.852.01-4.91-.135-.116-.303.113-.473.73-.541.925-.104 1.392-.262 1.765-.601.678-.617.662-.385.697-9.809.02-5.278-.003-8.461-.062-8.52-.06-.06-.148.01-.243.193-.144.275-1.942 4.238-6.078 13.39-2.468 5.46-2.735 5.997-2.976 5.997-.242 0-.12.23-1.754-3.316-.616-1.336-1.682-3.632-3.136-6.753-.157-.335-.421-.906-.589-1.268-.527-1.139-1.967-4.231-2.372-5.095a399.85 399.85 0 01-.739-1.585c-.192-.416-.39-.756-.437-.756-.052 0-.076 3.287-.059 7.875.028 7.346.042 7.911.21 8.411.39 1.153.838 1.54 2.037 1.759.809.148.927.21.927.495v.233H0v-.233c0-.293.125-.356 1-.504 1.762-.3 2.188-.913 2.391-3.446.137-1.713.132-14.581-.006-15.878-.169-1.574-.545-2.042-1.86-2.31A8.285 8.285 0 00.6 4.393c-.451-.028-.663-.26-.458-.504.115-.138.512-.153 3.486-.13l3.356.025.395.78z"
        fill="#ED5565"
      />
    </svg>
  );
};
MontroseIcon.displayName = "MontroseIcon";
