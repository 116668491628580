import { useCallback, useState, FC } from "react";
import { usePopper } from "react-popper";
import { Card } from "../Card";
import { useClickOutside, useUniqueId } from "../../hooks";
import type { Option } from "./types";
import { SearchPanel } from "./SearchPanel";
import type { ContributorSelection } from "./types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const PanelContainer = styled.div`
  position: relative;
  grid-area: control;
  color: ${(props) => props.theme.Color};
  min-width: calc(var(--spacing-xl) * 7.625);
  display: grid;
  svg.select-chevron {
    pointer-events: none;
    position: absolute;
    top: calc(var(--spacing-md) / 2);
    right: calc(var(--spacing-md) / 2);
  }
  button {
    border-radius: 0;
  }
`;

const PanelLabel = styled.label`
  display: inline-block;
  color: var(--color-gray-1);
  font: var(--font-label);
  line-height: var(--spacing-md);
  cursor: none;
`;

const SearchPanelWrapper = styled(Card)`
  max-width: calc(var(--spacing-xl) * 100);
  padding: 0;
  box-shadow: none;
  display: flex;

  [role="listbox"] {
    border-radius: 0 0 var(--spacing-xxs) var(--spacing-xxs);
    [role="option"] {
      padding: ${(props) => props.theme.SecondaryMediumPadding};
      svg {
        display: none;
      }
    }
    [role="option"]:first-of-type {
      margin-top: 0;
    }
  }

  [role="tree"] {
    [role="treeitem"]:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.DividerColor};
    }
    [role="treeitem"]:last-of-type {
      margin-bottom: calc(var(--spacing-xxs) * 2.5);
    }
  }
`;

export const ContributorSelector: FC<{
  label?: string;
  selections: ContributorSelection[];
  search: (query: string) => void;
  searchResult: {
    data?: ContributorSelection[];
    loading?: boolean;
    errors?: Error[];
  };
  onChange: (selections: ContributorSelection[]) => void;
  setInputClicked?: (query: boolean) => void;
  "aria-invalid"?: boolean;
  contributorTypeOptions: Option<string>[];
  defaultContributorType?: Option<string>;
}> = ({
  selections,
  search,
  searchResult,
  onChange,
  label,
  contributorTypeOptions,
  defaultContributorType,
}) => {
  const contributorSelectorId = `ContributorSelector_${useUniqueId()}`;
  const [panelElement, setPanelElement] = useState<HTMLDivElement | null>(null);
  const [panelContainerElement, setPanelContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isPanelPositioned] = useState<boolean>(false);
  const [isFocused, setFocused] = useState<boolean>(false);

  const { attributes: panelAttributes } = usePopper(panelElement);

  const close = useCallback(() => {
    if (isExpanded) {
      setExpanded(false);
    }
  }, [setExpanded, isExpanded]);

  useClickOutside(panelContainerElement, close);

  const onSelectionChange = useCallback(
    (contributorSelection: ContributorSelection | undefined) => {
      if (contributorSelection) {
        let filteredData = selections.filter(
          (item) =>
            !(
              item.id === contributorSelection.id &&
              item.role === contributorSelection.role
            )
        );
        onChange(filteredData);
      }
    },
    [onChange, selections]
  );

  const setInputClick = (focused: boolean) => {
    setFocused(focused);
    setExpanded(true);
  };

  return (
    <PanelContainer ref={setPanelContainerElement}>
      <PanelLabel>
        <FormattedMessage defaultMessage="Byline" />
      </PanelLabel>
      <SearchPanelWrapper ref={setPanelElement} {...panelAttributes.popper}>
        <SearchPanel
          idPrefix={contributorSelectorId}
          close={close}
          selections={selections}
          search={search}
          searchResult={searchResult}
          isFocused={isFocused}
          isExpanded={isExpanded}
          autofocus={isPanelPositioned}
          onChange={onChange}
          onSelectionsChange={onSelectionChange}
          setInputClicked={setInputClick}
          label={label}
          contributorTypeOptions={contributorTypeOptions}
          defaultContributorType={defaultContributorType}
        />
      </SearchPanelWrapper>
    </PanelContainer>
  );
};
ContributorSelector.displayName = "ContributorSelector";
export * from "./types";
