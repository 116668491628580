import { SVGAttributes } from "react";

export const CheckIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.707 4.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L6 9.586l5.293-5.293a1 1 0 011.414 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.277 5.31a1 1 0 011.446 1.38l-10.5 11a1 1 0 01-1.43.017l-4.5-4.5a1 1 0 111.414-1.414l3.776 3.776 9.794-10.26z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
CheckIcon.displayName = "CheckIcon";
