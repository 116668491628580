import { SVGAttributes } from "react";

export const USArchitecturalDigestIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={37}
      height={24}
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.02 24V.691h-2.405V.516H24.6c6.827 0 11.87 4.018 11.87 11.764 0 7.847-5.275 11.72-11.798 11.72h-5.651zm3.725-.16h1.783c1.333 0 2.84-.176 3.927-.86 2.349-1.543 3.784-4.949 3.784-10.7 0-6.056-.986-9.594-3.857-10.963-1.058-.51-2.477-.611-4.188-.611h-1.449v23.133zM0 23.998v-.175h1.905L10.427 0l8.507 23.999h-6.457v-.175h2.414c-.896-2.513-1.792-5.025-2.69-7.537H4.8s-2.56 7.156-2.705 7.537h1.804v.175H0zm4.843-7.887h7.3l-3.65-10.2-3.65 10.2z"
        fill="currentColor"
      />
    </svg>
  );
};
USArchitecturalDigestIcon.displayName = "USArchitecturalDigestIcon";
