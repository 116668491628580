import styled from "styled-components";
import { Button } from "../Button";
import { UploadIcon } from "../../icons";
import { FormattedMessage } from "react-intl";
import {
  forwardRef,
  useRef,
  ChangeEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from "react";
import { propagateRef } from "../../lib";

const Input = styled.input`
  display: None;
`;

const Upload = styled(Button)`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};
  display: inline-flex;
`;

export const UploadButton = forwardRef<
  HTMLButtonElement,
  Omit<HTMLAttributes<HTMLButtonElement>, "onChange"> & {
    onChange: (files: FileList) => void;
    accept: string;
    multiple: boolean;
    disabled?: boolean;
    children?: () => ReactNode;
  }
>(
  (
    { onChange, accept, multiple = true, disabled = false, children },
    forwardedRef
  ) => {
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();
      hiddenFileInput.current?.click();
    };
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      event.preventDefault();
      const fileUploaded = event.target.files;
      if (fileUploaded != undefined) {
        onChange(fileUploaded);
        // clear input so that it will fire onChange if the
        // same file is selected again
        event.target.value = "";
      }
    };
    return (
      <>
        <Upload
          onClick={handleClick}
          disabled={disabled}
          ref={(el) => {
            if (forwardedRef) {
              propagateRef(forwardedRef, el);
            }
          }}
        >
          {children ? (
            children()
          ) : (
            <>
              <UploadIcon size="regular" />
              <FormattedMessage defaultMessage="Upload" />
            </>
          )}
        </Upload>
        <Input
          type="file"
          aria-hidden
          ref={hiddenFileInput}
          onChange={handleChange}
          multiple={multiple}
          accept={accept}
        />
      </>
    );
  }
);

UploadButton.displayName = "UploadButton";
