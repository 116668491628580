import { forwardRef, useEffect, useState, AriaAttributes } from "react";
import { propagateRef } from "../../lib";
import {
  CheckboxCheckedIcon,
  CheckboxPartialIcon,
  CheckboxUncheckedIcon,
  HorizontalRuleIcon,
  CheckIcon,
} from "../../icons";
import styled from "styled-components";

const Label = styled.label`
  position: relative;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;

  & + * {
    border-radius: ${(props) => props.theme.CornerInsetRadius};
  }

  &:not(:disabled) + * {
    color: ${(props) => props.theme.ControlIconColor};
  }

  &:not(:disabled):checked + *,
  &:not(:disabled):indeterminate + * {
    color: ${(props) => props.theme.ControlOnBackground};
  }

  &:disabled + * {
    color: ${(props) => props.theme.ControlDisabledBackground};
  }

  &:focus + * {
    box-shadow: inset ${(props) => props.theme.FocusRing};
  }
`;

const LargeCheckbox = styled.div`
  outline: 1px solid ${(props) => props.theme.DividerColor};
  padding: var(--spacing-xxs);
  border-radius: ${(props) => props.theme.CornerRadius};
  background: ${(props) => props.theme.Background};
  height: var(--spacing-lg);
  width: var(--spacing-lg);
`;

export const Checkbox = forwardRef<
  HTMLInputElement,
  {
    id?: string;
    className?: string;
    disabled?: boolean;
    checked: boolean;
    indeterminate?: boolean;
    size?: "regular" | "large";
    onChange?: (checked: boolean) => void;
    tabIndex?: number;
  } & AriaAttributes
>((props, ref) => {
  let {
    className,
    indeterminate,
    onChange,
    checked,
    size = "regular",
    tabIndex,
    ...forwardProps
  } = props;
  let [checkbox, setCheckbox] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    if (checkbox && indeterminate != null) {
      checkbox.indeterminate = indeterminate;
    }
  }, [checkbox, indeterminate]);

  return (
    <Label className={className}>
      <Input
        type="checkbox"
        ref={(value) => {
          setCheckbox(value);
          propagateRef(ref, value);
        }}
        onChange={(evt) => {
          if (onChange) {
            if (indeterminate) {
              onChange(true);
            } else {
              onChange(!checked);
            }
          }
          evt.target.checked = checked;

          if (indeterminate) {
            evt.target.indeterminate = indeterminate;
          }
        }}
        checked={checked}
        tabIndex={tabIndex}
        {...forwardProps}
      />
      {size === "large" && (
        <LargeCheckbox>
          {indeterminate ? (
            <HorizontalRuleIcon size="regular" aria-hidden="true" />
          ) : checked ? (
            <CheckIcon size="regular" aria-hidden="true" />
          ) : null}
        </LargeCheckbox>
      )}

      {size === "regular" &&
        (indeterminate ? (
          <CheckboxPartialIcon size="regular" aria-hidden="true" />
        ) : checked ? (
          <CheckboxCheckedIcon size="regular" aria-hidden="true" />
        ) : (
          <CheckboxUncheckedIcon size="regular" aria-hidden="true" />
        ))}
    </Label>
  );
});
Checkbox.displayName = "Checkbox";
