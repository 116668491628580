import { useState, FC, ReactNode } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Button } from "../Button";
import { CloseIcon, InfoIcon } from "../../icons";
import { ThemeProvider } from "../../contexts";
import { Placement } from "@popperjs/core";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: var(--spacing-xs);
  right: var(--spacing-xs);
`;

const ContextualHelpHeader = styled.span`
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  margin-left: var(--spacing-xs);
`;

const ContextualHelpDialog = styled.div`
  z-index: 10;

  &[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none;
  }
`;

const ContextualHelpContainer = styled.div`
  background-color: ${(props) => props.theme.Background};
  border: none;
  border-radius: ${(props) => props.theme.CornerRadius};
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.Color};
  box-shadow: ${(props) => props.theme.MenuShadow};
  position: relative;
  padding: var(--spacing-sm) var(--spacing-xl) var(--spacing-sm)
    var(--spacing-sm);
  z-index: 10;

  p {
    &:not(:first-child) {
      margin-block-start: var(--spacing-sm);
    }
    &:not(:last-child) {
      margin-block-end: var(--spacing-sm);
    }
  }

  ul {
    list-style-type: disc;
    margin-block-start: var(--spacing-sm);
    &:not(:last-child) {
      margin-block-end: var(--spacing-sm);
    }

    li {
      margin-left: var(--spacing-sm);
    }
  }
`;

export const ContextualHelp: FC<{
  id: string;
  className?: string;
  children: ReactNode;
  placement?: Placement;
}> = (props) => {
  let intl = useIntl();
  let [popperTarget, setPopperTarget] = useState<HTMLButtonElement | null>(
    null
  );
  let [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(
    null
  );
  let [active, setActive] = useState(false);

  let {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes },
  } = usePopper(popperTarget, popoverElement, {
    placement: props.placement ?? "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      { name: "preventOverflow", options: { padding: 8, altBoundary: true } },
      { name: "flip", enabled: props.placement ? false : true },
    ],
  });

  let buttonId = `${props.id}-trigger`;
  let descriptionId = `${props.id}-description`;

  let Popover = (
    <ContextualHelpDialog
      ref={setPopoverElement}
      className="popper"
      style={popperStyles}
      role="status"
      {...popperAttributes}
    >
      <ThemeProvider theme="modal">
        <ContextualHelpContainer>
          <div id={descriptionId}>{props.children}</div>
          <ContextualHelpHeader>
            <CloseButton
              size="small"
              aria-label={intl.formatMessage({ defaultMessage: "Close" })}
              onClick={() => setActive(false)}
            >
              <CloseIcon size="small" />
            </CloseButton>
          </ContextualHelpHeader>
        </ContextualHelpContainer>
      </ThemeProvider>
    </ContextualHelpDialog>
  );

  return (
    <Container className={props.className}>
      <Button
        ref={setPopperTarget}
        size="small"
        aria-pressed={active}
        aria-label={intl.formatMessage({
          defaultMessage: "More info",
          description: "Tooltip for a contextual help button",
        })}
        aria-expanded={active}
        id={buttonId}
        onClick={() => {
          setActive(!active);
        }}
      >
        <InfoIcon size="small" />
      </Button>
      {active && Popover}
    </Container>
  );
};
