import { useCallback, useState, FC, ReactNode } from "react";
import { usePopper } from "react-popper";
import * as Sentry from "@sentry/react";
import { ChevronDownIcon } from "../../icons";
import { Card, Toast, SearchableTreeView, TreeNode } from "../index";
import { SearchResult } from "./SearchResult";
import { useClickOutside, useUniqueId } from "../../hooks";
import type { Category, Taxonomy } from "./types";
import { CategorySelections } from "./CategorySelections";
import { FormattedMessage, useIntl } from "react-intl";
import { modifiers as popperModifiers } from "../../lib";
import styled from "styled-components";

const PanelContainer = styled.div`
  position: relative;
  grid-area: control;
  color: ${(props) => props.theme.Color};
  min-width: calc(var(--spacing-xl) * 7.625);
  display: inline-block;
  width: 100%;
  svg.select-chevron {
    pointer-events: none;
    position: absolute;
    top: calc(var(--spacing-md) / 2);
    right: calc(var(--spacing-md) / 2);
  }
`;

const ErrorToast = styled(Toast)`
  width: 100%;
`;

const SearchPanelWrapper = styled(Card)`
  z-index: ${(props) => props.theme.ElevationMenu};
  width: calc(var(--spacing-lg) * 16);
  padding: 0;
  overflow: auto;

  background: ${(props) => props.theme.ToastBackground};
  box-shadow: ${(props) => props.theme.MenuShadow};

  [role="listbox"] {
    box-shadow: none;
    border-radius: 0 0 var(--spacing-xxs) var(--spacing-xxs);
    [role="option"] {
      padding: ${(props) => props.theme.SecondaryMediumPadding};
      svg {
        display: none;
      }
    }
    [role="option"]:first-of-type {
      margin-top: 0;
    }
  }

  [role="tree"] {
    [role="treeitem"]:not(:last-of-type) {
      border-bottom: 1px solid ${(props) => props.theme.DividerColor};
    }
    [role="treeitem"]:last-of-type {
      margin-bottom: calc(var(--spacing-xxs) * 2.5);
    }
  }
`;

const SelectButton = styled.button<{ $placeholder: boolean }>`
  /* Resets */
  appearance: none;
  border: 0;
  margin: 0;
  font: ${(props) => props.theme.FontBody};
  &::-moz-focus-inner {
    border: 0;
  }

  &[disabled] + svg {
    display: none;
  }

  &[aria-expanded] + svg {
    transform: rotate(180deg);
  }

  &[aria-expanded] + svg + ul {
    display: block;
  }

  width: 100%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${(props) => props.theme.SelectButtonBackgroundColor};

  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.ControlDisabledColor;
    } else {
      return props.theme.Color;
    }
  }};
  padding: ${(props) => props.theme.SecondaryPadding};
  padding-right: var(--spacing-xl);

  &:not([disabled]):hover {
    background-color: ${(props) =>
      props.theme.SelectButtonHoverBackgroundColor};
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &:not([disabled]):active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:not([disabled]):focus {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

export const CategorySelector: FC<{
  placeholder?: ReactNode;
  selections: Taxonomy[];
  search: (query: string) => void;
  searchResult: {
    data?: Category[];
    loading?: boolean;
    errors?: Error[];
  };
  roots: Category[];
  getChildren: (item: Category) => Promise<Category[]>; // or paginated data
  onChange: (selections: Taxonomy[]) => void;
  "aria-invalid"?: boolean;
  hideCategorySlats?: boolean;
  className?: string;
}> = ({
  placeholder,
  selections,
  search,
  searchResult,
  roots,
  getChildren,
  onChange,
  ["aria-invalid"]: ariaInvalid,
  hideCategorySlats,
  className,
}) => {
  let intl = useIntl();
  const categorySelectorId = `CategorySelector_${useUniqueId()}`;
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [panelElement, setPanelElement] = useState<HTMLDivElement | null>(null);
  const [panelContainerElement, setPanelContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isPanelPositioned, setPanelPositioned] = useState<boolean>(false);

  const onButtonClick = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);

  const onPopperFirstUpdate = useCallback(() => {
    setPanelPositioned(true);
  }, [setPanelPositioned]);

  const { styles: panelStyles, attributes: panelAttributes } = usePopper(
    buttonElement,
    panelElement,
    {
      placement: "bottom-start",
      modifiers: [
        popperModifiers.maxSize,
        popperModifiers.applyMaxSize,
        {
          name: "preventOverflow",
          options: { padding: 8 },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top-start"],
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
      onFirstUpdate: onPopperFirstUpdate,
    }
  );

  const close = useCallback(() => {
    if (isExpanded) {
      setExpanded(false);
    }
  }, [setExpanded, isExpanded]);

  useClickOutside(panelContainerElement, close);

  const onSearchSelect = useCallback(
    (categories: Category[]) => {
      const taxonomies = categories.reduce((taxonomies, category) => {
        const root = category.hierarchy[0];
        let taxonomy = taxonomies.find(
          (taxonomy) => taxonomy.slug === root.slug
        );
        if (!taxonomy) {
          taxonomy = {
            slug: root.slug,
            name: root.name,
            categories: [],
          };
          taxonomies.push(taxonomy);
        }
        taxonomy.categories.push(category);
        return taxonomies;
      }, [] as Taxonomy[]);
      onChange(taxonomies);
    },
    [onChange]
  );

  const onSelectionsChange = useCallback(
    (taxonomy: Taxonomy) => {
      const taxonomyIndex = selections.findIndex(
        (selection) => selection.slug === taxonomy.slug
      );
      if (taxonomyIndex > -1) {
        onChange([
          ...selections.slice(0, taxonomyIndex),
          taxonomy,
          ...selections.slice(taxonomyIndex + 1),
        ]);
      } else {
        onChange([...selections, taxonomy]);
      }
    },
    [onChange, selections]
  );

  const toTreeNode = useCallback(
    (category: Category): TreeNode<Category> => ({
      label: category.childCount
        ? `${category.name} (${category.childCount})`
        : category.name,
      value: category,
      childCount: category.childCount,
      children: async () => {
        const children = await getChildren(category).catch((error) => {
          // Send error to Sentry
          Sentry.captureException(error);
          throw (
            <ErrorToast type="error">
              <FormattedMessage defaultMessage="An error occurred loading your categories." />
            </ErrorToast>
          );
        });
        return children.map(toTreeNode);
      },
    }),
    [getChildren]
  );

  const treeNodeRoots = roots.map(toTreeNode);

  return (
    <div className={className}>
      <PanelContainer ref={setPanelContainerElement}>
        <SelectButton
          ref={setButtonElement}
          id={categorySelectorId}
          type="button"
          tabIndex={isExpanded ? -1 : 0}
          $placeholder={!!placeholder}
          aria-haspopup="listbox"
          aria-expanded={isExpanded ? "true" : undefined}
          aria-invalid={ariaInvalid}
          onClick={onButtonClick}
        >
          <label htmlFor={categorySelectorId}>{placeholder}</label>
        </SelectButton>
        <ChevronDownIcon className="select-chevron" size="small" />
        {isExpanded && (
          <SearchPanelWrapper
            ref={setPanelElement}
            style={panelStyles.popper}
            {...panelAttributes.popper}
          >
            <SearchableTreeView
              idPrefix={categorySelectorId}
              inputLabelMessage={intl.formatMessage({
                defaultMessage: "Category search input",
              })}
              emptyRootsMessage={intl.formatMessage({
                defaultMessage: "Categories haven’t been set up for this brand",
              })}
              close={close}
              selections={selections.reduce((categories, taxonomy) => {
                return categories.concat(taxonomy.categories);
              }, [] as Category[])}
              search={search}
              searchResult={searchResult}
              roots={treeNodeRoots}
              autofocus={isPanelPositioned}
              onChange={onSearchSelect}
            >
              {(category, query) => {
                return (
                  <SearchResult
                    query={query}
                    name={category.name}
                    description={category.hierarchy
                      .map((cat) => cat.name)
                      .join(" > ")}
                    showDescription
                  />
                );
              }}
            </SearchableTreeView>
          </SearchPanelWrapper>
        )}
      </PanelContainer>
      {selections.length > 0 && !hideCategorySlats && (
        <CategorySelections
          idPrefix={categorySelectorId}
          selections={selections}
          onChange={onSelectionsChange}
          buttonRef={buttonElement}
          label={intl.formatMessage({
            defaultMessage: "Category selections list",
          })}
        />
      )}
    </div>
  );
};
CategorySelector.displayName = "CategorySelector";
