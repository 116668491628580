import { SVGAttributes } from "react";

export const ContentTypeMenuIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 2a2 2 0 012-2h10a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2V2zm2 0v12h10V2H3zm6 3a1 1 0 011-1h1a1 1 0 110 2h-1a1 1 0 01-1-1zm-4 5a1 1 0 100 2h1a1 1 0 100-2H5zM4 3h4v4H4V3zm8 6H8v4h4V9z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 1a2 2 0 00-2 2v18a2 2 0 002 2h15a2 2 0 002-2V3a2 2 0 00-2-2H5zm0 20V3h15v18H5zm8-15a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zm0 4a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zm-6 3a1 1 0 100 2h4a1 1 0 100-2H7zm-1 5a1 1 0 011-1h4a1 1 0 110 2H7a1 1 0 01-1-1zm4-11H8v2h2V7zM6 5v6h6V5H6zm9 10h2v2h-2v-2zm-2 4v-6h6v6h-6z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeMenuIcon.displayName = "ContentTypeMenuIcon";
