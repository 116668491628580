import { SVGAttributes } from "react";

export const INTTatlerIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={89}
      height={24}
      viewBox="0 0 89 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M87.287 23.603L82.903 8.597c1.343-.428 2.684-1.237 3.378-2.333.82-1.295.95-3.227-.505-4.446C84.42.683 82.103.73 81.072.73h-5.274v.396h1.02v22.477H75.81V24h5.272v-.397h-1.007V1.126h.996c3.005.012 4.028 3.781 2.549 5.81-1.232 1.691-3.495 2.05-3.495 2.05l3.938 14.617h-1.06V24h5.322v-.397h-1.038zM67.95.726h-7.113v.397h1.008v22.478h-1.008V24h7.868v-4.882h-.4v4.483h-3.199V9.488h1.823v2.82h.401V6.276h-.401v2.812h-1.823V1.123h3.198v4.483h.401V.726h-.755zm-21.936 0v.397h1.004v22.478h-1.005V24h7.868v-4.882h-.4v4.483h-3.202V1.123h1.007V.726h-5.272zm-16.44 0v4.88h.4V1.14h2.263v22.462h-1.005V24h5.272v-.399h-1.006V1.14h2.265v4.467h.4V.726h-8.589zm-12.106 8.61l.986 5.9h-2.036l1.05-5.9zm-2.93 14.265h-1.015V24h2.47v-.397h-1.056l1.403-7.966h2.18l1.333 7.964h-1.093V24h5.272v-.399h-.922L18.87 0h-.193l-4.142 23.601zM0 .726v4.88h.4V1.14h2.264v22.462H1.657V24H6.93v-.399H5.924V1.14h2.264v4.467h.4V.726H0z"
        fill="currentColor"
      />
    </svg>
  );
};
INTTatlerIcon.displayName = "INTTatlerIcon";
