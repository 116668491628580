import { ReactNode } from "react";
import styled from "styled-components";
import { ChevronDownIcon } from "../../../icons";

const Container = styled.div`
  position: relative;
  grid-area: control;
  color: ${(props) => props.theme.Color};

  svg {
    pointer-events: none;
    position: absolute;
    top: calc(var(--spacing-md) / 2);
    right: calc(var(--spacing-md) / 2);
  }
`;

const StyledSelect = styled.select`
  /* Resets */
  appearance: none;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
  }
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;

  background: ${(props) =>
    props.disabled
      ? props.theme.ControlDisabledBackground
      : props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) =>
    props.disabled ? props.theme.ControlDisabledColor : props.theme.Color};
  padding: ${(props) => props.theme.SecondaryPadding};
  padding-right: var(--spacing-xl);
  height: ${(props) => (props.size ? undefined : "2.5rem")};

  &[disabled] + svg {
    display: none;
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &:not([disabled]):hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &:not([disabled]):active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:not([disabled]):focus {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

export const NativeSelect = (
  props: {
    id: string;
    className?: string;
    disabled?: boolean;
    placeholder?: string | ReactNode;
    options: Array<{ label: string | null; value: string }>;
    "aria-invalid"?: boolean;
  } & (
    | {
        multiple: true;
        value?: string[];
        onChange: (values: string[]) => void;
      }
    | {
        multiple?: false;
        value?: string;
        onChange: (value: string) => void;
      }
  )
) => {
  return (
    <Container>
      <StyledSelect
        id={props.id}
        disabled={props.disabled}
        multiple={props.multiple}
        size={props.multiple ? 4 : undefined}
        value={props.value}
        aria-invalid={props["aria-invalid"]}
        onChange={(evt) => {
          if (props.multiple) {
            let values = Array.from(evt.target.selectedOptions).map(
              (option) => option.value
            );

            props.onChange(values);
          } else {
            props.onChange(evt.target.value);
          }
        }}
      >
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {!props.multiple && <ChevronDownIcon size="small" />}
    </Container>
  );
};
