import { FC } from "react";
import { useIntl } from "react-intl";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SmallSpinner = styled.div<{
  $color?: { background: string; foreground: string };
}>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid
    ${(props) =>
      props.$color?.background ??
      props.theme.ControlOffBackground ??
      "var(--color-gray-4)"};
  border-right: 2px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  border-bottom: 2px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  border-left: 2px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const LargeSpinner = styled.div<{
  $color?: { background: string; foreground: string };
}>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 4px solid
    ${(props) =>
      props.$color?.background ??
      props.theme.ControlOffBackground ??
      "var(--color-gray-4)"};
  border-right: 4px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  border-bottom: 4px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  border-left: 4px solid
    ${(props) =>
      props.$color?.foreground ??
      props.theme.ControlOffForeground ??
      "var(--color-gray-6)"};
  background: transparent;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const Spinner: FC<{
  size: "small" | "large";
  className?: string;
  $color?: { background: string; foreground: string };
}> = (props) => {
  let intl = useIntl();
  let label = intl.formatMessage({
    defaultMessage: "Loading",
    description: "Accessibility label for spinners",
  });

  return props.size === "small" ? (
    <SmallSpinner aria-label={label} role="progressbar" {...props} />
  ) : (
    <LargeSpinner aria-label={label} role="progressbar" {...props} />
  );
};
