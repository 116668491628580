import { FC } from "react";
import styled from "styled-components";

type BylineProps = {
  contributorsLabel?: string;
};

const StyledByline = styled.div`
  grid-area: byline;
`;

export const Byline: FC<BylineProps> = ({ contributorsLabel }) => {
  return <StyledByline>{contributorsLabel}</StyledByline>;
};
