import { SVGAttributes } from "react";

export const ContentTypeLocationIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14l-1.697 1.059a2 2 0 003.394 0L8 14zm0 0s.506-.812 1.159-1.947C10.339 9.998 12 6.881 12 5.6 12 3.612 10.21 2 8 2S4 3.612 4 5.6c0 1.28 1.66 4.398 2.841 6.453A69.487 69.487 0 008 14zm0 0a1009.7 1009.7 0 001.697 1.058l.001-.002.003-.004.01-.016.036-.058.132-.216a72.747 72.747 0 001.88-3.266c.514-.95 1.046-2 1.456-2.961.205-.48.391-.969.53-1.43.129-.434.255-.97.255-1.505C14 2.314 11.11 0 8 0S2 2.314 2 5.6c0 .536.126 1.071.256 1.505.138.461.324.95.529 1.43.41.962.942 2.01 1.455 2.961a72.814 72.814 0 002.013 3.482l.036.058.01.016.003.005v.001L8 14zm0-6a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 20l-1.726 1.01a2 2 0 003.452 0L12 20zm0 0s.48-.821 1.143-2.033C14.62 15.267 17 10.629 17 8.8 17 6.149 14.761 4 12 4S7 6.149 7 8.8c0 1.83 2.381 6.467 3.857 9.167A89.86 89.86 0 0012 20zm0 0l1.727 1.01v-.003l.004-.005.012-.02.044-.077a79.478 79.478 0 00.742-1.31 97.993 97.993 0 001.755-3.297c.638-1.259 1.292-2.635 1.792-3.888.46-1.154.924-2.516.924-3.61C19 4.968 15.788 2 12 2S5 4.968 5 8.8c0 1.094.463 2.456.924 3.61.5 1.253 1.155 2.63 1.792 3.888a98.168 98.168 0 002.497 4.607l.044.076.012.02.003.006.001.002L12 20zm0-9a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeLocationIcon.displayName = "ContentTypeLocationIcon";
