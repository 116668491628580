import { SVGAttributes } from "react";

export const ChevronRightIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.121 7.707a1 1 0 010 1.414l-4 4a1 1 0 11-1.414-1.414L9 8.414 5.707 5.121a1 1 0 111.414-1.414l4 4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.06 13.06a1.5 1.5 0 000-2.12l-7-7a1.5 1.5 0 00-2.12 2.12L13.878 12l-5.94 5.94a1.5 1.5 0 102.122 2.12l7-7z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ChevronRightIcon.displayName = "ChevronRightIcon";
