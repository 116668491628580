import { ReactNode, useCallback, useMemo } from "react";
import styled from "styled-components";

const Input = styled.input`
  /* Resets */
  appearance: none;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
  }
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;

  grid-area: control;
  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  padding: ${(props) => props.theme.SecondaryPadding};

  &[readonly] {
    color: ${(props) => props.theme.FieldDisabledColor};
    outline: none;
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }

  &:hover:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldHoverRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }

  &:active:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:focus:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

export function NativeDatePicker(props: {
  date: Date | null;
  children?: ReactNode;
  onChange: (date: Date) => void;
}) {
  const { date, onChange, children, ...forwardProps } = props;

  // Return local date in YYYY-MM-DD format
  const value = useMemo(
    () =>
      `${date?.getFullYear()}-${date
        ?.getMonth()
        .toString()
        .padStart(2, "0")}-${date?.getDate().toString().padStart(2, "0")}`,
    [date]
  );

  const change = useCallback(
    (evt) => {
      const newDate = new Date(evt.target.value);
      if (!isNaN(newDate.getTime())) {
        onChange(newDate);
      }
    },
    [onChange]
  );
  return (
    <Input type="date" value={value} onChange={change} {...forwardProps} />
  );
}
