import {
  useCallback,
  ComponentPropsWithoutRef,
  ChangeEvent,
  ClipboardEvent,
  useRef,
  Ref,
  forwardRef,
  useLayoutEffect,
} from "react";
import { propagateRef } from "../../lib";
import { Field, TextCount } from "../index";
import { Input } from "./Input";
import { TextArea } from "./TextArea";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

type ChangeOrBlur =
  | {
      onChange: (value: string) => void;
      onBlur?: (value: string) => void;
    }
  | {
      onBlur: (value: string) => void;
      onChange?: (value: string) => void;
    };

export const TextField = forwardRef(
  (
    props: Omit<ComponentPropsWithoutRef<typeof Field>, "children"> & {
      value: string;
      count?: "words" | "characters";
      maxCount?: number | null;
      autogenButton?: HTMLButtonElement | null;
      step?: string;
    } & ChangeOrBlur &
      (
        | ({
            multiline: true;
          } & Omit<
            ComponentPropsWithoutRef<typeof TextArea>,
            "onChange" | "onBlur"
          >)
        | ({
            multiline: false;
            type: "text";
          } & Omit<
            ComponentPropsWithoutRef<typeof TextArea>,
            "onChange" | "onBlur"
          >)
        | ({
            multiline: false;
            type: string;
          } & Omit<
            ComponentPropsWithoutRef<typeof Input>,
            "onChange" | "onBlur"
          >)
        | ({
            multiline: false;
            type?: null;
          } & Omit<
            ComponentPropsWithoutRef<typeof TextArea>,
            "onChange" | "onBlur"
          >)
      ),
    ref?: Ref<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { onBlur, step, onChange, count, maxCount, ...forwardProps } = props;
    let shouldRenderInput =
      !props.multiline && props.type && props.type !== "text";

    let change = useCallback(
      (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange?.(evt.target.value);
      },
      [onChange]
    );

    let blur = useCallback(
      (evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onBlur?.(evt.target.value);
      },
      [onBlur]
    );

    useLayoutEffect(() => {
      ref && propagateRef(ref, textareaRef.current || inputRef.current);
    }, []);

    let onPaste = (evt: ClipboardEvent<HTMLTextAreaElement>) => {
      if (!shouldRenderInput && !props.multiline) {
        let clipboardData = evt.clipboardData.getData("text/plain");

        if (!clipboardData.includes("\n")) {
          return;
        }

        evt.preventDefault();
        evt.stopPropagation();
        let { selectionStart, selectionEnd, value } = evt.currentTarget;
        let sanitizedClipboardData = clipboardData.replace(/[\n\r]+/g, " ");
        let newValue = `${value.slice(
          0,
          selectionStart
        )}${sanitizedClipboardData}${value.slice(selectionEnd)}`;
        evt.currentTarget.value = newValue;
        evt.currentTarget.selectionStart =
          selectionStart + sanitizedClipboardData.length;
        evt.currentTarget.selectionEnd =
          selectionStart + sanitizedClipboardData.length;
        onChange?.(newValue);
        return;
      }
    };

    let textareaRef = useRef<HTMLTextAreaElement>(null);
    let inputRef = useRef<HTMLInputElement>(null);

    return (
      <Wrapper
        {...forwardProps}
        counter={
          count ? (
            <TextCount text={props.value} count={count} maxCount={maxCount} />
          ) : undefined
        }
      >
        {shouldRenderInput ? (
          <Input
            {...(forwardProps as ComponentPropsWithoutRef<typeof Input>)}
            onChange={change}
            onBlur={blur}
            aria-invalid={!!props.errors?.length}
            ref={inputRef}
            step={step}
          />
        ) : (
          <TextArea
            {...(forwardProps as ComponentPropsWithoutRef<typeof TextArea>)}
            multiline={props.multiline}
            onChange={change}
            onBlur={blur}
            onPaste={onPaste}
            aria-invalid={!!props.errors?.length}
            ref={textareaRef}
          />
        )}
      </Wrapper>
    );
  }
);
TextField.displayName = "TextField";
