import { SVGAttributes } from "react";

export const PublishedWithChangesIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.443 1.15A7.002 7.002 0 001 8c0 3.86 3.14 7 7 7a7.008 7.008 0 006.85-8.443 3.913 3.913 0 01-1.676.552A5.255 5.255 0 018 13.25 5.255 5.255 0 012.75 8a5.255 5.255 0 016.141-5.174c.056-.61.25-1.18.552-1.676zm.418 4.644c.39.44.877.794 1.428 1.026l-.008.01-3.5 3.961a.875.875 0 01-1.275.04l-1.75-1.75a.875.875 0 111.238-1.238l1.092 1.092L9.86 5.794z"
        fill="currentColor"
      />
      <circle cx={13} cy={3} r={2} fill="currentColor" />
    </svg>
  );
};
PublishedWithChangesIcon.displayName = "PublishedWithChangesIcon";
