import { SVGAttributes } from "react";

export const ChevronUpIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.293 4.293a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L8 6.414 4.707 9.707a1 1 0 01-1.414-1.414l4-4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.06 6.94a1.5 1.5 0 00-2.12 0l-7 7a1.5 1.5 0 002.12 2.12L12 10.122l5.94 5.94a1.5 1.5 0 002.12-2.122l-7-7z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ChevronUpIcon.displayName = "ChevronUpIcon";
