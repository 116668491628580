import { ComponentType } from "react";
import styled from "styled-components";
import { ContentType, Title } from "../-private";
import type { Props } from "../types";

const RecentSearchResult = styled.div`
  display: grid;
  grid-template-columns: var(--spacing-xs) auto auto 1fr auto var(--spacing-xs);
  gap: var(--spacing-xs);
  align-items: center;
  grid-template-areas:
    ". . . . . ."
    ". content-type title . slot ."
    ". . . . . .";
`;

const Slot = styled.span`
  grid-area: slot;
  display: inline-grid;
`;

export function Recent<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  let { result, query, children, cdnHost, assetAs, ...forwardProps } = props;
  return (
    <RecentSearchResult {...forwardProps}>
      <ContentType
        contentTypeLabel={result.contentTypeLabel}
        fidelity="compact"
      />
      <Title title={result.title} query={query} fidelity="compact" />
      <Slot>{children}</Slot>
    </RecentSearchResult>
  );
}
