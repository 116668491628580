import {
  useCallback,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
  ReactNode,
} from "react";
import { ARIA, Toast } from "../index";
import { useDebouncedCallback } from "../../hooks";
import { SearchIcon } from "../../icons";
import type { TreeNode } from "../index";
import { SearchInput } from "./SearchInput";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";

const LIST_KEY_EVENTS = ["ArrowDown", "ArrowUp", "Enter", "Home", "End"];
const TREE_KEY_EVENTS = [...LIST_KEY_EVENTS, "ArrowLeft", "ArrowRight", " "];

const QUICK_SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const NoSearchResults = styled.div`
  text-align: center;
  font: ${(props) => props.theme.FontBody};
  padding: var(--spacing-xs);
`;

const Exception = styled.div`
  padding: var(--spacing-xs);
  background: ${(props) => props.theme.Background};
`;

interface SearchableTreeViewProps<T> {
  idPrefix: string;
  inputLabelMessage?: string;
  emptyRootsMessage?: string;
  singleSelect?: boolean;
  selections: T[];
  search: (query: string) => void;
  searchResult: {
    data?: T[];
    loading?: boolean;
    errors?: Error[];
  };
  roots: TreeNode<T>[];
  onChange: (selections: T[]) => void;
  autofocus?: boolean;
  close: () => void;
  children: (item: T, query: string | undefined) => ReactNode;
}
export function SearchableTreeView<
  T extends { id: string | number; selectable?: boolean }
>(props: SearchableTreeViewProps<T>) {
  let {
    idPrefix,
    inputLabelMessage,
    emptyRootsMessage,
    singleSelect,
    selections,
    search,
    searchResult,
    roots,
    onChange,
    autofocus,
    close,
    children,
  } = props;
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);
  const [treeElement, setTreeElement] = useState<HTMLUListElement | null>(null);
  const [searchInputElement, setSearchInputElement] =
    useState<HTMLButtonElement | null>(null);
  const [listActiveDesecndant, setListActiveDescendant] = useState<
    string | undefined
  >(undefined);
  const [treeActiveDescendant, setTreeActiveDescendant] = useState<
    string | undefined
  >(undefined);
  const activeDescendant = query ? listActiveDesecndant : treeActiveDescendant;
  const intl = useIntl();

  const onSearchInputKeyDown = useCallback(
    (event: ReactKeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab" || (!query && event.key === "Escape")) {
        close();
        return;
      }
      if (!query && TREE_KEY_EVENTS.includes(event.key)) {
        treeElement?.dispatchEvent(new KeyboardEvent("keydown", event));
        event.preventDefault();
        event.stopPropagation();
      }
      if (query && LIST_KEY_EVENTS.includes(event.key)) {
        listElement?.dispatchEvent(new KeyboardEvent("keydown", event));
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [query, treeElement, listElement, close]
  );

  const updateDebouncedQuery = useDebouncedCallback(
    (query) => {
      setDebouncedQuery(String(query).trim());
    },
    QUICK_SEARCH_DEBOUNCE_INTERVAL,
    [setDebouncedQuery]
  );

  useEffect(() => {
    updateDebouncedQuery(query);
  }, [query, updateDebouncedQuery]);

  useEffect(() => {
    if (debouncedQuery) {
      search(debouncedQuery);
    }
  }, [debouncedQuery, search]);

  const selectSearchOption = useCallback(
    (dataItem: T | undefined) => {
      if (
        dataItem &&
        !selections.some((selection) => selection.id === dataItem.id)
      ) {
        onChange([...selections, dataItem]);
      }
      setQuery("");
      searchInputElement?.focus();
    },
    [selections, onChange, setQuery, searchInputElement]
  );

  const isSelected = useCallback(
    (dataItem) => {
      if (!dataItem.selectable) {
        return;
      }
      return selections.some((selected) => selected.id === dataItem.id);
    },
    [selections]
  );

  const onSelect = useCallback(
    (dataItem, isSelected): void => {
      if (singleSelect) {
        onChange([dataItem]);
        return close();
      }
      if (isSelected) {
        return onChange([...selections, dataItem]);
      }
      return onChange(
        selections.filter((selected) => selected.id !== dataItem.id)
      );
    },
    [close, selections, singleSelect, onChange]
  );

  const searchInputLabel =
    inputLabelMessage ||
    intl.formatMessage({
      defaultMessage: "Search Input",
    });

  const emptyRootsLabel =
    emptyRootsMessage ||
    intl.formatMessage({
      defaultMessage: "No Data To Display",
    });

  return (
    <div>
      <SearchInput
        forwardedRef={setSearchInputElement}
        value={query}
        icon={SearchIcon}
        onChange={setQuery}
        loading={searchResult.loading ?? false}
        onKeyDown={onSearchInputKeyDown}
        aria-label={searchInputLabel}
        aria-controls={`${idPrefix}-${debouncedQuery ? "list" : "tree"}`}
        aria-activedescendant={activeDescendant}
        autofocus={autofocus}
      />
      {debouncedQuery && searchResult.data && searchResult.data.length > 0 && (
        <ARIA.Listbox
          id={`${idPrefix}-Listbox`}
          options={searchResult.data}
          onChange={selectSearchOption}
          ref={setListElement}
          autofocus={false}
          onActiveDescendantChange={setListActiveDescendant}
          aria-busy={searchResult.loading ? "true" : undefined}
        >
          {(category) => children(category, query)}
        </ARIA.Listbox>
      )}
      {debouncedQuery &&
        searchResult.data?.length === 0 &&
        roots &&
        roots.length > 0 && (
          <Exception>
            <NoSearchResults>
              <FormattedMessage defaultMessage="Your search returned no results." />
            </NoSearchResults>
          </Exception>
        )}
      {debouncedQuery &&
        searchResult.errors?.length &&
        roots &&
        roots.length > 0 && (
          <Exception>
            <Toast type="error">
              <FormattedMessage defaultMessage="An error occurred processing your search." />
            </Toast>
          </Exception>
        )}
      {roots && roots.length === 0 && (
        <Exception>
          <Toast type="informational">
            {emptyRootsLabel || (
              <FormattedMessage defaultMessage="No Data To Display" />
            )}
          </Toast>
        </Exception>
      )}
      {roots && roots.length > 0 && (
        <ARIA.TreeView
          id={`${idPrefix}-TreeView`}
          roots={roots}
          singleSelect={singleSelect}
          isSelected={isSelected}
          onSelect={onSelect}
          ref={setTreeElement}
          onActiveDescendantChange={setTreeActiveDescendant}
          {...(query
            ? {
                style: {
                  display: "hidden",
                },
                hidden: true,
              }
            : {})}
        ></ARIA.TreeView>
      )}
    </div>
  );
}
SearchableTreeView.displayName = "SearchableTreeView";
