import { SVGAttributes } from "react";

export const ContentTypeRecipeIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_67)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.019 2.017a.756.756 0 00-.019.19c0 .599.272.973.69 1.259.428.293.975.456 1.397.534h1.658a2.8 2.8 0 00-.452-.586c-.16-.159-.509-.42-.985-.686a6.864 6.864 0 00-1.45-.62c-.467-.13-.734-.118-.839-.09zM1 2.207c0 .742.204 1.355.52 1.851A2 2 0 000 6c0 2.208.966 4.03 2.52 5.254.175.139.357.269.545.39A.998.998 0 003 12v2a2 2 0 002 2h6a2 2 0 002-2v-2a.996.996 0 00-.065-.356 7.22 7.22 0 00.545-.39C15.034 10.03 16 8.208 16 6a2 2 0 00-1.598-1.96 4.558 4.558 0 00-.271-1.457c-.19-.536-.484-1.09-.885-1.528C12.852.623 12.26.207 11.5.207c-.606 0-1.14.284-1.54.569-.418.298-.813.688-1.16 1.083-.207.234-.405.48-.592.727a4.9 4.9 0 00-.5-.586C7.365 1.66 6.84 1.294 6.285.983A8.846 8.846 0 004.393.181C3.774.01 2.983-.109 2.285.155 1.905.3 1.56.557 1.32.95c-.232.38-.32.817-.32 1.258zM9.65 4H12.4a2.66 2.66 0 00-.156-.751c-.124-.35-.298-.65-.476-.845a.834.834 0 00-.207-.174.236.236 0 00-.048-.02.052.052 0 00-.014-.003c-.013.002-.134.023-.379.197a5.137 5.137 0 00-.82.776c-.228.26-.448.54-.651.82zM11 12.55c-.952.303-1.97.45-3 .45a9.904 9.904 0 01-3-.45V14h6v-1.45zM2 6c0 .741.148 1.41.418 2C3.297 9.92 5.465 11 8 11c2.535 0 4.703-1.08 5.582-3 .27-.59.418-1.259.418-2H2z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_67">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5.207c0-.098.01-.158.019-.19.105-.027.372-.038.838.091.466.13.985.359 1.45.62.477.267.827.527.986.686.276.277.45.557.558.793H7.09a3.474 3.474 0 01-1.358-.606C5.29 6.261 5 5.821 5 5.207zM3.616 7.244A3.564 3.564 0 013 5.207c0-.441.088-.877.32-1.258.24-.392.586-.65.965-.794.698-.265 1.49-.146 2.108.026a8.846 8.846 0 011.893.802c.555.311 1.08.676 1.421 1.017.192.192.358.389.501.586.187-.247.385-.493.591-.727.348-.395.743-.785 1.161-1.083.4-.285.934-.569 1.54-.569.812 0 1.39.484 1.74.97.199.275.354.586.471.906L15.793 5c1.119-1.119 2.108-1.543 3.02-1.543.432 0 .78.098 1.015.173.071.022.114.037.146.048l.098.031c.238.016.482.107.682.291.2.183.287.396.328.537.076.26.06.519.046.67-.031.337-.129.752-.22 1.118l-.103.403c-.052.202-.102.397-.148.59A2 2 0 0122 9.208c0 2.8-1.158 5.117-3.068 6.697-.296.245-.608.47-.932.675v2.627a2 2 0 01-2 2H8a2 2 0 01-2-2V16.58a9.072 9.072 0 01-.932-.675C3.158 14.325 2 12.008 2 9.207a2 2 0 011.616-1.963zm15.015-.037l.043-.192c.06-.263.134-.552.203-.82l.09-.355c.033-.13.061-.248.085-.353a1.02 1.02 0 00-.24-.03c-.213 0-.724.076-1.605.957-.333.333-.54.597-.662.793h2.086zm-4.641 0h-2.488c.241-.345.514-.704.799-1.027.293-.333.574-.6.82-.776.214-.152.333-.187.368-.195.022.014.066.05.13.138.134.186.263.493.338.869.074.372.08.729.035.981l-.002.01zm-1.99 11c1.39 0 2.749-.218 4-.661v1.661H8v-1.661c1.251.443 2.61.661 4 .661zm-7.73-7c.913 3.187 4.028 5 7.73 5 3.702 0 6.817-1.813 7.73-5 .176-.616.27-1.284.27-2H4c0 .716.094 1.384.27 2zm9.203-6.005s.004 0 .01.004c-.007-.001-.01-.003-.01-.004zm5.667-.233zm.959-1.253h-.005.005z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeRecipeIcon.displayName = "ContentTypeRecipeIcon";
