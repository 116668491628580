import { SVGAttributes } from "react";

export const CloseIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12.293 2.293a1 1 0 111.414 1.414l-10 10a1 1 0 01-1.414-1.414l10-10z"
          fill="currentColor"
        />
        <path
          d="M2.293 3.707a1 1 0 011.414-1.414l10 10a1 1 0 01-1.414 1.414l-10-10z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.293 5.293a1 1 0 000 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
CloseIcon.displayName = "CloseIcon";
