import { SVGAttributes } from "react";

export const ContentTypeAudiofileIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0L4 2v9.142A3.537 3.537 0 003 11c-1.657 0-3 1.12-3 2.5S1.343 16 3 16s3-1.12 3-2.5c0-.171-.02-.338-.06-.5H6V4.636l7-1.272v4.778A3.54 3.54 0 0012 8c-1.657 0-3 1.12-3 2.5s1.343 2.5 3 2.5c1.451 0 2.662-.859 2.94-2H15V0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 2L6 5.5v11.642A3.537 3.537 0 005 17c-1.657 0-3 1.12-3 2.5S3.343 22 5 22c1.451 0 2.662-.859 2.94-2H8V8.533l11-2.566v9.175A3.537 3.537 0 0018 15c-1.657 0-3 1.12-3 2.5s1.343 2.5 3 2.5c1.451 0 2.662-.859 2.94-2H21V2z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeAudiofileIcon.displayName = "ContentTypeAudiofileIcon";
