import { SVGAttributes } from "react";

export const ChevronDownIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.707 10.707a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 8.586l3.293-3.293a1 1 0 111.414 1.414l-4 4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.94 17.06a1.5 1.5 0 002.12 0l7-7a1.5 1.5 0 00-2.12-2.12L12 13.878l-5.94-5.94a1.5 1.5 0 10-2.12 2.122l7 7z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ChevronDownIcon.displayName = "ChevronDownIcon";
