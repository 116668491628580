import { SVGAttributes } from "react";

export const ScheduledWithChangesIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.663 1H3.75a.875.875 0 100 1.75h.012c.093 1.298.725 2.532 1.395 3.484a11.078 11.078 0 001.576 1.788A7.781 7.781 0 005.48 9.487c-.598.906-1.12 2.092-1.547 3.763H3.75a.875.875 0 000 1.75h8.75a.875.875 0 000-1.75h-.14c-.426-1.671-.948-2.857-1.546-3.763a7.782 7.782 0 00-1.252-1.465A11.062 11.062 0 0010.9 6.558a3.958 3.958 0 01-1.268-1.229 9.329 9.329 0 01-1.074 1.236c-.153.146-.291.267-.412.365a6.654 6.654 0 01-.411-.365 9.329 9.329 0 01-1.148-1.338C6.012 4.408 5.61 3.543 5.52 2.75h3.505A3.915 3.915 0 019.664 1zm-2.722 9.45c.376-.57.775-.976 1.206-1.343.431.367.83.774 1.206 1.344.42.636.832 1.508 1.196 2.799H5.746c.363-1.29.775-2.163 1.195-2.8z"
        fill="currentColor"
      />
      <circle cx={13} cy={3} r={2} fill="currentColor" />
    </svg>
  );
};
ScheduledWithChangesIcon.displayName = "ScheduledWithChangesIcon";
