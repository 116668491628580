import { SVGAttributes } from "react";

export const EllipsisVerticalIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={6.5} y={11} width={3} height={3} rx={1} fill="currentColor" />
      <rect x={6.5} y={6.5} width={3} height={3} rx={1} fill="currentColor" />
      <rect x={6.5} y={2} width={3} height={3} rx={1} fill="currentColor" />
    </svg>
  );
};
EllipsisVerticalIcon.displayName = "EllipsisVerticalIcon";
