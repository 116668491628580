import { SVGAttributes } from "react";

export const ContentTypeGiphyIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4H7V2h6v6h-2V6H9V4zM6 2v2H5v8h6V9h2v5H3V2h3z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 4h4v2H8v12h8v-6h2v8H6V4zm8 2h-2V4h6v6h-2V8h-2V6z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeGiphyIcon.displayName = "ContentTypeGiphyIcon";
