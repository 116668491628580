import { SVGAttributes } from "react";

export const AlignLeftIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5a1 1 0 000 2h18a1 1 0 100-2H3zm0 8a1 1 0 100 2h18a1 1 0 100-2H3zm-1-3a1 1 0 011-1h12a1 1 0 110 2H3a1 1 0 01-1-1zm1 7a1 1 0 100 2h12a1 1 0 100-2H3z"
        fill="currentColor"
      />
    </svg>
  );
};
AlignLeftIcon.displayName = "AlignLeftIcon";
