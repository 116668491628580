import styled from "styled-components";
import { useEffect, useState } from "react";

type Asset = {
  id: string;
  type: string;
  filename: string | null;
  publicURL: string | null;
  altText: string | null;
  height: number | null;
  width: number | null;
};

type Modifications = {
  width?: number | null;
  height?: number | null;
  x?: number | null;
  y?: number | null;
};

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  background: ${(props) => props.theme.PlaceholderColor};
  display: inline-block;
`;

const SizedPicture = styled.picture<{ $height: string; $width: string }>`
  height: ${(props) => props.$height};
  width: ${(props) => props.$width};
`;

function buildModificationString(
  modifications: Modifications,
  scaling: number
) {
  let shouldScaleImage = modifications.x == null && modifications.y == null;

  if (shouldScaleImage && modifications.width && modifications.height) {
    return `w_${modifications.width * scaling},h_${
      modifications.height * scaling
    },c_limit`;
  }
  return `w_${modifications.width},h_${modifications.height},x_${modifications.x},y_${modifications.y}`;
}

function buildImageSource(
  cdnHost: string,
  asset: Asset,
  aspectRatio: string,
  modifications?: Modifications,
  scaling = 1
) {
  let modificationString;
  if (
    modifications == null ||
    modifications.width === null ||
    modifications.height === null
  ) {
    modificationString = "pass";
  } else {
    modificationString = buildModificationString(modifications, scaling);
  }

  return asset.filename
    ? `${cdnHost}/${asset.type}/${
        asset.id
      }/${aspectRatio}/${modificationString}/${encodeURIComponent(
        asset.filename
      )}`
    : "";
}

export interface ImageProps {
  asset: Asset;
  cdnHost?: string;
  className?: string;
  aspectRatio?: string;
  modifications?: Modifications;
  overflowStyle?: "fill" | "fit";
  onImageLoad?: (img: HTMLImageElement) => void;
}

export const Image = (props: ImageProps) => {
  let { asset, cdnHost, modifications, overflowStyle, onImageLoad } = props;
  let aspectRatio = props.aspectRatio || "master";
  let imgSource: string | undefined = "";
  if (asset.filename && cdnHost) {
    imgSource = buildImageSource(cdnHost, asset, aspectRatio, modifications);
  } else if (asset.publicURL) {
    imgSource = asset.publicURL;
  }

  let [dimensions, setDimensions] = useState<{
    height?: number;
    width?: number;
  }>({ height: undefined, width: undefined });

  const { height: modifiedHeight, width: modifiedWidth } = modifications ?? {};
  useEffect(() => {
    if (!imgSource) return;

    let img = document.createElement("img");
    img.setAttribute("src", imgSource);

    img.onload = () => {
      onImageLoad && onImageLoad(img);
      let scalingFactor = 1;
      if (modifiedHeight && modifiedWidth) {
        const desiredAspectRatio = modifiedWidth / modifiedHeight;
        const realAspectRatio = img.width / img.height;
        if (realAspectRatio >= desiredAspectRatio) {
          // real image is wider than what we want. To fill, scale based on the height. To fit, scale based on width
          scalingFactor =
            overflowStyle === "fill"
              ? modifiedHeight / img.height
              : modifiedWidth / img.width;
        } else {
          scalingFactor =
            overflowStyle === "fill"
              ? modifiedWidth / img.width
              : modifiedHeight / img.height;
        }
      }

      setDimensions({
        height: img.height * scalingFactor,
        width: img.width * scalingFactor,
      });
    };

    return () => {
      img.onload = () => {};
    };
  }, [
    imgSource,
    modifiedHeight,
    modifiedWidth,
    overflowStyle,
    setDimensions,
    onImageLoad,
  ]);

  if (!cdnHost) {
    return <Img style={dimensions} />;
  }

  return (
    <SizedPicture
      $height={`${dimensions.height}px`}
      $width={`${dimensions.width}px`}
      className={props.className}
    >
      {props.modifications && (
        <>
          <source
            srcSet={buildImageSource(
              cdnHost,
              asset,
              aspectRatio,
              modifications,
              2
            )}
            media="(resolution: 2x)"
          ></source>
          <source
            srcSet={buildImageSource(
              cdnHost,
              asset,
              aspectRatio,
              modifications,
              3
            )}
            media="(resolution: 3x)"
          ></source>
        </>
      )}
      <Img src={imgSource} alt={asset.altText ?? undefined} />
    </SizedPicture>
  );
};
