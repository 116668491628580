import {
  createRef,
  useState,
  useCallback,
  FC,
  KeyboardEvent,
  RefObject,
  useEffect,
  useLayoutEffect,
  useMemo,
  Key,
} from "react";
import { CloseIcon, PrimaryIcon } from "../../../icons";
import { Button, Label, DraggableList } from "../../index";
import { Category, Taxonomy } from "../types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import computeScrollIntoView from "compute-scroll-into-view";
import { Draggable, KeyedItem } from "../../DraggableList/types";

const Container = styled.div`
  margin-top: var(--spacing-xs);
  margin-left: var(--spacing-xs);

  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }
`;

const CategorySlat = styled.div`
  display: grid;
  grid-gap: 0 var(--spacing-xs);
  grid-template-areas: "primary content remove";
  grid-template-columns: auto 1fr auto;
  padding: var(--spacing-xs);

  &:not([disabled]):hover,
  &[aria-invalid="true"],
  &.focused {
    background: ${(props) => props.theme.SelectOptionHoverBackground};
    outline: none;
  }

  + div {
    border-top: 1px solid ${(props) => props.theme.DividerColor};
  }

  &:focus-visible {
    outline: none;
  }
`;

const PrimarySelector = styled(Button)`
  align-self: center;
  border-radius: 50%;
  grid-area: primary;
  padding: var(--spacing-xs);

  &[disabled] {
    color: ${(props) => props.theme.ControlOnBackground};
    background: ${(props) => props.theme.SecondaryActiveBackground};
  }
`;

const Content = styled.div`
  grid-area: content;
`;

const RemoveButton = styled(Button)`
  align-self: center;
  grid-area: remove;
`;

const Hierarchy = styled.div`
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SubheadingColor};
  margin-top: var(--spacing-xxs);
`;

const CategoryLabel = styled(Label)`
  font: ${(props) => props.theme.FontLabel};
`;

const RootLabel = styled(Label)`
  margin-top: var(--spacing-xs);
`;

interface SelectionData {
  ref: RefObject<HTMLDivElement>;
  taxonomy: Taxonomy;
  category: Category;
}

export const CategorySelections: FC<{
  idPrefix: string;
  label: string;
  selections: Taxonomy[];
  onChange: (taxonomy: Taxonomy) => void; // could change this to update all taxonomies
  buttonRef: HTMLButtonElement | null;
}> = ({ idPrefix, label, selections, onChange, buttonRef }) => {
  const intl = useIntl();
  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);
  const selectionRefs = useMemo(
    () =>
      selections.reduce(
        (list, taxonomy) =>
          list.concat(
            taxonomy.categories.map((category) => ({
              ref: createRef<HTMLDivElement>(),
              taxonomy,
              category,
            }))
          ),
        [] as SelectionData[]
      ),
    [selections]
  );
  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(
    selectionRefs[0].category.id
  );

  const displayRootLabel = selections.length > 1;

  const makePrimary = useCallback(
    (category: Category, taxonomy: Taxonomy) => {
      const index = taxonomy.categories.indexOf(category);
      if (index > 0) {
        onChange({
          ...taxonomy,
          categories: [
            category,
            ...taxonomy.categories.filter(
              (selection) => selection.id !== category.id
            ),
          ],
        });
      }
    },
    [onChange]
  );

  const remove = useCallback(
    (category: Category, taxonomy: Taxonomy) => {
      let updatedSelections = [
        ...taxonomy.categories.filter(
          (selection) => selection.id !== category.id
        ),
      ];
      onChange({
        ...taxonomy,
        categories: updatedSelections,
      });
      if (selectionRefs.length > 1) {
        containerElement?.focus();
      } else {
        buttonRef?.focus();
      }
    },
    [onChange, selectionRefs.length, containerElement, buttonRef]
  );

  // This side effect updates the cursor and currentCategoryId when the
  // selections update, as this component doesn't mutate its own selections
  useEffect(() => {
    const currentCategoryIndex = selectionRefs.findIndex(
      ({ category }) => category.id === currentCategoryId
    );
    if (currentCategoryIndex > -1) {
      setCursor(currentCategoryIndex);
    } else {
      let newCursor = cursor;
      if (cursor >= selectionRefs.length) {
        newCursor = selectionRefs.length - 1;
        setCursor(newCursor);
      }
      setCurrentCategoryId(selectionRefs[newCursor].category.id);
    }
    // only run when the selections update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRefs, setCursor, setCurrentCategoryId]);

  useLayoutEffect(() => {
    if (
      document.activeElement &&
      document.activeElement === containerElement &&
      cursorMode === "keyboard" &&
      cursor != null
    ) {
      let selection = selectionRefs[cursor]?.ref;
      if (selection?.current) {
        let actions = computeScrollIntoView(selection.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
      }
    }
  }, [cursorMode, cursor, selectionRefs, containerElement]);

  const onKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      let newCursor = cursor;
      switch (evt.key) {
        case "ArrowDown":
          if (cursor == null) {
            newCursor = -1;
          }
          newCursor = (newCursor + 1) % selectionRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          setCurrentCategoryId(selectionRefs[newCursor]?.category.id ?? null);
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "ArrowUp":
          if (cursor == null) {
            newCursor = selectionRefs.length;
          }
          newCursor =
            (newCursor - 1 + selectionRefs.length) % selectionRefs.length;
          setCursor(newCursor);
          setCursorMode("keyboard");
          setCurrentCategoryId(selectionRefs[newCursor]?.category.id ?? null);
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "Home":
          setCursor(0);
          break;
        case "End":
          setCursor(selectionRefs.length - 1);
          break;
        case " ":
        case "Enter":
          makePrimary(
            selectionRefs[newCursor].category,
            selectionRefs[newCursor].taxonomy
          );
          break;
        case "Backspace":
        case "Delete":
          remove(
            selectionRefs[newCursor].category,
            selectionRefs[newCursor].taxonomy
          );
          break;
      }
    },
    [
      cursor,
      selectionRefs,
      makePrimary,
      remove,
      setCurrentCategoryId,
      setCursor,
      setCursorMode,
    ]
  );

  const onMouseMove = useCallback(
    (newCursor) => {
      setCursor(newCursor);
      setCursorMode("keyboard");
      setCurrentCategoryId(selectionRefs[newCursor]?.category.id ?? null);
    },
    [setCursor, setCurrentCategoryId, setCursorMode, selectionRefs]
  );

  let selectionsCounter = 0;
  return (
    <Container
      tabIndex={0}
      ref={setContainerElement}
      onKeyDown={onKeyDown}
      aria-activedescendant={`${idPrefix}-CategorySelection_${cursor}`}
      aria-label={label}
    >
      {selections
        .filter((selection) => selection.categories.length > 0)
        .map((taxonomy) => {
          const { name, slug, categories } = taxonomy;
          return (
            <div key={`category-selections-${slug}`}>
              {displayRootLabel && <RootLabel>{name}</RootLabel>}
              <DraggableList
                items={categories.map((category, indexInRoot) => {
                  let indexOverSelections = selectionsCounter++;
                  const key = category.id as Key;
                  return {
                    key: category.id,
                    value: {
                      key,
                      category: category,
                      indexOverSelections,
                      indexInRoot,
                      taxonomy,
                    },
                  };
                })}
                onMove={function (
                  data: Draggable<
                    KeyedItem<{
                      key: Key;
                      category: Category;
                      indexOverSelections: number;
                      indexInRoot: number;
                      taxonomy: Taxonomy;
                    }>
                  >,
                  dropIndex: number
                ): void {
                  if (data.index !== dropIndex) {
                    let itemsToMove = taxonomy.categories.filter(
                      (selection) =>
                        selection.id !== data.item.value.category.id
                    );
                    itemsToMove.splice(dropIndex, 0, data.item.value.category);
                    onChange({
                      ...taxonomy,
                      categories: itemsToMove,
                    });
                  }
                }}
              >
                {(item) => (
                  <CategorySlat
                    ref={selectionRefs[item.indexOverSelections].ref}
                    tabIndex={-1}
                    key={item.key}
                    id={`${idPrefix}-CategorySelection_${item.indexOverSelections}`}
                    className={
                      currentCategoryId === item.category.id ? "focused" : ""
                    }
                    role="option"
                    onMouseMove={() => {
                      onMouseMove(item.indexOverSelections);
                    }}
                  >
                    <PrimarySelector
                      treatment={item.indexInRoot === 0 ? "primary" : undefined}
                      disabled={item.indexInRoot === 0 && true}
                      size="small"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Primary",
                        description:
                          "Denotes a category as primary. Primary categories power several distinct facets on the verso side including analytics, ads hierarchy, page layout and more",
                      })}
                      onClick={() => {
                        makePrimary(item.category, taxonomy);
                      }}
                      tabIndex={-1}
                    >
                      <PrimaryIcon size="small" />
                    </PrimarySelector>
                    <Content>
                      <CategoryLabel>{item.category.name}</CategoryLabel>
                      <Hierarchy>
                        {item.category.hierarchy
                          .map((cat) => cat.name)
                          .join(" > ")}
                      </Hierarchy>
                    </Content>
                    <RemoveButton
                      size="small"
                      aria-label={intl.formatMessage({
                        defaultMessage: "Remove",
                        description:
                          "Used as a tooltip label on a button for removing category selections",
                      })}
                      onClick={() => {
                        remove(item.category, taxonomy);
                      }}
                      tabIndex={-1}
                    >
                      <CloseIcon size="small" />
                    </RemoveButton>
                  </CategorySlat>
                )}
              </DraggableList>
            </div>
          );
        })}
    </Container>
  );
};
CategorySelections.displayName = "CategorySelections";
