import { SVGAttributes } from "react";
import {
  BulletedListIcon,
  ContentTypeAdvertiserIcon,
  ContentTypeAirlineIcon,
  ContentTypeAirportIcon,
  ContentTypeArticleIcon,
  ContentTypeAudiofileIcon,
  ContentTypeBarIcon,
  ContentTypeBrandIcon,
  ContentTypeBundleIcon,
  ContentTypeCartoonIcon,
  ContentTypeClipIcon,
  ContentTypeCookbookIcon,
  ContentTypeEventIcon,
  ContentTypeFashionshowIcon,
  ContentTypeGalleryIcon,
  ContentTypeGettyIcon,
  ContentTypeGiphyIcon,
  ContentTypeHotelIcon,
  ContentTypeHotlistIcon,
  ContentTypeLivestoryIcon,
  ContentTypeLocationIcon,
  ContentTypeMenuIcon,
  ContentTypeMusicalbumIcon,
  ContentTypeMusicrecordingIcon,
  ContentTypeNewsletterIcon,
  ContentTypePhotoIcon,
  ContentTypeProductIcon,
  ContentTypeRcalistIcon,
  ContentTypeRecipeIcon,
  ContentTypeRestaurantIcon,
  ContentTypeReviewIcon,
  ContentTypeRunwaymomentIcon,
  ContentTypeShipIcon,
  ContentTypeShopIcon,
  ContentTypeSkiresortIcon,
  ContentTypeSpaIcon,
  ContentTypeTaxonomyIcon,
  ContentTypeVenueIcon,
  HomeIcon,
  RedirectIcon,
  SearchIcon,
  UserIcon,
} from "../__generated__";

const Icons = {
  advertiser: ContentTypeAdvertiserIcon,
  airline: ContentTypeAirlineIcon,
  airport: ContentTypeAirportIcon,
  article: ContentTypeArticleIcon,
  audiofile: ContentTypeAudiofileIcon,
  bar: ContentTypeBarIcon,
  brand: ContentTypeBrandIcon,
  bundle: ContentTypeBundleIcon,
  cartoon: ContentTypeCartoonIcon,
  city: ContentTypeLocationIcon,
  clip: ContentTypeClipIcon,
  contributor: UserIcon,
  cookbook: ContentTypeCookbookIcon,
  curatedlist: BulletedListIcon,
  curatedsearch: SearchIcon,
  destination: ContentTypeLocationIcon,
  event: ContentTypeEventIcon,
  external: RedirectIcon,
  externallink: RedirectIcon,
  fashiongallery: ContentTypeGalleryIcon,
  fashionshow: ContentTypeFashionshowIcon,
  gallery: ContentTypeGalleryIcon,
  getty: ContentTypeGettyIcon,
  giphy: ContentTypeGiphyIcon,
  hotel: ContentTypeHotelIcon,
  hotlist: ContentTypeHotlistIcon,
  house: HomeIcon,
  list: BulletedListIcon,
  livestory: ContentTypeLivestoryIcon,
  menu: ContentTypeMenuIcon,
  musicalbum: ContentTypeMusicalbumIcon,
  musicrecording: ContentTypeMusicrecordingIcon,
  newsletter: ContentTypeNewsletterIcon,
  person: UserIcon,
  photo: ContentTypePhotoIcon,
  product: ContentTypeProductIcon,
  rcalist: ContentTypeRcalistIcon,
  recipe: ContentTypeRecipeIcon,
  restaurant: ContentTypeRestaurantIcon,
  review: ContentTypeReviewIcon,
  runwaymoment: ContentTypeRunwaymomentIcon,
  season: ContentTypeEventIcon,
  ship: ContentTypeShipIcon,
  shop: ContentTypeShopIcon,
  skiresort: ContentTypeSkiresortIcon,
  spa: ContentTypeSpaIcon,
  taxonomy: ContentTypeTaxonomyIcon,
  venue: ContentTypeVenueIcon,
} as const;

function hasIcon(contentType: string): contentType is keyof typeof Icons {
  return contentType in Icons;
}

export function ContentTypeIcon(
  props: SVGAttributes<SVGElement> & {
    contentType: string;
    size: "small" | "regular";
  }
) {
  let { contentType, size, ...forwardProps } = props;
  contentType = contentType.replace(/^native/, "");
  let Icon = hasIcon(contentType) ? Icons[contentType] : ContentTypeArticleIcon;
  return <Icon size={props.size} {...forwardProps} />;
}
ContentTypeIcon.displayName = "ContentTypeIcon";
