import { SVGAttributes } from "react";

export const ContentTypeShopIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_90)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 0H3a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zM2 3a1 1 0 011-1h10a1 1 0 011 1v1H2V3zm0 3v7a1 1 0 001 1h4v-3H6a1 1 0 110-2h1V6H2zm7 5h1a1 1 0 100-2H9V6h5v7a1 1 0 01-1 1H9v-3z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_90">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2h14a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3zM4 9v10a1 1 0 001 1h1V9H4zm4 0v11h3v-4h-1a1 1 0 110-2h1V9H8zm5 0v5h1a1 1 0 110 2h-1v4h3V9h-3zm5 0v11h1a1 1 0 001-1V9h-2zm2-2V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2h16z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeShopIcon.displayName = "ContentTypeShopIcon";
