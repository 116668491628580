import { SVGAttributes } from "react";

export const ContentTypeFashionshowIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_112)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0a2 2 0 00-2 2v6a2 2 0 002 2h2.263l-1.927 2.89A2 2 0 004 16h8a2 2 0 001.664-3.11L11.737 10H14a2 2 0 002-2V2a2 2 0 00-2-2H2zm7.333 10H6.667L4 14h8l-2.667-4zm0-2H10V2H6v6h3.333z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_112">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1a2 2 0 00-2 2v12a2 2 0 002 2h4.439l-3 3.75A2 2 0 006 24h12a2 2 0 001.562-3.25l-3-3.75H21a2 2 0 002-2V3a2 2 0 00-2-2H3zm11 16h-4l-4 5h12l-4-5zm0-2h1V3H9v12h5z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeFashionshowIcon.displayName = "ContentTypeFashionshowIcon";
