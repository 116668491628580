import { SVGAttributes } from "react";

export const MobileIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={7}
        y={4}
        width={10}
        height={16}
        rx={1}
        stroke="currentColor"
        strokeWidth={2}
      />
      <circle cx={12} cy={17} r={1} fill="currentColor" />
    </svg>
  );
};
MobileIcon.displayName = "MobileIcon";
