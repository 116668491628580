import { SVGAttributes } from "react";

export const ContentTypeCartoonIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.651 3.326c-1.079.755-1.619 1.703-1.619 2.637 0 .86.455 1.726 1.367 2.45.913.724 2.235 1.256 3.779 1.4a1 1 0 01.903 1.078 7.179 7.179 0 01-.469 1.95c.183-.04.37-.09.559-.151 1.098-.362 2.09-1.073 2.297-2.606a1 1 0 01.622-.796C12.998 8.53 14 7.218 14 5.963c0-.934-.54-1.882-1.619-2.637-1.076-.754-2.616-1.251-4.365-1.251-1.748 0-3.288.497-4.365 1.25zM5.09 13.44v.001h-.001a1 1 0 00.77 1.552l.063-.998-.062.998h.01l.017.001a3.704 3.704 0 00.246.006c.16 0 .382-.004.65-.023a8.501 8.501 0 002.014-.388c1.414-.465 3.035-1.503 3.549-3.667C14.418 9.94 16 8.176 16 5.963c0-1.766-1.024-3.262-2.472-4.276C12.077.671 10.125.075 8.016.075c-2.108 0-4.06.596-5.511 1.612C1.056 2.701.032 4.197.032 5.963c0 1.62.864 3.017 2.124 4.016 1.01.803 2.31 1.38 3.752 1.668-.1.34-.23.664-.363.95a7.943 7.943 0 01-.452.837l-.004.006z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.502 5.87C3.877 7.006 3 8.482 3 10c0 1.395.739 2.75 2.12 3.846 1.382 1.096 3.358 1.884 5.64 2.097a1 1 0 01.903 1.078c-.106 1.287-.542 2.501-.945 3.39a9.279 9.279 0 001.639-.37c1.666-.548 3.256-1.667 3.582-4.083a1 1 0 01.621-.796C19.382 14.043 21 12.043 21 10c0-1.517-.877-2.993-2.502-4.13C16.875 4.733 14.58 4 12 4c-2.581 0-4.876.734-6.498 1.87zm2.666 15.075l.009-.013.039-.062a11.808 11.808 0 00.637-1.19c.253-.545.505-1.198.665-1.883-2.188-.363-4.15-1.201-5.641-2.385C2.148 14.041 1 12.155 1 10c0-2.349 1.361-4.373 3.355-5.769C6.353 2.833 9.06 2 12 2s5.647.833 7.645 2.231C21.639 5.627 23 7.651 23 10c0 3.007-2.204 5.454-5.177 6.799-.637 3.044-2.86 4.49-4.841 5.142a11.74 11.74 0 01-2.781.535 11.798 11.798 0 01-1.23.024l-.022-.001H8.94L9 21.5l-.062.998a1 1 0 01-.77-1.552z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeCartoonIcon.displayName = "ContentTypeCartoonIcon";
