import { ModifierArguments, detectOverflow } from "@popperjs/core";

export const modifiers = {
  maxSize: {
    name: "maxSize",
    enabled: true,
    phase: "beforeWrite" as const,
    requiresIfExists: ["offset", "preventOverflow", "flip"],
    fn({ state, name, options }: ModifierArguments<Record<string, never>>) {
      let overflow = detectOverflow(state, options);
      let { x, y } = state.modifiersData.preventOverflow ?? {
        x: 0,
        y: 0,
      };
      let { width, height } = state.rects.popper;
      let [basePlacement] = state.placement.split("-");
      let widthProp: "left" | "right" =
        basePlacement === "left" ? "left" : "right";
      let heightProp: "top" | "bottom" =
        basePlacement === "top" ? "top" : "bottom";

      state.modifiersData[name] = {
        width: width - overflow[widthProp] - x - 16,
        height: height - overflow[heightProp] - y - 16,
      };
    },
  },
  applyMaxSize: {
    name: "applyMaxSize",
    enabled: true,
    phase: "beforeWrite" as const,
    requires: ["maxSize"],
    fn({ state }: ModifierArguments<Record<string, never>>) {
      const { width, height } = state.modifiersData.maxSize;
      state.styles.popper.maxHeight = `${Math.min(height, 35 * 16)}px`;
      state.styles.popper.maxWidth = `${width}px`;
    },
  },
};
