import { useContext, SVGAttributes } from "react";
import { ThemeContext } from "styled-components";

export const CNEIcon = (props: SVGAttributes<SVGElement>) => {
  let theme = useContext(ThemeContext);
  if (theme?._name.startsWith("dark")) {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path fill="#fff" d="M5 1h3.429v28H5z" />
        <path
          d="M24.833 1v2.917L5 4.429V1h19.833zM25 26.083V29H5v-3.429l20 .512zM21.12 10.714l1.686 2.774-8.473 5.012-1.686-2.774 8.473-5.012z"
          fill="#fff"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path fill="#171717" d="M5 1h3.429v28H5z" />
        <path
          d="M24.833 1v2.917L5 4.429V1h19.833zM25 26.083V29H5v-3.429l20 .512zM21.12 10.714l1.686 2.774-8.473 5.012-1.686-2.774 8.473-5.012z"
          fill="#171717"
        />
      </svg>
    );
  }
};
CNEIcon.displayName = "CNEIcon";
