import { SVGAttributes } from "react";

export const ContentTypeSpaIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm2 0v6h6V8H5z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.705 7.826l-2.011-.222C6.14 7.19 5.337 6.369 5.08 5.189a3.385 3.385 0 01.197-2.072c.298-.696.804-1.264 1.417-1.72L7.889.505l1.195.89c.547.408 1.49 1.277 1.809 2.493.183.701.161 1.55-.309 2.357-.441.758-1.146 1.252-1.879 1.58zm.266-3.374c-.127-.58-.65-1.13-1.082-1.452-.51.38-.765.772-.853 1.144a1.366 1.366 0 00-.029.452c.061.61.512 1.129.882 1.404.843-.377 1.127-.855 1.11-1.329a1.165 1.165 0 00-.028-.219zm-1.926.517v-.002.002z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g
          clipPath="url(#clip0_1871_93)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        >
          <path d="M5 11a2 2 0 012-2h10a2 2 0 012 2v11a2 2 0 01-2 2H7a2 2 0 01-2-2V11zm2 0v11h10V11H7zM11.634 8.305l-.94-.701C10.14 7.19 9.337 6.369 9.08 5.189a3.385 3.385 0 01.197-2.072c.298-.696.804-1.264 1.417-1.72l1.195-.891 1.195.89c.547.408 1.49 1.277 1.809 2.493.183.701.161 1.55-.309 2.357-.441.758-1.146 1.252-1.878 1.58l-1.072.479zm1.337-3.853c-.127-.58-.65-1.13-1.082-1.452-.51.38-.765.772-.853 1.144a1.369 1.369 0 00-.029.452c.061.61.512 1.129.882 1.404.843-.377 1.127-.855 1.11-1.329a1.198 1.198 0 00-.028-.219zm-1.925.517l-.001-.002v.002z" />
        </g>
        <defs>
          <clipPath id="clip0_1871_93">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
ContentTypeSpaIcon.displayName = "ContentTypeSpaIcon";
