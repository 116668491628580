import { ThemeProvider } from "../../contexts";
import { ComponentProps } from "react";
import { withTooltip } from "../withTooltip";
import styled from "styled-components";

export const ChipDiv = withTooltip(
  styled.div<{ $size: "small" | "regular" }>`
    display: inline-flex;
    align-items: center;
    line-height: var(--spacing-sm);
    color: ${(props) => props.theme.Color};
    background-color: ${(props) => props.theme.Background};
    border-radius: ${(props) =>
      props.$size === "small" ? "var(--spacing-sm)" : "var(--spacing-md)"};
    flex-shrink: 0;
    flex-grow: 0;
    white-space: nowrap;
    padding: ${(props) =>
      props.$size === "small"
        ? "var(--spacing-xxs) var(--spacing-xs)"
        : "var(--spacing-xxs) var(--spacing-sm)"};
    font: ${(props) =>
      props.$size === "small"
        ? props.theme.FontSmallStatement
        : props.theme.FontBody};
  `
);

export function Chip(
  props: ComponentProps<typeof ChipDiv> & { size?: "small" | "regular" }
) {
  let { size, ...forwardProps } = props;
  return (
    <ThemeProvider theme="dark">
      <ChipDiv {...forwardProps} $size={size} />
    </ThemeProvider>
  );
}

Chip.displayName = "Chip";
