import { SVGAttributes } from "react";

export const HomeIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 7h10v8H3z"
        />
        <rect x={5} y={9} width={3} height={4} rx={1} fill="currentColor" />
        <path
          d="M14 6L8 1 2 6"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 11a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2H7a2 2 0 01-2-2v-8zm2 0v8h10v-8H7z"
          fill="currentColor"
        />
        <path
          d="M9 14a1 1 0 011-1h1a1 1 0 011 1v2a1 1 0 01-1 1h-1a1 1 0 01-1-1v-2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.274 3.244a1 1 0 011.309 0l6.928 6a1 1 0 01-1.31 1.512l-6.273-5.433-6.273 5.433a1 1 0 11-1.31-1.512l6.929-6z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
HomeIcon.displayName = "HomeIcon";
