import { Card } from "../Card";
import { ReactNode } from "react";
import styled from "styled-components";

const Section = styled(Card).attrs({ as: "section" })`
  margin-block-start: var(--spacing-md);
`;

const Header = styled.div`
  display: grid;
  gap: var(--spacing-xxs);
  padding-bottom: var(--spacing-sm);
`;

const Title = styled.h2`
  font: ${(props) => props.theme.FontSubSectionHeading};
  color: ${(props) => props.theme.Color};
`;

const Description = styled.p`
  font: ${(props) => props.theme.FontStatement};
  color: ${(props) => props.theme.SubheadingColor};
`;

const Banner = styled.div`
  text-align: right;
  color: var(--color-gray-4);
  font: ${(props) => props.theme.FontSmallStatement};
`;

export function Fieldset(props: {
  name: string;
  title?: ReactNode;
  bannerText?: ReactNode;
  description?: ReactNode;
  children: ReactNode;
}) {
  return (
    <Section id={`§${props.name}`}>
      {props.bannerText ? <Banner>{props.bannerText}</Banner> : <></>}
      {(props.title || props.description) && (
        <Header>
          {props.title && <Title>{props.title}</Title>}
          {props.description && <Description>{props.description}</Description>}
        </Header>
      )}
      {props.children}
    </Section>
  );
}

Fieldset.displayName = "Fieldset";
