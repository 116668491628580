import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { ReactNode } from "react";
import { Button } from "../Button";
import { ChevronDownIcon, ChevronUpIcon } from "../../icons";

const Body = styled.div`
  a {
    display: block;
    color: ${(props) => props.theme.Color};
    text-decoration: none;

    &:hover {
      background: ${(props) => props.theme.SelectOptionHoverBackground};
    }
  }
`;
Body.displayName = "TableBody";

const Cell = styled.div.attrs({ role: "cell" })`
  font: ${(props) => props.theme.FontBody};
  display: flex;
  align-items: center;
  &:first-child {
    padding-left: var(--spacing-sm);
  }
  &:last-child {
    padding-right: var(--spacing-sm);
  }
`;
Cell.displayName = "TableCell";

const Head = styled.div.attrs({ role: "rowgroup" })`
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;
Head.displayName = "TableHead";

const ColumnHeader = styled.div.attrs({ role: "columnheader" })`
  display: flex;
  font: ${(props) => props.theme.FontLabel};
  color: ${(props) => props.theme.Color};
  text-decoration: none;
  min-height: var(--spacing-xxl);
  align-items: center;
  &:first-child {
    padding-left: var(--spacing-sm);
  }
  &:last-child {
    padding-right: var(--spacing-sm);
  }
`;
ColumnHeader.displayName = "Table(ColumnHeader)";

const SortButton = styled(Button).attrs({ treatment: "borderless" })`
  font: ${(props) => props.theme.FontLabel};
  color: ${(props) => props.theme.Color};
  svg {
    margin-left: 8px;
    color: ${(props) => props.theme.PrimaryBackground};
  }
`;

type Direction = "ascending" | "descending" | "none";

const SortableHeader = ({
  activeDirection = "none",
  children,
  onSort = () => {},
}: {
  children: ReactNode;
  activeDirection?: Direction;
  onSort?: (activeDirection: Direction) => void;
}) => {
  const directionIcon = {
    ascending: <ChevronDownIcon size="small" />,
    descending: <ChevronUpIcon size="small" />,
    none: "",
  };

  return (
    <ColumnHeader aria-sort={activeDirection}>
      <SortButton onClick={() => onSort(activeDirection)}>
        {children}
        {directionIcon[activeDirection]}
      </SortButton>
    </ColumnHeader>
  );
};
SortableHeader.displayName = "SortableHeader";

function Header(props: {
  activeDirection?: Direction;
  children: ReactNode;
  onSort?: (activeDirection: Direction) => void;
  sort?: string | boolean;
}) {
  let { sort, ...forwardProps } = props;
  if (sort) {
    return <SortableHeader {...forwardProps} />;
  } else {
    return <ColumnHeader {...forwardProps} />;
  }
}
Header.displayName = "TableHeader";

const Row = styled.div.attrs({ role: "row" })`
  &,
  a& {
    display: grid;
    gap: var(--spacing-sm);
    min-height: var(--spacing-xxl);
    align-content: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.DividerColor};
    }
  }
`;
Row.displayName = "TableRow";

export const Table = styled.div.attrs<
  { gridTemplateColumns: string },
  { role: "table"; $gridTemplateColumns?: string }
>((props) => ({
  $gridTemplateColumns: props.gridTemplateColumns,
  role: "table",
}))`
  overflow: auto;
  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  z-index: ${(props) => props.theme.ElevationCard};
  box-shadow: ${(props) => props.theme.CardShadow};
  ${Row} {
    grid-template-columns: ${(props) => props.$gridTemplateColumns};
  }
` as StyledComponent<
  "div",
  DefaultTheme,
  { role: "table"; gridTemplateColumns: string },
  "role"
> & {
  Row: typeof Row;
  Cell: typeof Cell;
  Head: typeof Head;
  Header: typeof Header;
  Body: typeof Body;
};
Table.displayName = "Table";

Table.Row = Row;
Table.Cell = Cell;
Table.Head = Head;
Table.Header = Header;
Table.Body = Body;
