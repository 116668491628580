import { SVGAttributes } from "react";

export const RadioSelectedIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 2a9 9 0 100-18 9 9 0 000 18z"
        fill="currentColor"
      />
      <path d="M17 12a5 5 0 11-10 0 5 5 0 0110 0z" fill="currentColor" />
    </svg>
  );
};
RadioSelectedIcon.displayName = "RadioSelectedIcon";
