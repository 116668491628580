import { ReactNode, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { AddIcon, CloseIcon, DraggableIcon } from "../../icons";
import { FormattedMessage } from "react-intl";
import { DraggableList, Button } from "../index";
import { ReplicatorItem } from "./types";

const RemoveButton = styled(Button)`
  grid-area: remove;
  width: var(--spacing-lg);
  height: var(--spacing-lg);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-self: center;
  color: ${(props) => props.theme.FieldColor};
  & svg {
    margin: auto;
  }
`;

const GrabPointerContainer = styled.div`
  cursor: grab;
  display: flex;
  align-items: center;
`;

const AddButton = styled(Button)<{ $treatment?: string }>`
  color: ${(props) => props.theme.SecondaryColor};
  background: ${(props) => props.theme.Background};

  ${({ $treatment }) =>
    ($treatment === "contact" || $treatment === "curatedList") &&
    css`
      padding-top: var(--spacing-xxs);
    `}
`;

const ReplicatorItemChildren = styled.div`
  grid-area: body;
`;

const Wrapper = styled.div<{
  $treatment?: string;
}>`
  display: grid;
  grid-template-areas: "drag body remove";
  grid-template-columns: auto 1fr auto;
  gap: var(--spacing-xs);

  ${(props) => {
    switch (props.$treatment) {
      case "social":
        return css`
          ${ReplicatorItemChildren} {
            display: grid;
            grid-template-columns: 1fr 3fr;
            * {
              border-radius: 0px;
              &:focus {
                z-index: 1;
              }
            }
            > :first-child * {
              border-top-left-radius: ${props.theme.CornerRadius};
              border-bottom-left-radius: ${props.theme.CornerRadius};
            }
            > :last-child * {
              border-top-right-radius: ${props.theme.CornerRadius};
              border-bottom-right-radius: ${props.theme.CornerRadius};
            }
          }
        `;
      case "contact":
        return css`
          ${ReplicatorItemChildren} {
            display: grid;
            grid-template-columns: 1fr;

            label {
              display none;
            }
          }
        `;
      case "curatedList":
        return css`
          & label {
            display none;
          }
        `;
      case "itemsReview":
        return css`
          padding-bottom: var(--spacing-sm);
          ${ReplicatorItemChildren} > * {
            box-shadow: none;
            border: 1px solid ${(props) => props.theme.DividerColor};
          }
        `;
      default:
        return css`
          grid-template-areas:
            "body remove"
            "body drag"
            "body .";
          grid-template-columns: 1fr auto;

          border: 1px solid ${(props) => props.theme.DividerColor};
          border-radius: ${props.theme.CornerRadius};
          padding: var(--spacing-xs);
          margin-bottom: var(--spacing-sm);

          ${GrabPointerContainer} {
            padding: var(--spacing-xxs);
            visibility: hidden;
          }

          &:hover ${GrabPointerContainer} {
            visibility: visible;
          }
        `;
    }
  }}
`;

const ButtonWrapper = styled.div<{ $treatment?: string }>`
  ${({ $treatment }) =>
    ($treatment === "contact" || $treatment === "curatedList") &&
    css`
      padding-top: var(--spacing-xxs);
    `}
`;

const ReplicatorLabel = styled.label`
  display: inline-block;
  color: var(--color-gray-1);
  font: var(--font-label);
  line-height: var(--spacing-md);
`;

function ReplicatorItems<T>(props: {
  item: ReplicatorItem<T>;
  index: number;
  sortable: boolean | null;
  treatment: string | null;
  children: (childProps: {
    item: ReplicatorItem<T>;
    onChange: (item: ReplicatorItem<T>) => void;
  }) => ReactNode;
  onItemChange: (index: number) => (item: ReplicatorItem<T>) => void;
  remove: (index: number) => () => void;
}) {
  const { item, index, sortable, treatment, children, onItemChange, remove } =
    props;
  return (
    <Wrapper $treatment={treatment || "default"}>
      {sortable === true ? (
        <GrabPointerContainer id={"grab_" + item._key}>
          <DraggableIcon size={"regular"} />
        </GrabPointerContainer>
      ) : (
        <></>
      )}
      <ReplicatorItemChildren>
        {children({
          item,
          onChange: onItemChange(index),
        })}
      </ReplicatorItemChildren>

      <RemoveButton
        id={"remove_" + item._key}
        size="small"
        treatment="borderless"
        onClick={() => {
          remove(index)();
        }}
      >
        <CloseIcon size="small" />
      </RemoveButton>
    </Wrapper>
  );
}

//rep component
export function Replicator<T>(props: {
  items: ReplicatorItem<T>[];
  children: (childProps: {
    item: ReplicatorItem<T>;
    onChange: (item: ReplicatorItem<T>) => void;
  }) => ReactNode;
  onChange: (items: ReplicatorItem<T>[]) => void;
  add: () => void;
  label: string | null;
  treatment: string | null;
  sortable: boolean | null;
}) {
  const {
    items,
    children,
    onChange,
    add,
    label,
    treatment,
    sortable = false,
  } = props;

  const onItemChange = useCallback(
    (index) => {
      return (item: ReplicatorItem<T>) => {
        onChange([...items.slice(0, index), item, ...items.slice(index + 1)]);
      };
    },
    [items, onChange]
  );

  const remove = useCallback(
    (index) => {
      return () =>
        onChange([...items.slice(0, index), ...items.slice(index + 1)]);
    },
    [items, onChange]
  );

  const moveItem = useCallback(
    (
      data: {
        index: number;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        let itemsToMove = [...items];
        let sourceElement = itemsToMove[data.index];
        itemsToMove.splice(data.index, 1);
        itemsToMove.splice(dropIndex, 0, sourceElement);
        onChange([...itemsToMove]);
      }
    },
    [items, onChange]
  );

  const replicatorItems = useMemo(
    () =>
      items.map((item: ReplicatorItem<T>) => {
        return {
          key: item._key ?? "",
          value: item,
        };
      }),
    [items]
  );

  return (
    <div>
      {label && <ReplicatorLabel>{label}</ReplicatorLabel>}
      {sortable ? (
        <DraggableList items={replicatorItems} onMove={moveItem}>
          {(item: ReplicatorItem<T>, index: number) => (
            <ReplicatorItems
              item={item}
              index={index}
              sortable={true}
              treatment={treatment}
              onItemChange={onItemChange}
              remove={remove}
              key={item._key}
            >
              {children}
            </ReplicatorItems>
          )}
        </DraggableList>
      ) : (
        <ul>
          {items?.map((item, index) => (
            <li key={"child_" + item._key} id={"child_" + item._key}>
              <ReplicatorItems
                item={item}
                index={index}
                sortable={false}
                treatment={treatment}
                onItemChange={onItemChange}
                remove={remove}
                key={item._key}
              >
                {children}
              </ReplicatorItems>
            </li>
          ))}
        </ul>
      )}
      <ButtonWrapper $treatment={treatment || "default"}>
        <AddButton
          id="addItem"
          onClick={() => {
            add();
          }}
        >
          <AddIcon size="regular" />
          <FormattedMessage defaultMessage={"Add Item"} />
        </AddButton>
      </ButtonWrapper>
    </div>
  );
}
Replicator.displayName = "Replicator";
