import { SVGAttributes } from "react";

export const AssetSmallIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7H8a2 2 0 00-2 2v6a2 2 0 002 2h8a2 2 0 002-2V9a2 2 0 00-2-2zm-8 8V9h8v6H8z"
        fill="currentColor"
      />
    </svg>
  );
};
AssetSmallIcon.displayName = "AssetSmallIcon";
