import { SVGAttributes } from "react";

export const CopyIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 12h8a1 1 0 001-1V2a1 1 0 00-1-1H6a1 1 0 00-1 1v9a1 1 0 001 1zm7-9v7H7V3h6z"
        fill="currentColor"
      />
      <path
        d="M4 13h7a1 1 0 110 2H3a1 1 0 01-1-1V5a1 1 0 012 0v8z"
        fill="currentColor"
      />
    </svg>
  );
};
CopyIcon.displayName = "CopyIcon";
