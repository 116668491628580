import { ReactNode } from "react";
import styled from "styled-components";
import { useMediaQuery } from "../../hooks";
import { Field } from "../Field";
import { NativeTimePicker } from "./NativeTimePicker";
import { PrettyTimePicker } from "./PrettyTimePicker";

const Wrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

export function TimePicker(props: {
  id: string;
  date: Date;
  children?: ReactNode;
  label?: string;
  onChange: (date: Date) => void;
}) {
  const { date, onChange, ...forwardProps } = props;
  const isTouchDevice = useMediaQuery("(hover: none), (hover: on-demand)");
  return isTouchDevice ? (
    <Wrapper {...forwardProps}>
      <NativeTimePicker date={date} onChange={onChange} {...forwardProps} />
    </Wrapper>
  ) : (
    <Wrapper {...forwardProps}>
      <PrettyTimePicker date={date} onChange={onChange} {...forwardProps} />
    </Wrapper>
  );
}
TimePicker.displayName = "TimePicker";
