import { useContext, SVGAttributes } from "react";
import { ThemeContext } from "styled-components";

export const CopilotRainbowIcon = (props: SVGAttributes<SVGElement>) => {
  let theme = useContext(ThemeContext);
  if (theme?._name.startsWith("dark")) {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_2132_2)">
          <path
            d="M15.026 27.343a3.455 3.455 0 01-.484-.148c-.445-.17-1.124-.517-1.828-1.206-.874-.854-1.585-2.029-2.112-3.49-.726-2.01-1.094-4.534-1.094-7.499 0-6.271 1.598-9.322 2.939-10.777.544-.59 1.148-1.035 1.794-1.32.242-.11.495-.193.754-.247 5.908.083 10.95 4.246 12.083 9.978 1.133 5.732-1.95 11.465-7.391 13.742a12.52 12.52 0 01-4.66.967h-.001zm-.2 2.657C23.204 30 30 23.283 30 15c0-8.283-6.795-15-15.175-15 0 0-8.003.504-8.003 15 0 14.336 8.003 15 8.003 15z"
            fill="url(#paint0_linear_2132_2)"
          />
          <path
            d="M14.973 27.343C9.072 27.246 4.045 23.078 2.92 17.35 1.794 11.623 4.878 5.898 10.313 3.624a12.522 12.522 0 014.692-.97c.26.055.513.138.754.247.646.286 1.25.73 1.794 1.321.813.882 1.471 2.071 1.956 3.531.652 1.967.983 4.405.983 7.247 0 2.81-.331 5.227-.985 7.185-.487 1.457-1.148 2.65-1.967 3.543-.552.601-1.164 1.058-1.82 1.358-.239.111-.489.198-.746.258zM15.174 30s8.004-.664 8.004-15c0-14.496-8.004-15-8.004-15C6.794 0 0 6.717 0 15c0 8.283 6.795 15 15.175 15z"
            fill="url(#paint1_linear_2132_2)"
          />
          <path
            d="M15.01 2.661a3.999 3.999 0 00-.025-.006h-.001a12.52 12.52 0 00-5.73 1.475C11.447.534 14.393.053 14.791.004a15.6 15.6 0 01.22-.004c.073 0 .146.002.22.004.398.05 3.35.531 5.543 4.137a12.572 12.572 0 00-5.737-1.486 2.99 2.99 0 00-.026.006zM15 27.343l.006.001a12.522 12.522 0 005.824-1.51c-2.18 3.655-5.147 4.111-5.589 4.16l-.23.004c-.077 0-.154-.002-.23-.004-.443-.049-3.415-.506-5.596-4.171A12.57 12.57 0 0015 27.343z"
            fill="#fff"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2132_2"
            x1={18.411}
            y1={30}
            x2={18.411}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E6EE00" />
            <stop offset={0.594} stopColor="#FF4561" />
            <stop offset={1} stopColor="#8145FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2132_2"
            x1={11.589}
            y1={30}
            x2={11.589}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3FF200" />
            <stop offset={0.666} stopColor="#56CCFF" />
            <stop offset={1} stopColor="#0044F3" />
          </linearGradient>
          <clipPath id="clip0_2132_2">
            <path fill="#fff" d="M0 0h30v30H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_2132_0)">
          <path
            d="M15.026 27.343a3.455 3.455 0 01-.484-.148c-.445-.17-1.124-.517-1.828-1.206-.874-.854-1.585-2.029-2.112-3.49-.726-2.01-1.094-4.534-1.094-7.499 0-6.271 1.598-9.322 2.939-10.777.544-.59 1.148-1.035 1.794-1.32.242-.11.495-.193.754-.247 5.908.083 10.95 4.246 12.083 9.978 1.133 5.732-1.95 11.465-7.391 13.742a12.52 12.52 0 01-4.66.967h-.001zm-.2 2.657C23.204 30 30 23.283 30 15c0-8.283-6.795-15-15.175-15 0 0-8.003.504-8.003 15 0 14.336 8.003 15 8.003 15z"
            fill="url(#paint0_linear_2132_0)"
          />
          <path
            d="M14.973 27.343C9.072 27.246 4.045 23.078 2.92 17.35 1.794 11.623 4.878 5.898 10.313 3.624a12.522 12.522 0 014.692-.97c.26.055.513.138.754.247.646.286 1.25.73 1.794 1.321.813.882 1.471 2.071 1.956 3.531.652 1.967.983 4.405.983 7.247 0 2.81-.331 5.227-.985 7.185-.487 1.457-1.148 2.65-1.967 3.543-.552.601-1.164 1.058-1.82 1.358-.239.111-.489.198-.746.258zM15.174 30s8.004-.664 8.004-15c0-14.496-8.004-15-8.004-15C6.794 0 0 6.717 0 15c0 8.283 6.795 15 15.175 15z"
            fill="url(#paint1_linear_2132_0)"
          />
          <path
            d="M14.99 27.338a1.2 1.2 0 00.025.006h.001a12.52 12.52 0 005.73-1.474c-2.193 3.596-5.139 4.077-5.537 4.126a16.01 16.01 0 01-.22.004c-.073 0-.146-.002-.22-.004-.398-.05-3.35-.531-5.543-4.137 1.72.92 3.675 1.451 5.737 1.485.009-.002.018-.003.026-.006zM15 2.659l-.006-.002a12.521 12.521 0 00-5.824 1.51C11.35.511 14.317.055 14.76.006l.23-.004c.077 0 .154.002.23.004.443.049 3.415.506 5.596 4.171A12.571 12.571 0 0015 2.657z"
            fill="#DE00A0"
          />
          <path
            d="M14.99 27.338a1.2 1.2 0 00.025.006h.001a12.52 12.52 0 005.73-1.474c-2.193 3.596-5.139 4.077-5.537 4.126a16.01 16.01 0 01-.22.004c-.073 0-.146-.002-.22-.004-.398-.05-3.35-.531-5.543-4.137 1.72.92 3.675 1.451 5.737 1.485.009-.002.018-.003.026-.006zM15 2.659l-.006-.002a12.521 12.521 0 00-5.824 1.51C11.35.511 14.317.055 14.76.006l.23-.004c.077 0 .154.002.23.004.443.049 3.415.506 5.596 4.171A12.571 12.571 0 0015 2.657z"
            fill="url(#paint2_linear_2132_0)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2132_0"
            x1={18.411}
            y1={30}
            x2={18.411}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E6EE00" />
            <stop offset={0.594} stopColor="#FF4561" />
            <stop offset={1} stopColor="#8145FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2132_0"
            x1={11.589}
            y1={30}
            x2={11.589}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3FF200" />
            <stop offset={0.666} stopColor="#56CCFF" />
            <stop offset={1} stopColor="#0044F3" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2132_0"
            x1={14.992}
            y1={30}
            x2={14.992}
            y2={0.002}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00A638" />
            <stop offset={1} stopColor="#fff" stopOpacity={0} />
          </linearGradient>
          <clipPath id="clip0_2132_0">
            <path
              fill="#fff"
              transform="rotate(-180 15 15)"
              d="M0 0h30v30H0z"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
CopilotRainbowIcon.displayName = "CopilotRainbowIcon";
