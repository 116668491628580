import { FC, ReactNode, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { ContextualHelp, Label } from "../index";
import computeScrollIntoView from "compute-scroll-into-view";

const FieldWrapper = styled.div`
  &.hasError {
    margin-bottom: var(--spacing-xs);
  }
`;

const LabelGroup = styled.div`
  grid-area: label;
  display: inline-flex;
`;

const FieldLabel = styled(Label)`
  pointer-events: none;
`;

const Message = styled.label`
  grid-area: message;
  display: grid;
  grid-template-columns: 1fr auto;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
  pointer-events: none;

  .hasError p {
    color: ${(props) => props.theme.PrimaryBackground};
    margin-bottom: var(--spacing-xs);
  }
`;

const Counter = styled.span`
  display: block;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
  pointer-events: none;
`;

function Messages(props: {
  htmlFor: string;
  message?: ReactNode;
  counter?: ReactNode;
  errors?: { name: string; message: string }[];
}) {
  let { htmlFor, counter, message, errors } = props;

  if (errors?.length) {
    return (
      <Message htmlFor={htmlFor}>
        <div>
          {errors.map(({ name, message }) => {
            return <p key={name}>{message}</p>;
          })}
        </div>
        {counter && <Counter>{counter}</Counter>}
      </Message>
    );
  }

  if (message || counter) {
    return (
      <Message htmlFor={htmlFor}>
        <div>{message}</div>
        {counter && <Counter>{counter}</Counter>}
      </Message>
    );
  }

  return null;
}

export const Field: FC<{
  id: string;
  className?: string;
  label?: ReactNode;
  help?: ReactNode;
  message?: ReactNode;
  counter?: ReactNode;
  errors?: {
    path: readonly string[];
    message: { name: string; message: string };
    isActive?: boolean;
  }[];
  children: ReactNode;
}> = (props) => {
  const hasError = props.errors?.length ? "hasError" : "";
  const className = [props.className, hasError].join(" ");
  const hasActiveError = props.errors?.some((error) => error.isActive);
  const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (hasActiveError && fieldRef) {
      computeScrollIntoView(fieldRef, {
        scrollMode: "always",
        block: "start",
        inline: "start",
      }).forEach(({ el, top, left }) => {
        // compute-scroll-into-view does not take scroll-padding or scroll-margin
        // into account yet (like element.scrollIntoView does) so adjust here
        const scrollPaddingTop =
          parseInt(getComputedStyle(el).scrollPaddingTop, 10) || 0;
        el.scrollTop = Math.max(top - scrollPaddingTop, 0);
        el.scrollLeft = left;
      });
    }
  }, [fieldRef, hasActiveError]);

  return (
    <FieldWrapper className={className} ref={setFieldRef}>
      {props.label && (
        <LabelGroup>
          <FieldLabel htmlFor={props.id}>{props.label}</FieldLabel>
          {props.help && (
            <ContextualHelp id={`${props.id}-help`}>
              {props.help}
            </ContextualHelp>
          )}
        </LabelGroup>
      )}
      {props.children}
      <div aria-live="polite">
        <Messages
          htmlFor={props.id}
          {...props}
          errors={props.errors?.map((error) => error.message)}
        />
      </div>
    </FieldWrapper>
  );
};
