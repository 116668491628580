import { useUniqueId } from "../../hooks";
import { RadioSelectedIcon, RadioUnselectedIcon } from "../../icons";
import { ComponentPropsWithoutRef, ReactNode, useCallback } from "react";
import styled from "styled-components";

const RadioIconLabel = styled.label`
  position: relative;
  height: 24px;
  width: 24px;
`;

const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;

  & + * {
    border-radius: 50%;
  }

  &:not(:disabled) + * {
    color: ${(props) => props.theme.ControlIconColor};
  }

  &:not(:disabled):checked + * {
    color: ${(props) => props.theme.ControlOnBackground};
  }

  &:disabled + * {
    color: ${(props) => props.theme.ControlDisabledBackground};
  }

  &:focus + * {
    box-shadow: inset ${(props) => props.theme.FocusRing};
  }
`;

const RadioInputLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  font: ${(props) => props.theme.FontSmallStatement};
`;

const RadioContainer = styled.div<{ $direction: "row" | "column" }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => props.$direction};
  row-gap: var(--spacing-xxs);
  column-gap: var(--spacing-sm);
`;

type RadioOption = ComponentPropsWithoutRef<"input"> & {
  value: string;
  label?: ReactNode;
  name?: undefined;
  type?: "radio";
};

export function RadioGroup(
  props: Omit<ComponentPropsWithoutRef<"div">, "onChange"> & {
    value?: string;
    onChange: (value: string) => void;
    direction?: "row" | "column";
    options: Array<RadioOption>;
  }
) {
  let { onChange, direction, options, value, ...forwardProps } = props;
  let sharedId = useUniqueId();
  let name = `radio_${sharedId}`;

  let radioChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <RadioContainer
      role="radiogroup"
      $direction={direction || "row"}
      {...forwardProps}
    >
      {options.map((option) => {
        let { label: optionLabel, ...inputProps } = option;

        let optionId = `${name}_${option.value}`;
        let isChecked = option.value === value;
        return (
          <RadioInputLabel htmlFor={optionId} key={optionId}>
            <RadioIconLabel>
              <RadioInput
                {...inputProps}
                id={optionId}
                type="radio"
                role="radio"
                name={name}
                checked={isChecked}
                onChange={radioChange}
              />
              {isChecked ? (
                <RadioSelectedIcon size="regular" />
              ) : (
                <RadioUnselectedIcon size="regular" />
              )}
            </RadioIconLabel>
            {optionLabel || option.value}
          </RadioInputLabel>
        );
      })}
    </RadioContainer>
  );
}

RadioGroup.displayName = "RadioGroup";
