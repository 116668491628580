import { SVGAttributes } from "react";

export const FilterIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3.60554 2.75H12.4033L7.9956 8.2625L3.60554 2.75ZM1.18616 2.40875C2.9633 4.675 6.24485 8.875 6.24485 8.875V14.125C6.24485 14.6062 6.64075 15 7.12463 15H8.88417C9.36805 15 9.76394 14.6062 9.76394 14.125V8.875C9.76394 8.875 13.0367 4.675 14.8138 2.40875C15.2625 1.83125 14.849 1 14.1188 1H1.88118C1.15097 1 0.737479 1.83125 1.18616 2.40875Z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_5185_51)">
          <path
            d="M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39A.998.998 0 0018.95 4H5.04c-.83 0-1.3.95-.79 1.61z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_5185_51">
            <path fill="currentColor" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
FilterIcon.displayName = "FilterIcon";
