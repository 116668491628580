import { SVGAttributes } from "react";

export const VersoSplitRightIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 19h8a1 1 0 001-1V6a1 1 0 00-1-1h-8a1 1 0 00-1 1v12a1 1 0 001 1zm7-12v10h-6V7h6z"
        fill="currentColor"
      />
      <path
        d="M10 16a1 1 0 01-1 1H3a1 1 0 110-2h6a1 1 0 011 1zM10 12a1 1 0 01-1 1H3a1 1 0 110-2h6a1 1 0 011 1zM10 8a1 1 0 01-1 1H3a1 1 0 010-2h6a1 1 0 011 1z"
        fill="currentColor"
      />
    </svg>
  );
};
VersoSplitRightIcon.displayName = "VersoSplitRightIcon";
