import styled from "styled-components";
import { CloseIcon } from "../../../icons";
import {
  ReactNode,
  cloneElement,
  ReactElement,
  useEffect,
  useState,
  MouseEvent,
  MouseEventHandler,
} from "react";
import { useFocusTrap, useUniqueId } from "../../../hooks";
import { ThemeProvider } from "../../../contexts";
import { Button } from "../../Button";

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background: var(--color-scrim);
  z-index: ${(props) => props.theme.ElevationModal};
`;

const Wrapper = styled.div<{
  $size?: "narrow" | "regular";
}>`
  position: fixed;
  z-index: ${(props) => props.theme.ElevationModal};
  border-radius: ${(props) =>
    `0 0 ${props.theme.CornerRadius} ${props.theme.CornerRadius}`};
  top: 0;
  background: ${(props) => props.theme.ToastBackground};
  margin: 0 auto;
  width: 100%;
  max-height: calc(
    100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top)
  );
  overflow: auto;

  max-width: ${(props) =>
    props.$size === "narrow"
      ? props.theme.NarrowDialogSize
      : props.theme.DialogSize}px;
  left: ${(props) =>
    props.$size === "narrow"
      ? `calc(50% - ${props.theme.NarrowDialogSize / 2}px)`
      : `calc(50% - ${props.theme.DialogSize / 2}px)`};

  @media (max-width: ${(props) =>
      props.$size === "narrow"
        ? props.theme.NarrowDialogSize
        : props.theme.DialogSize}px) {
    display: grid;
    grid-template-areas: "header" "body" "footer";
    grid-template-rows: auto 1fr auto;
    left: 0;
    bottom: 0;
    top: auto;
    max-width: initial;
    max-height: initial;
    border-radius: ${(props) =>
      `${props.theme.CornerRadius} ${props.theme.CornerRadius} 0 0`};
  }
`;

const DialogHeader = styled.div`
  grid-area: header;
  display: grid;
  grid-template-areas:
    "title close"
    "description description";
  grid-template-columns: 1fr auto;
  padding: var(--spacing-sm) var(--spacing-md);
`;

const CloseButton = styled.div`
  grid-area: close;
`;

const DialogTitle = styled.div`
  grid-area: title;
  font: ${(props) => props.theme.FontSectionHeading};
  padding-bottom: var(--spacing-xs);
`;

const Description = styled.div`
  grid-area: description;
  font: ${(props) => props.theme.FontStatement};
  color: ${(props) => props.theme.SupportColor};
`;

const DialogBody = styled.div`
  grid-area: body;
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const DialogFooter = styled.div<{ $gridTemplateAreas: string }>`
  grid-area: footer;
  display: grid;
  grid-template-areas: ${(props) => `"${props.$gridTemplateAreas}"`};
  grid-template-columns: auto 1fr auto auto;
  gap: var(--spacing-xs);
  justify-content: flex-end;
  padding: var(--spacing-sm) var(--spacing-md);
`;

const DeleteButton = styled(Button)`
  grid-area: delete;
`;

const CancelButton = styled(Button)`
  grid-area: cancel;
`;

const SubmitButton = styled(Button)`
  grid-area: submit;
`;

export function AlertDialog(props: {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  size?: "narrow" | "regular";
  onClose: () => unknown;
  deleteButton?: ReactElement<typeof Button>;
  cancelButton?: ReactElement<typeof Button>;
  submitButton?: ReactElement<typeof Button>;
}) {
  let id = useUniqueId();
  let [element, setElement] = useState<HTMLElement | null>(null);
  useFocusTrap(element, props.onClose);
  useEffect(() => {
    if ("Intercom" in window) {
      Intercom("update", {
        hide_default_launcher: true,
      });
    }
    return () => {
      if ("Intercom" in window) {
        Intercom("update", {
          hide_default_launcher: false,
        });
      }
    };
  });

  return (
    <Scrim
      onClick={(evt) => {
        props.onClose();
        evt.stopPropagation();
      }}
    >
      <Wrapper
        onClick={(evt) => evt.stopPropagation()}
        ref={setElement}
        role="alertdialog"
        aria-modal="true"
        $size={props.size}
        aria-labelledby={`DialogTitle-${id}`}
        aria-describedby={
          props.description ? `DialogDescription-${id}` : undefined
        }
      >
        <DialogHeader>
          <DialogTitle id={`DialogTitle-${id}`}>{props.title}</DialogTitle>
          <CloseButton>
            <Button
              aria-label="Close"
              size="medium"
              tabIndex={-1}
              aria-hidden="true"
              onClick={(evt) => {
                props.onClose();
                evt.preventDefault();
                evt.stopPropagation();
              }}
            >
              <CloseIcon size="regular" />
            </Button>
          </CloseButton>
          {props.description && (
            <Description id={`DialogDescription-${id}`}>
              {props.description}
            </Description>
          )}
        </DialogHeader>
        {props.children && <DialogBody>{props.children}</DialogBody>}
        {(props.deleteButton || props.cancelButton || props.submitButton) && (
          <DialogFooter
            $gridTemplateAreas={
              props.deleteButton && props.cancelButton && props.submitButton
                ? "delete . cancel submit"
                : props.deleteButton == null &&
                  props.cancelButton &&
                  props.submitButton
                ? ". . cancel submit"
                : ". . . submit"
            }
          >
            {props.deleteButton && (
              <ThemeProvider tint="red">
                {cloneElement(props.deleteButton as ReactElement, {
                  as: DeleteButton,
                })}
              </ThemeProvider>
            )}
            {props.cancelButton &&
              cloneElement(props.cancelButton as ReactElement, {
                as: CancelButton,
                onClick: (evt: MouseEvent) => {
                  if (props.cancelButton?.props.onClick) {
                    (props.cancelButton.props.onClick as MouseEventHandler)(
                      evt
                    );
                  }
                  props.onClose();
                  evt.preventDefault();
                  evt.stopPropagation();
                },
              })}
            {props.submitButton &&
              cloneElement(props.submitButton as ReactElement, {
                as: SubmitButton,
                treatment: "primary",
              })}
          </DialogFooter>
        )}
      </Wrapper>
    </Scrim>
  );
}
AlertDialog.displayName = "ARIA.AlertDialog";
