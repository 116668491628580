import { SVGAttributes } from "react";

export const USTeenVogueIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={164}
      height={24}
      viewBox="0 0 164 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M92.469.56v.233h2.016l-5.127 16.632L83.285.795h1.998V.56h-8.14v.233h2.142l8.408 22.99L94.92.793h1.94V.56H92.47zm38.017 0h8.164v.234h-2.165v17.727c0 2.776 2.86 4.467 5.362 4.467 2.693 0 4.961-1.874 4.961-4.626V.796h-2.561V.56h19.202v8.224h-.16c-.242-2.176-.659-4.41-2.157-6.108-1.515-1.715-3.573-1.882-5.688-1.882h-2.285v10.574c.47.05.946.084 1.422.084 2.87 0 3.614-1.974 3.74-4.417h.208v9.277h-.208c-.359-2.216-1.339-4.593-3.941-4.593-.409 0-.81.026-1.221.059v11.31h3.421c1.774 0 3.539-.77 4.794-2.008a7.294 7.294 0 002.075-3.906c.142-.828.208-1.672.251-2.51h.184v8.659h-16.472v-.235h1.915V.794h-2.177v17.634c0 3.648-3.463 5.262-6.709 5.262-3.672 0-7.723-1.78-7.723-5.982V.794h-2.231V.56h-.001z"
        fill="currentColor"
      />
      <path
        d="M108.123 12.029c0-3.322-1.189-11.62-5.68-11.62-4.509 0-5.68 8.29-5.68 11.62 0 3.329 1.18 11.628 5.68 11.628 4.493 0 5.68-8.308 5.68-11.628zM102.427 24c-2.703 0-5.288-1.515-6.96-3.58-1.908-2.352-2.837-5.388-2.837-8.391 0-2.887.853-5.79 2.619-8.09 1.74-2.258 4.357-3.873 7.269-3.873 2.677 0 5.246 1.532 6.894 3.572 1.898 2.351 2.844 5.396 2.844 8.4 0 2.876-.862 5.779-2.619 8.08-1.723 2.242-4.324 3.882-7.21 3.882z"
        fill="currentColor"
      />
      <path
        d="M76.235 23.686h-6.68L72.46 10.54c.059-.375.116-.731.174-1.063.057-.331.087-.673.087-1.019 0-.522-.095-.949-.282-1.28a2.18 2.18 0 00-.673-.76 2.636 2.636 0 00-.824-.369 3.147 3.147 0 00-.738-.109c-.52 0-1.012.117-1.474.347a4.688 4.688 0 00-1.217.868c-.549.55-.975 1.238-1.28 2.062-.309.842-.563 1.704-.76 2.58l-2.733 11.89h-6.68l5.075-22.346h6.727l-.651 2.69 1.02-1.02c.39-.39.845-.744 1.366-1.062a9.035 9.035 0 011.801-.825c.68-.23 1.453-.347 2.32-.347.839 0 1.635.123 2.387.37a5.64 5.64 0 011.974 1.105 5.13 5.13 0 011.324 1.823c.318.723.478 1.561.478 2.516 0 .405-.037.818-.11 1.237-.073.42-.137.831-.195 1.237l-3.34 14.621zM42.608 8.153c.262-.49.601-.954 1.02-1.388.434-.444.94-.81 1.497-1.085.579-.289 1.287-.433 2.127-.433.838 0 1.618.202 2.341.606.463.261.826.558 1.086.89.26.332.448.673.564 1.02.115.347.188.672.217.976.028.304.044.558.044.76h-9.546c.172-.405.39-.853.65-1.346zm-5.597 12.736c.868 1.026 1.996 1.858 3.384 2.495.245.111.502.21.766.302h8.69c.022-.007.047-.012.068-.02 1.23-.449 2.264-1.006 3.103-1.671.84-.666 1.525-1.373 2.062-2.127.534-.751.99-1.43 1.366-2.038l-5.598-1.996c-.173.29-.39.623-.65.998a5.506 5.506 0 01-2.582 1.974c-.622.246-1.366.368-2.235.368-1.591 0-2.791-.578-3.6-1.735a3.46 3.46 0 01-.63-1.562c-.073-.55-.108-1.273-.108-2.17h16.27c.115-.98.173-1.965.174-2.952 0-1.82-.297-3.383-.889-4.684-.593-1.301-1.368-2.357-2.321-3.168a9.126 9.126 0 00-3.298-1.778 13.139 13.139 0 00-3.818-.564c-2.229 0-4.137.433-5.728 1.3-1.591.869-2.9 1.954-3.927 3.256a13.506 13.506 0 00-2.277 4.229c-.493 1.52-.738 2.93-.738 4.231 0 1.274.202 2.554.607 3.84a9.933 9.933 0 001.91 3.471"
        fill="#ED1C24"
      />
      <path
        d="M19.872 8.153c.26-.49.6-.954 1.02-1.388.433-.444.94-.81 1.496-1.085.579-.289 1.287-.433 2.127-.433.838 0 1.62.202 2.343.606.462.261.825.558 1.084.89.261.332.449.673.564 1.02.116.347.188.672.217.976.028.304.044.558.044.76h-9.546c.174-.405.39-.853.65-1.346zm-5.598 12.736c.868 1.026 1.996 1.858 3.385 2.495.245.111.503.21.765.302h8.69c.022-.007.047-.012.07-.02 1.228-.449 2.262-1.006 3.1-1.671.84-.666 1.527-1.373 2.063-2.127.534-.751.99-1.43 1.367-2.038l-5.598-1.996c-.174.29-.39.623-.65.998a5.506 5.506 0 01-2.582 1.974c-.623.246-1.368.368-2.235.368-1.59 0-2.792-.578-3.601-1.735a3.46 3.46 0 01-.629-1.562c-.074-.55-.11-1.273-.11-2.17h16.272c.116-.98.174-1.965.174-2.952 0-1.82-.297-3.383-.89-4.684-.593-1.301-1.366-2.357-2.321-3.168a9.122 9.122 0 00-3.298-1.778 13.138 13.138 0 00-3.818-.564c-2.227 0-4.137.433-5.727 1.3-1.592.869-2.9 1.954-3.928 3.256a13.506 13.506 0 00-2.277 4.229c-.493 1.52-.738 2.93-.738 4.231 0 1.274.202 2.554.608 3.84a9.95 9.95 0 001.909 3.471"
        fill="#ED1C24"
      />
      <path
        d="M.478 16.7c-.059.406-.124.805-.196 1.194a6.54 6.54 0 00-.108 1.193c0 1.244.266 2.207.802 2.886a4.78 4.78 0 001.973 1.498c.21.085.423.153.638.215h6.048c.114-.014.23-.027.343-.043l1.172-5.163h-1.3c-.32 0-.622-.022-.911-.065a2.458 2.458 0 01-.803-.26 1.422 1.422 0 01-.586-.587c-.145-.26-.217-.621-.217-1.083 0-.232.037-.527.109-.89.071-.362.136-.702.195-1.02l2.125-9.329h3.862l.912-4.47H.91L0 5.247h3.038L.477 16.7h.001z"
        fill="#ED1C24"
      />
      <path
        d="M128.67.158v7.99h-.183c-.344-1.574-.829-3.104-1.674-4.493-1.004-1.623-2.601-3.447-4.685-3.447-4.784 0-5.219 8.575-5.219 11.847 0 2.928.234 5.947 1.297 8.69.744 1.925 1.822 2.87 3.823 2.87 1.589 0 3.279-.802 4.124-2.183.217-.36.233-.761.233-1.172v-6.174h-2.092v-.242h7.656v.243h-1.79v9.236H130c-.452-.753-1.163-1.247-2.075-1.247-.659 0-1.304.234-1.881.551l-.519.286c-1.172.519-2.292.895-3.589.895-5.939 0-9.101-6.333-9.101-11.585 0-3.84 1.464-7.95 4.575-10.35C118.808.794 120.497 0 122.296 0c.703 0 1.398.217 2.05.476l.477.183c.543.202 1.137.395 1.722.395.728 0 1.38-.478 1.942-.895h.183z"
        fill="currentColor"
      />
    </svg>
  );
};
USTeenVogueIcon.displayName = "USTeenVogueIcon";
