import { SVGAttributes } from "react";

export const ContentTypeSkiresortIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_92)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.343 10.308a2 2 0 00-.256 1.703c.176.578.538.981.752 1.194.25.248.535.464.81.649.554.37 1.237.722 1.955 1.025C5.011 15.472 6.853 16 8.522 16c1.533 0 3.012-.608 4.115-1.206a14.832 14.832 0 002.698-1.876 2 2 0 00.322-2.61c-.888-1.312-2.694-4-3.079-4.634a2.003 2.003 0 00-.22-.295 4.01 4.01 0 01-.096-.184 15.695 15.695 0 01-.444-1.018c-.332-.822-.67-1.77-.88-2.39a2 2 0 00-.934-1.113 6.738 6.738 0 00-.86-.389C8.849.176 8.315 0 7.739 0 6.395 0 5.561.875 5.25 1.389a2 2 0 00-.292 1.037c0 .006-.007.07-.066.215a4.95 4.95 0 01-.36.676c-.057.094-.129.206-.21.334-.315.495-.772 1.212-1.044 1.883-.131.323-.595 1.135-1.239 2.168a90.919 90.919 0 01-1.695 2.606zm12.043-4.883l-.002-.002c.02.027.02.032.002.002zm-2.067.336c-.447-.984-.983-2.468-1.276-3.332C8.783 2.286 8.157 2 7.74 2a.959.959 0 00-.782.429c0 .803-.51 1.607-1.02 2.41-.305.482-.61.965-.807 1.447-.053.13-.129.29-.223.472.287.235.687.518 1.125.737.595.298 1.087.395 1.415.321.23-.051.477-.177.766-.39.247-.183.477-.391.74-.63l.17-.154c.307-.276.691-.613 1.12-.841a3.22 3.22 0 01.075-.04z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_92">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.408 15.36a2 2 0 00-.3 1.86c.22.644.67 1.088.941 1.327.32.282.694.536 1.071.76.76.454 1.72.895 2.75 1.28C7.904 21.35 10.501 22 12.782 22c2 0 4.014-.714 5.612-1.484 1.626-.782 3.024-1.721 3.851-2.38a2 2 0 00.346-2.776c-1.325-1.74-4.048-5.343-4.637-6.204a2 2 0 00-.294-.34 4.587 4.587 0 01-.209-.346 20.32 20.32 0 01-.686-1.396 60.454 60.454 0 01-1.331-3.214 2 2 0 00-.994-1.087 9.572 9.572 0 00-1.141-.459C12.886 2.178 12.249 2 11.609 2c-1.441 0-2.418.848-2.824 1.441a2 2 0 00-.35 1.13c0 .172-.124.562-.726 1.442-.098.142-.215.306-.344.487-.475.663-1.106 1.545-1.489 2.384-.221.485-.95 1.613-1.91 2.981a121.498 121.498 0 01-2.558 3.495zm16.293-6.49l-.014-.017c.01.01.014.017.014.017zM7.159 10.681c.236-.384.422-.716.537-.967.293-.643.752-1.285 1.21-1.928.765-1.072 1.529-2.143 1.529-3.215.13-.19.548-.571 1.174-.571.626 0 1.565.381 1.956.571.476 1.252 1.383 3.478 2.082 4.767a.289.289 0 01-.005.002c-.579.275-1.114.688-1.58 1.06l-.194.156c-.922.74-1.67 1.34-2.564 1.52-.7.14-1.593-.057-2.522-.47a10.038 10.038 0 01-1.623-.925z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeSkiresortIcon.displayName = "ContentTypeSkiresortIcon";
