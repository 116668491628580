import { SVGAttributes } from "react";

export const INTArchitecturalDigestIcon = (
  props: SVGAttributes<SVGElement>
) => {
  return (
    <svg
      width={35}
      height={24}
      viewBox="0 0 35 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.85 12.531c0-3.031-.764-5.844-2.131-7.812-1.209-1.75-2.48-2.656-4.452-3.157-1.24-.312-2.99-.5-4.516-.5h-6.423v.236c1.717.469 2.194 1.108 2.194 3.14v13.78c0 2.881-.243 4.857-1.052 5.566-.257-.296-.49-.89-.77-1.6-.114-.292-.236-.604-.37-.922a59.959 59.959 0 01-1.017-2.668L9.858 0 3.411 18.093c-.19.5-.567 1.597-.6 1.69-.349.97-.68 1.592-1.03 2.186-.573.937-1.05 1.39-1.781 1.796V24h6.837l-.004-.228c-.945-.075-1.816-.72-1.985-1.739-.056-.339-.125-1.42-.12-1.797v-.06c.01-.902.03-2.511.439-4.315h6.665l.983 3.295c.032.125.096.313.16.5.35 1.188.476 1.782.476 2.375 0 1.031-.54 1.546-1.749 1.734V24h14.244c2.257 0 3.847-.531 5.246-1.75 2.29-1.969 3.657-5.625 3.657-9.719zm-23.081 3.116H5.204c.445-1.844 1.25-4.74 1.726-6.304l.318-1.062.318-1.031.35-1.032c0-.029.094-.286.205-.589.128-.348.277-.755.328-.939l3.32 10.957zm17.605 4.647c.985-1.614 1.479-3.482 1.564-7.607 0-2.593-.19-4.218-.604-5.531-1.449-4.391-3.178-5.879-7.281-5.855l.002 22.464c4.06-.103 4.799-.982 6.319-3.47z"
        fill="currentColor"
      />
    </svg>
  );
};
INTArchitecturalDigestIcon.displayName = "INTArchitecturalDigestIcon";
