import { MouseEvent, ReactNode, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { useScrollWhenDragging, useUniqueId } from "../../hooks";
import { memo } from "../../lib";
import type { Draggable, KeyedItem } from "./types";
import { NativeTypes } from "react-dnd-html5-backend";

const DropIndicator = styled.div<{ $isActive: boolean }>`
  position: absolute;
  border-radius: calc(0.5 * var(--spacing-sm));
  background-color: ${(props) =>
    props.$isActive ? "var(--color-blue-50);" : "none"};

  ${(props) =>
    props.$isActive &&
    css`
      z-index: ${(props) => props.theme.ElevationMenu};
    `}
`;

const DropZone = styled.div<{
  $placement: "top" | "left" | "bottom" | "right";
}>`
  position: absolute;
  pointer-events: none;

  ${(props) =>
    props.$placement === "top" &&
    css`
      width: 100%;
      height: 50%;
      top: 0;
      ${DropIndicator} {
        top: 0;
        transform: translateY(-50%);
        width: 100%;
        height: calc(0.5 * var(--spacing-sm));
      }
    `}
  ${(props) =>
    props.$placement === "bottom" &&
    css`
      width: 100%;
      height: 50%;
      bottom: 0;
      ${DropIndicator} {
        bottom: 0;
        transform: translateY(50%);
        width: 100%;
        height: calc(0.5 * var(--spacing-sm));
      }
    `}
  ${(props) =>
    props.$placement === "left" &&
    css`
      width: 50%;
      height: 100%;
      left: 0;
      ${DropIndicator} {
        left: 0;
        transform: translateX(-50%);
        height: 100%;
        width: calc(0.5 * var(--spacing-sm));
      }
    `}
  ${(props) =>
    props.$placement === "right" &&
    css`
      width: 50%;
      height: 100%;
      right: 0;
      ${DropIndicator} {
        right: 0;
        transform: translateX(50%);
        height: 100%;
        width: calc(0.5 * var(--spacing-sm));
      }
    `}
`;

const List = styled.ul<{
  $orientation: "horizontal" | "vertical";
  $isDragging: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.$orientation === "horizontal" ? "row" : "column"};
  cursor: ${(props) => (props.$isDragging ? "grabbing" : "auto")};
  flex-wrap: wrap;
  ${DropZone} {
    pointer-events: ${(props) => (props.$isDragging ? "auto" : "none")};
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
`;

function isInteractiveElement(element: Element) {
  return (
    ["A", "INPUT", "BUTTON", "SELECT", "TEXTAREA"].indexOf(element.tagName) >=
      0 || element.hasAttribute("contenteditable")
  );
}

function isContainedInInteractiveElement(target: EventTarget | null) {
  let currentElement = target instanceof Element ? target : null;
  let interactive = false;
  while (currentElement && !interactive) {
    interactive = isInteractiveElement(currentElement);
    currentElement = currentElement.parentElement;
  }
  return interactive;
}

type DraggedKeyedItem<T> = Draggable<KeyedItem<T>>;
type FileUpload = { files: File[]; dataTranser: DataTransfer };
function isFileUpload<T>(
  data: DraggedKeyedItem<T> | FileUpload
): data is FileUpload {
  return "files" in data;
}

const DraggableItem = memo(function <T>(props: {
  listID: string;
  item: KeyedItem<T>;
  index: number;
  onMove: (data: Draggable<KeyedItem<T>>, index: number) => void;
  children: (value: T, index: number) => ReactNode;
  orientation: "horizontal" | "vertical";
  onDraggingChange: (isDragging: boolean) => void;
  onFileDrop?: (files: FileList, index: number) => void;
  onInvalidFiles?: () => void;
  allowedFiles?: string[];
}) {
  const {
    listID,
    children,
    item,
    index,
    orientation,
    onMove,
    onDraggingChange,
    onFileDrop,
    allowedFiles,
    onInvalidFiles,
  } = props;
  const [draggable, setDraggable] = useState(true);
  const updateDraggableState = useCallback(
    (evt: MouseEvent) => {
      setDraggable(!isContainedInInteractiveElement(evt.target));
    },
    [setDraggable]
  );
  const enableDrag = useCallback(() => {
    setDraggable(true);
  }, [setDraggable]);

  const [{ isDragging }, dragRef] = useDrag<
    DraggedKeyedItem<T>,
    void,
    { isDragging: boolean }
  >(
    () => ({
      type: listID,
      item: () => ({ item, index }),
      canDrag: () => draggable,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [item, index, draggable]
  );
  useEffect(() => {
    onDraggingChange(isDragging);
    // We only need to fire this when isDragging changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  const handleDrop = useCallback(
    (data: DraggedKeyedItem<T> | FileUpload, index: number) => {
      if (isFileUpload(data)) {
        onFileDrop?.(data.dataTranser.files, index);
      } else {
        onMove(data, index);
      }
    },
    [onMove, onFileDrop]
  );
  const dropAcceptList = onFileDrop ? [listID, NativeTypes.FILE] : listID;
  const hasFileDrop = !!onFileDrop;
  const canDropItemOrFiles = useCallback(
    (data: DraggedKeyedItem<T> | FileUpload) => {
      if (isFileUpload(data)) {
        if (!hasFileDrop) {
          return false;
        }
        const isCorrectFileType = data.files.every((file) =>
          allowedFiles?.includes(file.type)
        );
        if (!isCorrectFileType) {
          onInvalidFiles?.();
        }
        return isCorrectFileType;
      }
      return true;
    },
    [hasFileDrop, allowedFiles, onInvalidFiles]
  );

  const [{ canDropBefore }, dropBefore] = useDrop<
    DraggedKeyedItem<T> | { files: File[]; dataTranser: DataTransfer },
    void,
    { canDropBefore: boolean }
  >(
    () => ({
      accept: dropAcceptList,
      drop: (data) => handleDrop(data, index),
      canDrop: canDropItemOrFiles,
      collect: (monitor) => ({
        canDropBefore: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [index, onMove, canDropItemOrFiles]
  );

  const [{ canDropAfter }, dropAfter] = useDrop<
    DraggedKeyedItem<T> | { files: File[]; dataTranser: DataTransfer },
    void,
    { canDropAfter: boolean }
  >(
    () => ({
      accept: dropAcceptList,
      drop: (data) => handleDrop(data, index + 1),
      canDrop: canDropItemOrFiles,
      collect: (monitor) => ({
        canDropAfter: monitor.isOver() && monitor.canDrop(),
      }),
    }),
    [index, onMove]
  );

  return (
    <Item
      ref={dragRef}
      onMouseDownCapture={updateDraggableState}
      onMouseUp={enableDrag}
    >
      {children(item.value, index) ?? null}
      <DropZone
        ref={dropBefore}
        $placement={orientation === "vertical" ? "top" : "left"}
      >
        <DropIndicator $isActive={canDropBefore} />
      </DropZone>
      <DropZone
        ref={dropAfter}
        $placement={orientation === "vertical" ? "bottom" : "right"}
      >
        <DropIndicator $isActive={canDropAfter} />
      </DropZone>
    </Item>
  );
});

export const DraggableList = function <T>(props: {
  items: KeyedItem<T>[];
  onMove: (item: DraggedKeyedItem<T>, index: number) => void;
  children: (value: T, index: number) => ReactNode;
  orientation?: "horizontal" | "vertical";
  onFileDrop?: (files: FileList, index: number) => void;
  onInvalidFiles?: () => void;
  allowedFiles?: string[];
  className?: string;
}) {
  const {
    children,
    items,
    onMove,
    orientation = "vertical",
    onFileDrop,
    onInvalidFiles,
    allowedFiles,
    className,
  } = props;
  const id = useUniqueId();
  const listID = `draggable-list-${id}`;
  const [isDragging, setIsDragging] = useState(false);

  const scrollHandlers = useScrollWhenDragging({
    dragZoneSize: 200,
    distancePerFrame: 100,
    msPerFrame: 120,
    scrollBehavior: "smooth",
  });

  return (
    <List
      $orientation={orientation}
      $isDragging={isDragging}
      className={className}
      {...scrollHandlers}
    >
      {items.map((item, index) => {
        return (
          <DraggableItem
            key={item.key}
            listID={listID}
            index={index}
            onMove={onMove}
            item={item}
            orientation={orientation}
            onDraggingChange={setIsDragging}
            onFileDrop={onFileDrop}
            onInvalidFiles={onInvalidFiles}
            allowedFiles={allowedFiles}
          >
            {children}
          </DraggableItem>
        );
      })}
    </List>
  );
};

DraggableList.displayName = "DraggableList";
