import { SVGAttributes } from "react";

export const PrimaryIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1a1 1 0 00-1 1v12a1 1 0 102 0v-4h9a1 1 0 00.832-1.555L12.202 6l1.63-2.445A1 1 0 0013 2H4a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
};
PrimaryIcon.displayName = "PrimaryIcon";
