import { AriaAttributes, Children, ComponentType, ReactNode } from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { withTooltip } from "../withTooltip";
import type { Placement } from "@popperjs/core";

function PrimaryButton(theme: DefaultTheme) {
  return {
    $Background: theme.PrimaryBackground,
    $HoverBackground: theme.PrimaryHoverBackground,
    $ActiveBackground: theme.PrimaryActiveBackground,
    $DisabledBackground: theme.PrimaryDisabledBackground,

    $Color: theme.PrimaryColor,
    $ActiveColor: theme.PrimaryColor,
    $HoverColor: theme.PrimaryColor,
    $DisabledColor: theme.PrimaryDisabledColor,

    $Ring: ["none"],
    $HoverRing: ["none"],
    $ActiveRing: ["none"],
    $DisabledRing: ["none"],
    $FocusRing: [theme.FocusRing],
    $HoverTextDecoration: "none",
  };
}

function BorderlessButton(theme: DefaultTheme) {
  return {
    $Background: "transparent",
    $HoverBackground: theme.BorderlessHoverBackground,
    $ActiveBackground: theme.BorderlessActiveBackground,
    $DisabledBackground: "transparent",

    $Color: theme.SecondaryColor,
    $ActiveColor: theme.SecondaryActiveColor,
    $HoverColor: theme.SecondaryColor,
    $DisabledColor: theme.SecondaryDisabledColor,

    $Ring: ["none"],
    $HoverRing: ["none"],
    $ActiveRing: ["none"],
    $DisabledRing: ["none"],
    $FocusRing: [theme.FocusRing],
    $HoverTextDecoration: "none",
  };
}

function SecondaryButton(theme: DefaultTheme) {
  return {
    ...BorderlessButton(theme),
    $HoverBackground: "transparent",
    $ActiveBackground: theme.SecondaryActiveBackground,
    $Ring: [theme.SecondaryRing],
    $HoverRing: [theme.SecondaryHoverRing],
    $ActiveRing: [theme.SecondaryFocusRing, theme.SecondaryActiveRing],
    $DisabledRing: [theme.SecondaryDisabledRing],
    $FocusRing: [theme.SecondaryFocusRing, theme.FocusRing],
  };
}

function TextButton(theme: DefaultTheme) {
  return {
    ...BorderlessButton(theme),
    $HoverBackground: "transparent",
    $ActiveBackground: "transparent",
    $HoverTextDecoration: "underline",
  };
}

function CTAButton(theme: DefaultTheme) {
  return {
    ...BorderlessButton(theme),
    $Color: theme.Color,
    $ActiveColor: theme.Color,
    $HoverColor: theme.Color,
    $Ring: [theme.SecondaryRing],
    $HoverRing: [],
    $ActiveRing: [],
    $DisabledRing: [theme.SecondaryDisabledRing],
    $FocusRing: [theme.FocusRing],
  };
}

function IconButton(theme: DefaultTheme) {
  return {
    ...BorderlessButton(theme),
    $Color: theme.Color,
    $ActiveColor: theme.Color,
    $HoverColor: theme.Color,
  };
}

function SmallButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontSmallInteractive,
    $Padding: theme.SecondarySmallPadding,
    $SVGLeft: "0",
    $ColumnGap: "var(--spacing-xxs)",
  };
}

function MediumButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontInteractive,
    $Padding: theme.SecondaryMediumPadding,
    $SVGLeft: "calc(-1 * var(--spacing-xxs))",
    $ColumnGap: "0",
  };
}

function RegularButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontInteractive,
    $Padding: theme.SecondaryPadding,
    $SVGLeft: "calc(-1 * var(--spacing-xs))",
    $ColumnGap: "0",
  };
}

function SmallIconButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontSmallInteractive,
    $Padding: "var(--spacing-xxs)",
    $SVGLeft: "0",
    $ColumnGap: "0",
  };
}

function MediumIconButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontInteractive,
    $Padding: "var(--spacing-xxs)",
    $SVGLeft: "0",
    $ColumnGap: "0",
  };
}

function RegularIconButton(theme: DefaultTheme) {
  return {
    $Font: theme.FontInteractive,
    $Padding: "var(--spacing-xs)",
    $SVGLeft: "0",
    $ColumnGap: "0",
  };
}

const StyledButton: StyledComponent<
  "button",
  DefaultTheme,
  {
    size?: "small" | "medium" | "regular";
    treatment?: "primary" | "borderless" | "text";
    "aria-label"?: AriaAttributes["aria-label"];
    tooltipPlacement?: Placement;
  },
  never
> = styled.button.attrs(
  (props: {
    children: ReactNode;
    theme: DefaultTheme;
    size?: "small" | "medium" | "regular";
    treatment?: "primary" | "borderless";
    "aria-label"?: string;
    type?: "submit" | "reset" | "button";
    tooltipPlacement?: Placement;
  }) => {
    let { size, treatment, theme, ...forwardProps } = props;
    forwardProps.type = forwardProps.type ?? "button";
    let children = Children.toArray(props.children);
    let isIcon =
      children.length === 1 &&
      "aria-label" in forwardProps &&
      forwardProps["aria-label"] != null;

    let hasIcon =
      children.length > 1 &&
      children.some((child) => {
        if (typeof child === "object" && "type" in child) {
          if (typeof child.type !== "string" && "displayName" in child.type) {
            let name = (child.type as ComponentType).displayName ?? "";
            // Handle invocations of Buttons in MDX
            if (name === "MDXCreateElement") {
              name = child.props.mdxType;
            }
            return name.endsWith("Icon");
          }
        }
        return false;
      });

    let treatmentTokens =
      treatment === "primary"
        ? PrimaryButton(theme)
        : treatment === "borderless"
        ? BorderlessButton(theme)
        : treatment === "text"
        ? TextButton(theme)
        : hasIcon
        ? CTAButton(theme)
        : isIcon
        ? IconButton(theme)
        : SecondaryButton(theme);
    let sizeTokens = isIcon
      ? size === "small"
        ? SmallIconButton(theme)
        : size === "medium"
        ? MediumIconButton(theme)
        : RegularIconButton(theme)
      : size === "small"
      ? SmallButton(theme)
      : size === "medium"
      ? MediumButton(theme)
      : RegularButton(theme);

    return {
      $Length: hasIcon ? 2 : 1,
      ...treatmentTokens,
      ...sizeTokens,
      ...forwardProps,
    };
  }
)`
  /** Reset */
  appearance: none;
  border: 0;
  text-decoration: none;
  &::-moz-focus-inner {
    border: 0;
  }
  border-radius: ${(props) => props.theme.CornerRadius};

  display: inline-grid;
  font: ${(props) => props.$Font};
  padding: ${(props) => props.$Padding};
  grid-template-columns: ${(props) => "auto ".repeat(props.$Length)};
  column-gap: ${(props) => props.$ColumnGap};

  svg {
    position: relative;
    left: ${(props) => props.$SVGLeft};
  }

  &:focus {
    outline: none;
  }

  // Debugging style to alert that there's an anchor without a destination
  a&:not([href]),
  a&[href=""] {
    outline: 3px solid ${(props) => props.theme.DebuggingColor};
  }

  &,
  a& {
    background: ${(props) => props.$Background};
    box-shadow: ${(props) => props.$Ring};
    color: ${(props) => props.$Color};
  }

  &:not(:disabled):hover {
    background: ${(props) => props.$HoverBackground};
    box-shadow: ${(props) => props.$HoverRing};
    cursor: pointer;
    text-decoration: ${(props) => props.$HoverTextDecoration};
  }

  &:not(:disabled):active {
    box-shadow: ${(props) => props.$FocusRing};
  }

  &:not(:disabled)[aria-pressed="true"],
  &:not(:disabled)[aria-expanded="true"],
  &:not(:disabled):active {
    background: ${(props) => props.$ActiveBackground};
    color: ${(props) => props.$ActiveColor};
  }

  &:not(:disabled):focus {
    text-decoration: none;
    box-shadow: ${(props) => props.$FocusRing.join(", ")};
  }

  &:disabled {
    background: ${(props) => props.$DisabledBackground};
    box-shadow: ${(props) => props.$DisabledRing.join(", ")};
    color: ${(props) => props.$DisabledColor};
  }
`;
StyledButton.displayName = "Button";

export const Button = withTooltip(StyledButton);
