import { SVGAttributes } from "react";

export const ContentTypeLivestoryIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10 8a2 2 0 11-4 0 2 2 0 014 0zM3.05 3A6.978 6.978 0 001 7.95c0 1.933.784 3.683 2.05 4.95l.9-.9a5.71 5.71 0 01-1.677-4.05A5.71 5.71 0 013.95 3.9l-.9-.9zM12.05 3.9l.9-.9A6.978 6.978 0 0115 7.95a6.978 6.978 0 01-2.05 4.95l-.9-.9a5.71 5.71 0 001.677-4.05A5.71 5.71 0 0012.05 3.9z"
          fill="currentColor"
        />
        <path
          d="M4.85 4.8a4.44 4.44 0 00-1.305 3.15A4.44 4.44 0 004.85 11.1l.9-.9a3.172 3.172 0 01-.932-2.25c0-.879.356-1.674.932-2.25l-.9-.9zM10.25 5.7l.9-.9a4.44 4.44 0 011.305 3.15 4.44 4.44 0 01-1.305 3.15l-.9-.9a3.172 3.172 0 00.932-2.25c0-.879-.356-1.674-.932-2.25z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0zM4.222 4.222A10.966 10.966 0 001 12c0 3.037 1.231 5.788 3.222 7.778l1.414-1.414A8.972 8.972 0 013 12a8.972 8.972 0 012.636-6.364L4.222 4.222zM18.364 5.636l1.414-1.414A10.966 10.966 0 0123 12c0 3.037-1.231 5.788-3.222 7.778l-1.414-1.414A8.972 8.972 0 0021 12a8.972 8.972 0 00-2.636-6.364z"
          fill="currentColor"
        />
        <path
          d="M7.05 7.05A6.978 6.978 0 005 12c0 1.933.784 3.683 2.05 4.95l1.414-1.414A4.984 4.984 0 017 12c0-1.38.56-2.63 1.464-3.536L7.05 7.05zM15.536 8.464L16.95 7.05A6.978 6.978 0 0119 12a6.978 6.978 0 01-2.05 4.95l-1.414-1.414A4.984 4.984 0 0017 12c0-1.38-.56-2.63-1.464-3.536z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeLivestoryIcon.displayName = "ContentTypeLivestoryIcon";
