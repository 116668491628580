import styled from "styled-components";
import { FC, ComponentPropsWithRef } from "react";

const StyledTabPanel = styled.div``;
export const TabPanel: FC<{ id: string } & ComponentPropsWithRef<"div">> = (
  props
) => {
  const { children, ...forwardProps } = props;
  return (
    <StyledTabPanel role="tabpanel" {...forwardProps}>
      {props.children}
    </StyledTabPanel>
  );
};
TabPanel.displayName = "ARIA.TabPanel";
