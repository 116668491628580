import { SVGAttributes } from "react";

export const ContentTypeBarIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_75)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.415 10.22a2 2 0 00.585.512V14H5a1 1 0 100 2h6a1 1 0 100-2H9v-3.268a2 2 0 00.585-.512l5.196-6.75A2 2 0 0013.196.25H2.804a2 2 0 00-1.585 3.22l5.196 6.75zM11.85 4l1.347-1.75H2.804L4.15 4h7.698zm-1.54 2L8 9 5.69 6h4.62z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_75">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.415 14.22a2 2 0 00.585.512V20H9a1 1 0 100 2h6a1 1 0 100-2h-2v-5.268a2 2 0 00.585-.512l6.928-9A2 2 0 0018.928 2H5.072a2 2 0 00-1.585 3.22l6.928 9zM17.39 6l1.54-2H5.071l1.54 2h10.777zm-1.54 2L12 13 8.151 8h7.698z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeBarIcon.displayName = "ContentTypeBarIcon";
