import { SVGAttributes } from "react";

export const ContentTypeGettyIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H4zm2.918 3.98c0-.534.389-.956.945-.956.494 0 .893.422.893.955 0 .533-.399.955-.893.955-.556 0-.945-.422-.945-.955zm1.23 5.11c-1.094 0-1.45-.2-1.45-.645 0-.245.21-.667.924-.667 1.26 0 1.649.4 1.649.667 0 .2-.284.644-1.124.644zM9.66 5.067c.2-.133.536-.133.84-.133v-.933c-.62-.022-1.03.155-1.281.51-.337-.31-.883-.51-1.345-.51-1.418 0-2.153 1-2.153 2.01 0 .7.357 1.145.809 1.533-.242.078-.851.5-.851 1.056 0 .503.364.694.637.837.064.033.123.064.171.096v.022c-.472 0-.987.345-.987 1.067 0 .91.966 1.377 2.437 1.377 1.744 0 2.532-.889 2.532-1.71 0-1.414-1.344-1.49-2.387-1.548-.664-.037-1.206-.067-1.206-.43 0-.333.452-.356 1.019-.356 1.365 0 2.059-.777 2.059-1.866 0-.444-.126-.777-.294-1.022z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2a4 4 0 00-4 4v12a4 4 0 004 4h12a4 4 0 004-4V6a4 4 0 00-4-4H6zm4.553 6.464c0-.933.699-1.673 1.701-1.673.89 0 1.607.74 1.607 1.673s-.718 1.671-1.607 1.671c-1.002 0-1.701-.738-1.701-1.671zm2.212 8.942c-1.967 0-2.61-.35-2.61-1.127 0-.428.379-1.167 1.664-1.167 2.27 0 2.969.7 2.969 1.167 0 .35-.511 1.127-2.023 1.127zM15.487 6.87c.36-.234.965-.234 1.513-.234V5.003c-1.115-.039-1.853.272-2.307.894-.604-.544-1.588-.894-2.42-.894-2.553 0-3.876 1.75-3.876 3.519 0 1.224.643 2.002 1.456 2.682-.435.137-1.532.875-1.532 1.847 0 .881.657 1.215 1.147 1.465.116.059.223.113.31.169v.038c-.852 0-1.778.603-1.778 1.866C8 18.184 9.74 19 12.387 19c3.138 0 4.556-1.555 4.556-2.994 0-2.472-2.417-2.604-4.296-2.707-1.194-.065-2.17-.119-2.17-.753 0-.583.813-.622 1.834-.622 2.458 0 3.706-1.361 3.706-3.266 0-.778-.227-1.36-.53-1.788z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeGettyIcon.displayName = "ContentTypeGettyIcon";
