import { SVGAttributes } from "react";

export const ArrowRightIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 14V2a1 1 0 011.6-.8l8 6a1 1 0 010 1.6l-8 6A1 1 0 013 14z"
        fill="currentColor"
      />
    </svg>
  );
};
ArrowRightIcon.displayName = "ArrowRightIcon";
