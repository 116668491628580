import { SVGAttributes } from "react";

export const ContentTypeShipIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_88)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 1a1 1 0 011-1h6a1 1 0 011 1v3h3a1 1 0 110 2h-1v3.629l.268.074a1 1 0 01.637 1.388c-.22.47-.621.995-1.072 1.49a14.256 14.256 0 01-1.66 1.537c-.618.489-1.304.948-1.995 1.29C9.501 15.74 8.743 16 8 16c-.743 0-1.501-.259-2.178-.593-.691-.34-1.377-.8-1.995-1.29a14.26 14.26 0 01-1.66-1.537c-.45-.494-.852-1.02-1.072-1.489a1 1 0 01.637-1.388L2 9.63V6H1a1 1 0 010-2h3V1zm2 3h4V2H6v2zM4 6v3.073l3.732-1.037a1 1 0 01.536 0L12 9.073V6H4zm-.344 5.245c.388.424.877.882 1.411 1.304a9.54 9.54 0 001.64 1.065c.101.05.2.095.293.134v-3.432l-3.344.929zm5.637 2.369c-.101.05-.2.095-.293.134v-3.432l3.344.929a12.28 12.28 0 01-1.411 1.304 9.541 9.541 0 01-1.64 1.065z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_88">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a1 1 0 00-1 1v4H4a1 1 0 100 2h1v6.14l-1.298.405a1 1 0 00-.625 1.34c.262.63.76 1.373 1.354 2.104a19.696 19.696 0 002.167 2.258c.81.721 1.705 1.394 2.603 1.893.88.49 1.854.86 2.799.86.945 0 1.918-.37 2.8-.86.897-.499 1.792-1.172 2.602-1.893a19.694 19.694 0 002.167-2.258c.593-.731 1.091-1.474 1.354-2.104a1 1 0 00-.625-1.34L19 14.14V8h1a1 1 0 100-2h-3V2a1 1 0 00-1-1H8zm7 5H9V3h6v3zm-8 7.515V8h10v5.515l-4.702-1.47a1 1 0 00-.596 0L7 13.516zm-1.016 3.214c-.186-.229-.35-.446-.492-.648L11 14.361v6.421a6.392 6.392 0 01-.829-.39c-.734-.408-1.508-.985-2.244-1.64a17.707 17.707 0 01-1.943-2.023zM13 20.782c.255-.096.531-.226.829-.39.734-.408 1.508-.985 2.244-1.64a17.7 17.7 0 001.942-2.023c.187-.229.351-.446.493-.648L13 14.361v6.421zM9 11a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeShipIcon.displayName = "ContentTypeShipIcon";
