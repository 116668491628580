import { SVGAttributes } from "react";

export const TextRightIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.086 10.5H3.5a1 1 0 100 2h9.586l-3.293 3.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414l-5-5a1 1 0 00-1.414 1.414l3.293 3.293zm6.414 10a1 1 0 102 0v-18a1 1 0 10-2 0v18z"
        fill="currentColor"
      />
    </svg>
  );
};
TextRightIcon.displayName = "TextRightIcon";
