import {
  useCallback,
  ComponentPropsWithoutRef,
  ReactNode,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  FC,
} from "react";
import computeScrollIntoView from "compute-scroll-into-view";
import styled, { css } from "styled-components";
import { BackIcon, CloseIcon, AnimatedEllipsisIcon } from "../../../icons";
import type { SmallIcons } from "../../../icons/types";
import { useClickOutside, useMediaQuery, useUniqueId } from "../../../hooks";
import { usePopper } from "react-popper";
import { Rect, Placement, ModifierArguments } from "@popperjs/core";
import { useIntl } from "react-intl";
import { Button } from "../../Button";
import { ThemeProvider } from "../../../contexts";

const Field = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  background: ${(props) =>
    props.$disabled
      ? props.theme.ControlDisabledBackground
      : props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) =>
    props.$disabled ? props.theme.FieldDisabledRing : props.theme.FieldRing};
  color: ${(props) =>
    props.$disabled ? props.theme.FieldDisabledColor : props.theme.Color};
  position: relative;

  [aria-busy="true"] &,
  [aria-expanded="true"] & {
    z-index: ${(props) => props.theme.ElevationMenu + 1};
  }

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }

  &:active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};
  }

  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;
  }

  .inputIcon {
    position: absolute;
    left: var(--spacing-xs);
    z-index: 0;
  }
`;

const Input = styled.input<{ $withIcon: boolean }>`
  /* Resets */
  appearance: none;
  border: 0;
  box-shadow: none;
  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }

  background: transparent;
  flex-grow: 1;
  padding: ${(props) => props.theme.SecondaryPadding};

  ${(props) =>
    props.$withIcon &&
    css`
      padding-left: calc(2 * var(--spacing-sm));
    `}

  font: inherit;
  color: inherit;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button {
    display: none;
  }

  &:disabled {
    color: ${(props) => props.theme.FieldDisabledColor};
  }
`;

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.Background};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;

  @media (max-width: 650px) {
    &:focus-within,
    &[aria-expanded="true"] {
      position: fixed;
      top: -2px;
      left: -2px;
      width: calc(100vw + 4px);
      max-width: calc(100vw + 4px);
      height: calc(var(--spacing-sm) * 3);
      z-index: ${(props) => props.theme.ElevationModal};

      ${Field} {
        border-radius: 0;
      }

      ${Input} {
        height: calc(var(--spacing-sm) * 3);
        line-height: calc(var(--spacing-sm) * 3);
        padding-left: calc(2.5 * var(--spacing-sm));
      }
    }
  }
`;

const CancelButton = styled(Button)`
  margin-right: var(--spacing-xs);
`;

const List = styled.ul`
  /* Resets */
  list-style: none;

  /* Positioning */
  position: relative;
  top: calc(1.5rem + 2 * var(--spacing-xs));
  width: 100%;
  z-index: ${(props) => props.theme.ElevationMenu};

  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  color: ${(props) => props.theme.Color};
`;

const EmptyMessageContainer = styled.div`
  position: relative;
  top: calc(1.5rem + 2 * var(--spacing-xs));
  width: 100%;
  z-index: ${(props) => props.theme.ElevationMenu};

  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  color: ${(props) => props.theme.Color};
`;

const OptionGroup = styled.li`
  padding: var(--spacing-xs) 0;
  border-top: 1px solid ${(props) => props.theme.DividerColor};
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
  & + &,
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
`;
const OptionGroupLabel = styled.span`
  display: block;
  font: ${(props) => props.theme.FontLabel};
`;

const SubList = styled.ul`
  &[aria-orientation="horizontal"] {
    display: flex;
    overflow-x: auto;
  }
`;

const Option = styled.li`
  /* Resets */
  font-size: inherit;
  cursor: default;
  user-select: none;
`;

export type ComboboxGroup<T> = {
  items: ComboboxItem<T>[];
  label: string;
  orientation?: "horizontal" | "vertical";
  role: "group";
};

export type ComboboxOptionProps<T> = {
  value: T;
  role: "option";
};

export type ComboboxItem<T> = ComboboxOptionProps<T> | ComboboxGroup<T>;

export function isComboboxGroup<T>(
  item: ComboboxItem<T>
): item is ComboboxGroup<T> {
  return item.role === "group";
}

export function isComboboxOption<T>(
  item: ComboboxItem<T>
): item is ComboboxOptionProps<T> {
  return item.role === "option";
}

export type ComboboxItemProps<T> = {
  idPrefix: string;
  item: ComboboxItem<T>;
  items: T[];
  selectedIndex: number;
  setMouseCursor: (index: number) => void;
  selectItem: (index: number) => void;
  children: (item: T) => ReactNode;
};

const ComboboxItem = function <T>(props: ComboboxItemProps<T>) {
  const {
    selectedIndex,
    setMouseCursor,
    selectItem,
    item,
    idPrefix,
    children,
    items,
  } = props;
  const groupLabelId = `${idPrefix}-group-${useUniqueId()}`;

  if (isComboboxGroup<T>(item)) {
    const { label, orientation = "vertical" } = item;
    return (
      <OptionGroup>
        <OptionGroupLabel id={groupLabelId} role="presentation">
          {label}
        </OptionGroupLabel>
        <SubList
          role="group"
          aria-labelledby={groupLabelId}
          aria-orientation={
            orientation === "horizontal" ? "horizontal" : undefined
          }
        >
          {item.items.map((subItem, index) => (
            <ComboboxItem<T>
              item={subItem}
              key={index}
              selectedIndex={selectedIndex}
              setMouseCursor={setMouseCursor}
              selectItem={selectItem}
              idPrefix={idPrefix}
              items={items}
            >
              {children}
            </ComboboxItem>
          ))}
        </SubList>
      </OptionGroup>
    );
  } else {
    const index = items.indexOf(item.value);
    return (
      <Option
        role="option"
        id={`${idPrefix}-item-${index}`}
        key={index}
        aria-selected={index === selectedIndex ? "true" : undefined}
        onMouseEnter={() => setMouseCursor(index)}
        onClick={() => selectItem(index)}
      >
        {children(item.value)}
      </Option>
    );
  }
};

function flatten<T>(items: ComboboxItem<T>[]) {
  return items.reduce((list, item) => {
    if (isComboboxGroup<T>(item)) {
      list.push(...flatten<T>(item.items));
    } else {
      list.push(item.value);
    }
    return list;
  }, [] as T[]);
}

function modulus(value: number, base: number) {
  if (value < 0) {
    return (value % base) + base;
  }
  return value % base;
}

export type ComboboxProps<T> = ComponentPropsWithoutRef<typeof Input> & {
  items?: Array<ComboboxItem<T>>;
  children: (item: T) => ReactNode;
  loading?: boolean;
  onChange?: (value: string) => void;
  onSelect?: (result: T, index: number) => string | void;
  icon?: SmallIcons;
  type?: "text" | "search" | "url" | "email" | "tel";
  EmptyMessage?: FC<{ value: string }>;
};

const padding = {
  name: "padding",
  enabled: true,
  phase: "beforeWrite" as const,
  requires: ["flip"],
  fn({ state, name }: ModifierArguments<Record<string, never>>) {
    let { height } = state.rects.reference;
    let [basePlacement] = state.placement.split("-");
    let top = basePlacement === "bottom" ? height : 0;
    let bottom = basePlacement === "top" ? height : 0;

    state.modifiersData[name] = {
      top,
      bottom,
    };
  },
};

const applyPadding = {
  name: "applyPadding",
  enabled: true,
  phase: "beforeWrite" as const,
  requires: ["padding"],
  fn({ state }: ModifierArguments<Record<string, never>>) {
    const { top, bottom } = state.modifiersData.padding;
    state.styles.popper.paddingTop = top;
    state.styles.popper.paddingBottom = bottom;
  },
};

function placeUnderField(props: {
  popper: Rect;
  reference: Rect;
  placement: Placement;
}): [number | null | undefined, number | null | undefined] {
  return [0, -1 * props.reference.height];
}

export function Combobox<T>(props: ComboboxProps<T>) {
  const {
    items = [],
    children,
    loading = false,
    disabled,
    onChange,
    onSelect,
    icon: Icon,
    type = "text",
    className,
    value,
    defaultValue,
    EmptyMessage,
    ...forwardProps
  } = props;
  const intl = useIntl();
  const itemList = useMemo(() => flatten(items), [items]);
  const [isOpen, setIsOpen] = useState(false);
  const [hasInput, setHasInput] = useState(!!defaultValue);
  const [cursor, setCursor] = useState(0);
  const [cursorMode, setCursorMode] = useState<"mouse" | "keyboard">("mouse");
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  );
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(
    null
  );
  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);
  const [emptyMessageContainerElement, setEmptyMessageContainerElement] =
    useState<HTMLDivElement | null>(null);
  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);
  const uidPrefix = `combobox-${useUniqueId()}`;
  const useMobileMenu = useMediaQuery("(max-width: 650px)");

  const activeID = `${uidPrefix}-item-${cursor}`;

  useEffect(() => {
    setCursor(0);
  }, [itemList]);

  useLayoutEffect(() => {
    setIsOpen(
      !loading &&
        inputElement !== null &&
        document.activeElement === inputElement
    );
  }, [loading, inputElement]);

  useEffect(() => {
    setHasInput(!!(value || defaultValue));
  }, [value, defaultValue, setHasInput]);

  const closeAndReset = useCallback(() => {
    setIsOpen(false);
    setCursor(0);
  }, [setIsOpen, setCursor]);

  useClickOutside(containerElement, closeAndReset);

  const change = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(evt.target.value);
      setHasInput(!!evt.target.value);
    },
    [onChange]
  );

  const clear = useCallback(() => {
    if (inputElement) {
      if (!useMobileMenu) {
        inputElement.focus();
      } else {
        closeAndReset();
      }
      if (inputElement.value !== "") {
        inputElement.value = "";
        onChange && onChange("");
        setHasInput(false);
      } else {
        closeAndReset();
      }
    }
  }, [useMobileMenu, inputElement, onChange, setHasInput, closeAndReset]);

  const selectItem = useCallback(
    (index) => {
      let item = itemList[index];
      let value = onSelect ? onSelect(item, index) : undefined;
      if (value != null && inputElement) {
        inputElement.value = value;
      }
      setIsOpen(false);
      setCursor(index);
    },
    [itemList, inputElement, onSelect, setIsOpen, setCursor]
  );

  const keyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      let updatedCursor;
      switch (event.key) {
        case "ArrowDown":
          if (isOpen) {
            updatedCursor = modulus(cursor + 1, itemList.length);
            setCursor(updatedCursor);
            setCursorMode("keyboard");
            event.preventDefault();
            event.stopPropagation();
          } else {
            setIsOpen(true);
          }
          break;
        case "ArrowUp":
          if (isOpen) {
            updatedCursor = modulus(cursor - 1, itemList.length);
            setCursor(updatedCursor);
            setCursorMode("keyboard");
            event.preventDefault();
            event.stopPropagation();
          } else {
            setIsOpen(true);
          }
          break;
        case "Enter":
          if (document.activeElement === inputElement) {
            selectItem(cursor);
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          break;
        case "Escape":
          clear();
          if (useMobileMenu) {
            closeAndReset();
            inputElement?.blur();
          }
          event.preventDefault();
          event.stopPropagation();
          break;
        case "Tab":
          if (
            !buttonElement?.isConnected ||
            (document.activeElement === inputElement && event.shiftKey) ||
            (document.activeElement === buttonElement && !event.shiftKey)
          ) {
            closeAndReset();
          }
      }
    },
    [
      isOpen,
      itemList,
      inputElement,
      buttonElement,
      cursor,
      clear,
      closeAndReset,
      selectItem,
      setIsOpen,
      useMobileMenu,
    ]
  );

  const setMouseCursor = useCallback(
    (index) => {
      setCursorMode("mouse");
      setCursor(index);
    },
    [setCursor, setCursorMode]
  );

  useLayoutEffect(() => {
    if (
      document.activeElement &&
      document.activeElement === inputElement &&
      cursorMode === "keyboard"
    ) {
      const activeElement = document.querySelector(`#${activeID}`);
      if (activeElement) {
        const actions = computeScrollIntoView(activeElement, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top, left }) => {
          el.scrollTop = top;
          el.scrollLeft = left;
        });
      }
    }
  }, [cursorMode, activeID, inputElement]);

  const { styles: listStyles, attributes: listAttributes } = usePopper(
    containerElement,
    listElement,
    {
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top"],
          },
        },
        {
          name: "offset",
          options: {
            offset: placeUnderField,
          },
        },
        padding,
        applyPadding,
      ],
    }
  );

  const {
    styles: emptyMessageContainerStyles,
    attributes: emptyMessageContainerAttributes,
  } = usePopper(containerElement, emptyMessageContainerElement, {
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
      {
        name: "offset",
        options: {
          offset: placeUnderField,
        },
      },
      padding,
      applyPadding,
    ],
  });

  return (
    <Container
      role="combobox"
      aria-disabled={disabled}
      aria-busy={loading ? "true" : undefined}
      aria-haspopup="listbox"
      aria-owns={`${uidPrefix}-list`}
      aria-expanded={isOpen}
      ref={setContainerElement}
      className={className}
      onKeyDown={keyDown}
      onFocus={() => {
        setIsOpen(!loading);
      }}
    >
      <Field $disabled={disabled}>
        {Icon &&
          (loading ? (
            <AnimatedEllipsisIcon size="small" className="inputIcon loading" />
          ) : isOpen && !hasInput && useMobileMenu ? (
            <Button
              size="medium"
              className="inputIcon"
              aria-label={intl.formatMessage({ defaultMessage: "Go back" })}
              onClick={closeAndReset}
            >
              <BackIcon size="regular" />
            </Button>
          ) : (
            <Icon size="small" className="inputIcon" />
          ))}
        <Input
          ref={setInputElement}
          $withIcon={Icon != null}
          onChange={change}
          type={type}
          list={`${uidPrefix}-list`}
          aria-autocomplete="list"
          aria-controls={`${uidPrefix}-list`}
          aria-activedescendant={isOpen ? activeID : undefined}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          {...forwardProps}
        />
        <ThemeProvider theme="modal">
          {hasInput && (
            <CancelButton
              ref={setButtonElement}
              aria-label={intl.formatMessage({
                defaultMessage: "Clear",
                description: "Button used to clear a text field",
              })}
              size="small"
              onClickCapture={(evt: MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                evt.stopPropagation();
                clear();
              }}
            >
              <CloseIcon size="small" />
            </CancelButton>
          )}
        </ThemeProvider>
      </Field>
      {(isOpen || loading) && useMobileMenu && (
        <Scrim onClick={closeAndReset} />
      )}
      {isOpen ? (
        items.length > 0 ? (
          <ThemeProvider theme="modal">
            <List
              id={`${uidPrefix}-list`}
              ref={setListElement}
              role="listbox"
              style={listStyles.popper}
              {...listAttributes.popper}
            >
              {items.map((item, index) => (
                <ComboboxItem<T>
                  item={item}
                  key={index}
                  selectedIndex={cursor}
                  idPrefix={uidPrefix}
                  items={itemList}
                  setMouseCursor={setMouseCursor}
                  selectItem={selectItem}
                >
                  {children}
                </ComboboxItem>
              ))}
            </List>
          </ThemeProvider>
        ) : EmptyMessage ? (
          <EmptyMessageContainer
            ref={setEmptyMessageContainerElement}
            style={emptyMessageContainerStyles.popper}
            {...emptyMessageContainerAttributes.popper}
          >
            {EmptyMessage({ value })}
          </EmptyMessageContainer>
        ) : (
          <></>
        )
      ) : (
        <ul id={`${uidPrefix}-list`} role="listbox" aria-hidden="true"></ul>
      )}
    </Container>
  );
}

Combobox.displayName = "ARIA.Combobox";
