import { ComponentType, ReactNode, useMemo } from "react";
import styled from "styled-components";
import Document from "@atjson/document";
import ReactRenderer, { ReactRendererProvider } from "@atjson/renderer-react";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";

import { highlight } from "../../lib";

const components = {
  Bold: styled.strong`
    font-weight: bold;
  `,
  Italic: styled.em`
    font-style: italic;
  `,
  Highlight: styled.span`
    background: ${(props) => props.theme.SearchMatchBackground};
  `,
  SmallCaps: styled.span`
    font-variant: all-small-caps;
  `,
  Strikethrough: styled.span`
    text-decoration: line-through;
  `,
  Subscript: styled.sub``,
  Superscript: styled.sup``,
  LineBreak: styled.br``,
  Default: (props: { children: ReactNode }) => <>{props.children}</>,
};

const Wrapper = styled.div`
  grid-area: title;
`;

export function HighlightedText(props: {
  children?: ReactNode;
  query?: string;
  className?: string;
  document?: Document;
  md?: string;
  text?: string;
  // ReactRendererProvider also uses 'any' as a type parameter for ComponentType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components?: { [key: string]: ComponentType<any> };
}) {
  const { document, md, text, query, className } = props;
  const doc = useMemo(() => {
    return (
      document ??
      (md
        ? CopilotMarkdownSource.fromRaw(md ?? "")
            .convertTo(VersoSource)
            .canonical()
        : new VersoSource({ content: text ?? "", annotations: [] }))
    );
  }, [document, md, text]);
  const highlightedDoc = useMemo(
    () => (query ? highlight(doc, query) : doc),
    [doc, query]
  );
  return (
    <Wrapper className={className}>
      <ReactRendererProvider value={{ ...components, ...props.components }}>
        {ReactRenderer.render(highlightedDoc)}
      </ReactRendererProvider>
    </Wrapper>
  );
}
HighlightedText.displayName = "HighlightedText";
