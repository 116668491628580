import { SVGAttributes } from "react";

export const ContentTypeBrandIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_60)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.305 7.303A2 2 0 00.89 8.719L6.97 14.8a2 2 0 002.828 0l4.873-4.874a2 2 0 000-2.829L8.592 1.017A2 2 0 007.176.432L2.308.437A2 2 0 00.31 2.435L.305 7.303zm2 .002l.005-4.868 4.868-.005 6.079 6.079-4.873 4.873-6.08-6.08zM6 8a2 2 0 100-4 2 2 0 000 4z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_60">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.225 11.687a2 2 0 00.586 1.416l8.063 8.063a2 2 0 002.828 0l6.464-6.464a2 2 0 000-2.828L13.103 3.81a2 2 0 00-1.416-.586l-6.457.007A2 2 0 003.232 5.23l-.007 6.457zm2 .002l.007-6.457 6.457-.007 8.063 8.063-6.464 6.464-8.063-8.063zM10 12a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeBrandIcon.displayName = "ContentTypeBrandIcon";
