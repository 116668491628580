import { SVGAttributes } from "react";

export const ContentTypeProductIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_58)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0a4 4 0 00-4 4H2.857A2 2 0 00.87 5.787l-.858 8A2 2 0 002 16h12a2 2 0 001.989-2.213l-.857-8A2 2 0 0013.142 4H12a4 4 0 00-4-4zm2 6v1a1 1 0 102 0V6h1.143L14 14H2l.857-8H4v1a1 1 0 102 0V6h4zm0-2a2 2 0 10-4 0h4z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_58">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2a4 4 0 00-4 4H5.143A2 2 0 003.15 7.825l-1.143 13A2 2 0 004 23h16a2 2 0 001.992-2.175l-1.143-13A2 2 0 0018.857 6H16a4 4 0 00-4-4zm2 6v2a1 1 0 102 0V8h2.857L20 21H4L5.143 8H8v2a1 1 0 102 0V8h4zm0-2a2 2 0 10-4 0h4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeProductIcon.displayName = "ContentTypeProductIcon";
