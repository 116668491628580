import { SVGAttributes } from "react";

export const NumberedListAscendingIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8 9a1 1 0 010-2h5a1 1 0 110 2H8zM8 14a1 1 0 110-2h5a1 1 0 110 2H8zM8 4a1 1 0 010-2h5a1 1 0 110 2H8zM2.25 2.75H2a.75.75 0 010-1.5h1a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0V2.75zM2.19 7.75H2a.75.75 0 010-1.5h2a.75.75 0 01.53 1.28l-.72.72H4a.75.75 0 010 1.5H2a.75.75 0 01-.53-1.28l.72-.72zM3.25 12.75H2a.75.75 0 010-1.5h2a.75.75 0 01.75.75v2a.75.75 0 01-.75.75H2a.75.75 0 010-1.5h1.25v-.5z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10 5a1 1 0 011-1h10a1 1 0 010 2H11a1 1 0 01-1-1zM10 12a1 1 0 011-1h10a1 1 0 010 2H11a1 1 0 01-1-1zM11 18a1 1 0 100 2h10a1 1 0 000-2H11z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.144 1.362A.75.75 0 015.5 2v5A.75.75 0 114 7V3.207a.75.75 0 01-.585-1.378l1-.5a.75.75 0 01.73.033zM3.735 10.641a.75.75 0 01-1.47-.301l.735.15-.735-.15v-.002l.001-.002v-.004l.003-.009.005-.023.017-.064a2.269 2.269 0 01.39-.755c.344-.435.928-.831 1.817-.831.888 0 1.475.395 1.822.822a2.316 2.316 0 01.423.813l.006.022.002.01v.003l.001.002s0 .002-.73.168l.75-.021V10.49a1.51 1.51 0 01-.004.131 2.92 2.92 0 01-.038.316 3.574 3.574 0 01-.323.98l-.005.01a2.04 2.04 0 01-.266.364c-.097.11-.22.244-.354.387-.272.287-.614.634-.947.967l-.005.005h1.593a.75.75 0 010 1.5H3a.75.75 0 01-.522-1.289L3 14.4l-.522-.539.11-.106a123.215 123.215 0 001.186-1.17c.33-.33.66-.666.918-.938.13-.137.235-.252.311-.34.046-.053.07-.082.079-.094a2.064 2.064 0 00.183-.618.814.814 0 00-.11-.177c-.095-.117-.27-.268-.657-.268-.385 0-.55.149-.638.26a.768.768 0 00-.127.241l-.001.003v.002-.005l.002-.005v-.005zm2.286-.15l.75-.022a.755.755 0 00-.018-.145l-.732.166zM3.84 17.944v.002a.75.75 0 11-1.362-.63c.203-.44.58-.709.99-.861.399-.149.873-.205 1.387-.205a.73.73 0 01.074.004c.633.062 1.246.274 1.644.78.4.508.451 1.145.34 1.76a1.494 1.494 0 01-.283.687c.152.197.255.432.295.703.11.615.06 1.252-.34 1.76-.398.504-1.009.716-1.64.78-.598.065-1.17.041-1.648-.158a1.69 1.69 0 01-1.025-1.183.75.75 0 011.456-.362c.02.081.047.12.145.16.143.06.424.105.91.051h.01c.432-.043.571-.163.613-.216.035-.045.113-.185.04-.575a.774.774 0 01-.007-.047.233.233 0 00-.051-.033c-.111-.056-.344-.118-.764-.122h-.057a.75.75 0 01-.032-1.5h.011a.858.858 0 01.076 0c.414-.005.644-.067.754-.123a.232.232 0 00.052-.032.76.76 0 01.008-.047c.072-.39-.006-.53-.042-.575-.04-.052-.173-.166-.578-.212-.4.003-.665.05-.826.11a.48.48 0 00-.137.072l-.013.012zm1.586.644zm.012 1.802z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
NumberedListAscendingIcon.displayName = "NumberedListAscendingIcon";
