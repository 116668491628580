import { SVGAttributes } from "react";

export const USVogueIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={94}
      height={24}
      viewBox="0 0 94 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.107 14.296v-.52h7.21v.535h-1.715v9.383h-.321l-.031-.123c-.199-.933-1.163-1.255-1.653-1.255h-.184c-.81.03-1.537.372-2.287.725l-.085.04c-.077.031-.15.062-.222.093l-.222.091c-.796.383-1.653.72-2.694.72-5.571 0-9.643-4.806-9.643-11.587 0-8.311 5.45-12.337 9.413-12.337.873 0 1.44.169 2.204.413l.322.092.23.078c.169.058.353.12.55.183l.307.076c.214.046.49.092.81.107.659.016 1.424-.153 1.746-.918L54.888 0h.336v8.418H54.872l-.03-.122c-1.408-5.602-3.138-7.684-6.153-7.7h-.03c-2.48 0-3.307 1.593-3.75 2.45-1.18 2.28-1.424 7.485-1.424 9.076 0 4.454.398 6.368 1.148 8.358.765 2.035 2.096 3.015 4.102 2.984 1.852-.03 4.224-.826 4.224-4.393v-4.775h-1.852zm41.832.98v-.138h.443v8.418H76.836v-.55h2.02V1.208H74.71v16.439C74.755 20.526 72.84 24 68.663 24c-6.23 0-8.357-3.245-8.357-7.439V1.21h-2.143V.673h8.036v.536h-2.067v17.265c0 .184.138 4.745 4.669 4.914 1.24.046 2.602-.444 3.566-1.363.796-.75 1.714-2.188 1.76-4.53V1.209h-1.775V.673h20.097v7.715h-.368l-.03-.123c-1.416-7.17-4.695-7.102-8.055-7.033l-.379.008c-.245.015-.643.015-.888.015v10.347h.187c1.849 0 4.1 0 4.589-4.485l.015-.137h.367v9.872h-.367l-.015-.138c-.357-4.604-2.457-4.582-4.315-4.563l-.17.002h-.306V23.02h.184l.235.002c1.294.015 4.882.056 7.372-2.19 1.515-1.363 2.326-3.23 2.434-5.557zM27.444.121c-4.699 0-9.857 4.7-9.857 11.878 0 7.179 5.327 11.939 9.857 11.939 4.53 0 9.857-4.76 9.857-11.939C37.286 4.821 32.143.122 27.444.122zm5.48 10.99V12.092c0 1.332-.123 6.459-1.47 9.015-.765 1.485-2.25 2.265-4.04 2.265-1.791 0-3.291-.78-4.041-2.265-1.347-2.556-1.454-7.683-1.454-9.015V11.05c.046-2.051.306-5.893 1.454-8.143.918-1.867 2.785-2.265 4.04-2.265 1.271 0 3.123.398 4.041 2.265 1.148 2.25 1.393 6.092 1.454 8.143.016.015.016.046.016.061zM15.199.673h4.362v.52h-2.067L10.776 23.68h-.122L2.204 1.194H0V.673h7.607v.521H5.893l6.03 16.714 4.99-16.714h-1.867v-.52h.153z"
        fill="currentColor"
      />
    </svg>
  );
};
USVogueIcon.displayName = "USVogueIcon";
