import { SVGAttributes } from "react";

export const PublishedIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7zm1.75 0A5.255 5.255 0 008 13.25 5.255 5.255 0 0013.25 8 5.255 5.255 0 008 2.75 5.255 5.255 0 002.75 8z"
        fill="currentColor"
      />
      <path
        d="M10.25 5.338a1 1 0 111.5 1.324l-4 4.528a1 1 0 01-1.457.045l-2-2A1 1 0 015.707 7.82l1.248 1.247 3.296-3.73z"
        fill="currentColor"
      />
    </svg>
  );
};
PublishedIcon.displayName = "PublishedIcon";
