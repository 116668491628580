import { SVGAttributes } from "react";

export const PeopleIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.668 6.221a3.6 3.6 0 10-5.73-4.146 3.6 3.6 0 00-3.205 6.146c-.86.342-1.553.897-2.036 1.68-.705 1.147-.7 2.453-.697 3.014V13a2 2 0 002 2h6.4a2 2 0 002-2h2a2 2 0 002-2v-.085c.003-.56.01-1.867-.696-3.013-.482-.784-1.175-1.339-2.036-1.68zM11.18 11h2.22c0-.585 0-1.36-.37-2-.39-.674-1.189-1.2-2.83-1.2-.49 0-.904.047-1.256.13a3.62 3.62 0 01-.276.291c.861.342 1.554.897 2.036 1.68.221.36.372.736.476 1.099zM9.772 5.142A1.602 1.602 0 0011.8 3.6a1.6 1.6 0 00-3.091-.582 3.59 3.59 0 011.062 2.124zM6.2 7.2a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2zM9.03 11c.37.64.37 1.415.37 2H3v-.006c0-.584 0-1.356.37-1.994.39-.674 1.189-1.2 2.83-1.2 1.64 0 2.44.526 2.83 1.2z"
        fill="currentColor"
      />
    </svg>
  );
};
PeopleIcon.displayName = "PeopleIcon";
