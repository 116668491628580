import styled from "styled-components";
import {
  PublishedIcon,
  ScheduledIcon,
  PublishedWithChangesIcon,
  ScheduledWithChangesIcon,
} from "../../icons";

import { withTooltip } from "../withTooltip";
import { PublishInfo } from "../SearchResult/types";
import { useIntl } from "react-intl";

export const StatusIcon = withTooltip(
  styled.span<{ $isLive: boolean; $hasEdits: boolean }>`
    color: ${(props) =>
      props.$isLive ? `var(--color-green-30)` : `var(--color-yellow-30)`};
    circle {
      fill: ${(props) => (props.$hasEdits ? `var(--color-red-50)` : `default`)};
    }
  `
);

export function PublishStatusIcon(props: {
  publishInfo: PublishInfo;
  size: "small";
  latestRevision: number;
}) {
  let { publishInfo, latestRevision, size, ...forwardProps } = props;
  const intl = useIntl();
  let hasEdits = publishInfo.version < latestRevision;
  let isLive = new Date(publishInfo?.pubDate).getTime() < new Date().getTime();
  let Icon;
  if (isLive) {
    if (hasEdits) {
      Icon = PublishedWithChangesIcon;
    } else {
      Icon = PublishedIcon;
    }
  } else {
    if (hasEdits) {
      Icon = ScheduledWithChangesIcon;
    } else {
      Icon = ScheduledIcon;
    }
  }
  let localizedPubDate = intl.formatDate(publishInfo.pubDate, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <StatusIcon
      $isLive={isLive}
      $hasEdits={hasEdits}
      aria-label={localizedPubDate}
    >
      <Icon size={props.size} {...forwardProps} />
    </StatusIcon>
  );
}

PublishStatusIcon.displayName = "PublishStatusIcon";
