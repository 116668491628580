import styled from "styled-components";
import { FC, ComponentPropsWithRef } from "react";

const StyledTab = styled.li`
  font: var(--font-smaller-heading);
  cursor: pointer;
  border-bottom: 2px solid transparent;
  flex: none;
  list-style-type: none;
  line-height: var(--spacing-xl);

  &:hover,
  &:focus {
    border-bottom: 1px solid ${(props) => props.theme.ActiveTabTextColor};
  }

  &[aria-selected="true"] {
    color: ${(props) => props.theme.ActiveTabTextColor};
    font-weight: bold;
    border-bottom: 2px solid ${(props) => props.theme.ActiveTabTextColor};
  }
`;

export const Tab: FC<ComponentPropsWithRef<"li">> = (props) => {
  const { children, ...forwardProps } = props;
  return (
    <StyledTab role="tab" {...forwardProps}>
      {props.children}
    </StyledTab>
  );
};
Tab.displayName = "ARIA.Tab";
