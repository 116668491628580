import { FC, ComponentPropsWithoutRef } from "react";
import styled, { keyframes } from "styled-components";

const wipe = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const Wrapper = styled.div`
  background: ${(props) => props.theme.SkeletonColor};
  width: 100%;
  border-radius: ${(props) => props.theme.CornerRadius};
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: " ";
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      ${(props) => props.theme.SkeletonColor},
      ${(props) => props.theme.SkeletonWaveColor},
      ${(props) => props.theme.SkeletonColor}
    );
    transform: translateX(-100%);

    animation: ${wipe} 1.5s ease-in-out infinite;
  }
`;

export const Skeleton: FC<ComponentPropsWithoutRef<"div"> & { label: string }> =
  (props) => {
    let { label, ...divProps } = props;
    return (
      <Wrapper {...divProps} aria-label={label} role="progressbar">
        &zwnj;
      </Wrapper>
    );
  };
Skeleton.displayName = "Skeleton";
