import { useState, ReactElement } from "react";
import { AccordionContext } from "./Accordion.context";
import { AccordionPanel } from "./Accordion.panel";

interface AccordionProps {
  children:
    | ReactElement<typeof AccordionPanel>
    | ReactElement<typeof AccordionPanel>[];
  multiExpansion?: boolean;
}

export const Accordion = ({
  children,
  multiExpansion = false,
}: AccordionProps) => {
  const [activePanel, setActivePanel] = useState(-1);

  return (
    <AccordionContext.Provider
      value={{
        active: activePanel,
        setActive: setActivePanel,
        multiExpansion,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

Accordion.Panel = AccordionPanel;
Accordion.displayName = "Accordion";
