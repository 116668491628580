import { SVGAttributes } from "react";

export const ContentTypeClipIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2H3a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V5a3 3 0 00-3-3zM2 5a1 1 0 011-1h10a1 1 0 011 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V5zm3.75 5.598L11 8 5.75 5.402v5.196z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 16V8a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H6a3 3 0 01-3-3zm3-9a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V8a1 1 0 00-1-1H6zm3.25 8.464L16 12 9.25 8.536v6.928z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeClipIcon.displayName = "ContentTypeClipIcon";
