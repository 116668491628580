import { CloseIcon, AnimatedEllipsisIcon, SmallIcons } from "../../../icons";
import { Button, Chip, DraggableList } from "../../index";
import {
  forwardRef,
  useCallback,
  ChangeEvent,
  ComponentPropsWithoutRef,
  Ref,
  useState,
  useMemo,
  Key,
} from "react";
import styled from "styled-components";
import { useUniqueId } from "../../../hooks";
import { propagateRef } from "../../../lib";

const Wrapper = styled.div`
  width: 100%;
  grid-area: input;
  padding: var(--spacing-xs) var(--spacing-sm);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: var(--spacing-xxs);
  column-gap: var(--spacing-xs);
  @media (max-width: 650px) {
    & > ul {
      display: inline-block;
    }
  }
`;

const SelectionChip = styled(Chip)`
  color: ${(props) => props.theme.MedallionColor};
  background-color: ${(props) => props.theme.MedallionBackground};
  margin-right: var(--spacing-xxs);

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectorInput = styled.div`
  outline: 0px solid transparent;
  line-height: var(--spacing-md);
  flex-grow: 1;
  min-width: 2in;
  &:empty:not(:focus):before {
    content: attr(data-ph);
    color: ${(props) => props.theme.PlaceholderColor};
  }
`;

const RemoveButton = styled(Button)`
  padding: 0;
  margin-left: var(--spacing-xs);
  background: transparent;
  color: ${(props) => props.theme.MedallionColor};

  &:not(:focus) {
    box-shadow: none;
  }

  &:not(:disabled):hover {
    background: none;
    box-shadow: none;
  }
  &:not(:disabled):active {
    box-shadow: none;
  }
  .removeIcon {
    left: 0;
    width: calc(var(--spacing-xs) * 1.5);
    height: calc(var(--spacing-xs) * 1.5);
  }
`;

type Option<T> = {
  key: React.Key;
  label?: string;
  value: T;
  disabled?: boolean;
};

export interface KeyedItem<T> {
  key: Key;
  value: T;
}

export type SearchInputProps<T> = ComponentPropsWithoutRef<
  typeof SelectorInput
> & {
  loading?: boolean;
  icon?: SmallIcons;
  onInputChange: (value: string) => void;
  selections: Option<T>[];
  onSelectionsChange: (selections: T[]) => void;
  setInputClick: (value: boolean) => void;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  sortable?: boolean;
  disabled?: boolean;
};
export const SearchInput = forwardRef(function <
  T extends KeyedItem<Option<T>>[]
>(props: SearchInputProps<T>, forwardedRef: Ref<HTMLDivElement>) {
  const {
    loading,
    onInputChange,
    icon: Icon,
    selections,
    onSelectionsChange,
    setInputClick,
    multiple,
    label,
    placeholder,
    sortable,
    children,
    disabled,
    ...forwardProps
  } = props;

  const disableSelector =
    disabled !== undefined
      ? disabled
      : (multiple === false && selections?.length === 1) ?? false;

  const id = useUniqueId();
  const [searchInput, setSearchInput] = useState<HTMLDivElement | null>(null);
  const searchItems = useMemo(
    () =>
      selections?.map((option: Option<T>) => {
        return {
          key: option.key ?? "",
          value: option,
        };
      }) ?? [],
    [selections]
  );

  const change = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onInputChange?.(evt.target.innerHTML);
    },
    [onInputChange]
  );

  const moveItem = useCallback(
    (
      data: {
        index: number;
      },
      dropIndex: number
    ) => {
      if (data.index !== dropIndex) {
        const sourceElement = selections[data.index];
        const newSelections = [...selections];
        newSelections.splice(data.index, 1);
        newSelections.splice(dropIndex, 0, sourceElement);
        onSelectionsChange?.(newSelections.map((option) => option.value));
      }
    },
    [selections, onSelectionsChange]
  );

  const removeItem = useCallback(
    (option: Option<T>) => {
      const newSelections = selections
        .filter((selection) => selection.key !== option.key)
        .map((selection) => selection.value);
      onSelectionsChange(newSelections);
    },
    [selections, onSelectionsChange]
  );

  return (
    <Wrapper
      onClick={(evt) => {
        // if you click anywhere on the wrapper border or padding, focus the search input
        if (evt.target === evt.currentTarget) {
          searchInput?.focus();
        }
      }}
    >
      {Icon &&
        (loading ? (
          <AnimatedEllipsisIcon size="small" className="loading" />
        ) : (
          <Icon size="small" />
        ))}
      {selections &&
        searchItems &&
        (sortable ? (
          <DraggableList
            items={searchItems}
            onMove={moveItem}
            orientation={"horizontal"}
          >
            {(option: Option<T>) => (
              <SelectionChip size="small" key={option.key}>
                <span>{option.label}</span>
                {option.disabled ? (
                  <></>
                ) : (
                  <RemoveButton
                    aria-label={`Remove ${option.label}`}
                    onClick={() => removeItem(option)}
                  >
                    <CloseIcon size="small" className="removeIcon" />
                  </RemoveButton>
                )}
              </SelectionChip>
            )}
          </DraggableList>
        ) : (
          selections.map((option) => {
            return (
              <SelectionChip size="small" key={option.key}>
                <span contentEditable={false}>{option.label}</span>
                {option.disabled ? (
                  <></>
                ) : (
                  <RemoveButton
                    aria-label={`Remove ${option.label}`}
                    onClick={() => removeItem(option)}
                  >
                    <CloseIcon size="small" className="removeIcon" />
                  </RemoveButton>
                )}
              </SelectionChip>
            );
          })
        ))}
      {!disableSelector && (
        <SelectorInput
          ref={(el) => {
            setSearchInput(el);
            if (forwardedRef) {
              propagateRef(forwardedRef, el);
            }
          }}
          id={`SelectorField-${id}`}
          contentEditable
          suppressContentEditableWarning
          onInput={change}
          onFocus={() => {
            setInputClick(true);
          }}
          onBlur={() => {
            setInputClick(false);
          }}
          data-ph={placeholder}
          {...forwardProps}
        />
      )}
    </Wrapper>
  );
});
SearchInput.displayName = "SearchInput";
