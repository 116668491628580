import { SVGAttributes } from "react";

export const VersoStoryImageGridWidthIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2a1 1 0 012 0v2a1 1 0 01-2 0V2zm12 0a1 1 0 112 0v2a1 1 0 11-2 0V2zM6 19a1 1 0 00-1 1v2a1 1 0 102 0v-2a1 1 0 00-1-1zm11 1a1 1 0 112 0v2a1 1 0 11-2 0v-2zM7 16V8h10v8H7zM5 7a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1H6a1 1 0 01-1-1V7z"
        fill="currentColor"
      />
    </svg>
  );
};
VersoStoryImageGridWidthIcon.displayName = "VersoStoryImageGridWidthIcon";
