import {
  createRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  RefAttributes,
  KeyboardEvent,
} from "react";
import styled from "styled-components";
import { CheckIcon } from "../../../icons";
import { ThemeProvider } from "../../../contexts";
import { propagateRef } from "../../../lib";
import { useUniqueId } from "../../../hooks";
import computeScrollIntoView from "compute-scroll-into-view";

const List = styled.ul`
  /* Resets */
  list-style: none;

  /* Positioning */
  position: relative;
  width: 100%;
  max-height: calc(var(--spacing-xl) * 5.5);
  overflow-y: auto;

  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  color: ${(props) => props.theme.Color};

  &:focus {
    outline: none;
  }

  li:first-of-type {
    margin-top: var(--spacing-xs);
  }
  li:last-of-type {
    margin-bottom: var(--spacing-xs);
  }
`;

const Option = styled.li`
  /* Resets */
  font-size: inherit;
  cursor: default;
  user-select: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${(props) => props.theme.SecondaryPadding};

  .check-mark {
    color: ${(props) => props.theme.Background};
    float: right;
  }

  &.focused {
    background: ${(props) => props.theme.SelectOptionHoverBackground};

    .check-mark {
      color: ${(props) => props.theme.SelectOptionHoverBackground};
    }
  }

  &[aria-selected="true"] {
    .check-mark,
    &.focused .check-mark {
      color: ${(props) => props.theme.SelectOptionSelectedCheckmarkColor};
    }

    &.focused {
      background: ${(props) => props.theme.SelectOptionSelectedHoverBackground};
    }
  }
`;

const SEARCH_TIMEOUT = 400;

type ListboxProps<T> = Omit<HTMLAttributes<HTMLUListElement>, "onChange"> & {
  id: string;
  children: (option: T, query?: string) => ReactNode;
  options: T[];
  onClose?: () => void;
  onSearch?: (options: T[], query: string) => T | undefined;
  onActiveDescendantChange?: (id: string) => void;
  autofocus?: boolean;
  trackEvent?: (component: string, type: string, label?: string) => void;
} & (
    | {
        multiple: true;
        value: T[];
        onChange: (options: T[]) => void;
      }
    | {
        multiple?: false;
        value?: T;
        onChange: (option: T | undefined) => void;
      }
  );

// The type of this function is at the bottom of the file (as a cast statement) to avoid an issue with forwardRef and generic functions
// if you are editing this file and want the type checking to work right, use this:

// export const Listbox = forwardRef(function <T>(props: ListboxProps<T>, forwardedRef: Ref<HTMLUListElement>) {

// eslint-disable-next-line react/display-name
export const Listbox = forwardRef(function (props, forwardedRef) {
  let {
    options,
    value,
    onChange,
    onClose,
    onSearch,
    onActiveDescendantChange,
    autofocus = true,
    trackEvent,
    ...forwardProps
  } = props;
  let initialCursorPosition = props.multiple
    ? options.indexOf(props.value[props.value.length - 1])
    : options.indexOf(props.value);
  if (initialCursorPosition === -1) {
    initialCursorPosition = 0;
  }

  let [activeCursor, setActiveCursor] = useState("keyboard");
  let [keyboardCursor, setKeyboardCursor] = useState(initialCursorPosition);
  let [pointerCursor, setPointerCursor] = useState<number | null>(null);
  let cursor = activeCursor === "keyboard" ? keyboardCursor : pointerCursor;

  let [query, setQuery] = useState("");
  let [match, setMatch] = useState<unknown | null>(null);
  let optionRefs = useMemo(
    () => options.map(() => createRef<HTMLLIElement>()),
    [options]
  );
  let [element, setElement] = useState<HTMLUListElement | null>(null);

  let lastQueryTime = useRef(0);

  let length = props.options.length;
  let uid = useUniqueId();
  let optionIdPrefix = `option-${uid}`;

  // Manually forward ref to the outer scope
  useEffect(() => {
    if (element && autofocus) {
      element.focus();
    }
  }, [element, autofocus]);

  useEffect(() => {
    onActiveDescendantChange &&
      onActiveDescendantChange(`${optionIdPrefix}-${cursor}`);
  }, [cursor, optionIdPrefix, onActiveDescendantChange]);

  let onKeyDown = (evt: KeyboardEvent<HTMLUListElement>) => {
    let newCursorPosition = cursor;
    let newQuery = query;
    if (evt.timeStamp - lastQueryTime.current > SEARCH_TIMEOUT) {
      newQuery = "";
    }

    switch (evt.key) {
      case "ArrowDown":
        if (cursor == null) {
          cursor = -1;
        }
        newCursorPosition = Math.min(cursor + 1, length - 1);
        newQuery = "";
        break;
      case "ArrowUp":
        if (cursor == null) {
          cursor = length;
        }
        newCursorPosition = Math.max(cursor - 1, 0);
        newQuery = "";
        break;
      case "Home":
        newCursorPosition = 0;
        newQuery = "";
        break;
      case "End":
        newCursorPosition = length - 1;
        newQuery = "";
        break;
      case "Escape":
        onClose && onClose();
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case "Backspace":
        newQuery = query.slice(0, query.length - 1);
        break;
      case " ":
        if (newQuery.length === 0) {
          if (cursor != null) {
            select(options[cursor]);
          }
          evt.preventDefault();
          evt.stopPropagation();
          return;
        } else {
          newQuery += " ";
        }
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case "Enter":
        if (cursor != null) {
          select(options[cursor]);
        }
        evt.preventDefault();
        evt.stopPropagation();
        return;
      case "Tab":
        // It's easier to encapsulate this component if we have focus
        // but we still want tab to go to the next control instead of
        // focusing our descendants
        onClose && onClose();
        evt.preventDefault();
        evt.stopPropagation();
        break;
      default:
        if (evt.key.length === 1) {
          newQuery += evt.key;
          evt.preventDefault();
          evt.stopPropagation();
        }
    }

    if (newQuery !== query) {
      setQuery(newQuery);

      if (newCursorPosition === cursor) {
        lastQueryTime.current = evt.timeStamp;

        let option = onSearch ? onSearch(options, newQuery) : null;
        setMatch(option);
        if (option) {
          newCursorPosition = props.options.indexOf(option);
        }
      }
    }

    if (newCursorPosition !== cursor && newCursorPosition != null) {
      setKeyboardCursor(newCursorPosition);
      setActiveCursor("keyboard");
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  useLayoutEffect(() => {
    if (
      document.activeElement &&
      document.activeElement === element &&
      activeCursor === "keyboard"
    ) {
      let option = optionRefs[keyboardCursor];
      if (option?.current) {
        let actions = computeScrollIntoView(option.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
      }
    }
  }, [activeCursor, keyboardCursor, optionRefs, element]);

  // eslint-disable-next-line
  let select = (option: any) => {
    if (props.multiple === true) {
      if (props.value.includes(option)) {
        props.onChange(props.value.filter((value) => value !== option));
      } else {
        props.onChange([...props.value, option]);
      }
    } else {
      props.onChange(option);
    }
  };

  useLayoutEffect(() => {
    let lastSelectedValue = props.multiple
      ? props.value?.[props.value.length - 1]
      : props.value;
    if (
      document.activeElement &&
      document.activeElement === element &&
      options.includes(lastSelectedValue)
    ) {
      let index = options.indexOf(lastSelectedValue);
      let option = optionRefs[index];
      if (option?.current) {
        let actions = computeScrollIntoView(option.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, optionRefs, options]);

  return (
    <ThemeProvider theme="modal">
      <List
        ref={(el) => {
          if (forwardedRef) {
            propagateRef(forwardedRef, el);
          }
          setElement(el);
        }}
        role="listbox"
        aria-multiselectable={props.multiple}
        tabIndex={0}
        onKeyDownCapture={onKeyDown}
        aria-activedescendant={
          cursor != null ? `${optionIdPrefix}-${cursor}` : undefined
        }
        {...forwardProps}
      >
        {options.map((option, index) => (
          <Option
            ref={optionRefs[index]}
            key={index}
            id={`${optionIdPrefix}-${index}`}
            role="option"
            className={cursor === index ? "focused" : ""}
            aria-selected={
              props.multiple
                ? props.value.includes(option)
                : option === props.value
            }
            onMouseEnter={() => {
              setPointerCursor(index);
            }}
            onMouseLeave={() => {
              setPointerCursor(null);
            }}
            onMouseMove={() => setActiveCursor("pointer")}
            onClick={() => {
              if (trackEvent) {
                trackEvent("tag_selector", "button_click", "onManualTagSelect");
              }
              select(option);
            }}
          >
            {props.children(
              option,
              match === option && query.length ? query : undefined
            )}
            <CheckIcon size="small" className="check-mark" />
          </Option>
        ))}
      </List>
    </ThemeProvider>
  );
}) as (<T>(
  props: ListboxProps<T> & Omit<RefAttributes<HTMLUListElement>, "onChange">
) => ReactElement) & { displayName?: string };
// n.b. This is a type hack so types are automatically
//      inferred via the value of options.
// @tim-evans

Listbox.displayName = "ARIA.Listbox";
