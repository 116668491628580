import { SVGAttributes } from "react";

export const RefreshIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.13 10.496a4.001 4.001 0 01-6.992-3.53 4 4 0 017.257-1.082l-.911-.244a1 1 0 00-.518 1.931l3.38.906a1 1 0 001.225-.707l.906-3.38a1 1 0 00-1.932-.518l-.317 1.183a6 6 0 10-.535 6.688 1 1 0 10-1.563-1.247z"
        fill="currentColor"
      />
    </svg>
  );
};
RefreshIcon.displayName = "RefreshIcon";
