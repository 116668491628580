import { SVGAttributes } from "react";

export const ScheduledIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.625 1a.875.875 0 100 1.75h.012c.093 1.298.725 2.532 1.395 3.484a11.078 11.078 0 001.576 1.788 7.781 7.781 0 00-1.252 1.465c-.598.906-1.12 2.092-1.547 3.763h-.184a.875.875 0 000 1.75h8.75a.875.875 0 000-1.75h-.14c-.426-1.671-.948-2.857-1.546-3.763a7.782 7.782 0 00-1.252-1.465 11.072 11.072 0 001.575-1.787c.671-.953 1.303-2.188 1.396-3.486A.875.875 0 0012.375 1h-8.75zm4.397 8.107c-.43.367-.83.774-1.206 1.344-.42.636-.832 1.508-1.195 2.799h4.803c-.364-1.29-.776-2.163-1.196-2.8a6.196 6.196 0 00-1.206-1.343zm0-2.177c.12-.098.26-.22.412-.365A9.33 9.33 0 009.58 5.227c.577-.819.979-1.684 1.069-2.477H5.394c.09.793.493 1.658 1.069 2.477.366.52.776.981 1.148 1.338.152.146.29.267.411.365z"
        fill="currentColor"
      />
    </svg>
  );
};
ScheduledIcon.displayName = "ScheduledIcon";
