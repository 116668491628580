import { createGlobalStyle } from "styled-components";

// https://www.figma.com/file/ciWj8Rf3tOvUeXwkuwnoDE/Copilot+Library
export const CSSVariables = createGlobalStyle`
:root {
  --font-size: 16px;
  --spacing-xxs: .25rem;
  --spacing-xs: .5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 2.5rem;
  --spacing-xxl: 3rem;
  --font-family-ibm-plex-sans: "IBM Plex Sans", sans-serif;
  --font-family-ibm-plex-mono: "IBM Plex Mono", monospace;
  --font-page-heading: 700 2rem / 2.5rem var(--font-family-ibm-plex-sans);
  --font-section-heading: 400 1.75rem / 2.25rem var(--font-family-ibm-plex-sans);
  --font-subhed: 500 1.25rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-smaller-heading: 400 1.125rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-body: 400 1rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-statements: 400 1rem / 1.25rem var(--font-family-ibm-plex-sans);
  --font-button: 700 1rem / 1.5rem var(--font-family-ibm-plex-sans);
  --font-small-statements: 400 0.875rem / 1rem var(--font-family-ibm-plex-sans);
  --font-label: 700 0.875rem / 1rem var(--font-family-ibm-plex-sans);
  --font-smaller-tag: 400 0.75rem / 1rem var(--font-family-ibm-plex-sans);
  --font-code: 400 0.875rem / 1rem var(--font-family-ibm-plex-mono);
  --font-asset-title: 500 1rem / 1.25rem var(--font-family-ibm-plex-sans);
  --font-asset-title-media: 400 0.875rem / 1.25rem var(--font-family-ibm-plex-sans);
  --color-black: #000000;
  --color-blue-10: #1B1E2E;
  --color-blue-20: #222B5C;
  --color-blue-30: #2A388A;
  --color-blue-40: #3246B9;
  --color-blue-50: #3D5AFE;
  --color-blue-60: #778BFE;
  --color-blue-70: #C5CDFF;
  --color-blue-80: #ECEFFF;
  --color-blue-90: #3349C4;
  --color-blue-100: #3953E7;
  --color-gray-1: #171717;
  --color-gray-2: #282828;
  --color-gray-3: #565656;
  --color-gray-4: #8B8B8B;
  --color-gray-5: #DCDCDC;
  --color-gray-6: #F3F3F3;
  --color-green-10: #1C3423;
  --color-green-20: #1F4229;
  --color-green-30: #245F34;
  --color-green-40: #297C40;
  --color-green-50: #31A752;
  --color-green-60: #77CF90;
  --color-green-70: #DEF1E3;
  --color-green-80: #ECF8EF;
  --color-green-90: #D9F2E2;
  --color-orange-10: #453318;
  --color-orange-20: #5D4118;
  --color-orange-30: #8B5D19;
  --color-orange-40: #B9791A;
  --color-orange-50: #FFA31B;
  --color-orange-60: #FFBF5F;
  --color-orange-70: #FFDAA4;
  --color-orange-80: #FFF6E8;
  --color-purple-10: #2E1F45;
  --color-purple-20: #3A235C;
  --color-purple-30: #512B89;
  --color-purple-40: #6833B7;
  --color-purple-50: #8A3FFC;
  --color-purple-60: #AD79FD;
  --color-purple-70: #D0B2FE;
  --color-purple-80: #F3ECFF;
  --color-red-10: #2B1819;
  --color-red-20: #52191C;
  --color-red-30: #791A1F;
  --color-red-40: #A01C23;
  --color-red-40-on-black: #E2525A;
  --color-red-50: #DA1E28;
  --color-red-60: #E56168;
  --color-red-70: #F0A5A9;
  --color-red-80: #FBE8E9;
  --color-scrim: rgba(23,23,23,0.5);
  --color-white: #FFFFFF;
  --color-yellow-10: #453C1E;
  --color-yellow-20: #5C4F22;
  --color-yellow-30: #8A7428;
  --color-yellow-40: #B8992F;
  --color-yellow-50: #FDD13A;
  --color-yellow-60: #FEDF75;
  --color-yellow-70: #FEEDB0;
  --color-yellow-80: #FFFAEB;
  --color-yellow-90: #FFECA8;
}
`;
