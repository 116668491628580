import { SVGAttributes } from "react";

export const ContentTypeMusicrecordingIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2a1 1 0 011 1v4a1 1 0 01-2 0V3a1 1 0 011-1zM5 3a3 3 0 016 0v4a3 3 0 01-6 0V3zM4 5a1 1 0 00-2 0v2a6.002 6.002 0 005 5.917V14H6a1 1 0 100 2h4a1 1 0 100-2H9v-1.083A6.002 6.002 0 0014 7V5a1 1 0 10-2 0v2a4 4 0 01-8 0V5z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 6v5a3 3 0 11-6 0V6a3 3 0 116 0zM7 6a5 5 0 0110 0v5a5 5 0 01-10 0V6zM5 9a1 1 0 10-2 0v2c0 4.633 3.5 8.448 8 8.945V22H9a1 1 0 100 2h6a1 1 0 100-2h-2v-2.055c4.5-.497 8-4.312 8-8.945V9a1 1 0 10-2 0v2a7 7 0 11-14 0V9zm5-2a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm1 2a1 1 0 100 2h2a1 1 0 100-2h-2z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeMusicrecordingIcon.displayName = "ContentTypeMusicrecordingIcon";
