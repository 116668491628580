import { SVGAttributes } from "react";

export const AssetIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 13h5a1 1 0 110 2H2a1 1 0 01-1-1V2a1 1 0 011-1h12a1 1 0 011 1v6a1 1 0 11-2 0V3H3v10z"
          fill="currentColor"
        />
        <path
          d="M11.054 7.238l-4.43 3.543A1 1 0 015.4 10.8l-4-3 1.2-1.6 3.38 2.535 4.395-3.516a1 1 0 011.18-.051l3 2-1.11 1.664-2.391-1.594z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill="currentColor"
        />
        <path d="M11 10a1 1 0 112 0v4a1 1 0 11-2 0v-4z" fill="currentColor" />
        <path d="M14 11a1 1 0 110 2h-4a1 1 0 110-2h4z" fill="currentColor" />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 16a1 1 0 001-1V4a2 2 0 00-2-2H4a2 2 0 00-2 2v16a2 2 0 002 2h11a1 1 0 100-2H4V4h16v11a1 1 0 001 1zm-2 4a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1v-1a1 1 0 10-2 0v1h-1zM12 9a3 3 0 11-6 0 3 3 0 016 0zm-3 1a1 1 0 100-2 1 1 0 000 2zm-2.707 7.707a1 1 0 001.414 0l2.42-2.42 2.318 1.545a1 1 0 001.262-.125l4-4a1 1 0 00-1.414-1.414l-3.42 3.42-2.318-1.545a1 1 0 00-1.262.125l-3 3a1 1 0 000 1.414z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
AssetIcon.displayName = "AssetIcon";
