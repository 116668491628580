import { SVGAttributes } from "react";

export const ContentTypeCookbookIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_64)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 3a1 1 0 011-1h3c.523 0 .737.16.827.257.11.12.173.297.173.493V4H5a1 1 0 01-1-1zm1 11h6V6H5v8zM2 6v8a2 2 0 002 2h7a2 2 0 002-2V6a2 2 0 00-2-2V2.75c0-.591-.186-1.288-.702-1.848C9.763.322 8.978 0 8 0H5a3 3 0 00-3 3v3zm4 2a1 1 0 011-1h2a1 1 0 010 2H7a1 1 0 01-1-1zm1 2a1 1 0 100 2h2a1 1 0 100-2H7z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_64">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 4a3 3 0 013-3h7a3 3 0 013 3v1a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm11 0v1H7.5C6 5 6 4 6 4a1 1 0 011-1h7a1 1 0 011 1zm2 3H8v14h9a1 1 0 001-1V8a1 1 0 00-1-1zm-7 4a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zm1 3a1 1 0 100 2h4a1 1 0 100-2h-4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeCookbookIcon.displayName = "ContentTypeCookbookIcon";
