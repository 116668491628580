import { ComponentPropsWithoutRef } from "react";
import { RoutableLink } from "../../../../index";

type MenuLink<T> = ComponentPropsWithoutRef<typeof RoutableLink> & {
  role: "link";
  value: T;
};

type MenuAnchor<T> = ComponentPropsWithoutRef<"a"> & {
  role: "link";
  value: T;
};

type MenuAction<T> = {
  role: "action";
  value: T;
  disabled?: boolean;
  onClick: () => Promise<unknown> | void;
};

type MenuItemRadio<T> = {
  role: "radio";
  value: T;
  checked: boolean;
  onChange: (value: T) => void;
};

type MenuItemCheckbox<T> = {
  role: "checkbox";
  value: T;
  checked: boolean;
  onChange: (value: boolean) => void;
};

type Separator = {
  role: "separator";
};

type Item<T> = {
  role: "item";
  value: T;
};

type Group<T> = {
  role: "group";
  label: string;
  items: Array<MenuItem<T>>;
};

export type MenuItem<T> =
  | MenuLink<T>
  | MenuAnchor<T>
  | MenuAction<T>
  | MenuItemRadio<T>
  | MenuItemCheckbox<T>
  | Separator
  | Item<T>
  | Group<T>;

export function isMenuLink<T>(item: MenuItem<T>): item is MenuLink<T> {
  return item.role === "link" && "to" in item;
}

export function isMenuAnchor<T>(item: MenuItem<T>): item is MenuAnchor<T> {
  return item.role === "link" && "href" in item;
}

export function isMenuAction<T>(item: MenuItem<T>): item is MenuAction<T> {
  return item.role === "action";
}

export function isMenuItemRadio<T>(
  item: MenuItem<T>
): item is MenuItemRadio<T> {
  return item.role === "radio";
}

export function isMenuItemCheckbox<T>(
  item: MenuItem<T>
): item is MenuItemCheckbox<T> {
  return item.role === "checkbox";
}

export function isGroup<T>(item: MenuItem<T>): item is Group<T> {
  return item.role === "group";
}

export function isSeparator<T>(item: MenuItem<T>): item is Separator {
  return item.role === "separator";
}

export function isItem<T>(item: MenuItem<T>): item is Item<T> {
  return item.role === "item";
}

type InteractiveItems<T> = Array<
  | MenuLink<T>
  | MenuAnchor<T>
  | MenuAction<T>
  | MenuItemRadio<T>
  | MenuItemCheckbox<T>
>;

export function getInteractiveItems<T>(items: Array<MenuItem<T>>) {
  return items.reduce((interactiveItems, item) => {
    if (isGroup(item)) {
      interactiveItems.push(...getInteractiveItems(item.items));
    } else if (!isSeparator(item) && !isItem(item)) {
      interactiveItems.push(item);
    }
    return interactiveItems;
  }, [] as InteractiveItems<T>);
}
