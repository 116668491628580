import { SVGAttributes } from "react";

export const ReloadIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12a7 7 0 0111.884-5.015l-2.057.008a1 1 0 10.008 2l4.123-.016a1 1 0 00.984-.844l.592-3.762a1 1 0 00-1.975-.31l-.241 1.53A9 9 0 1021 12a1 1 0 10-2-.001 7 7 0 11-14 0z"
        fill="currentColor"
      />
    </svg>
  );
};
ReloadIcon.displayName = "ReloadIcon";
