import { SVGAttributes } from "react";

export const CheckboxPartialIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM7 11a1 1 0 100 2h10a1 1 0 100-2H7z"
        fill="currentColor"
      />
    </svg>
  );
};
CheckboxPartialIcon.displayName = "CheckboxPartialIcon";
