import { SVGAttributes } from "react";

export const ContentTypeAirlineIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6.3 2.2a1.7 1.7 0 013.4 0v2.162l4.615 3.75a.5.5 0 01.132.612l-.6 1.2a.5.5 0 01-.615.247L9.7 8.91v3.181l.924.462a.5.5 0 01.223.67l-.6 1.2a.5.5 0 01-.447.277H6.2a.5.5 0 01-.447-.276l-.6-1.2a.5.5 0 01.223-.671l.924-.462V8.91l-3.532 1.26a.5.5 0 01-.615-.246l-.6-1.2a.5.5 0 01.132-.612L6.3 4.362V2.2z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2a1 1 0 00-1 1v4a1 1 0 01-.37.776L3.254 13.77l.245.49 6.166-2.202A1 1 0 0111 13v7a1 1 0 01-.553.894l-1.105.553.276.553h4.764l.276-.553-1.105-.553A1 1 0 0113 20v-7a1 1 0 011.336-.942l6.166 2.202.245-.49-7.378-5.994A1 1 0 0113 7V3a1 1 0 00-1-1zM9 3a3 3 0 116 0v3.524l7.63 6.2a1 1 0 01.264 1.223l-1 2a1 1 0 01-1.23.495L15 14.419v4.963l1.447.724a1 1 0 01.447 1.341l-1 2A1 1 0 0115 24H9a1 1 0 01-.894-.553l-1-2a1 1 0 01.447-1.341L9 19.382v-4.963l-5.664 2.023a1 1 0 01-1.23-.495l-1-2a1 1 0 01.263-1.223L9 6.524V3z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeAirlineIcon.displayName = "ContentTypeAirlineIcon";
