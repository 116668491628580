import { SVGAttributes } from "react";

export const AddIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M7 2a1 1 0 012 0v12a1 1 0 11-2 0V2z" fill="currentColor" />
        <path d="M14 7a1 1 0 110 2H2a1 1 0 010-2h12z" fill="currentColor" />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
AddIcon.displayName = "AddIcon";
