import { SVGAttributes } from "react";

export const ContentTypeReviewIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1a2 2 0 011.805 1.139l1.26 2.642 2.903.382a2 2 0 011.115 3.433l-2.123 2.016.533 2.878a2 2 0 01-2.92 2.122L8 14.216l-2.573 1.396a2 2 0 01-2.92-2.122l.533-2.878L.917 8.596a2 2 0 011.115-3.433l2.902-.383L6.195 2.14A2 2 0 018 1zM6.272 6.621l-3.978.525 2.91 2.763-.73 3.945L8 11.94l3.527 1.914-.73-3.945 2.91-2.763-3.979-.525L8 3 6.272 6.621z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2a2 2 0 011.805 1.139l1.837 3.849 4.228.557a2 2 0 011.115 3.433l-3.093 2.937.777 4.193a2 2 0 01-2.92 2.122L12 18.196 8.252 20.23a2 2 0 01-2.92-2.122l.776-4.194-3.093-2.936A2 2 0 014.13 7.545l4.228-.557 1.837-3.85A2 2 0 0112 2zM9.696 8.829l-5.304.699 3.88 3.683-.974 5.261L12 15.92l4.702 2.552-.974-5.26 3.88-3.684-5.304-.7L12 4 9.696 8.829z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeReviewIcon.displayName = "ContentTypeReviewIcon";
