import { SVGAttributes } from "react";

export const ContentTypeNewsletterIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H2zm0 3.302V12h12V5.302L8.64 9.768a1 1 0 01-1.28 0L2 5.302zM12.438 4H3.562L8 7.698 12.438 4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 4H4a3 3 0 00-3 3v10a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3zM3 7.414V17a1 1 0 001 1h16a1 1 0 001-1V7.414l-8.293 8.293a1 1 0 01-1.414 0L3 7.414zM19.586 6H4.414L12 13.586 19.586 6z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeNewsletterIcon.displayName = "ContentTypeNewsletterIcon";
