import { base } from "./base";
import type { Theme } from "./light";

const dark: Theme = {
  ...base,

  _name: "dark",

  Background: "var(--color-gray-1)",
  SurfaceColor: "var(--color-gray-1)",
  Color: "var(--color-gray-6)",

  FocusRing: "0 0 0 3px var(--color-blue-30)",

  PrimaryBackground: "var(--color-blue-50)",
  PrimaryColor: "var(--color-white)",
  PrimaryHoverBackground: "var(--color-blue-40)",
  PrimaryActiveBackground: "var(--color-blue-20)",
  PrimaryDisabledBackground: "var(--color-gray-3)",
  PrimaryDisabledColor: "var(--color-gray-2)",

  SecondaryColor: "var(--color-blue-60)",
  SecondaryRing: "inset 0 0 0 1px var(--color-gray-3)",
  SecondaryHoverRing: "inset 0 0 0 1px var(--color-blue-50)",
  SecondaryFocusRing: "inset 0 0 0 2px var(--color-blue-50)",
  SecondaryActiveBackground: "var(--color-gray-2)",
  SecondaryActiveColor: "var(--color-blue-70)",
  SecondaryActiveRing: "inset 0 0 0 1px var(--color-blue-50)",
  SecondaryDisabledColor: "var(--color-gray-3)",
  SecondaryDisabledRing: "inset 0 0 0 1px var(--color-gray-3)",

  BorderlessHoverBackground: "var(--color-gray-2)",
  BorderlessActiveBackground: "var(--color-gray-3)",

  FieldBackground: "var(--color-gray-2)",
  FieldColor: "var(--color-gray-6)",
  FieldRing: "inset 0 0 0 1px var(--color-gray-3)",

  ErrorRing: "inset 0 0 0 1px var(--color-red-50)",
  ErrorHoverRing: "inset 0 0 0 1px var(--color-red-50)",
  ErrorFocusRing:
    "inset 0 0 0 2px var(--color-red-50), 0 0 0 3px var(--color-red-30)",

  ControlIconColor: "var(--color-gray-4)",
  ControlOnBackground: "var(--color-blue-60)",
  ControlOnForeground: "var(--color-gray-2)",
  ControlOffBackground: "var(--color-gray-4)",
  ControlOffForeground: "var(--color-gray-2)",
  ControlDisabledBackground: "var(--color-gray-3)",
  ControlDisabledForeground: "var(--color-gray-1)",
  ControlDisabledColor: "var(--color-gray-5)",

  CardShadow: "none",
  MenuShadow: "0 0 var(--spacing-xs) rgba(43, 58, 68, 0.4)",

  SubheadingColor: "var(--color-gray-5)",
  PlaceholderColor: "var(--color-gray-4)",
  SupportColor: "var(--color-gray-4)",
  DividerColor: "var(--color-gray-3)",

  TooltipBackground: "var(--color-gray-2)",
  TooltipColor: "var(--color-white)",
  TooltipShadow: "0 0 0 1px var(--color-gray-3)",

  OutOfStockIconColor: "var(--color-red-50)",

  ToastIconColor: "var(--color-blue-70)",
  ToastBackground: "var(--color-gray-2)",
  ToastTintedBackground: "var(--color-blue-10)",
  ToastBorderColor: "var(--color-blue-50)",

  MedallionBackground: "var(--color-gray-3)",
  MedallionColor: "var(--color-white)",
  MedallionRing: "0 0 0 1px var(--color-gray-1)",

  StatusSuccessBackground: "var(--color-blue-40)",
  StatusPendingBackground: "var(--color-blue-30)",
  StatusDisabledBackground: "var(--color-blue-40)",
  StatusSuccessColor: "var(--color-white)",
  StatusPendingColor: "var(--color-gray-1)",
  StatusDisabledColor: "var(--color-white)",

  SearchMatchBackground: "var(--color-blue-20)",
  SearchImageBackground: "var(--color-gray-2)",
  SearchImageRing: "0 0 0 1px var(--color-gray-3)",

  NonInteractiveRing: "0 0 0 1px var(--color-gray-2)",

  SelectOptionHoverBackground: "var(--color-gray-2)",
  SelectOptionSelectedHoverBackground: "var(--color-blue-20)",
  SelectOptionSelectedCheckmarkColor: "var(--color-blue-50)",

  SelectButtonBackgroundColor: "var(--color-gray-2)",
  SelectButtonHoverBackgroundColor: "var(--color-gray-3)",

  AutofillButtonBackgroundColor: "transparent",
  AutofillButtonColor: "var(--color-purple-70)",
  AutofillButtonHoverBackground: "transparent",
  AutofillButtonHoverCursor: "pointer",

  SkeletonColor: "var(--color-gray-2)",
  SkeletonWaveColor: "var(--color-gray-3)",

  PillRing: "0 0 0 1px var(--color-gray-6)",

  ActionBarShadow: "0 4px 8px 0 var(--color-scrim)",

  ArchivedBackground: "var(--color-gray-6)",

  SelectedTabBorder: "var(--color-blue-60)",
  TabListBorder: "var(--color-gray-3)",
  InactiveTabTextColor: "var(--color-white)",
  ActiveTabTextColor: "var(--color-blue-60)",
};

export default dark;
