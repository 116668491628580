import { SVGAttributes } from "react";

export const ContentTypePhotoIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_57)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2zm2 8.366V14h5.754l-4.31-5.03-1.237 1.237a1.003 1.003 0 01-.207.16zm0-2.78l.793-.793a1 1 0 011.466.056l6 7c.041.048.077.098.107.151H14V2H2v5.586zM12 6a2 2 0 11-4 0 2 2 0 014 0z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_57">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6a3 3 0 00-3 3zm10.914 13H18a1 1 0 001-1V6a1 1 0 00-1-1H6a1 1 0 00-1 1v7.086l.793-.793a1 1 0 011.414 0L13.914 19zM5 15.914V18a1 1 0 001 1h5.086L6.5 14.414l-1.5 1.5zM17 9a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypePhotoIcon.displayName = "ContentTypePhotoIcon";
