import { SVGAttributes } from "react";

export const BinIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3a1 1 0 011-1h8a1 1 0 011 1v3h4a1 1 0 110 2H3a1 1 0 010-2h4V3zm2 3V4h6v2H9zm-3 4a1 1 0 00-1 1v7.5C5 20.552 6.448 22 8.5 22h7c2.052 0 3.5-1.448 3.5-3.5V11a1 1 0 10-2 0v7.5c0 .948-.552 1.5-1.5 1.5h-7c-.948 0-1.5-.552-1.5-1.5V11a1 1 0 00-1-1zm3 3a1 1 0 112 0v4a1 1 0 11-2 0v-4zm5-1a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1z"
        fill="currentColor"
      />
    </svg>
  );
};
BinIcon.displayName = "BinIcon";
