import styled from "styled-components";
import { HighlightedText } from "../../HighlightedText";

const Name = styled(HighlightedText)`
  font: ${(props) => props.theme.FontStatement};
`;
const Highlight = styled.span`
  font-weight: bolder;
`;
export const SearchResult = (props: { name?: string; query?: string }) => (
  <div>
    <Name text={props.name} query={props.query} components={{ Highlight }} />
  </div>
);
