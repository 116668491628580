import { useContext, SVGAttributes } from "react";
import { ThemeContext } from "styled-components";

export const CopilotIcon = (props: SVGAttributes<SVGElement>) => {
  let theme = useContext(ThemeContext);
  if (theme?._name.startsWith("dark")) {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_437_0)">
          <path
            d="M14.974 2.678c.165.037.326.087.484.148.445.17 1.124.516 1.828 1.203.874.853 1.585 2.025 2.112 3.484.726 2.007 1.094 4.526 1.094 7.485 0 6.26-1.598 9.305-2.939 10.757-.544.59-1.148 1.033-1.794 1.319-.242.109-.495.191-.754.245-5.908-.083-10.95-4.238-12.083-9.96-1.133-5.72 1.95-11.443 7.391-13.716a12.54 12.54 0 014.66-.965h.001zm.2-2.652C6.796.026 0 6.73 0 14.998S6.795 29.97 15.175 29.97c0 0 8.003-.503 8.003-14.972C23.178.688 15.175.026 15.175.026z"
            fill="#FF4561"
          />
          <path
            d="M15.027 2.678c5.901.097 10.928 4.257 12.054 9.974 1.125 5.717-1.959 11.431-7.394 13.701-1.486.62-3.08.95-4.692.967a3.612 3.612 0 01-.754-.245c-.646-.286-1.25-.73-1.794-1.319-.813-.88-1.471-2.067-1.956-3.524-.653-1.963-.983-4.397-.983-7.234 0-2.805.331-5.217.985-7.171.486-1.455 1.148-2.645 1.967-3.536.551-.601 1.164-1.057 1.82-1.356a3.81 3.81 0 01.746-.258zM14.825.026S6.822.69 6.822 14.998c0 14.469 8.003 14.972 8.003 14.972C23.205 29.97 30 23.266 30 14.998S23.205.026 14.825.026z"
            fill="#00BFF3"
          />
          <path
            d="M20.82 25.812a12.557 12.557 0 01-5.814 1.508h-.012a12.558 12.558 0 01-5.814-1.506c2.18 3.646 5.145 4.104 5.589 4.151l.231.004c.077.001.154 0 .231-.004.444-.047 3.408-.505 5.589-4.153zM14.974 2.678l.027.005.027-.005a12.54 12.54 0 015.73 1.472C18.562.56 15.615.08 15.22.031A29.865 29.865 0 0015 .026c-.073-.001-.147 0-.22.004-.396.05-3.343.53-5.536 4.12a12.54 12.54 0 015.73-1.473z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="clip0_437_0">
            <path fill="#fff" d="M0 0h30v30H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_437_1)">
          <path
            d="M14.974 2.678c.165.037.326.087.484.148.445.17 1.124.516 1.828 1.203.874.853 1.585 2.026 2.112 3.484.726 2.007 1.094 4.526 1.094 7.486 0 6.259-1.598 9.304-2.939 10.756-.544.59-1.148 1.034-1.794 1.319-.242.11-.495.192-.755.246-5.907-.083-10.948-4.239-12.082-9.96-1.133-5.722 1.95-11.444 7.391-13.717a12.54 12.54 0 014.66-.965h.001zm.2-2.652C6.796.026 0 6.731 0 15 0 23.265 6.795 29.97 15.175 29.97c0 0 8.003-.504 8.003-14.973C23.178.688 15.174.027 15.174.027z"
            fill="#FF4561"
          />
          <path
            d="M15.027 2.678c5.901.097 10.928 4.257 12.054 9.974 1.125 5.717-1.959 11.432-7.394 13.702-1.486.62-3.08.949-4.692.967a3.614 3.614 0 01-.754-.246c-.646-.285-1.25-.729-1.794-1.319-.813-.88-1.471-2.067-1.956-3.524-.652-1.963-.983-4.397-.983-7.233 0-2.806.331-5.218.985-7.172.486-1.455 1.148-2.645 1.967-3.536.551-.601 1.164-1.057 1.82-1.356.239-.111.489-.198.746-.258zM14.826.026S6.822.69 6.822 15c0 14.468 8.003 14.972 8.003 14.972C23.206 29.97 30 23.266 30 14.998 30 6.732 23.205.027 14.825.027z"
            fill="#00BFF3"
          />
          <path
            d="M20.82 25.813a12.559 12.559 0 01-5.814 1.508h-.012a12.557 12.557 0 01-5.814-1.507c2.18 3.646 5.145 4.104 5.589 4.151.077.003.154.004.23.005.078 0 .155 0 .232-.005.444-.047 3.408-.505 5.589-4.152zM14.974 2.678l.027.006.027-.006a12.54 12.54 0 015.73 1.472C18.562.56 15.615.08 15.22.031A29.86 29.86 0 0015 .026c-.073-.001-.147 0-.22.004-.396.05-3.343.53-5.536 4.12a12.54 12.54 0 015.73-1.473z"
            fill="#2B3A44"
          />
        </g>
        <defs>
          <clipPath id="clip0_437_1">
            <path fill="#fff" d="M0 0h30v30H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
CopilotIcon.displayName = "CopilotIcon";
