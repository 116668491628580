import { SVGAttributes } from "react";

export const UploadIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 15a1 1 0 110-2h10a1 1 0 110 2H3zM5.707 6.707a1 1 0 01-1.414-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L8 4.414 5.707 6.707z"
          fill="currentColor"
        />
        <path d="M7 4a1 1 0 112 0v7a1 1 0 11-2 0V4z" fill="currentColor" />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.293 8.707a1 1 0 001.414 0L11 5.414V15a1 1 0 102 0V5.414l3.293 3.293a1 1 0 101.414-1.414l-5-5a1 1 0 00-1.414 0l-5 5a1 1 0 000 1.414zM3 19a1 1 0 100 2h18a1 1 0 100-2H3z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
UploadIcon.displayName = "UploadIcon";
