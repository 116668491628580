import {
  ComponentType,
  ComponentPropsWithRef,
  ReactNode,
  useEffect,
} from "react";
import { Quick } from "./Quick";
import { Recent } from "./Recent";
import { AssetTile } from "./AssetTile";
import { SlatResult } from "./SlatResult";
import { AssetSelectorResult } from "./AssetSelectorResult";
import Document from "@atjson/document";
import CopilotMarkdownSource from "@condenast/atjson-source-copilot-markdown";
import VersoSource from "@condenast/atjson-source-verso";
import { Override, Props, ContentSummary } from "./types";
import { useInView } from "react-intersection-observer";

export type SearchResultItem = {
  treatment:
    | "quick"
    | "recent"
    | "asset-selector"
    | "asset-tile"
    | "slat-result";
  cdnHost?: string;
  result: ContentSummary;
  draggable?: boolean;
  loading?: string;
};

function enrichFields<
  T extends {
    title: {
      format: "markdown" | "plaintext";
      content: string;
    } | null;
    credit?: {
      format: "markdown" | "plaintext";
      content: string;
    } | null;
  }
>(result: T): Override<T, { title: VersoSource; credit?: VersoSource }> {
  const titleDocument =
    result.title?.format === "markdown"
      ? CopilotMarkdownSource.fromRaw(result.title?.content).convertTo(
          VersoSource
        )
      : new VersoSource({
          content: result.title?.content || "",
          annotations: [],
        });

  const creditDocument = result.credit
    ? result.credit?.format === "markdown"
      ? CopilotMarkdownSource.fromRaw(result.credit?.content).convertTo(
          VersoSource
        )
      : new VersoSource({
          content: result.credit?.content || "",
          annotations: [],
        })
    : undefined;

  return {
    ...result,
    credit: creditDocument,
    title: titleDocument,
  };
}

export type SearchResultProps<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
> = {
  children?: ReactNode;
  query?: string;
  as?: C;
  assetAs?: C;
  draggable?: boolean;
  assetindex?: number;
  blur?: boolean;
  trackNavigation?: () => void;
  trackViewInUse?: (index: number) => void;
  id?: string;
  selectionInitiated?: boolean;
  hasContextualOverrides?: boolean;
  hideIndex?: boolean;
  clampTitle?: number;
} & SearchResultItem &
  ComponentPropsWithRef<
    C extends keyof JSX.IntrinsicElements | ComponentType<unknown> ? C : "div"
  >;

export function SearchResult<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown>
>(props: SearchResultProps<C>) {
  let {
    treatment,
    query,
    cdnHost,
    as,
    assetAs,
    result,
    children,
    draggable,
    assetindex,
    trackNavigation,
    trackViewInUse,
    hideIndex,
    ...forwardProps
  } = props;
  const resultProps = {
    result: enrichFields(result) as Override<
      ContentSummary,
      { title?: Document; credit?: Document }
    >,
    query,
    cdnHost,
    as,
    assetAs,
    draggable,
    assetindex,
    hideIndex,
    trackNavigation,
    trackViewInUse,
    ...forwardProps,
  } as Props<C>;

  const [inViewRef, isVisible] = useInView({
    rootMargin: "80px",
    threshold: 0.25,
    triggerOnce: true,
    fallbackInView: true,
  });

  useEffect(() => {
    if (
      isVisible &&
      trackViewInUse &&
      assetindex != undefined &&
      assetindex != null
    ) {
      trackViewInUse?.(assetindex);
    }
  }, [isVisible, trackViewInUse, assetindex]);

  if (treatment === "quick") {
    return <Quick {...resultProps}>{children}</Quick>;
  } else if (treatment === "recent") {
    return <Recent {...resultProps}>{children}</Recent>;
  } else if (treatment === "asset-tile") {
    return (
      <div ref={inViewRef}>
        <AssetTile {...resultProps}>{children}</AssetTile>
      </div>
    );
  } else if (treatment === "asset-selector") {
    return (
      <AssetSelectorResult {...resultProps}>{children}</AssetSelectorResult>
    );
  } else if (treatment === "slat-result") {
    return (
      <div ref={inViewRef}>
        <SlatResult {...resultProps}>{children}</SlatResult>
      </div>
    );
  }

  throw new Error("the given treatment is unimplemented");
}

SearchResult.displayName = "SearchResult";
