import { forwardRef, ComponentPropsWithoutRef, Ref } from "react";
import { Link } from "react-router-dom";

export const RoutableLink = forwardRef(function (
  props: ComponentPropsWithoutRef<typeof Link>,
  forwardedRef: Ref<HTMLAnchorElement>
) {
  if (typeof props.to === "string") {
    const { to, ...linkProps } = props;
    try {
      const url = new URL(props.to);
      if (url.origin === window.location.origin) {
        return (
          <Link
            to={url.pathname + url.search + url.hash}
            {...linkProps}
            ref={forwardedRef}
            onClick={props.onClick}
          >
            {props.children}
          </Link>
        );
      } else {
        return (
          <a
            href={props.to}
            {...linkProps}
            ref={forwardedRef}
            onClick={props.onClick}
          >
            {props.children}
          </a>
        );
      }
    } catch {
      // Not an absolute URL. Fallback to the React Router
      // Link component below
    }
  }
  return <Link {...props} ref={forwardedRef} onClick={props.onClick} />;
});

RoutableLink.displayName = "RoutableLink";
