import { useEffect, useMemo, useState } from "react";

/**
 * Check to see if any queries are targeted by the browser.
 * If any media query returns `true`, then it will return `true`.
 * @param mediaQueries
 */
export const useMediaQuery = (...mediaQueries: string[]) => {
  let queries = useMemo(
    () => mediaQueries.map((query) => window.matchMedia(query), [mediaQueries]),
    [mediaQueries]
  );
  let [matches, setMatches] = useState(queries.some((query) => query.matches));

  useEffect(() => {
    function updateMatch() {
      setMatches(queries.some((query) => query.matches));
    }
    for (let query of queries) {
      if (query.addEventListener) {
        query.addEventListener("change", updateMatch);
      } else {
        query.addListener(updateMatch);
      }
    }

    return () => {
      for (let query of queries) {
        if (query.removeEventListener) {
          query.removeEventListener("change", updateMatch);
        } else {
          query.removeListener(updateMatch);
        }
      }
    };
  }, [queries]);
  return matches;
};
