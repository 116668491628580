import styled, { css } from "styled-components";
import Document from "@atjson/document";
import { HighlightedText } from "../../../HighlightedText";

const StyledHighlightedText = styled(HighlightedText)<{
  $clamp: number;
  $fidelity: "compact" | "default" | "full";
}>`
  overflow: hidden;
  ${(props) =>
    props.$clamp > 0
      ? css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${props.$clamp};
        `
      : css`
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
  font: ${(props) =>
    props.$fidelity === "full"
      ? props.theme.FontAssetTitle
      : props.theme.FontStatement};
`;

export function Title(props: {
  title?: Document;
  query?: string;
  fidelity: "compact" | "default" | "full";
  clamp?: number;
  className?: string;
}) {
  return (
    <StyledHighlightedText
      $clamp={props.clamp ?? 1}
      $fidelity={props.fidelity}
      className={props.className}
      document={props.title}
      query={props.query}
    />
  );
}
