import { SVGAttributes } from "react";

export const SettingsIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.293 10.293a1 1 0 111.414 1.414l-1 1a1 1 0 01-1.414-1.414l1-1zM11.293 3.293a1 1 0 111.414 1.414l-1 1a1 1 0 11-1.414-1.414l1-1zM10.293 11.707a1 1 0 111.414-1.414l1 1a1 1 0 01-1.414 1.414l-1-1zM3.293 4.707a1 1 0 011.414-1.414l1 1a1 1 0 01-1.414 1.414l-1-1zM7 12a1 1 0 112 0v2a1 1 0 11-2 0v-2zM7 2a1 1 0 012 0v2a1 1 0 01-2 0V2zM12 9a1 1 0 110-2h2a1 1 0 110 2h-2zM2 9a1 1 0 010-2h2.024a1 1 0 010 2H2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 13A5 5 0 108 3a5 5 0 000 10zm0-8a3 3 0 110 6 3 3 0 010-6z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.48 3a1 1 0 00-.991.876l-.246 1.968a7.07 7.07 0 00-1.505.875l-1.829-.773a1 1 0 00-1.255.422l-1.52 2.636a1 1 0 00.262 1.297l1.579 1.197a7.02 7.02 0 000 1.747l-1.579 1.197a1 1 0 00-.262 1.296l1.52 2.637a1 1 0 001.255.421l1.829-.772c.463.353.969.646 1.505.875l.246 1.968a1 1 0 00.992.876h3.04a1 1 0 00.993-.876l.245-1.968a7.068 7.068 0 001.506-.875l1.828.772a1 1 0 001.255-.421l1.52-2.636a1 1 0 00-.262-1.297l-1.579-1.197c.036-.29.055-.58.055-.872l-1-.002 1-.002c0-.291-.019-.582-.055-.871l1.58-1.197a1 1 0 00.262-1.297l-1.52-2.636a1 1 0 00-1.256-.422l-1.828.773a7.07 7.07 0 00-1.506-.875l-.245-1.968A1 1 0 0013.52 3h-3.04zm6.483 8.282c.078.358.118.723.12 1.09-.002.366-.042.73-.12 1.088a1 1 0 00.372 1.012l1.36 1.031-.638 1.107-1.575-.665a1 1 0 00-1.065.184 5.072 5.072 0 01-1.88 1.093 1 1 0 00-.687.828l-.211 1.693h-1.275l-.212-1.693a1 1 0 00-.686-.828 5.071 5.071 0 01-1.88-1.093 1 1 0 00-1.066-.184l-1.575.665-.638-1.107 1.36-1.031a1 1 0 00.372-1.013 5.02 5.02 0 010-2.175 1 1 0 00-.372-1.013L5.307 9.24l.638-1.107 1.575.665a1 1 0 001.066-.185 5.07 5.07 0 011.88-1.092 1 1 0 00.687-.829L11.363 5h1.276l.21 1.692a1 1 0 00.688.829 5.07 5.07 0 011.88 1.092 1 1 0 001.065.185l1.575-.665.639 1.107-1.36 1.03a1 1 0 00-.373 1.012zm-6.242 1.09c0-.71.574-1.284 1.28-1.284.706 0 1.28.574 1.28 1.283 0 .71-.574 1.284-1.28 1.284-.706 0-1.28-.574-1.28-1.284zM12 9.087a3.282 3.282 0 00-3.28 3.283A3.282 3.282 0 0012 15.655a3.282 3.282 0 003.28-3.284 3.282 3.282 0 00-3.28-3.283z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
SettingsIcon.displayName = "SettingsIcon";
