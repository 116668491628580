import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Field, NativeSelect, PrettySelect } from "../index";
import { useMediaQuery } from "../../hooks";
import styled from "styled-components";
import type { SmallIcons, RegularIcons } from "../../icons/types";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--spacing-xxs) 0;
  grid-template-rows: auto auto auto;
  height: fit-content;
  grid-template-areas:
    "label"
    "control"
    "."
    "message";
`;

export const Select = (
  props: Omit<ComponentPropsWithoutRef<typeof Field>, "children"> & {
    className?: string;
    disabled?: boolean;
    options: Array<{ label: string | null; value: string; disabled?: boolean }>;
    icon?: SmallIcons;
    regularIcon?: RegularIcons;
    alwaysUsePlaceholder?: boolean;
  } & (
      | {
          multiple: true;
          placeholder: string | ReactNode;
          value: string[];
          onChange: (values: string[]) => void;
        }
      | {
          multiple?: false;
          placeholder?: string | ReactNode;
          value?: string;
          onChange: (value: string | undefined) => void;
        }
    )
) => {
  let isTouchDevice = useMediaQuery("(hover: none), (hover: on-demand)");
  let { message, label, help, errors, ...forwardProps } = props;

  return (
    <Wrapper
      id={props.id}
      label={label}
      help={help}
      message={message}
      errors={errors}
      className={props.className}
    >
      {isTouchDevice ? (
        <NativeSelect {...forwardProps} aria-invalid={!!errors?.length} />
      ) : (
        <PrettySelect {...forwardProps} aria-invalid={!!errors?.length} />
      )}
    </Wrapper>
  );
};
