import {
  useCallback,
  useState,
  FC,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
} from "react";
import { ARIA, PrettySelect } from "../../index";
import { useDebouncedCallback } from "../../../hooks";
import type { Option } from "../types";
import { SearchIcon } from "../../../icons";
import { SearchInput } from "../SearchInput";
import { SearchResult } from "../SearchResult";
import { useIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import type { ContributorSelection } from "../types";

const LIST_KEY_EVENTS = ["ArrowDown", "ArrowUp", "Enter", "Home", "End"];

const QUICK_SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const NoSearchResults = styled.div`
  font: ${(props) => props.theme.FontBody};
  padding: var(--spacing-xs);
`;

const Exception = styled.div`
  padding: var(--spacing-xs);
  box-shadow: ${(props) => props.theme.FieldRing};
`;

const SelectBoxWrap = styled.div`
  width: 20%;
`;

const SearchBoxWarp = styled.div`
  width: 80%;
`;

export const SearchPanel: FC<{
  idPrefix: string;
  selections: ContributorSelection[];
  search: (query: string) => void;
  searchResult: {
    data?: ContributorSelection[];
    loading?: boolean;
    errors?: Error[];
  };
  onChange: (selections: ContributorSelection[]) => void;
  onSelectionsChange: (selections: ContributorSelection | undefined) => void;
  autofocus?: boolean;
  isFocused?: boolean;
  isExpanded?: boolean;
  setInputClicked?: (focus: boolean) => void;
  close: () => void;
  label?: string;
  contributorTypeOptions: Option<string>[];
  defaultContributorType?: Option<string>;
}> = (props) => {
  let {
    idPrefix,
    selections,
    search,
    searchResult,
    onChange,
    onSelectionsChange,
    close,
    setInputClicked,
    isFocused,
    isExpanded,
    label,
    contributorTypeOptions,
    defaultContributorType,
  } = props;
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [dropDownValue, setDropDownValue] = useState(
    defaultContributorType?.value || contributorTypeOptions[0].value
  );
  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);
  const [searchSpanElement, setSearchSpanElement] =
    useState<HTMLSpanElement | null>(null);

  const [listActiveDesecndant, setListActiveDescendant] = useState<
    string | undefined
  >(undefined);
  const activeDescendant = query ? listActiveDesecndant : "";
  const intl = useIntl();

  const onSearchInputKeyDown = useCallback(
    (event: ReactKeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Tab" || (!query && event.key === "Escape")) {
        close();
        return;
      }

      if (query && LIST_KEY_EVENTS.includes(event.key)) {
        listElement?.dispatchEvent(new KeyboardEvent("keydown", event));
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [query, listElement, close]
  );

  const updateDebouncedQuery = useDebouncedCallback(
    (query) => {
      setDebouncedQuery(String(query).trim());
    },
    QUICK_SEARCH_DEBOUNCE_INTERVAL,
    [setDebouncedQuery]
  );

  useEffect(() => {
    updateDebouncedQuery(query);
  }, [query, updateDebouncedQuery]);

  useEffect(() => {
    if (debouncedQuery) {
      search(debouncedQuery);
    }
  }, [debouncedQuery, search]);

  const selectSearchOption = useCallback(
    (contributorSelection: ContributorSelection | undefined) => {
      if (
        contributorSelection &&
        !selections.some(
          (selection) =>
            selection.id === contributorSelection.id &&
            selection.role === dropDownValue
        )
      ) {
        contributorSelection.role = dropDownValue;
        onChange([...selections, contributorSelection]);
      }
      setQuery("");
      searchSpanElement?.focus();
    },
    [selections, onChange, setQuery, searchSpanElement, dropDownValue]
  );

  return (
    <>
      <SelectBoxWrap>
        <label htmlFor="selectRole" style={{ display: "none" }}>
          SelectRole
        </label>
        <PrettySelect
          id="selectRole"
          value={dropDownValue}
          options={contributorTypeOptions}
          onChange={(value) => {
            if (value) {
              setDropDownValue(value);
            }
          }}
        />
      </SelectBoxWrap>
      <SearchBoxWarp role="main">
        <SearchInput
          forwardedRef={setSearchSpanElement}
          value={query}
          icon={SearchIcon}
          onChange={(query: string) => {
            setQuery(String(query).trim());
          }}
          loading={searchResult.loading ?? false}
          onKeyDown={onSearchInputKeyDown}
          aria-label={intl.formatMessage({
            defaultMessage: "Role Assign Input",
          })}
          label={label}
          aria-controls={`${idPrefix}-list`}
          aria-activedescendant={activeDescendant}
          selections={selections}
          onSelectionChange={onSelectionsChange}
          setInputClick={setInputClicked}
        />
        {debouncedQuery &&
          searchResult.data &&
          searchResult.data.length > 0 &&
          isExpanded && (
            <ARIA.Listbox
              id={`${idPrefix}-Listbox`}
              options={searchResult.data}
              onChange={selectSearchOption}
              ref={setListElement}
              autofocus={false}
              onActiveDescendantChange={setListActiveDescendant}
              aria-busy={searchResult.loading ? "true" : undefined}
            >
              {(contributorSelection: ContributorSelection) => (
                <SearchResult
                  name={contributorSelection.name}
                  query={debouncedQuery}
                />
              )}
            </ARIA.Listbox>
          )}
        {!debouncedQuery &&
          !query &&
          !selections.length &&
          isFocused &&
          isExpanded && (
            <Exception>
              <NoSearchResults>
                <FormattedMessage defaultMessage="Type to search" />
              </NoSearchResults>
            </Exception>
          )}

        {debouncedQuery &&
          searchResult.data?.length === 0 &&
          isExpanded &&
          !searchResult.loading && (
            <Exception>
              <NoSearchResults>
                <FormattedMessage defaultMessage="Your search returned no results." />
              </NoSearchResults>
            </Exception>
          )}
      </SearchBoxWarp>
    </>
  );
};
SearchPanel.displayName = "SearchPanel";
