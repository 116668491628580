import { SVGAttributes } from "react";

export const INTGQIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={46}
      height={24}
      viewBox="0 0 46 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1944_180)">
        <path
          d="M42.147 19.022s2.222-2.895 2.23-6.674C44.392 4.932 39.197.116 31.959.108 27.763.101 24.2 2.145 22.04 5.141c-.077-.109-.155-.21-.232-.318C20.005 2.431 16.885.008 12.046 0 6.356-.008 1.96 3.801.511 8.454c-.016.054-.031.116-.046.17v.008A11.8 11.8 0 000 11.915c.008 1.084.155 2.129.41 3.112.914 3.399 3.29 6.201 6.426 7.711.023.008.047.023.078.039l.046.023a12.72 12.72 0 005.241 1.13 12.118 12.118 0 009.716-4.985c.047-.062.086-.124.132-.186C24.155 21.794 27.654 24 31.997 24c2.88 0 4.916-1.231 5.288-1.424l.767 1.006h7.378l-3.283-4.56zM12.132 17.35c-2.578 0-5.505-2.036-5.56-5.304-.061-3.383 2.865-5.597 5.56-5.504 2.19.077 3.027.704 4.242 1.904h3.972v.008a11.53 11.53 0 00-.395 1.603h-6.96v5.628h3.05c0 .147-1.564 1.665-3.91 1.665zm25.525-3.732l-.627-.851h-7.487l3.043 4.397c-3.167.62-6.163-2.029-6.116-5.11.046-3.15 2.585-5.504 5.628-5.489 3.337.023 6.658 3.616 5.559 7.053z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_180">
          <path fill="#fff" d="M0 0h45.43v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
INTGQIcon.displayName = "INTGQIcon";
