import { SVGAttributes } from "react";

export const ContentTypeMusicalbumIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_104)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.079 2a2 2 0 012-2H14.11a2 2 0 012 2v12.033a2 2 0 01-2 2H2.08a2 2 0 01-2-2V2zm4 0h-2v5.026A6.002 6.002 0 0113.197 5h.914V2H4.08zm10.032 9h-.914A6.002 6.002 0 012.078 8.974v5.059h12.033V11zm-3.465-6H9a1 1 0 00-1 1v4a1 1 0 001 1h1.646a4 4 0 110-6z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_104">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 3a2 2 0 012-2h18a2 2 0 012 2v18a2 2 0 01-2 2H3a2 2 0 01-2-2V3zm20 5V3H3v18h18v-5h-.936a9 9 0 110-8H21zm-3.255 0a7 7 0 100 8H14a1 1 0 01-1-1V9a1 1 0 011-1h3.745z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeMusicalbumIcon.displayName = "ContentTypeMusicalbumIcon";
