import { SVGAttributes } from "react";

export const ChevronLeftIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.707 9.121a1 1 0 010-1.414l4-4a1 1 0 111.414 1.414L7.828 8.414l3.293 3.293a1 1 0 11-1.414 1.414l-4-4z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.94 10.94a1.5 1.5 0 000 2.12l7 7a1.5 1.5 0 002.12-2.12L10.122 12l5.94-5.94a1.5 1.5 0 00-2.122-2.12l-7 7z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ChevronLeftIcon.displayName = "ChevronLeftIcon";
