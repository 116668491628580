import { SVGAttributes } from "react";

export const EllipsisIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect x={2} y={6} width={3} height={3} rx={1} fill="currentColor" />
        <rect x={6.5} y={6} width={3} height={3} rx={1} fill="currentColor" />
        <rect x={11} y={6} width={3} height={3} rx={1} fill="currentColor" />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx={6} cy={12} r={2} fill="currentColor" />
        <circle cx={12} cy={12} r={2} fill="currentColor" />
        <circle cx={18} cy={12} r={2} fill="currentColor" />
      </svg>
    );
  }
};
EllipsisIcon.displayName = "EllipsisIcon";
