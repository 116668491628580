import { ReactNode, useCallback } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../icons";
import { Button } from "../../Button";

import {
  de,
  enUS,
  enGB,
  es,
  fr,
  it,
  ja,
  ru,
  zhCN,
  zhTW,
} from "date-fns/locale";

// Register these as lowercase locale names
registerLocale("de-de", de);
registerLocale("en-gb", enGB);
registerLocale("en-us", enUS);
registerLocale("es-es", es);
registerLocale("fr-fr", fr);
registerLocale("it-it", it);
registerLocale("ja-jp", ja);
registerLocale("ru-ru", ru);
registerLocale("zh-hans", zhCN);
registerLocale("zh-hant", zhTW);

const Wrapper = styled.div`
  .react-datepicker-popper {
    z-index: ${(props) => props.theme.ElevationMenu};
  }

  .react-datepicker__header {
    background: ${(props) => props.theme.SurfaceColor};
    border-bottom: 1px solid ${(props) => props.theme.DividerColor};
    padding: ${(props) => props.theme.SecondarySmallPadding};
  }

  .react-datepicker__month {
    padding: ${(props) => props.theme.SecondarySmallPadding};
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .react-datepicker__day-name {
    flex-grow: 1;
    padding: var(--spacing-xxs);
    text-align: center;
  }

  .react-datepicker__day {
    &:hover {
      background: ${(props) => props.theme.SecondaryActiveBackground};
      border-radius: ${(props) => props.theme.CornerRadius};
    }
    &[aria-selected="true"] {
      color: ${(props) => props.theme.PrimaryColor};
      background: ${(props) => props.theme.PrimaryActiveBackground};
      border-radius: ${(props) => props.theme.CornerRadius};
    }
  }
`;

const TextInput = styled.input`
  /* Resets */
  appearance: none;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
  }
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;

  grid-area: control;
  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  padding: ${(props) => props.theme.SecondaryPadding};

  &[readonly] {
    color: ${(props) => props.theme.FieldDisabledColor};
    outline: none;
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }

  &:hover:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldHoverRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }

  &:active:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:focus:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  padding: var(--spacing-xxs) 0;

  span {
    flex-grow: 1;
    align-self: center;
    text-align: center;
    font: ${(props) => props.theme.FontSmallerHeading};
  }
`;

const CalendarContainer = styled.div`
  /* Positioning */
  position: relative;
  width: 100%;

  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  color: ${(props) => props.theme.Color};
`;

const CalendarDay = styled.div`
  flex-grow: 1;
  padding: var(--spacing-xxs);
  text-align: center;
  cursor: pointer;
`;

export function PrettyDatePicker(props: {
  date: Date | null;
  children?: ReactNode;
  locale?: string;
  onChange: (date: Date) => void;
}) {
  const { date, onChange, locale, children, ...forwardProps } = props;
  const intl = useIntl();
  const resolvedLocale = locale || "en-GB";

  const CustomHeader = useCallback(
    ({ decreaseMonth, increaseMonth, monthDate }) => (
      <CalendarHeader>
        <Button
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            decreaseMonth();
          }}
          aria-label={intl.formatMessage({
            defaultMessage: "Previous Month",
            description: "Label for going to the previous month in a calendar",
          })}
        >
          <ChevronLeftIcon size="small" />
        </Button>
        <span>
          {monthDate.toLocaleString(resolvedLocale, {
            month: "long",
            year: "numeric",
          })}
        </span>
        <Button
          onClick={(evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            increaseMonth();
          }}
          aria-label={intl.formatMessage({
            defaultMessage: "Next Month",
            description: "Label for going to the next month in a calendar",
          })}
        >
          <ChevronRightIcon size="small" />
        </Button>
      </CalendarHeader>
    ),
    [resolvedLocale, intl]
  );

  const CustomDay = useCallback(
    (day, date) => (
      <CalendarDay
        role="option"
        aria-label={intl.formatMessage(
          {
            defaultMessage: "Select {date}",
            description: "Label for selecting a date from a calendar",
          },
          {
            date: Intl.DateTimeFormat(resolvedLocale, {
              day: "numeric",
              month: "long",
              year: "numeric",
            }).format(date),
          }
        )}
      >
        {day}
      </CalendarDay>
    ),
    [resolvedLocale, intl]
  );

  return (
    <Wrapper>
      <DatePicker
        selected={date}
        onChange={onChange}
        locale={resolvedLocale.toLowerCase()}
        dateFormat="P"
        popperPlacement="bottom-start"
        popperModifiers={[
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top-start"],
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 4],
            },
          },
          { name: "preventOverflow", options: { padding: 8 } },
        ]}
        customInput={<TextInput {...forwardProps} />}
        calendarContainer={CalendarContainer}
        renderCustomHeader={CustomHeader}
        renderDayContents={CustomDay}
        placeholderText="MM/DD/YYYY"
      />
    </Wrapper>
  );
}
