import { SVGAttributes } from "react";

export const USWiredIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={121}
      height={24}
      viewBox="0 0 121 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1944_175)">
        <path
          d="M101.291 3.73v16.6h8.18c2.286 0 3.609-.36 4.572-1.203 1.203-1.082 1.804-3.007 1.804-7.097s-.601-6.015-1.804-7.098c-.963-.842-2.286-1.203-4.572-1.203h-8.18zm11.188 8.3c0 3.609-.241 4.45-.962 4.932-.482.361-1.083.481-2.286.481h-4.571V6.496h4.571c1.203 0 1.804 0 2.286.481.721.602.962 1.444.962 5.053zM120.058 24h-24V0h24v24zM76.45 6.496V3.73h14.195v5.293h-3.008V6.496h-5.775v3.97h4.572v2.647h-4.572v4.45h5.896v-2.887h3.007v5.654H76.45v-2.766h2.285V6.496H76.45zM63.578 16c0 1.804.12 3.127.36 4.21h3.25c-.122-.842-.242-2.406-.242-4.45-.12-2.407-.843-2.768-2.527-3.129 1.925-.36 2.768-1.203 2.768-4.21 0-2.406-.362-3.368-1.084-3.97-.48-.481-1.322-.722-2.646-.722H53.352V20.33h3.368v-6.496h4.693c.962 0 1.322.12 1.684.361.362.24.481.602.481 1.805zm-6.858-4.812V6.616h5.415c.721 0 .962.12 1.081.241.24.24.481.601.481 2.045s-.24 1.925-.48 2.165c-.12.12-.36.241-1.082.241l-5.415-.12zM72 24h-24.06V0h24v24h.06zm-30.315-6.436v2.766h-11.43v-2.766h4.09V6.496h-4.09V3.73h11.429v2.767h-4.09v11.068h4.09zM22.376 3.729h-3.128l-2.286 13.113-2.646-12.15c-.12-.842-.482-.963-1.204-.963h-1.684c-.721 0-1.082.24-1.203.963L7.58 16.842 5.293 3.729H1.925l3.248 15.76c.12.721.36.841 1.203.841H8.66c.722 0 .963-.12 1.203-.842L12.391 8.06l2.526 11.428c.12.722.36.842 1.203.842h2.165c.722 0 1.083-.12 1.203-.842L22.375 3.73zM24.06 24H0V0h24v24h.06z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_175">
          <path fill="#fff" d="M0 0h120.058v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
USWiredIcon.displayName = "USWiredIcon";
