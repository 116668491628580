import { AlertDialog } from "./AlertDialog";
import { Combobox } from "./Combobox";
import { Dialog } from "./Dialog";
import { Listbox } from "./Listbox";
import { Menu } from "./Menu";
import { MenuButton } from "./MenuButton";
import { TabList } from "./TabList";
import { TabPanel } from "./TabPanel";
import { TreeView } from "./TreeView";

export const ARIA = {
  AlertDialog,
  Combobox,
  Dialog,
  Listbox,
  Menu,
  MenuButton,
  TabList,
  TabPanel,
  TreeView,
};
