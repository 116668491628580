import { ReactNode, ReactNodeArray, useState, MouseEventHandler } from "react";
import { useAccordionContext } from "./useAccordionContext";
import styled from "styled-components";
import { ArrowRightIcon } from "../../icons";

export interface AccordionPanelProps {
  id: number;
  header: ReactNode;
  children?: ReactNode | ReactNodeArray;
  className?: string;
  "aria-level"?: number;
}

const StyledPanel = styled.div<{ $border?: boolean }>`
  border-bottom: 0;
  background: ${(props) => props.theme.ControlOnForeground};
`;

const StyledPanelHeaderTrigger = styled.button<{ $active: boolean }>`
  /** Reset */
  appearance: none;
  border: 0;
  text-decoration: none;
  &::-moz-focus-inner {
    border: 0;
  }

  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--spacing-sm);
  width: 100%;
  text-align: left;
  color: inherit;
  font: inherit;
  background: ${(props) => props.theme.SelectButtonBackgroundColor};
  &:disabled {
    background: ${(props) => props.theme.Background};
    border: 1px solid ${(props) => props.theme.DividerColor};
    cursor: default;
  }
  ${(props) => {
    const {
      $active,
      theme: { SelectButtonHoverBackgroundColor },
    } = props;
    if (!$active) {
      return `&:not(:disabled):hover {
        background: ${SelectButtonHoverBackgroundColor};
      }`;
    }
    return ``;
  }}
`;

const StyledArrowContainer = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  ${(props) => props.$active && `transform: rotate(90deg);`}
`;

const StyledHeaderContainer = styled.div`
  flex: 1;
`;

const StyledPanelBody = styled.div<{ $active: boolean }>`
  ${(props) =>
    props.$active && `padding: 0 var(--spacing-sm) var(--spacing-sm);`}
`;

export const AccordionPanel = ({
  children,
  className,
  header,
  id,
  "aria-level": ariaLevel = 3,
}: AccordionPanelProps) => {
  const { active, setActive, multiExpansion } = useAccordionContext();
  const [localActive, setLocalActive] = useState(false);
  const isActive = multiExpansion ? localActive : active === id;
  const enableExpansion = Boolean(children);

  const getClickHandler = (): MouseEventHandler<HTMLButtonElement> => {
    if (!enableExpansion) {
      return () => null;
    }
    if (multiExpansion) {
      return () => setLocalActive((state) => !state);
    }
    return () => {
      setActive(isActive ? -1 : id);
    };
  };

  return (
    <StyledPanel id={`accordion-panel-${id}`} className={className}>
      <div role="heading" aria-level={ariaLevel}>
        <StyledPanelHeaderTrigger
          onClick={getClickHandler()}
          $active={isActive}
          aria-controls={`accrodrion-panel-body-${id}`}
          aria-expanded={isActive}
          disabled={!enableExpansion}
          id={`accordion-panel-header-${id}`}
        >
          <StyledHeaderContainer>{header}</StyledHeaderContainer>
          {enableExpansion && (
            <StyledArrowContainer $active={isActive}>
              <ArrowRightIcon size="small" />
            </StyledArrowContainer>
          )}
        </StyledPanelHeaderTrigger>
      </div>
      <StyledPanelBody
        $active={isActive}
        aria-labelledby={`accordion-panel-header-${id}`}
        id={`accrodrion-panel-body-${id}`}
        role="region"
      >
        {enableExpansion && isActive && <section>{children}</section>}
      </StyledPanelBody>
    </StyledPanel>
  );
};

AccordionPanel.displayName = "AccordionPanel";
