import { useCallback, useMemo, useState, ReactNode } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { ARIA } from "../../ARIA";

const StyledCombobox = styled(ARIA.Combobox)`
  [role="listbox"] {
    max-height: 25rem;
    overflow: scroll;
  }

  [role="option"][aria-selected="true"] {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }

  input {
    width: 100%;
    padding-right: 0;
  }
`;

const TimeOption = styled.span`
  padding: ${(props) => props.theme.SecondaryTightPadding};
`;

export function PrettyTimePicker(props: {
  date: Date;
  onChange: (date: Date) => void;
  children?: ReactNode;
}) {
  const { date, onChange, ...forwardProps } = props;
  const intl = useIntl();
  const [formattedTime, setFormattedTime] = useState(
    intl.formatDate(date, {
      hour: "numeric",
      minute: "numeric",
    })
  );
  const dateString = useMemo(() => new Date(date).toDateString(), [date]);
  const timeOptions = useMemo(
    () =>
      [...Array(48).keys()].map((index) => {
        const hour = Math.floor(index / 2);
        const min = index % 2 === 0 ? 0 : 30;
        const dateWithTime = new Date(dateString);
        dateWithTime.setHours(hour, min);
        return {
          role: "option" as const,
          value: {
            date: dateWithTime,
            label: intl.formatDate(dateWithTime, {
              hour: "numeric",
              minute: "numeric",
            }),
          },
        };
      }),
    [intl, dateString]
  );

  const onInputChange = useCallback(
    (value) => {
      setFormattedTime(value);
      const newDate = new Date(`${date.toDateString()} ${value}`);
      if (!isNaN(newDate.getTime())) {
        onChange(newDate);
      }
    },
    [date, onChange]
  );

  const onSelect = useCallback(
    ({ date }) => {
      setFormattedTime(
        intl.formatDate(date, {
          hour: "numeric",
          minute: "numeric",
        })
      );
      onChange(date);
    },
    [intl, onChange]
  );

  return (
    <StyledCombobox
      items={timeOptions}
      onChange={onInputChange}
      onSelect={onSelect}
      value={formattedTime}
      {...forwardProps}
    >
      {(item: { date: Date; label: string }) => (
        <TimeOption>{item.label}</TimeOption>
      )}
    </StyledCombobox>
  );
}
PrettyTimePicker.displayName = "PrettyTimePicker";
