import { SVGAttributes } from "react";

export const ContentTypeRestaurantIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_86)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0a1 1 0 011 1v5h1.024l-.012-4.998a1 1 0 112-.004L6.024 6H7V1a1 1 0 012 0v6a1 1 0 01-1 1H6.029l.016 6.998a1 1 0 01-2 .004L4.03 8H2a1 1 0 01-1-1V1a1 1 0 011-1zm10.428.883a1.1 1.1 0 011.13-.882L13.603 0a1 1 0 011.003.998l.033 14a1 1 0 11-2 .004l-.012-5.01a1.802 1.802 0 01-1.592-2.147L12.428.883z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_86">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3a1 1 0 00-2 0v6a1 1 0 001 1h2.029l.026 11.002a1 1 0 002-.004L6.029 10H8a1 1 0 001-1V3a1 1 0 00-2 0v5h-.976l-.012-5.002a1 1 0 10-2 .004L4.024 8H3V3zm14 3c0 1.61-1.008 3-1.982 3.633l.027 11.365a1 1 0 01-2 .004l-.027-11.346C12.033 9.034 11 7.631 11 6c0-2.21 1.895-4 3-4h.006c.028 0 .056.001.084.004C15.204 2.095 17 3.85 17 6zm2.823-3.117a1.1 1.1 0 011.13-.882L21 2a1 1 0 011.002.998l.043 18a1 1 0 11-2 .004l-.021-9.01a1.802 1.802 0 01-1.593-2.147l1.392-6.962z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeRestaurantIcon.displayName = "ContentTypeRestaurantIcon";
