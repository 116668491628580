import { SVGAttributes } from "react";

export const TextLeftIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 21.5a1 1 0 01-1-1v-18a1 1 0 112 0v18a1 1 0 01-1 1zm10.707-5.707a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L10.914 10.5H20.5a1 1 0 110 2h-9.586l3.293 3.293z"
        fill="currentColor"
      />
    </svg>
  );
};
TextLeftIcon.displayName = "TextLeftIcon";
