import styled from "styled-components";

export const Input = styled.input`
  /* Resets */
  appearance: none;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
  }
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;

  grid-area: control;
  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  padding: ${(props) => props.theme.SecondaryPadding};

  &[readonly] {
    color: ${(props) => props.theme.FieldDisabledColor};
    outline: none;
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }

  &:hover:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldHoverRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }

  &:active:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:focus:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;
