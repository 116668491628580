import React, { ComponentPropsWithRef, ReactNode } from "react";
import {
  useKeyboardNavigation,
  ElementType,
} from "../../../hooks/useKeyboardNavigation";

import styled from "styled-components";
import { Tab } from "../Tab";

const Panels = styled.div<{ $activeTab: string }>`
  > [role="tabpanel"] {
    display: none;

    &#${(props) => props.$activeTab} {
      display: block;
    }
  }
`;

type TabListProps = {
  tabs: {
    id: string;
    Label: ReactNode | string;
    onClick?: () => void;
  }[];
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
} & ComponentPropsWithRef<"ul">;

const TabListContainer = styled.ul`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.TabListBorder};
  box-sizing: border-box;
  overflow-x: auto;

  > [role="tab"]:not(:first-child) {
    margin-left: var(--spacing-sm);
  }
`;

export const TabList: React.FC<TabListProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  children,
  ...props
}) => {
  const containerRef = React.createRef<HTMLUListElement>();

  const selectTabAtIndex = (index: number) => {
    tabs[index]?.onClick?.();
    setActiveTab(tabs[index]?.id);
  };

  const { onKeyDown, onMouseMove, onFocus, onBlur } = useKeyboardNavigation(
    ElementType.TabList,
    tabs.length,
    {
      onEnter: selectTabAtIndex,
    }
  );

  return (
    <>
      <TabListContainer
        role="tablist"
        ref={containerRef}
        tabIndex={0}
        {...props}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {tabs.map(({ id, Label }, index) => (
          <Tab
            key={id}
            aria-selected={activeTab === id}
            onMouseMove={() => {
              onMouseMove(index);
            }}
            onClick={() => selectTabAtIndex(index)}
          >
            {Label}
          </Tab>
        ))}
      </TabListContainer>
      <Panels $activeTab={activeTab}>{children}</Panels>
    </>
  );
};

TabList.displayName = "ARIA.TabList";
