import { SVGAttributes } from "react";

export const USSelfIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={73}
      height={24}
      viewBox="0 0 73 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1944_166)">
        <path
          d="M70.95.14H58.456c-1.277 0-1.696.455-1.696 1.835v20.177c0 1.28.456 1.696 1.836 1.696H59.9c1.38 0 1.835-.43 1.835-1.696v-7.923h7.747c1.279 0 1.696-.455 1.696-1.835v-.886c0-1.38-.43-1.836-1.696-1.836h-7.734V4.925h9.202c1.28 0 1.697-.455 1.697-1.835V1.99C72.647.596 72.228.14 70.95.14zM52.05 19.075h-5.95c-1.417 0-2.165-.215-2.658-.67-.57-.532-.747-1.28-.747-2.66V1.977c0-1.381-.43-1.837-1.696-1.837h-1.595c-1.28 0-1.696.456-1.696 1.836v14.266c0 2.582.494 4.316 1.658 5.594 1.304 1.38 3.19 2.013 6.228 2.013h6.443c1.28 0 1.697-.455 1.697-1.835v-1.101c.012-1.38-.405-1.836-1.684-1.836zm-18.43 0H27.1c-1.417 0-2.165-.215-2.659-.67-.569-.532-.746-1.28-.746-2.66V14.23h8.177c1.279 0 1.696-.455 1.696-1.835v-.886c0-1.38-.43-1.836-1.696-1.836h-8.177V4.925h9.632c1.278 0 1.696-.455 1.696-1.835V1.99c0-1.38-.43-1.836-1.696-1.836h-12.91c-1.28 0-1.697.456-1.697 1.836v14.266c0 2.582.494 4.316 1.659 5.595 1.303 1.38 3.189 2.012 6.227 2.012h7.013c1.279 0 1.697-.456 1.697-1.835v-1.103c0-1.392-.418-1.848-1.697-1.848zM5.252 6.936c0-1.28 1.418-2.164 3.747-2.164 2.165 0 3.784.455 4.886.455.784 0 1.025-.43 1.025-1.279V2.405c0-1.126-.139-1.519-1.38-1.835C12.227.215 10.417 0 8.682 0 3.974 0 .075 2.76.075 7.291c0 7.824 11.076 6.229 11.076 9.633 0 1.342-1.417 2.304-4.215 2.304-1.557 0-3.823-.392-5.202-.785-.393-.1-.608-.14-.81-.14-.672 0-.924.432-.924 1.242v1.873c0 1.025.278 1.342 1.417 1.734 1.557.531 4.038.848 5.95.848 5.81 0 8.924-2.936 8.924-7.405.013-7.747-11.038-6.19-11.038-9.659z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_166">
          <path fill="#fff" d="M0 0h72.647v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
USSelfIcon.displayName = "USSelfIcon";
