import dark from "./dark";
import light from "./light";
import { DefaultTheme } from "styled-components";

function lighten(theme: Record<string, number | string>) {
  let lightenedTheme = { ...theme };
  for (let key of Object.keys(theme) as (keyof DefaultTheme)[]) {
    let value = theme[key];
    if (typeof value === "string") {
      lightenedTheme[key] = value
        .replace(/-blue-(\d+)/g, (_, number) => {
          return `-blue-${Math.min(parseInt(number, 10) + 10, 80)}`;
        })
        .replace(/-gray-(\d+)/g, (_, number) => {
          if (number === "6") {
            return `-white`;
          }
          return `-gray-${Math.min(parseInt(number, 10) + 1, 6)}`;
        });
    } else {
      lightenedTheme[key] = value;
    }
  }
  return lightenedTheme as unknown as DefaultTheme;
}

export default {
  dark: dark,
  light: light,
  darkModal: {
    ...lighten(dark),
    _name: "darkModal",
  },
  lightModal: {
    ...light,
    _name: "lightModal",
  },
};
