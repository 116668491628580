import { SVGAttributes } from "react";

export const ContentTypeAdvertiserIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_96)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 5h3v3H3V5zm3 5c.224 0 .44-.037.64-.105.132.122.281.228.445.312l5 2.572A2 2 0 0015 11V2A2 2 0 0012.085.221l-5 2.572c-.164.084-.313.19-.445.312A1.998 1.998 0 006 3H3a2 2 0 00-2 2v3a2 2 0 002 2v4a1 1 0 001 1h2.054a.946.946 0 00.464-1.771l-.342-.192a1 1 0 01-.51-.872V10H6zm2-1.571V4.57L13 2v9L8 8.429z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_96">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 9h4v4H5V9zm4 6c.088 0 .174-.006.258-.017a2 2 0 00.848.806l8 4A2 2 0 0021 18V4a2 2 0 00-2.894-1.789l-8 4a1.999 1.999 0 00-.848.806A2.02 2.02 0 009 7H5a2 2 0 00-2 2v4a2 2 0 002 2v6a1 1 0 001 1h2.5a1 1 0 001-1v-.465a1 1 0 00-.445-.832l-.61-.406A1 1 0 018 18.465V15h1zm2-1V8l8-4v14l-8-4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeAdvertiserIcon.displayName = "ContentTypeAdvertiserIcon";
