import { SVGAttributes } from "react";

export const NewlineIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 4H5a1 1 0 010-2h8a1 1 0 011 1v8a1 1 0 01-1 1H4a1 1 0 110-2h8V4z"
        fill="currentColor"
      />
      <path
        d="M6.535 12.95a1 1 0 11-1.414 1.414l-2.828-2.829a1 1 0 010-1.414L5.12 7.293a1 1 0 011.414 1.414l-2.12 2.121 2.12 2.122z"
        fill="currentColor"
      />
    </svg>
  );
};
NewlineIcon.displayName = "NewlineIcon";
