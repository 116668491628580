import { SVGAttributes } from "react";

export const CheckCircleIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8zm2 0c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6z"
        fill="currentColor"
      />
      <path
        d="M10.25 5.338a1 1 0 111.5 1.324l-4 4.528a1 1 0 01-1.457.045l-2-2A1 1 0 015.707 7.82l1.248 1.247 3.296-3.73z"
        fill="currentColor"
      />
    </svg>
  );
};
CheckCircleIcon.displayName = "CheckCircleIcon";
