import {
  useLayoutEffect,
  useState,
  forwardRef,
  ComponentPropsWithoutRef,
  KeyboardEvent,
} from "react";
import { propagateRef } from "../../../lib";
import styled from "styled-components";
import { useResizeEffect } from "../../../hooks";

const StyledTextArea = styled.textarea`
  /* Resets */
  appearance: none;
  border: 0;
  &::-moz-focus-inner {
    border: 0;
  }
  font: ${(props) => props.theme.FontBody};
  width: 100%;
  text-align: left;
  resize: none;

  grid-area: control;
  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  padding: ${(props) => props.theme.SecondaryPadding};

  &[readonly] {
    color: ${(props) => props.theme.FieldDisabledColor};
    outline: none;
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }

  &:hover:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldHoverRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }

  &:active:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:focus:not([readonly]) {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  ComponentPropsWithoutRef<"textarea"> & {
    rows?: number;
    maxRows?: number;
    autogenButton?: HTMLElement;
    multiline?: boolean;
  }
>((props, externalRef) => {
  let [ref, setRef] = useState<HTMLTextAreaElement | null>(null);
  let [height, setHeight] = useState(0);
  let [rowHeight, setRowHeight] = useState("${rows}");

  const refWidth = useResizeEffect(ref, (ref) => ref.clientWidth, [ref]);

  useLayoutEffect(() => {
    let element = ref;
    if (element) {
      let buttonRect =
        props.autogenButton?.getBoundingClientRect() ?? undefined;

      if (buttonRect) {
        props.autogenButton?.setAttribute(
          "style",
          `top: calc(var(--spacing-lg)); right: calc(var(--spacing-xxs))`
        );
        let computedHostPadding = element
          ? getComputedStyle(element).paddingRight
          : undefined;
        element.style.paddingRight =
          (computedHostPadding &&
            buttonRect &&
            (computedHostPadding < `${buttonRect.width}px`
              ? `calc(${computedHostPadding} + ${buttonRect.width}px)`
              : computedHostPadding)) ||
          "";
      }
    }
  }, [ref, props.autogenButton]);

  useLayoutEffect(() => {
    if (ref) {
      let styles = getComputedStyle(ref);
      setRowHeight(
        `calc(${styles.paddingTop} + (${styles.lineHeight} * \${rows}) + ${styles.paddingBottom})`
      );
    }
  }, [ref]);

  useLayoutEffect(() => {
    if (ref) {
      let wasHeight = ref.style.height;
      ref.style.height = "0px";
      setHeight(ref.scrollHeight);
      ref.style.height = wasHeight;
    }
  }, [ref, props.value, props.autogenButton, refWidth]);

  let onKeyDown = (evt: KeyboardEvent<HTMLTextAreaElement>) => {
    if (evt.key === "Enter" && !props.multiline) {
      evt.preventDefault();
      evt.stopPropagation();
      return;
    }
  };

  const { style, ...newProps } = props;

  return (
    <StyledTextArea
      style={{
        ...style,
        height,
        minHeight: rowHeight.replace("${rows}", (props.rows || 1).toString()),
        maxHeight: props.maxRows
          ? rowHeight.replace("${rows}", props.maxRows.toString())
          : undefined,
      }}
      ref={(element) => {
        if (externalRef) {
          propagateRef(externalRef, element);
        }
        setRef(element);
      }}
      onKeyDownCapture={onKeyDown}
      {...newProps}
    />
  );
});

TextArea.displayName = "TextArea";

TextArea.defaultProps = {
  rows: 1,
};
