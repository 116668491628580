import { SVGAttributes } from "react";

export const WandIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={17}
        height={16}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2 14l8-8M12 10l.707.707M14 7h1.414M13 3.414L14.414 2M5.707 4.707L5 4M9 2.414V1"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g
          clipPath="url(#clip0_47_71)"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
        >
          <path d="M14.828 9.172a1 1 0 010 1.414L4.708 20.707a1 1 0 11-1.415-1.414L13.414 9.172a1 1 0 011.414 0zM12.707 3.222a1 1 0 011 1v1.414a1 1 0 11-2 0V4.222a1 1 0 011-1zm4.95 1.707a1 1 0 111.414 1.414l-1.414 1.414a1 1 0 11-1.414-1.414l1.414-1.414zM8.465 7.05a1 1 0 011.414 0l.707.707a1 1 0 01-1.414 1.415l-.707-.708a1 1 0 010-1.414zm8.899 4.243a1 1 0 011-1h1.414a1 1 0 110 2h-1.414a1 1 0 01-1-1zm-2.536 2.121a1 1 0 011.415 0l.707.707a1 1 0 01-1.414 1.415l-.707-.708a1 1 0 010-1.414z" />
        </g>
        <defs>
          <clipPath id="clip0_47_71">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
WandIcon.displayName = "WandIcon";
