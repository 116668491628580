import { SVGAttributes } from "react";

export const BackIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M2 9a1 1 0 110-2h12a1 1 0 110 2H2z" fill="currentColor" />
        <path
          d="M7.707 12.293a1 1 0 11-1.414 1.414l-5-5a1 1 0 011.414-1.414l5 5z"
          fill="currentColor"
        />
        <path
          d="M6.293 2.293a1 1 0 011.414 1.414l-5 5a1 1 0 01-1.414-1.414l5-5z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M6 13a1 1 0 110-2h12a1 1 0 110 2H6z" fill="currentColor" />
        <path
          d="M11.707 16.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 111.414-1.414l5 5z"
          fill="currentColor"
        />
        <path
          d="M10.293 6.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414-1.414l5-5z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
BackIcon.displayName = "BackIcon";
