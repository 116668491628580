import { SVGAttributes } from "react";

export const ContentTypeEventIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_30)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1a1 1 0 00-2 0v1H2a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2h-2V1a1 1 0 10-2 0v1H6V1zm5 4a1 1 0 01-1-1H6a1 1 0 01-2 0H2v2h12V4h-2a1 1 0 01-1 1zm-9 9V8h12v6H2zm3-1a2 2 0 100-4 2 2 0 000 4z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_30">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 7a1 1 0 01-1-1V5H3v3h18V5h-4v1a1 1 0 11-2 0V5H9v1a1 1 0 01-1 1zM3 20V10h18v10H3zM15 3H9V2a1 1 0 00-2 0v1H2a1 1 0 00-1 1v17a1 1 0 001 1h20a1 1 0 001-1V4a1 1 0 00-1-1h-5V2a1 1 0 10-2 0v1zM7 16a2 2 0 100-4 2 2 0 000 4z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeEventIcon.displayName = "ContentTypeEventIcon";
