import { SVGAttributes } from "react";

export const USPitchforkIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={138}
      height={24}
      viewBox="0 0 138 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 21.72c1.793 0 2.522-.842 2.522-2.759V5.043c0-1.918-.597-2.76-2.522-2.76V.366h10.29c5.624 0 9.333 2.53 9.333 6.958 0 4.08-3.364 6.956-9.454 6.956H8.49v4.558c0 2.158 1.076 2.76 4.069 2.76v1.919H0v-1.799.004zM8.493 2.167v10.44h.96c2.872 0 4.427-2.158 4.427-5.281 0-3.24-1.555-5.16-4.546-5.16h-.84zM19.875 21.72c1.681 0 2.272-.72 2.272-2.53v-6.468c0-.96-.717-1.56-1.681-1.439h-.591V9.233l6.34-1.919h1.557v11.754c0 1.798.596 2.53 2.272 2.53v1.918H19.743v-1.799h.132v.004zM25.005.607a3.115 3.115 0 013.111 3.12 3.114 3.114 0 01-3.11 3.118 3.115 3.115 0 01-3.11-3.119A3.095 3.095 0 0123.81.836a3.078 3.078 0 011.195-.23zm6.58 9.838h-2.152V9.122c2.522-.72 5.625-2.759 6.58-5.757h1.197V8.39h3.949v2.04H37.21v7.798c0 1.918 1.076 2.528 2.151 2.528a3.7 3.7 0 002.036-.598l.596 1.079c-.958 1.56-3.231 2.76-5.504 2.76-3.362 0-4.904-2.042-4.904-5.398V10.444zm18.555-3.12c2.053.02 4.07.555 5.863 1.56l-2.036 5.519H52.65c-.358-3.373-1.913-4.679-3.589-4.679-2.034 0-3.231 1.798-3.231 4.198 0 3.6 2.522 5.398 5.144 5.398 2.036 0 3.111-.843 3.829-2.4l1.196.48c-.717 3.72-2.393 6.598-6.938 6.598-4.188 0-7.18-2.998-7.18-7.677.12-5.518 3.363-8.992 8.256-8.992"
        fill="currentColor"
      />
      <path
        d="M55.514 21.72c1.682 0 2.273-.719 2.273-2.529V5.286c0-.961-.717-1.44-1.681-1.44h-.718v-2.16L62.21 0h1.195v10.678c.841-2.28 2.522-3.372 4.787-3.372 2.99 0 5.025 1.686 5.025 5.158v6.605c0 1.798.597 2.53 2.272 2.53v1.918h-9.447v-1.92c1.435 0 1.435-.842 1.435-2.398v-6.592c0-1.44-.717-2.158-2.034-2.158a2.178 2.178 0 00-2.152 1.797v6.958c0 1.56.12 2.4 1.434 2.4v1.918h-9.448v-1.798h.238l-.002-.003zm20.94 0c1.68 0 2.273-.719 2.273-2.529v-8.747h-2.275v-1.32c1.794 0 2.394-.48 2.394-1.799 0-3.118 2.752-7.305 8.375-7.305 1.794 0 3.712.599 5.385 1.32l-2.394 5.985-1.197-.239c.121-3.24-.959-5.28-2.872-5.28-1.556 0-2.393 1.321-2.393 2.998 0 1.44.358 2.64.597 3.6h3.362v2.04h-3.242v8.624c0 1.798.597 2.53 2.273 2.53v1.918H76.452v-1.798l.001.003z"
        fill="currentColor"
      />
      <path
        d="M87.58 15.601c0-5.398 3.827-8.399 8.255-8.399 4.428 0 8.255 2.998 8.255 8.4 0 5.4-3.827 8.398-8.255 8.398-4.428 0-8.256-2.998-8.256-8.399zm6.34 0c0 5.28.48 6.839 1.914 6.839 1.433 0 1.915-1.56 1.915-6.839 0-5.28-.48-6.84-1.914-6.84-1.435.122-1.913 1.686-1.913 6.84h-.002zm10.528 6.12c1.683 0 2.275-.72 2.275-2.53v-6.228c0-.962-.719-1.56-1.683-1.56h-.717v-2.04l5.984-1.798h1.68v2.53c0 1.32-.12 3.238-.12 3.958h.12c.479-4.8 2.632-6.84 5.145-6.84.325-.027.651.014.959.121v5.51c-2.034-.36-5.625-.238-5.625 3.479v2.746c0 1.799.598 2.53 2.273 2.53v1.918h-10.29V21.72l-.001.002z"
        fill="currentColor"
      />
      <path
        d="M117.612 21.72c1.681 0 2.272-.719 2.272-2.529V5.286c0-.961-.717-1.44-1.681-1.44h-.718v-2.16L124.424 0h1.197v14.637h.238l2.753-1.321c1.316-.599 1.555-1.2 1.555-2.04 0-1.079-.841-1.686-2.153-1.686V7.686h8.382V9.37c-1.794 2.158-3.469 2.998-5.383 3.96l3.709 5.038c1.198 1.559 1.915 3.24 3.111 3.24v1.919h-7.062l-4.904-7.919h-.238v3.96c0 1.32.12 1.918 1.435 1.918v1.92h-9.452V21.72z"
        fill="currentColor"
      />
    </svg>
  );
};
USPitchforkIcon.displayName = "USPitchforkIcon";
