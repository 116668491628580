import { withTooltip } from "../withTooltip";
import type { ContentSummaryMetadata } from "../SearchResult/types";
import { OutOfStockIcon } from "../../icons";
import { useIntl } from "react-intl";
import styled from "styled-components";

const NBSP_DOT_NBSP = String.fromCharCode(160, 8226, 160);

const Wrapper = styled.div`
  display: inline-flex;
  gap: var(--spacing-xs);
  flex-direction: row;
  align-items: center;
  grid-area: metadata;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Chip = styled.span`
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  padding: calc(var(--spacing-xxs) / 2) calc(var(--spacing-xxs) * 1.5);
  font: 400 0.75rem / 1rem var(--font-family-ibm-plex-sans);
  max-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;

  > *:not(:last-child)::after {
    content: "${NBSP_DOT_NBSP}";
  }
`;

const IconBadge = styled.span`
  padding: calc(var(--spacing-xxs) / 2);
  border-radius: ${(props) => props.theme.CornerRadius};
  color: ${(props) => props.theme.OutOfStockIconColor};
  display: flex;
`;

const CategoryName = styled(Chip)`
  text-transform: uppercase;
`;

const SUPPORTED_METADATA = [
  "ContentSummaryCategory",
  "ContentSummaryRating",
  "ContentSummaryDimensions",
  "ContentSummarySlideCount",
  "ContentSummaryOffer",
  "ContentSummarySyndication",
];

const MetadataItem = (props: { data: ContentSummaryMetadata }) => {
  const intl = useIntl();
  const { data } = props;
  switch (data.type) {
    case "ContentSummaryCategory":
      return <CategoryName>{data.category}</CategoryName>;
    case "ContentSummaryRating":
      return (
        <Chip>
          {intl.formatNumber(data.rating, {
            maximumSignificantDigits: 1,
          })}
        </Chip>
      );
    case "ContentSummaryDimensions":
      return (
        <Chip>
          {intl.formatMessage(
            {
              defaultMessage: "{width} x {height}",
              description: "The dimensions of a 2 dimensional image",
            },
            { height: data.height ?? "?", width: data.width ?? "?" }
          )}
        </Chip>
      );
    case "ContentSummarySlideCount":
      return data.count > 0 ? (
        <Chip>
          {intl.formatMessage(
            {
              defaultMessage:
                "{count} {count, plural, one {slide} other {slides} }",
              description: "The number of slides in a Gallery",
            },
            { count: data.count }
          )}
        </Chip>
      ) : (
        <></>
      );
    case "ContentSummaryOffer":
      if (data.count === 1 && data.price && data.currency && data.seller) {
        const price = intl.formatNumber(data.price, {
          style: "currency",
          currency: data.currency,
          currencyDisplay: "narrowSymbol",
          maximumFractionDigits: 0,
        });
        return (
          <Chip>
            {price} {data.seller}
          </Chip>
        );
      } else {
        return (
          <Chip>
            {intl.formatMessage(
              {
                defaultMessage:
                  "{count} {count, plural, one {offer} other {offers} }",
                description: "The number of offers available for a Product",
              },
              { count: data.count }
            )}
          </Chip>
        );
      }
    default:
      return <></>;
  }
};

const OutOfStockBadge = withTooltip(IconBadge);

export const Metadata = (props: { metadata: ContentSummaryMetadata[] }) => {
  const intl = useIntl();
  const isOutOfStock = props.metadata.some(
    (md) => md.type === "ContentSummaryOffer" && md.isOutOfStock
  );
  const metadata = props.metadata.filter((md) =>
    SUPPORTED_METADATA.includes(md.type)
  );

  return metadata.length ? (
    <Wrapper>
      {metadata.map((data, index) => {
        return <MetadataItem data={data} key={index} />;
      })}

      {isOutOfStock && (
        <OutOfStockBadge
          role="img"
          aria-label={intl.formatMessage({
            defaultMessage: "Out of stock",
          })}
        >
          <OutOfStockIcon size="small" />
        </OutOfStockBadge>
      )}
    </Wrapper>
  ) : (
    <></>
  );
};
