import { SVGAttributes } from "react";

export const VersoSplitLeftIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5H3a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1zM4 17V7h6v10H4z"
        fill="currentColor"
      />
      <path
        d="M14 8a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM14 12a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zM14 16a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
        fill="currentColor"
      />
    </svg>
  );
};
VersoSplitLeftIcon.displayName = "VersoSplitLeftIcon";
