import { useState, useEffect, RefObject, useCallback } from "react";
import ResizeObserverPolyfill from "resize-observer-polyfill";
import "requestidlecallback-polyfill";

const DEBOUNCE_INTERVAL = 50;

function windowResizeEffectHandler<R>(
  item: typeof window,
  callback: (element: typeof window) => R,
  setValue: (value: R | null) => void
): () => void {
  let idleCallbackId = 0;

  function handleResize() {
    setValue(callback(item));
  }

  window.addEventListener("resize", () => {
    window.cancelIdleCallback(idleCallbackId);
    idleCallbackId = window.requestIdleCallback(handleResize, {
      timeout: DEBOUNCE_INTERVAL,
    });
  });

  handleResize();

  return () => {
    window.cancelIdleCallback(idleCallbackId);
    window.removeEventListener("resize", handleResize);
  };
}

function elementResizeEffectHandler<R>(
  item: Element,
  callback: (el: Element) => R,
  setValue: (value: R) => void
): () => void {
  let observer = new ResizeObserverPolyfill(
    (entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        let [entry] = entries;

        setValue(callback(entry.target));
      }
    }
  );

  observer.observe(item);

  return () => {
    observer.disconnect();
  };
}

export function useResizeEffect<T extends typeof window | Element, R>(
  item: T | RefObject<T | undefined> | null | undefined,
  callback: (element: T) => R,
  dependencies: unknown[]
): R | null {
  const [value, setValue] = useState<R | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(
    function (): (() => void) | void {
      if (item === window) {
        return windowResizeEffectHandler(
          window,
          memoizedCallback as (w: typeof window) => R,
          setValue
        );
      } else if (item instanceof Element) {
        return elementResizeEffectHandler(
          item,
          memoizedCallback as (el: Element) => R,
          setValue
        );
      } else if (item) {
        let itemRef = item as RefObject<Element | undefined>;
        if (itemRef.current instanceof Element) {
          let el = itemRef.current;
          return elementResizeEffectHandler(
            el,
            memoizedCallback as (el: Element) => R,
            setValue
          );
        }
      }
    },
    [item, memoizedCallback]
  );

  return value;
}
