import { SVGAttributes } from "react";

export const UserIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9a4 4 0 100-8 4 4 0 000 8zm0-6a2 2 0 110 4 2 2 0 010-4z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 15a1 1 0 001-1v-3c0-.59-.218-1.374-.857-2.14C12.157 7.676 10.466 7 8 7c-2.466 0-4.157.676-5.143 1.86C2.217 9.626 2 10.41 2 11v3a1 1 0 001 1h10zm-1-4v2H4v-2c0-.16.095-.502.393-.86C4.97 9.45 6.091 9 8 9c1.909 0 3.03.449 3.607 1.14.298.358.393.7.393.86z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 8a2 2 0 114 0 2 2 0 01-4 0zm2-4a4 4 0 00-2.385 7.211c-.763.152-1.423.385-1.989.697a4.743 4.743 0 00-2.181 2.483c-.378.958-.475 1.995-.492 2.944-.008.479.004.954.018 1.4l.006.177c.012.386.023.745.023 1.088a1 1 0 001 1h12a1 1 0 001-1c0-.343.011-.702.023-1.088l.006-.177c.014-.446.026-.921.018-1.4-.017-.949-.114-1.986-.492-2.944a4.743 4.743 0 00-2.181-2.483c-.566-.312-1.226-.545-1.989-.697A4 4 0 0012 4zm5.02 15H6.98l-.004-.154-.006-.172a27.333 27.333 0 01-.017-1.305c.015-.862.105-1.617.352-2.245a2.746 2.746 0 011.287-1.464C9.285 13.278 10.351 13 12 13c1.65 0 2.715.278 3.408.66a2.746 2.746 0 011.287 1.464c.247.627.337 1.383.352 2.245.007.427-.004.86-.017 1.305l-.006.172-.004.154z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
UserIcon.displayName = "UserIcon";
