import { SVGAttributes } from "react";

export const ContentTypeTaxonomyIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2a1 1 0 011-1h4a1 1 0 011 1v2a1 1 0 01-1 1H9v2h4a1 1 0 011 1v3h1a1 1 0 011 1v2a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2a1 1 0 011-1h1V9H4v2h1a1 1 0 011 1v2a1 1 0 01-1 1H1a1 1 0 01-1-1v-2a1 1 0 011-1h1V8a1 1 0 011-1h4V5H6a1 1 0 01-1-1V2z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 4a2 2 0 012-2h6a2 2 0 012 2v3a2 2 0 01-2 2h-2v2h5a1 1 0 011 1v3h2a2 2 0 012 2v3a2 2 0 01-2 2h-6a2 2 0 01-2-2v-3a2 2 0 012-2h2v-2H7v2h2a2 2 0 012 2v3a2 2 0 01-2 2H3a2 2 0 01-2-2v-3a2 2 0 012-2h2v-3a1 1 0 011-1h5V9H9a2 2 0 01-2-2V4zm2 0v3h6V4H9zM3 17v3h6v-3H3zm12 3v-3h6v3h-6z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeTaxonomyIcon.displayName = "ContentTypeTaxonomyIcon";
