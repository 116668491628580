import { ReactNode } from "react";
import { useMediaQuery } from "../../hooks";
import { NativeDatePicker } from "./NativeDatePicker";
import { PrettyDatePicker } from "./PrettyDatePicker";
import { Field } from "../Field";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-xxs);
  grid-template-areas:
    "label"
    "control"
    "message";
`;

export function DatePicker(props: {
  id: string;
  children?: ReactNode;
  date: Date | null;
  label?: string;
  locale?: string;
  onChange: (date: Date) => void;
}) {
  const { date, locale, onChange, ...forwardProps } = props;
  const isTouchDevice = useMediaQuery("(hover: none), (hover: on-demand)");

  return isTouchDevice ? (
    <Wrapper {...forwardProps}>
      <NativeDatePicker date={date} onChange={onChange} {...forwardProps} />
    </Wrapper>
  ) : (
    <Wrapper {...forwardProps}>
      <PrettyDatePicker
        date={date}
        onChange={onChange}
        locale={locale}
        {...forwardProps}
      />
    </Wrapper>
  );
}
DatePicker.displayName = "DatePicker";
