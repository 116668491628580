import { useEffect } from "react";

/**
 * Fires a callback when a click is received outside of the element.
 * @param element
 * @param callback
 */
export const useClickOutside = (
  element: HTMLElement | null,
  callback?: (evt: MouseEvent) => void
) => {
  useEffect(() => {
    if (element && callback) {
      let clickOutside = (evt: MouseEvent) => {
        let target = evt.target as Node;
        if (
          target.isConnected &&
          target !== element &&
          element &&
          !element.contains(target)
        ) {
          callback(evt);
        }
      };

      document.addEventListener("click", clickOutside, true);

      return () => {
        document.removeEventListener("click", clickOutside, true);
      };
    }
    return;
  });
};
