import { SVGAttributes } from "react";

export const SaveIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 2v11h11V3.593L11.305 2H2zM0 2v11a2 2 0 002 2h11a2 2 0 002-2V3.593a2 2 0 00-.63-1.458L12.673.542A2 2 0 0011.304 0H2a2 2 0 00-2 2z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 1.5A1.5 1.5 0 014.5 0h6A1.5 1.5 0 0112 1.5v4A1.5 1.5 0 0110.5 7h-6A1.5 1.5 0 013 5.5v-4zM5 2v3h5V2H5z"
          fill="currentColor"
        />
        <path
          d="M9 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3v18h18V5.865L17.95 3l1.37-1.458 3.05 2.866A2 2 0 0123 5.865V21a2 2 0 01-2 2H3a2 2 0 01-2-2V3a2 2 0 012-2h14.95a2 2 0 011.37.542L17.95 3H3z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3a2 2 0 012-2h10a2 2 0 012 2v7a2 2 0 01-2 2H7a2 2 0 01-2-2V3zm12 0H7v7h10V3z"
          fill="currentColor"
        />
        <path
          d="M14 5a1 1 0 112 0v3a1 1 0 11-2 0V5zM14 15a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
SaveIcon.displayName = "SaveIcon";
