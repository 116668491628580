import { FC } from "react";

function xScale(value: number, totalWidth: number, count: number) {
  return value * (totalWidth / count);
}

function yScale(value: number, totalHeight: number, dataRange: number) {
  if (dataRange) {
    return (value * totalHeight) / dataRange;
  } else {
    return totalHeight - 1;
  }
}

export const BarChart: FC<{
  data: number[];
  fill?: string[] | ((value: number, index: number) => string);
  height?: number;
  width?: number;
  padding?: number;
  className?: string;
}> = ({
  data,
  fill = ["#3D5AFE"],
  height = 16,
  width = 32,
  padding = 0.1,
  className,
}) => {
  const maxValue = Math.max(...data, 0);
  const minValue = Math.min(...data, 0);
  const dataRange = maxValue - minValue;

  const bars = data.map((value, index) => {
    const x = xScale(index + padding, width, data.length);
    const scaledValue = yScale(value, height, dataRange);
    const scaledMax = Math.max(0, yScale(maxValue, height, dataRange));
    const y = scaledMax - Math.max(scaledValue, 0);
    const h = Math.abs(scaledValue);
    const w = xScale(1 - padding, width, data.length);
    return {
      x,
      y,
      h,
      w,
    };
  });

  return (
    <svg height={height} width={width} className={className}>
      {bars.map(({ x, y, w, h }, index) => (
        <rect
          key={index}
          fill={
            typeof fill === "function"
              ? fill(data[index], index)
              : fill[index % fill.length]
          }
          x={x}
          y={y}
          width={w}
          height={h}
        />
      ))}
    </svg>
  );
};

BarChart.displayName = "BarChart";
