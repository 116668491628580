import { useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ThemeProvider } from "../../contexts";

const UnderMax = styled.span`
  display: inline-flex;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SupportColor};
`;

const OverMax = styled.span`
  display: inline-flex;
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SecondaryColor};
`;

const COUNT = defineMessages({
  characters: {
    defaultMessage: "{count, plural, =1 {# char} other {# chars}}",
  },
  words: {
    defaultMessage: "{count, plural, =1 {# word} other {# words}}",
  },
});

const COUNT_WITH_MAXIMUM = defineMessages({
  characters: {
    defaultMessage: "{count} / {maxCount, plural, =1 {# char} other {# chars}}",
  },
  words: {
    defaultMessage: "{count} / {maxCount, plural, =1 {# word} other {# words}}",
  },
});

export function TextCount(props: {
  text: string | null | undefined;
  count: "characters" | "words";
  maxCount?: number | null;
}) {
  let count = useMemo(() => {
    let text = props.text ?? "";
    if (props.count === "characters") {
      // Used to get the proper number of characters for Unicode strings
      let trimmed = text.trim();
      if (trimmed.length === 0) {
        return null;
      } else {
        return [...trimmed].length;
      }
    } else {
      let trimmed = text.trim();
      if (trimmed.length === 0) {
        return null;
      } else {
        return trimmed.split(/\s+/g).length;
      }
    }
  }, [props.count, props.text]);
  return props.maxCount != null ? (
    count && count > props.maxCount ? (
      <ThemeProvider tint="red">
        <OverMax>
          <FormattedMessage
            {...COUNT_WITH_MAXIMUM[props.count]}
            values={{ maxCount: props.maxCount, count }}
          />
        </OverMax>
      </ThemeProvider>
    ) : (
      <UnderMax>
        <FormattedMessage
          {...COUNT_WITH_MAXIMUM[props.count]}
          values={{ maxCount: props.maxCount, count }}
        />
      </UnderMax>
    )
  ) : count != null ? (
    <UnderMax>
      <FormattedMessage {...COUNT[props.count]} values={{ count }} />
    </UnderMax>
  ) : (
    <></>
  );
}
TextCount.displayName = "TextCount";
