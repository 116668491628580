import styled, { DefaultTheme, StyledComponent } from "styled-components";

export const Card: StyledComponent<
  "div",
  DefaultTheme,
  {
    size?: "narrow" | "regular";
  },
  never
> = styled.div.attrs(
  (props: { theme: DefaultTheme; size?: "narrow" | "regular" }) => {
    return {
      $Width:
        props.size === "narrow"
          ? props.theme.NarrowCardSize
          : props.theme.CardSize,
    };
  }
)`
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.CardShadow};
  background: ${(props) => props.theme.Background};
  margin: 0 auto;
  padding: var(--spacing-sm);
  width: 100%;
  max-width: ${(props) => props.$Width};
`;
Card.displayName = "Card";
