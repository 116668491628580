import { SVGAttributes } from "react";

export const UtilitiesIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 14a1 1 0 001 1h5a1 1 0 001-1V2a1 1 0 00-1-1H2a1 1 0 00-1 1v12zm5-1H3V3h3v10z"
        fill="currentColor"
      />
      <path
        d="M2 6a1 1 0 010-2h2a1 1 0 010 2H2zM2 9a1 1 0 010-2h2a1 1 0 010 2H2zM12 6a1 1 0 110-2h2a1 1 0 110 2h-2zM2 12a1 1 0 110-2h2a1 1 0 110 2H2z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1h-3a1 1 0 00-1 1v10a1 1 0 00.2.6l1.5 2a1 1 0 001.6 0l1.5-2a1 1 0 00.2-.6V2a1 1 0 00-1-1zm-2 10.667V3h1v8.667l-.5.666-.5-.666z"
        fill="currentColor"
      />
    </svg>
  );
};
UtilitiesIcon.displayName = "UtilitiesIcon";
