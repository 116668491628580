import { Ref, MutableRefObject, memo as ReactMemo } from "react";

export function propagateRef<T extends Ref<Element>, Element>(
  ref: T,
  element: Element
) {
  if (typeof ref === "function") {
    ref(element);
  } else if (ref) {
    (ref as MutableRefObject<Element>).current = element;
  }
}

export const memo: <T>(c: T) => T = ReactMemo;
