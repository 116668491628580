import { SVGAttributes } from "react";

export const GridIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5zm16 0h-6v6h6V5zm0 8h-6v6h6v-6zm-8-2V5H5v6h6zm-6 2h6v6H5v-6z"
        fill="currentColor"
      />
    </svg>
  );
};
GridIcon.displayName = "GridIcon";
