import { SVGAttributes } from "react";

export const CautionIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 16h14a1 1 0 00.894-1.449l-7-13.999c-.34-.678-1.449-.678-1.789 0l-7 14A1.001 1.001 0 001 16zm12.382-2H2.62L8 3.235l5.382 10.763z"
        fill="currentColor"
      />
      <path d="M7 7a1 1 0 012 0v2a1 1 0 11-2 0V7z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  );
};
CautionIcon.displayName = "CautionIcon";
