import { useTabListKeyboardNavigation } from "./useTabListKeyboardNavigation";
export enum ElementType {
  TabList = "TabList",
}

export type KeyboardNavigationOptions = {
  defaultCursor?: number;
  onEnter?: (cursor: number) => void;
};

export const useKeyboardNavigation = (
  elementType: ElementType,
  itemsLength: number,
  options?: KeyboardNavigationOptions
) => {
  switch (elementType) {
    case "TabList":
      return useTabListKeyboardNavigation(itemsLength, options);
    default:
      throw new Error(`Unsupported element type: ${elementType}`);
  }
};
