import { SVGAttributes } from "react";

export const OutOfStockIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.134 6H2.857L2 14h12l-.857-8h-1.277a1 1 0 01-1.732 0H5.866a1 1 0 01-1.732 0zM6 4h4a2 2 0 00-4 0zm6 0h1.143a2 2 0 011.989 1.787l.857 8A2 2 0 0114 16H2a2 2 0 01-1.989-2.213l.858-8A2 2 0 012.857 4H4a4 4 0 018 0zM8 8.586L6.707 7.293a1 1 0 10-1.414 1.414L6.586 10l-1.293 1.293a1 1 0 101.414 1.414L8 11.414l1.293 1.293a1 1 0 001.414-1.414L9.414 10l1.293-1.293a1 1 0 00-1.414-1.414L8 8.586z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 5a4 4 0 118 0h2.857a2 2 0 011.992 1.825l1.143 13A2 2 0 0120 22H4a2 2 0 01-1.992-2.175l1.143-13A2 2 0 015.143 5H8zm6 3V7h-4v1a1 1 0 11-2 0V7H5.143L4 20h16L18.857 7H16v1a1 1 0 11-2 0zm-2-5a2 2 0 012 2h-4a2 2 0 012-2zm-3.707 8.293a1 1 0 011.414 0L12 13.586l2.293-2.293a1 1 0 011.414 1.414L13.414 15l2.293 2.293a1 1 0 01-1.414 1.414L12 16.414l-2.293 2.293a1 1 0 01-1.414-1.414L10.586 15l-2.293-2.293a1 1 0 010-1.414z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
OutOfStockIcon.displayName = "OutOfStockIcon";
