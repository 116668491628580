import { ComponentPropsWithoutRef, ReactNode } from "react";
import styled from "styled-components";
import { ChevronRightIcon } from "../../icons";

const Container = styled.div`
  width: min(100%, calc(100vw - var(--spacing-sm) * 2));
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Heading = styled.h1`
  font: ${(props) => props.theme.FontPageHeading};
`;

const BreadcrumbList = styled.ul`
  display: flex;
  justify-content: center;
  padding-bottom: var(--spacing-xs);
`;

const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
`;

export function Title(
  props: {
    title?: string;
    breadcrumbs?: ReactNode[];
  } & ComponentPropsWithoutRef<"h1">
) {
  const { breadcrumbs, children, title, ...forwardProps } = props;
  return (
    <Container>
      {breadcrumbs && (
        <BreadcrumbList>
          {breadcrumbs.map((breadcrumb, index) => {
            return (
              <BreadcrumbItem key={index}>
                {breadcrumb}
                <ChevronRightIcon size="small" />
              </BreadcrumbItem>
            );
          })}
        </BreadcrumbList>
      )}
      <Heading {...forwardProps}>{children}</Heading>
    </Container>
  );
}
Title.displayName = "Title";
