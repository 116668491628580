import { SVGAttributes } from "react";

export const FullscreenIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5a1 1 0 00-1 1v3a1 1 0 002 0V7h2a1 1 0 000-2H6zm0 9a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 100-2H7v-2a1 1 0 00-1-1zm11 1a1 1 0 112 0v3a1 1 0 01-1 1h-3a1 1 0 110-2h2v-2zm0-8h-2a1 1 0 110-2h3a1 1 0 011 1v3a1 1 0 11-2 0V7z"
        fill="currentColor"
      />
    </svg>
  );
};
FullscreenIcon.displayName = "FullscreenIcon";
