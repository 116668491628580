import { usePopper } from "react-popper";
import { useClickOutside, useUniqueId } from "../../hooks";
import type { Option } from "./types";

import styled from "styled-components";
import {
  useCallback,
  useState,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
  useMemo,
} from "react";
import { ARIA, HighlightedText, Toast } from "../../";
import { useDebouncedCallback } from "../../hooks";
import { SearchIcon, WandIcon } from "../../icons";
import { SearchInput } from "./SearchInput";
import { useIntl, FormattedMessage } from "react-intl";
import { Chip } from "../Chip";

const LIST_KEY_EVENTS = ["ArrowDown", "ArrowUp", "Enter", "Home", "End"];

const QUICK_SEARCH_DEBOUNCE_INTERVAL = 250; // milliseconds

const SearchErrorMessage = styled.ul`
  /* Resets */
  list-style: none;

  /* Positioning */
  position: relative;
  z-index: ${(props) => props.theme.ElevationMenu};

  /* Popover styles */
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  width: 100%;
`;

const NoSearchResults = styled(SearchErrorMessage)`
  font: ${(props) => props.theme.FontBody};
  background: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  padding: var(--spacing-xs);
`;

const Highlight = styled.span`
  font-weight: bolder;
`;

const SearchMultiselectWrapper = styled.div`
  position: relative;
  width: 100%;

  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};
  position: relative;

  [aria-busy="true"] &,
  [aria-expanded="true"] & {
    z-index: ${(props) => props.theme.ElevationMenu + 1};
  }

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }

  &:active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};
  }

  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;
  }

  .inputIcon {
    position: absolute;
    left: var(--spacing-xs);
  }

  ul[role="listbox"] {
    z-index: ${(props) => props.theme.ElevationMenu};
    .check-mark {
      display: none;
    }
  }
`;

const SuggestedTagResults = styled(SearchErrorMessage)`
  font: ${(props) => props.theme.FontBody};
  background: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  padding: var(--spacing-xs);
`;

const SelectionChip = styled(Chip)`
  color: var(--color-purple-40);
  background-color: var(--color-purple-80);
  padding: var(--spacing-xs) var(--spacing-sm);
  margin-right: var(--spacing-xs);
  margin-top: var(--spacing-xs);
  cursor: pointer;

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }

  svg {
    margin-right: var(--spacing-xs);
  }
`;

const PurpleText = styled.div`
  color: var(--color-purple-40);
`;
//todo: make this night mode sensitive

type SearchMultiselectProps<T> = {
  label?: string;
  selections: Option<T>[];
  search?: (query: string) => void;
  searchResults?: Option<T>[];
  loading?: boolean;
  searchErrors?: Error[];
  multiple?: boolean;
  onChange?: (selections: T[]) => void;
  setInputClicked?: (query: boolean) => void;
  "aria-invalid"?: boolean;
  hasSuggestedTags?: boolean;
  suggestedTagsSearch?: () => void;
  suggestedTagsResults?: Option<T>[];
  suggestedTagsLoading?: boolean;
  sortable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  trackEvent?: (component: string, type: string, label?: string) => void;
};

export function SearchMultiselect<T>({
  selections,
  search,
  searchResults,
  loading,
  searchErrors,
  onChange,
  ["aria-invalid"]: ariaInvalid,
  label,
  multiple,
  hasSuggestedTags,
  suggestedTagsSearch,
  suggestedTagsResults,
  suggestedTagsLoading,
  sortable,
  disabled,
  trackEvent,
}: SearchMultiselectProps<T>) {
  const searchMultiselectId = `SearchMultiselect_${useUniqueId()}`;
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [showSuggestedTags, setShowSuggestedTags] = useState<boolean>(false);

  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);
  const [searchInputElement, setSearchInputElement] =
    useState<HTMLDivElement | null>(null);
  const [listActiveDesecndant, setListActiveDescendant] = useState<
    string | undefined
  >(undefined);

  const activeDescendant = query ? listActiveDesecndant : "";
  const intl = useIntl();

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    wrapperElement,
    listElement,
    {
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["top", "right"],
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 4],
          },
        },
      ],
    }
  );

  const close = useCallback(() => {
    if (isExpanded) {
      setExpanded(false);
    }
    if (query) {
      setQuery("");
    }
    setShowSuggestedTags(false);
  }, [setExpanded, isExpanded]);

  useClickOutside(wrapperElement, close);

  const wrappedOnChange: (newValue: Option<T>[]) => void = useCallback(
    (newSelections) => {
      onChange?.(newSelections.map((option) => option.value));
    },
    [onChange]
  );

  const setInputClick = (focused: boolean) => {
    if (focused) {
      setExpanded(true);
    }
    if (focused && hasSuggestedTags && suggestedTagsSearch) {
      suggestedTagsSearch();
      setShowSuggestedTags(true);
    }
  };

  const onSearchInputKeyDown = useCallback(
    (event: ReactKeyboardEvent<HTMLInputElement>) => {
      setShowSuggestedTags(false);
      if (event.key === "Tab" || (!query && event.key === "Escape")) {
        close();
        return;
      }

      if (query && LIST_KEY_EVENTS.includes(event.key)) {
        listElement?.dispatchEvent(new KeyboardEvent("keydown", event));
        event.preventDefault();
        event.stopPropagation();
      }

      if (event.key === "Enter" && searchInputElement) {
        searchInputElement.innerHTML = "";
        return;
      }
    },
    [query, close, listElement, searchInputElement]
  );

  const updateDebouncedQuery = useDebouncedCallback(
    (query: string) => {
      setDebouncedQuery(query.trim());
    },
    QUICK_SEARCH_DEBOUNCE_INTERVAL,
    [setDebouncedQuery]
  );

  useEffect(() => {
    updateDebouncedQuery(query);
  }, [query, updateDebouncedQuery]);

  useEffect(() => {
    if (debouncedQuery) {
      search?.(debouncedQuery);
    }
  }, [debouncedQuery, search]);

  const selectSearchOption = useCallback(
    (newSelection: Option<T> | undefined, preventFocus?: boolean) => {
      setQuery("");
      let updatedSelections = selections?.length ? selections : [];
      if (
        newSelection &&
        !updatedSelections.some(
          (selection) => selection.key === newSelection.key
        )
      ) {
        wrappedOnChange([...updatedSelections, newSelection]);
      }
      if (searchInputElement) {
        if (!preventFocus) {
          searchInputElement.focus();
        }
        searchInputElement.innerHTML = "";
      }
    },
    [selections, wrappedOnChange, setQuery, searchInputElement]
  );

  const unusedSuggestedTags = useMemo(
    () =>
      suggestedTagsResults?.filter((tag) => {
        return !selections.some((selection) => selection.key === tag.key);
      }) ?? [],
    [suggestedTagsResults, selections]
  );

  return (
    <SearchMultiselectWrapper ref={setWrapperElement}>
      <SearchInput
        ref={setSearchInputElement}
        value={query}
        icon={disabled ? undefined : SearchIcon}
        onInputChange={(query: string) => {
          setQuery(String(query).trim());
        }}
        loading={showSuggestedTags ? suggestedTagsLoading : loading ?? false}
        onKeyDown={onSearchInputKeyDown}
        aria-label={intl.formatMessage({
          defaultMessage: "Search input",
        })}
        label={label}
        role="textbox"
        aria-controls={isExpanded ? `${searchMultiselectId}-Listbox` : null}
        aria-activedescendant={activeDescendant}
        selections={selections}
        onSelectionsChange={onChange}
        setInputClick={setInputClick}
        aria-invalid={ariaInvalid}
        multiple={multiple}
        id={`${searchMultiselectId}-search-input`}
        sortable={sortable}
        placeholder={intl.formatMessage({ defaultMessage: "Type to search" })}
        disabled={disabled}
      />
      {debouncedQuery &&
        searchResults &&
        searchResults.length > 0 &&
        isExpanded && (
          <ARIA.Listbox
            id={`${searchMultiselectId}-Listbox`}
            options={searchResults}
            onChange={selectSearchOption}
            ref={setListElement}
            autofocus={false}
            onActiveDescendantChange={setListActiveDescendant}
            aria-busy={loading ? "true" : undefined}
            style={popperStyles.popper}
            trackEvent={trackEvent}
            {...popperAttributes.popper}
          >
            {(option) => {
              return (
                <HighlightedText
                  query={query}
                  text={option.label}
                  components={{ Highlight }}
                />
              );
            }}
          </ARIA.Listbox>
        )}
      {!loading && debouncedQuery && searchResults?.length === 0 && isExpanded && (
        <NoSearchResults
          ref={setListElement}
          style={popperStyles.popper}
          {...popperAttributes.popper}
        >
          <FormattedMessage defaultMessage="Your search returned no results." />
        </NoSearchResults>
      )}
      {debouncedQuery && searchErrors?.length && (
        <SearchErrorMessage
          ref={setListElement}
          style={popperStyles.popper}
          {...popperAttributes.popper}
        >
          <Toast type="error">
            <FormattedMessage defaultMessage="An error occurred processing your search." />
          </Toast>
        </SearchErrorMessage>
      )}
      {suggestedTagsLoading &&
        showSuggestedTags &&
        hasSuggestedTags &&
        isExpanded && (
          <SuggestedTagResults
            ref={setListElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            <PurpleText>
              <FormattedMessage defaultMessage="Searching for suggested tags..." />
            </PurpleText>
          </SuggestedTagResults>
        )}
      {!suggestedTagsLoading &&
        showSuggestedTags &&
        hasSuggestedTags &&
        suggestedTagsResults &&
        unusedSuggestedTags?.length === 0 &&
        isExpanded && (
          <NoSearchResults
            ref={setListElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            <FormattedMessage defaultMessage="No suggestions found. Search for tags." />
          </NoSearchResults>
        )}
      {!suggestedTagsLoading &&
        showSuggestedTags &&
        hasSuggestedTags &&
        suggestedTagsResults &&
        unusedSuggestedTags?.length > 0 &&
        isExpanded && (
          <SuggestedTagResults
            ref={setListElement}
            style={popperStyles.popper}
            {...popperAttributes.popper}
          >
            <div>
              <FormattedMessage defaultMessage="Suggested Tags" />
            </div>
            {unusedSuggestedTags
              .filter((tag) => {
                return !selections.some(
                  (selection) => selection.key === tag.key
                );
              })
              .map((tag) => (
                <SelectionChip
                  size="small"
                  key={tag.key}
                  onClick={() => {
                    selectSearchOption(tag, true);
                    if (trackEvent) {
                      trackEvent(
                        "tag_selector",
                        "button_click",
                        "onSuggestedTagSelect"
                      );
                    }
                  }}
                >
                  <WandIcon size="small" />
                  <span contentEditable={false}>{tag.label}</span>
                </SelectionChip>
              ))}
          </SuggestedTagResults>
        )}
    </SearchMultiselectWrapper>
  );
}
SearchMultiselect.displayName = "SearchMultiselect";
