import styled from "styled-components";
import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";
import { Asset, ContentType, Title } from "../-private";
import { Metadata, PublishStatusIcon } from "../../index";
import type { Props } from "../types";
import { getMetaData } from "../helper";

const Result = styled.div<{ $archived: boolean }>`
  grid-area: search-result;
  display: grid;
  grid-template-areas:
    ". . . . . . ."
    ". image . . . slot ."
    ". image . title-bar . slot ."
    ". image . asset-info . slot ."
    ". image . . . slot ."
    ". . . . . . .";
  grid-template-columns: auto auto auto 1fr auto auto auto;
  grid-template-rows: auto auto auto auto;
  gap: var(--spacing-xs);
  align-items: center;
  ${({ $archived }) => {
    if ($archived)
      return `
      background: var(--color-gray-6);
      background-color: get-color(gray, x-light);
      background-image: repeating-linear-gradient(
        -60deg,
        #fff,
        #fff 8px,
        transparent 8px,
        transparent 16px
      );
    `;
    else return;
  }}
`;

const Slot = styled.span`
  grid-area: slot;
  align-self: flex-start;
`;

const AssetUpdateDate = styled.div`
  font: ${(props) => props.theme.FontSmallStatement};
`;

const AssetInfo = styled.div`
  grid-area: asset-info;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
`;

const AssetTitleBar = styled.div`
  grid-area: title-bar;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxs);
`;

export function AssetSelectorResult<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  const { result, cdnHost, query, children, ...forwardProps } = props;
  const modifiedDate = result.modifiedAt
    ? new Date(result.modifiedAt)
    : undefined;
  const hasDate = modifiedDate && !isNaN(modifiedDate.getTime());
  return (
    <Result $archived={!!result.archived} {...forwardProps}>
      <Asset
        cdnHost={cdnHost}
        asset={result.asset || undefined}
        contentType={result.contentType}
        modifications={{ width: 60, height: 60 }}
      />
      <AssetTitleBar>
        <Title title={result.title} query={query} fidelity="full" />
        {result.publishInfo && result.revisionInfo && (
          <PublishStatusIcon
            publishInfo={result.publishInfo}
            latestRevision={result.revisionInfo.version}
            size="small"
          />
        )}
      </AssetTitleBar>
      <AssetInfo>
        <ContentType
          contentTypeLabel={result.contentTypeLabel}
          fidelity="full"
        />
        {result.metadata && (
          <Metadata metadata={getMetaData(result.metadata)} />
        )}
        {hasDate && (
          <AssetUpdateDate>
            <FormattedMessage
              defaultMessage={"Modified {lastModified, date, medium}"}
              description="shows the date on which a piece of content was last edited"
              values={{
                lastModified: modifiedDate,
              }}
            />
          </AssetUpdateDate>
        )}
      </AssetInfo>
      <Slot>{children}</Slot>
    </Result>
  );
}
