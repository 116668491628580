import { FC } from "react";
import styled from "styled-components";
import { EllipsisIcon } from "../../../../icons";
import { ARIA } from "../../../ARIA";

type ActionsMenuProps = { id: string; actions: any };

const MenuIcon = styled(EllipsisIcon)`
  color: ${(props) => props.theme.Color};
`;

const MenuButton = styled(ARIA.MenuButton)`
  border: 1px solid ${(props) => props.theme.SelectButtonHoverBackgroundColor};
  background: ${(props) => props.theme.Background};
`;
const StyledActionsMenu = styled.div`
  grid-area: actions-menu;
  align-self: start;
`;
export const ActionsMenu: FC<ActionsMenuProps> = ({ id, actions }) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };
  return (
    <StyledActionsMenu onClick={handleClick}>
      <MenuButton
        id={`${id}-actions`}
        size="small"
        treatment="secondary"
        aria-label="Actions menu"
        tabIndex={-1}
        menu={{
          items: actions,
          children: (item: string) => item,
        }}
      >
        <MenuIcon size="regular" />
      </MenuButton>
    </StyledActionsMenu>
  );
};
