import { SVGAttributes } from "react";

export const CropIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.091 3a1 1 0 00-2 0v3.91H3a1 1 0 100 2H15.909v6.18H8.09v-4.009a1 1 0 10-2 0v5.01a1 1 0 001 1H21a1 1 0 100-2H17.91V7.909a1 1 0 00-1-1H8.09V3zm9.817 16a1 1 0 10-2 0v2a1 1 0 102 0v-2z"
        fill="currentColor"
      />
    </svg>
  );
};
CropIcon.displayName = "CropIcon";
