import { ComponentType } from "react";
import styled from "styled-components";
import { Asset, ContentType, Title } from "../-private";
import type { Props } from "../types";

const Result = styled.div<{ $archived: boolean }>`
  display: grid;
  width: 100%;
  grid-template-areas:
    ". image . . . ."
    ". image title . slot ."
    ". image content-type . slot ."
    ". image . . . .";
  grid-template-columns: var(--spacing-xs) auto 1fr auto auto var(--spacing-xs);
  grid-template-rows: auto auto auto auto;
  gap: var(--spacing-xs);
  align-items: center;
  ${({ $archived }) => {
    if ($archived)
      return `
      background: var(--color-gray-6);
      background-color: get-color(gray, x-light);
      background-image: repeating-linear-gradient(
        -60deg,
        #fff,
        #fff 8px,
        transparent 8px,
        transparent 16px
      );
    `;
    else return;
  }}
`;

const Slot = styled.span`
  grid-area: slot;
`;

export function Quick<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  let { result, query, cdnHost, children, assetAs, ...forwardProps } = props;

  return (
    <Result $archived={!!result.archived} {...forwardProps}>
      <Asset
        cdnHost={cdnHost}
        asset={result.asset || undefined}
        contentType={result.contentType}
        modifications={{ width: 48, height: 48 }}
      />
      <Title title={result.title} query={props.query} fidelity="default" />
      <ContentType
        contentTypeLabel={result.contentTypeLabel}
        fidelity="default"
      />
      <Slot>{children}</Slot>
    </Result>
  );
}
Quick.displayName = "SearchResult__Quick";
