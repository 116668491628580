import { CloseIcon, AnimatedEllipsisIcon, SmallIcons } from "../../../icons";
import { Button } from "../../Button";
import { ThemeProvider } from "../../../contexts";
import { propagateRef } from "../../../lib";
import {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  ComponentPropsWithoutRef,
  MouseEvent,
  Ref,
} from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.ToastBackground};
  padding: calc(var(--spacing-xxs) * 2.5);
  border-bottom: 1px solid ${(props) => props.theme.DividerColor};
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  background: ${(props) => props.theme.FieldBackground};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => props.theme.Color};

  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }

  &:active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};
  }

  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;
  }

  .inputIcon {
    position: absolute;
    left: var(--spacing-xs);
    z-index: 0;
  }
`;

const Input = styled.input`
  /* Resets */
  appearance: none;
  border: 0;
  box-shadow: none;
  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }

  background: transparent;
  flex-grow: 1;
  padding: ${(props) => props.theme.SecondaryPadding};
  padding-left: calc(2 * var(--spacing-sm));

  font: inherit;
  color: inherit;
  &::placeholder {
    opacity: 1;
    color: ${(props) => props.theme.PlaceholderColor};
  }
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button {
    display: none;
  }
`;

const CancelButton = styled(Button)`
  margin-right: var(--spacing-xs);
`;

export type SearchInputProps = ComponentPropsWithoutRef<typeof Input> & {
  loading?: boolean;
  icon?: SmallIcons;
  onChange: (value: string) => void;
  autofocus?: boolean;
};

export const SearchInput = forwardRef(function (
  props: SearchInputProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  const intl = useIntl();
  const {
    loading,
    onChange,
    icon: Icon,
    autofocus = true,
    ...forwardProps
  } = props;
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  );
  const [hasInput, setHasInput] = useState<boolean>(false);

  const change = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(evt.target.value);
      setHasInput(!!evt.target.value);
    },
    [onChange]
  );

  const clear = useCallback(() => {
    if (inputElement) {
      inputElement.focus();
      if (inputElement.value !== "") {
        inputElement.value = "";
        onChange && onChange("");
        setHasInput(false);
      }
    }
  }, [inputElement, onChange, setHasInput]);

  useEffect(() => {
    if (inputElement && autofocus) {
      inputElement.focus();
    }
  }, [inputElement, autofocus]);

  return (
    <Wrapper>
      <Field role="search">
        {Icon &&
          (loading ? (
            <AnimatedEllipsisIcon size="small" className="inputIcon loading" />
          ) : (
            <Icon size="small" className="inputIcon" />
          ))}
        <Input
          ref={(el) => {
            if (forwardedRef) {
              propagateRef(forwardedRef, el);
            }
            setInputElement(el);
          }}
          onChange={change}
          type="search"
          {...forwardProps}
        />
        <ThemeProvider theme="modal">
          {hasInput && (
            <CancelButton
              aria-label={intl.formatMessage({
                defaultMessage: "Clear",
                description: "Button used to clear a text field",
              })}
              size="small"
              onClickCapture={(evt: MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                evt.stopPropagation();
                clear();
              }}
            >
              <CloseIcon size="small" />
            </CancelButton>
          )}
        </ThemeProvider>
      </Field>
    </Wrapper>
  );
});
SearchInput.displayName = "SearchInput";
