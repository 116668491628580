import { SVGAttributes } from "react";

export const NewTabIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 2H9a3 3 0 00-3 3H5a3 3 0 00-3 3v11a3 3 0 003 3h11a3 3 0 003-3v-1a3 3 0 003-3V5a3 3 0 00-3-3zm-3 3H8a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1V8a3 3 0 00-3-3zM5 7a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1V8a1 1 0 00-1-1H5zm10.707 4.707a1 1 0 00-1.414-1.414L9 15.586l-2.293-2.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l6-6z"
        fill="currentColor"
      />
    </svg>
  );
};
NewTabIcon.displayName = "NewTabIcon";
