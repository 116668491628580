import { SVGAttributes } from "react";

export const CalendarIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6a1 1 0 002 0V5h6v1a1 1 0 102 0V5h4v3H3V5h4v1zm-4 4v10h18V10H3zm6-7h6V2a1 1 0 112 0v1h5a1 1 0 011 1v17a1 1 0 01-1 1H2a1 1 0 01-1-1V4a1 1 0 011-1h5V2a1 1 0 012 0v1z"
        fill="currentColor"
      />
    </svg>
  );
};
CalendarIcon.displayName = "CalendarIcon";
