import { SVGAttributes } from "react";

export const TextTopIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2a1 1 0 000 2h18a1 1 0 100-2H3zm10 8.414l3.293 3.293a1 1 0 001.414-1.414l-5-5a1 1 0 00-1.414 0l-5 5a1 1 0 101.414 1.414L11 10.414V20a1 1 0 102 0v-9.586z"
        fill="currentColor"
      />
    </svg>
  );
};
TextTopIcon.displayName = "TextTopIcon";
