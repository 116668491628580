import { SVGAttributes } from "react";

export const RadioUnselectedIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={8} stroke="currentColor" strokeWidth={2} />
    </svg>
  );
};
RadioUnselectedIcon.displayName = "RadioUnselectedIcon";
