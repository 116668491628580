import { SVGAttributes } from "react";

export const HideIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.078 2.263c-.366-.367-.98-.347-1.37.043-.391.39-.41 1.004-.044 1.371l1.172 1.172a9.63 9.63 0 00-.043.043c-.83.83-1.386 1.66-1.681 2.248a1.005 1.005 0 000 .918c.295.587.85 1.417 1.681 2.248C4.216 11.729 5.956 12.599 8 12.599c.785 0 1.525-.128 2.22-.367l1.383 1.384c.367.367.98.347 1.371-.043.39-.39.41-1.005.043-1.371l-9.939-9.94zm5.477 8.305L7.53 9.543A2.003 2.003 0 016.056 8.07L4.25 6.263A8.053 8.053 0 003.16 7.6l.047.07c.278.417.613.835 1 1.223C5.284 9.969 6.545 10.599 8 10.599c.188 0 .373-.01.555-.031z"
          fill="currentColor"
        />
        <path
          d="M7.835 5.606l2.159 2.158a2 2 0 00-2.158-2.158z"
          fill="currentColor"
        />
        <path
          d="M11.793 8.892a7.336 7.336 0 01-.346.325l1.416 1.417a9.61 9.61 0 00.344-.328c.831-.83 1.386-1.66 1.682-2.248a.995.995 0 000-.917c-.296-.588-.85-1.418-1.682-2.25C11.784 3.47 10.045 2.6 8 2.6c-.947 0-1.828.186-2.643.528l1.587 1.587C7.284 4.638 7.636 4.6 8 4.6c1.456 0 2.716.63 3.793 1.707a8.054 8.054 0 011.047 1.293l-.047.07a8.054 8.054 0 01-1 1.223z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.634 4.223c-.327-.327-.893-.29-1.266.082-.372.373-.41.94-.083 1.266l1.239 1.238c-.432.285-.836.581-1.208.877a17.313 17.313 0 00-2.283 2.167 9.155 9.155 0 00-.658.843c-.08.118-.155.24-.215.359a1.73 1.73 0 00-.092.213 1.19 1.19 0 00-.068.388c0 .197.055.352.08.42.033.084.071.159.104.218.067.119.15.24.236.358.176.238.418.524.708.835a19.91 19.91 0 002.389 2.15c1.931 1.481 4.65 3.02 7.483 3.02 1.518 0 3.008-.407 4.365-1.006l1.787 1.786c.326.326.893.29 1.265-.083.372-.372.41-.939.083-1.265L5.634 4.223zm9.195 11.891l-.934-.934a4 4 0 01-5.418-5.418L6.971 8.257c-.502.31-.975.648-1.413.995a15.32 15.32 0 00-2.014 1.911c-.156.18-.284.34-.383.471.11.136.253.301.428.489.51.547 1.251 1.244 2.143 1.927 1.819 1.395 4.1 2.607 6.267 2.607.94 0 1.9-.205 2.83-.543z"
          fill="currentColor"
        />
        <path
          d="M18.284 14.225c-.248.182-.505.36-.77.531l1.442 1.443c.175-.12.346-.24.51-.361.988-.725 1.822-1.489 2.419-2.154.297-.331.55-.654.736-.95.093-.147.18-.303.246-.463.06-.147.133-.365.133-.615 0-.41-.195-.796-.337-1.043a6.99 6.99 0 00-.684-.956c-.56-.671-1.35-1.442-2.312-2.172C17.754 6.034 15.046 4.656 12 4.656c-1.358 0-2.646.299-3.825.761l1.574 1.574A8.056 8.056 0 0112 6.657c2.454 0 4.746 1.122 6.458 2.421.85.645 1.528 1.312 1.985 1.86.229.274.39.504.486.672l.017.03-.017.028a5.37 5.37 0 01-.533.68c-.496.553-1.225 1.227-2.112 1.877z"
          fill="currentColor"
        />
        <path
          d="M15.766 13.008l-5.118-5.117a4 4 0 015.118 5.118z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
HideIcon.displayName = "HideIcon";
