import { SVGAttributes } from "react";

export const ShareIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.172 3.414L6.05 5.535a1 1 0 11-1.414-1.414l2.829-2.828a1 1 0 011.414 0l2.828 2.828a1 1 0 01-1.414 1.414l-2.121-2.12z"
        fill="currentColor"
      />
      <path
        d="M6.998 3a1 1 0 112 0L9 10.649a1 1 0 11-2 0L6.998 3z"
        fill="currentColor"
      />
      <path
        d="M13 8h-1.086V6H14a1 1 0 011 1v7a1 1 0 01-1 1H2a1 1 0 01-1-1V7a1 1 0 011-1h1.914v2H3v5h10V8z"
        fill="currentColor"
      />
    </svg>
  );
};
ShareIcon.displayName = "ShareIcon";
