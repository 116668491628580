import {
  forwardRef,
  useState,
  useEffect,
  ReactNode,
  Ref,
  AriaAttributes,
  ComponentType,
} from "react";
import styled from "styled-components";
import { propagateRef } from "../../lib";
import { useUniqueId } from "../../hooks";
import { Tooltip } from "../Tooltip";
import type { Placement } from "@popperjs/core";

const TooltipBody = styled.div`
  text-align: left;
  max-width: 200px;
  white-space: normal;
`;

const TooltipLabel = styled.p`
  font: ${(props) => props.theme.FontLabel};
`;

export function withTooltip<
  C extends ComponentType<
    {
      children?: ReactNode;
      tooltipPlacement?: Placement;
    } & AriaAttributes
  >
>(Component: C) {
  function ComponentWithTooltip(
    props: JSX.LibraryManagedAttributes<
      C,
      {
        children?: ReactNode;
        tooltipPlacement?: Placement;
      } & AriaAttributes
    >,
    ref: Ref<HTMLElement>
  ) {
    let {
      "aria-details": details,
      "aria-label": label,
      ...forwardProps
    } = props;

    let [element, setElement] = useState<HTMLElement | null>(null);
    let uniqueId = useUniqueId();

    // Manually forward ref to the outer scope
    useEffect(() => {
      if (ref) {
        propagateRef(ref, element);
      }
    }, [element, ref]);

    return (
      <>
        {/* @ts-expect-error Something is wrong with the React types here */}
        <Component
          {...forwardProps}
          ref={setElement}
          aria-label={label}
          aria-describedby={
            details ? `icon-button-description-${uniqueId}` : undefined
          }
        />
        {label && (
          <Tooltip
            target={element}
            hidden={
              forwardProps["aria-expanded"] === "true" ||
              forwardProps["aria-expanded"] === true
            }
            tooltipPlacement={props.tooltipPlacement}
          >
            {details ? (
              <TooltipBody>
                <TooltipLabel>{label}</TooltipLabel>
                <p id={`icon-button-description-${uniqueId}`}>{details}</p>
              </TooltipBody>
            ) : (
              label
            )}
          </Tooltip>
        )}
      </>
    );
  }
  ComponentWithTooltip.displayName = `withTooltip(${
    Component.displayName ?? "anonymous"
  })`;

  let ForwardedComponentWithTooltip = forwardRef(ComponentWithTooltip);
  ForwardedComponentWithTooltip.displayName = `forwardRef(${ComponentWithTooltip.displayName})`;
  return ForwardedComponentWithTooltip as unknown as C;
}
