import { base } from "./base";

const light = {
  ...base,

  _name: "light",

  Background: "var(--color-white)",
  SurfaceColor: "var(--color-gray-6)",
  Color: "var(--color-gray-1)",
  FocusRing: "0 0 0 3px var(--color-blue-70)",

  PrimaryBackground: "var(--color-blue-50)",
  PrimaryColor: "var(--color-white)",
  PrimaryHoverBackground: "var(--color-blue-40)",
  PrimaryActiveBackground: "var(--color-blue-30)",
  PrimaryDisabledBackground: "var(--color-gray-6)",
  PrimaryDisabledColor: "var(--color-gray-4)",

  SecondaryColor: "var(--color-blue-40)",
  SecondaryRing: "inset 0 0 0 1px var(--color-gray-5)",
  SecondaryHoverRing: "inset 0 0 0 1px var(--color-blue-40)",
  SecondaryFocusRing: "inset 0 0 0 2px var(--color-blue-50)",
  SecondaryActiveBackground: "var(--color-blue-80)",
  SecondaryActiveColor: "var(--color-blue-30)",
  SecondaryActiveRing: "0 0 0 1px var(--color-blue-50)",
  SecondaryDisabledColor: "var(--color-gray-4)",
  SecondaryDisabledRing: "inset 0 0 0 1px var(--color-gray-5)",

  BorderlessHoverBackground: "var(--color-gray-6)",
  BorderlessActiveBackground: "var(--color-gray-5)",

  FieldBackground: "var(--color-white)",
  FieldColor: "var(--color-gray-1)",
  FieldRing: "inset 0 0 0 1px var(--color-gray-5)",

  ErrorRing: "inset 0 0 0 1px var(--color-red-50)",
  ErrorHoverRing: "inset 0 0 0 1px var(--color-red-50)",
  ErrorFocusRing:
    "inset 0 0 0 2px var(--color-red-50), 0 0 0 3px var(--color-red-70)",

  ControlIconColor: "var(--color-gray-3)",
  ControlOnBackground: "var(--color-blue-50)",
  ControlOnForeground: "var(--color-white)",
  ControlOffBackground: "var(--color-gray-4)",
  ControlOffForeground: "var(--color-gray-6)",
  ControlDisabledBackground: "var(--color-gray-6)",
  ControlDisabledForeground: "var(--color-white)",
  ControlDisabledColor: "var(--color-gray-4)",

  CardShadow:
    "0 calc(var(--spacing-xxs) / 2) var(--spacing-xxs) rgba(43, 58, 68, 0.25)",
  MenuShadow: "0 0 var(--spacing-xs) rgba(43, 58, 68, 0.4)",

  SubheadingColor: "var(--color-gray-2)",
  PlaceholderColor: "var(--color-gray-4)",
  SupportColor: "var(--color-gray-3)",
  DividerColor: "var(--color-gray-5)",

  TooltipBackground: "var(--color-gray-1)",
  TooltipColor: "var(--color-white)",
  TooltipShadow: "none",

  OutOfStockIconColor: "var(--color-red-50)",

  ToastIconColor: "var(--color-blue-30)",
  ToastBackground: "var(--color-white)",
  ToastTintedBackground: "var(--color-blue-80)",
  ToastBorderColor: "var(--color-blue-50)",

  MedallionBackground: "var(--color-gray-1)",
  MedallionColor: "var(--color-white)",
  MedallionRing: "0 0 0 1px var(--color-white)",

  SearchMatchBackground: "var(--color-blue-80)",
  SearchImageBackground: "var(--color-gray-6)",
  SearchImageRing: "0 0 0 1px var(--color-gray-5)",

  StatusSuccessBackground: "var(--color-blue-40)",
  StatusPendingBackground: "var(--color-blue-50)",
  StatusDisabledBackground: "var(--color-blue-50)",
  StatusSuccessColor: "var(--color-white)",
  StatusPendingColor: "var(--color-gray-1)",
  StatusDisabledColor: "var(--color-white)",

  NonInteractiveRing: "0 0 0 1px var(--color-gray-6)",

  SelectOptionHoverBackground: "var(--color-gray-6)",
  SelectOptionSelectedHoverBackground: "var(--color-blue-80)",
  SelectOptionSelectedCheckmarkColor: "var(--color-blue-50)",

  SelectButtonBackgroundColor: "var(--color-gray-6)",
  SelectButtonHoverBackgroundColor: "var(--color-gray-5)",

  AutofillButtonBackgroundColor: "transparent",
  AutofillButtonColor: "var(--color-purple-50)",
  AutofillButtonHoverBackground: "var(--color-gray-6)",
  AutofillButtonHoverCursor: "pointer",

  SkeletonColor: "var(--color-gray-6)",
  SkeletonWaveColor: "var(--color-gray-5)",

  PillRing: "0 0 0 1px var(--color-gray-4)",

  ArchivedBackground: "var(--color-gray-6)",

  ActionBarShadow: "0 4px 8px 0 var(--color-scrim)",

  SelectedTabBorder: "var(--color-blue-40)",
  TabListBorder: "var(--color-gray-5)",
  InactiveTabTextColor: "var(--color-gray-1)",
  ActiveTabTextColor: "var(--color-blue-40)",
};

export default light;
export type Theme = typeof light;
