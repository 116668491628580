import {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  HTMLAttributes,
} from "react";
import computeScrollIntoView from "compute-scroll-into-view";
import styled from "styled-components";
import { propagateRef } from "../../../lib";
import { useClickOutside } from "../../../hooks";
import { MenuItem } from "./MenuItem";
import {
  MenuItem as TMenuItem,
  getInteractiveItems,
  isMenuAction,
  isMenuItemCheckbox,
  isMenuItemRadio,
} from "./MenuItem/types";

export type MenuProps<T> = Omit<
  HTMLAttributes<HTMLUListElement>,
  "onChange"
> & {
  id: string;
  items: Array<TMenuItem<T>>;
  hasInputFields?: boolean;
  initialKeyboardCursor?: "start" | "end";
  autofocus?: boolean;
  children: (item: T, query?: string) => ReactNode;
  onSearch?: (items: T[], query: string) => T | undefined;
  onClose?: (
    evt: KeyboardEvent<Element> | ReactMouseEvent<Element> | MouseEvent
  ) => void;
  onKeyDown?: (evt: KeyboardEvent<Element>) => void;
};

export const MenuList = styled.ul`
  /* Resets */
  list-style: none;

  /* Positioning */
  position: relative;
  overflow-y: auto;

  background: ${(props) => props.theme.Background};
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.MenuShadow};
  color: ${(props) => props.theme.Color};
  z-index: ${(props) => props.theme.ElevationMenu};
  min-width: calc(10 * var(--spacing-md));
  font: ${(props) => props.theme.FontStatement};

  & > li:first-of-type {
    margin-top: var(--spacing-xs);
  }
  & > li:last-of-type {
    margin-bottom: var(--spacing-xs);
  }
`;

const SEARCH_TIMEOUT = 400;

// eslint-disable-next-line react/display-name
export const Menu = forwardRef(function <T>(
  props: MenuProps<T>,
  forwardedRef: Ref<HTMLUListElement>
) {
  let {
    items,
    onClose,
    onSearch,
    onKeyDown: onKeyDownProp,
    initialKeyboardCursor,
    autofocus = true,
    hasInputFields = false,
    ...forwardProps
  } = props;

  let [query, setQuery] = useState("");
  let [match, setMatch] = useState<unknown | null>(null);
  let focusableItems = useMemo(() => {
    return getInteractiveItems(items).map((item) => ({
      item,
      current: null as HTMLAnchorElement | HTMLLIElement | null,
    }));
  }, [items]);
  let [element, setElement] = useState<HTMLUListElement | null>(null);
  let [activeCursor, setActiveCursor] = useState("keyboard");
  let [keyboardCursor, setKeyboardCursor] = useState(
    initialKeyboardCursor === "end" ? focusableItems.length - 1 : 0
  );
  let [pointerCursor, setPointerCursor] = useState(0);
  let cursor = activeCursor === "keyboard" ? keyboardCursor : pointerCursor;
  let focusedItem = focusableItems[cursor];

  let lastQueryTime = useRef(0);

  let length = focusableItems.length;

  // Focus the first item
  useLayoutEffect(() => {
    if (autofocus) {
      let index =
        initialKeyboardCursor === "end" ? focusableItems.length - 1 : 0;
      let element = focusableItems[index].current;
      if (element) {
        element.focus();
      }
    }
  }, [focusableItems, initialKeyboardCursor, autofocus]);

  let onKeyDown = (evt: KeyboardEvent<HTMLUListElement>) => {
    let newCursorPosition = cursor;
    let newQuery = query;
    if (evt.timeStamp - lastQueryTime.current > SEARCH_TIMEOUT) {
      newQuery = "";
    }
    onKeyDownProp?.(evt);
    switch (evt.key) {
      case "ArrowDown":
        if (cursor === length - 1) {
          newCursorPosition = 0;
        } else {
          newCursorPosition = cursor + 1;
        }
        newQuery = "";
        break;
      case "ArrowUp":
        if (cursor === 0) {
          newCursorPosition = length - 1;
        } else {
          newCursorPosition = cursor - 1;
        }
        newQuery = "";
        break;
      case "Home":
        newCursorPosition = 0;
        newQuery = "";
        break;
      case "End":
        newCursorPosition = length - 1;
        newQuery = "";
        break;
      case "Enter":
        evt.preventDefault();
        evt.stopPropagation();
        return;
      case "Tab":
        onClose && onClose(evt);
        break;
      case "Escape":
        onClose && onClose(evt);
        evt.preventDefault();
        evt.stopPropagation();
        break;
      case "Backspace":
        newQuery = query.slice(0, query.length - 1);
        break;
      case " ":
        if (newQuery.length > 0) {
          newQuery += " ";
        }
        if (!hasInputFields) {
          evt.preventDefault();
          evt.stopPropagation();
        }
        break;
      default:
        if (evt.key.length === 1) {
          newQuery += evt.key;
          if (!hasInputFields) {
            evt.preventDefault();
            evt.stopPropagation();
          }
        }
    }

    if (newQuery !== query) {
      setQuery(newQuery);

      if (newQuery && newCursorPosition === cursor) {
        lastQueryTime.current = evt.timeStamp;
        let searchMatch = onSearch
          ? onSearch(
              focusableItems.map((item) => item.item.value),
              newQuery
            )
          : null;
        setMatch(searchMatch);
        if (searchMatch) {
          newCursorPosition = focusableItems.findIndex(
            (item) => item.item.value === searchMatch
          );
        }
      }
    }

    if (newCursorPosition !== cursor && newCursorPosition != null) {
      setKeyboardCursor(newCursorPosition);
      setActiveCursor("keyboard");
      if (!hasInputFields) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    }
  };

  useLayoutEffect(() => {
    if (activeCursor === "keyboard") {
      let item = focusableItems[keyboardCursor];
      if (
        document.activeElement &&
        element?.contains(document.activeElement) &&
        item.current
      ) {
        const actions = computeScrollIntoView(item.current, {
          scrollMode: "if-needed",
          block: "nearest",
          inline: "nearest",
        });
        actions.forEach(({ el, top }) => {
          el.scrollTop = top;
        });
        item.current.focus();
      }
    }
  }, [activeCursor, keyboardCursor, focusableItems, element]);

  const onKeyUp = useCallback(
    (evt: KeyboardEvent<HTMLUListElement>) => {
      if (!hasInputFields) {
        switch (evt.key) {
          case "Enter":
            if (focusedItem) {
              let item = focusedItem.item;
              if (isMenuAction(item)) {
                item.onClick();
              } else if (isMenuItemRadio(item)) {
                item.onChange(item.value);
              } else if (isMenuItemCheckbox(item)) {
                item.onChange(!item.checked);
              } else {
                focusedItem.current?.dispatchEvent(
                  new MouseEvent("click", { bubbles: false })
                );
              }
            }
            onClose && onClose(evt);
            evt.preventDefault();
            evt.stopPropagation();
            return;
          case " ":
            if (query.length === 0) {
              if (focusedItem) {
                let item = focusedItem.item;
                if (isMenuAction(item)) {
                  item.onClick();
                } else if (isMenuItemRadio(item)) {
                  item.onChange(item.value);
                } else if (isMenuItemCheckbox(item)) {
                  item.onChange(!item.checked);
                } else {
                  focusedItem.current?.dispatchEvent(
                    new MouseEvent("click", {})
                  );
                }
              }
              onClose && onClose(evt);
              evt.preventDefault();
              evt.stopPropagation();
            }
            return;
          default:
            return;
        }
      }
    },
    [query, focusedItem, onClose, hasInputFields]
  );

  useClickOutside(element, onClose);

  return (
    <MenuList
      ref={(el) => {
        if (forwardedRef) {
          propagateRef(forwardedRef, el);
        }
        setElement(el);
      }}
      role="menu"
      onKeyDownCapture={onKeyDown}
      onKeyUp={onKeyUp}
      {...forwardProps}
    >
      {items.map((item, index) => {
        return (
          <MenuItem<T>
            item={item}
            key={"id" in item ? item.id : index}
            onClose={onClose}
            focused={focusedItem?.item.value}
            updateRef={(value, element) => {
              let ref = focusableItems.find(
                (item) => item.item.value === value
              );
              if (ref) {
                ref.current = element;
              }
            }}
            onMouseEnter={(evt) => {
              let item = focusableItems.find(
                (item) => item.current === evt.target
              );
              if (item) {
                item.current?.focus();
                setPointerCursor(focusableItems.indexOf(item));
              }
            }}
            onMouseMove={(evt) => {
              let item = focusableItems.find(
                (item) =>
                  item.current &&
                  evt.target &&
                  (item.current === evt.target ||
                    item.current.contains(evt.target as Node | null))
              );
              if (item !== focusableItems[cursor] && item) {
                item.current?.focus();
                setPointerCursor(focusableItems.indexOf(item));
              }
              setActiveCursor("pointer");
            }}
            onMouseLeave={() => {
              setPointerCursor(0);
            }}
          >
            {(value) =>
              props.children(
                value,
                match === value && query.length ? query : undefined
              )
            }
          </MenuItem>
        );
      })}
    </MenuList>
  );
}) as (<T>(
  props: MenuProps<T> & RefAttributes<HTMLUListElement>
) => ReactElement) & { displayName?: string };
// n.b. This is a type hack so types are automatically
//      inferred via the value of options.
// @tim-evans

Menu.displayName = "ARIA.Menu";
