import { SVGAttributes } from "react";

export const ContentTypeArticleIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0H4a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V3a3 3 0 00-3-3zM3 3a1 1 0 011-1h8a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V3zm3 1a1 1 0 000 2h4a1 1 0 100-2H6zM5 8a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1zm1 2a1 1 0 100 2h2a1 1 0 100-2H6z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 21H7a3 3 0 01-3-3V6a3 3 0 013-3h10a3 3 0 013 3v12a3 3 0 01-3 3zM6 18a1 1 0 001 1h10a1 1 0 001-1V6a1 1 0 00-1-1H7a1 1 0 00-1 1v12zM9 7a1 1 0 000 2h6a1 1 0 100-2H9zm-1 5a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zm1 3a1 1 0 100 2h4a1 1 0 100-2H9z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeArticleIcon.displayName = "ContentTypeArticleIcon";
