import { SVGAttributes } from "react";

export const DesktopIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5h14v9H5V5zM3 5a2 2 0 012-2h14a2 2 0 012 2v9a2 2 0 01-2 2h-3v3h1a1 1 0 110 2H7a1 1 0 110-2h1v-3H5a2 2 0 01-2-2V5zm7 14h4v-3h-4v3z"
        fill="currentColor"
      />
    </svg>
  );
};
DesktopIcon.displayName = "DesktopIcon";
