import { SVGAttributes } from "react";

export const ContentTypeGalleryIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_54)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2a2 2 0 012-2h8a2 2 0 012 2v8a2 2 0 01-2 2h-2v2a2 2 0 01-2 2H2a2 2 0 01-2-2V6a2 2 0 012-2h2V2zm2 4H2v4.086l.293-.293a1 1 0 011.414 0l4 4c.063.063.117.133.16.207H10V6H6zm-.914 8L3 11.914l-.793.793a1.003 1.003 0 01-.207.16V14h3.086zM12 10V6a2 2 0 00-2-2H6V2h8v8h-2zM9 9a2 2 0 11-4 0 2 2 0 014 0z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_54">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5v2H5a3 3 0 00-3 3v9a3 3 0 003 3h9a3 3 0 003-3v-2h2a3 3 0 003-3V5a3 3 0 00-3-3h-9a3 3 0 00-3 3zm6.414 15H14a1 1 0 001-1v-9a1 1 0 00-1-1H5a1 1 0 00-1 1v4.586l1.293-1.293a1 1 0 011.414 0L13.414 20zM4 17.366V19a1 1 0 001 1h5.586L6 15.414l-1.793 1.793a1.003 1.003 0 01-.207.16zM17 15h2a1 1 0 001-1V5a1 1 0 00-1-1h-9a1 1 0 00-1 1v2h5a3 3 0 013 3v5zm-3-3a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeGalleryIcon.displayName = "ContentTypeGalleryIcon";
