import { useContext, SVGAttributes } from "react";
import { ThemeContext } from "styled-components";

export const EncoreIcon = (props: SVGAttributes<SVGElement>) => {
  let theme = useContext(ThemeContext);
  if (theme?._name.startsWith("dark")) {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_545_268)">
          <path
            d="M3.815 26.74c.882-.061 2.1-.421 3.523-1.08 3.215-1.487 7.006-4.259 10.535-7.787 3.528-3.529 6.3-7.32 7.787-10.535.659-1.423 1.019-2.641 1.08-3.523.017-.23.011-.414-.008-.547a2.67 2.67 0 00-.546-.009c-.883.062-2.1.422-3.524 1.08-3.215 1.488-7.006 4.26-10.535 7.788-3.528 3.529-6.3 7.32-7.787 10.535-.659 1.423-1.019 2.641-1.08 3.524-.017.23-.011.413.008.546.133.02.316.025.547.009zM.924 29.077C-1.932 26.22 2.055 17.603 9.829 9.83 17.603 2.055 26.22-1.932 29.076.924c2.856 2.856-1.131 11.473-8.905 19.247C12.397 27.945 3.78 31.932.924 29.076z"
            fill="#00BFF3"
          />
          <path
            d="M3.26 3.815c.061.882.421 2.1 1.08 3.523 1.487 3.215 4.259 7.006 7.787 10.535 3.529 3.528 7.32 6.3 10.535 7.787 1.423.659 2.641 1.019 3.524 1.08.23.017.413.011.546-.008.02-.133.025-.316.009-.546-.062-.883-.422-2.1-1.08-3.524-1.488-3.215-4.26-7.006-7.788-10.535-3.529-3.528-7.32-6.3-10.535-7.787C5.915 3.68 4.697 3.32 3.815 3.26a2.67 2.67 0 00-.547.008 2.67 2.67 0 00-.009.547zM.923.924C3.78-1.932 12.397 2.055 20.17 9.829c7.774 7.774 11.761 16.391 8.905 19.247-2.856 2.856-11.473-1.131-19.247-8.905C2.055 12.397-1.932 3.78.924.924z"
            fill="#FF4561"
          />
          <path
            d="M12.41 22.59A44.488 44.488 0 0015 20.531c.865.74 1.732 1.428 2.59 2.057A48.997 48.997 0 0115 24.71a49.038 49.038 0 01-2.59-2.12zM20.532 15a44.488 44.488 0 002.057-2.59A49.038 49.038 0 0124.71 15a48.997 48.997 0 01-2.12 2.59A44.488 44.488 0 0020.531 15zM17.59 7.41A44.434 44.434 0 0015 9.469a44.434 44.434 0 00-2.59-2.057A49.009 49.009 0 0115 5.29a48.968 48.968 0 012.59 2.12zM9.468 15a44.434 44.434 0 00-2.057 2.59A48.968 48.968 0 015.29 15a49.009 49.009 0 012.12-2.59c.63.858 1.318 1.725 2.058 2.59z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="clip0_545_268">
            <path fill="#fff" d="M0 0h30v30H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_545_267)">
          <path
            d="M3.815 26.74c.882-.061 2.1-.421 3.523-1.08 3.215-1.487 7.006-4.259 10.535-7.787 3.528-3.529 6.3-7.32 7.787-10.535.659-1.423 1.019-2.641 1.08-3.523.017-.23.011-.414-.008-.547a2.67 2.67 0 00-.546-.009c-.883.062-2.1.422-3.524 1.08-3.215 1.488-7.006 4.26-10.535 7.788-3.528 3.529-6.3 7.32-7.787 10.535-.659 1.423-1.019 2.641-1.08 3.524-.017.23-.011.413.008.546.133.02.316.025.547.009zM.924 29.077C-1.932 26.22 2.055 17.603 9.829 9.83 17.603 2.055 26.22-1.932 29.076.924c2.856 2.856-1.131 11.473-8.905 19.247C12.397 27.945 3.78 31.932.924 29.076z"
            fill="#00BFF3"
          />
          <path
            d="M3.26 3.815c.061.882.421 2.1 1.08 3.523 1.487 3.215 4.259 7.006 7.787 10.535 3.529 3.528 7.32 6.3 10.535 7.787 1.423.659 2.641 1.019 3.524 1.08.23.017.413.011.546-.008.02-.133.025-.316.009-.546-.062-.883-.422-2.1-1.08-3.524-1.488-3.215-4.26-7.006-7.788-10.535-3.529-3.528-7.32-6.3-10.535-7.787C5.915 3.68 4.697 3.32 3.815 3.26a2.67 2.67 0 00-.547.008 2.67 2.67 0 00-.009.547zM.923.924C3.78-1.932 12.397 2.055 20.17 9.829c7.774 7.774 11.761 16.391 8.905 19.247-2.856 2.856-11.473-1.131-19.247-8.905C2.055 12.397-1.932 3.78.924.924z"
            fill="#FF4561"
          />
          <path
            d="M12.41 22.59A44.488 44.488 0 0015 20.531c.865.74 1.732 1.428 2.59 2.057A48.997 48.997 0 0115 24.71a49.038 49.038 0 01-2.59-2.12zM20.532 15a44.488 44.488 0 002.057-2.59A49.038 49.038 0 0124.71 15a48.997 48.997 0 01-2.12 2.59A44.488 44.488 0 0020.531 15zM17.59 7.41A44.434 44.434 0 0015 9.469a44.434 44.434 0 00-2.59-2.057A49.009 49.009 0 0115 5.29a48.968 48.968 0 012.59 2.12zM9.468 15a44.434 44.434 0 00-2.057 2.59A48.968 48.968 0 015.29 15a49.009 49.009 0 012.12-2.59c.63.858 1.318 1.725 2.058 2.59z"
            fill="#2B3A44"
          />
        </g>
        <defs>
          <clipPath id="clip0_545_267">
            <path fill="#fff" d="M0 0h30v30H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
EncoreIcon.displayName = "EncoreIcon";
