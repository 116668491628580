import { SVGAttributes } from "react";

export const ContentTypeBundleIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_18)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 0a2 2 0 00-2 2v2H2a2 2 0 00-2 2v8a2 2 0 002 2h7a2 2 0 002-2v-2h3a2 2 0 002-2V2a2 2 0 00-2-2H7zM5 6h4v3H2V6h3zm-3 5v3h7v-3H2zm9.351-1H14V7.087L11.351 10zM14 4.113V2H7v2h2a2 2 0 012 2v1.413l3-3.3z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_18">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4a2 2 0 012-2h9a2 2 0 012 2v10a2 2 0 01-2 2h-3v2a2 2 0 01-2 2h-3v2a2 2 0 01-2 2H2a2 2 0 01-2-2V12a2 2 0 012-2h3V8a2 2 0 012-2h3V4zm6 14h-3v-.626l3-3.45V18zm0-7.124l-3 3.45V12a2 2 0 00-2-2H7V8h9v2.876zm2-2.3V8a2 2 0 00-2-2h-4V4h9v1.126l-3 3.45zm0 3.048V14h3V8.174l-3 3.45zM11 16v-4H2v4h9zm-9 2v4h9v-4H2z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeBundleIcon.displayName = "ContentTypeBundleIcon";
