import { SVGAttributes } from "react";

export const SearchIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.617 11.032a5.5 5.5 0 111.414-1.414l3.676 3.675a1 1 0 01-1.414 1.414l-3.676-3.675zM10 6.5a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.046 14.01a7 7 0 10-1.414 1.414l4.464 4.465a1 1 0 001.414-1.414l-4.464-4.465zm-5.607.808a5 5 0 100-10 5 5 0 000 10z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
SearchIcon.displayName = "SearchIcon";
