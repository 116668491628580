import { SVGAttributes } from "react";

export const ArrowDownIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 3h12a1 1 0 01.8 1.6l-6 8a1 1 0 01-1.6 0l-6-8A1 1 0 012 3z"
        fill="currentColor"
      />
    </svg>
  );
};
ArrowDownIcon.displayName = "ArrowDownIcon";
