import { useCallback, FC } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

const Button = styled.button`
  /** Reset */
  appearance: none;
  border: 0;
  padding: 0;

  grid-area: control;
  position: relative;
  width: var(--spacing-xxl);
  height: var(--spacing-md);
  border-radius: calc(var(--spacing-md) / 2);

  &:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: calc(var(--spacing-xxs) / 2);
    width: calc(var(--spacing-md) - var(--spacing-xxs));
    height: calc(var(--spacing-md) - var(--spacing-xxs));
    border-radius: calc((var(--spacing-md) - var(--spacing-xxs)) / 2);
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: ${(props) => props.theme.FocusRing};
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &[aria-checked="true"]:after {
    left: calc(var(--spacing-md) + var(--spacing-xxs) / 2);
  }

  &[aria-checked="false"]:after {
    left: calc(var(--spacing-xxs) / 2);
  }

  &:not([aria-readonly]),
  &[aria-readonly="false"] {
    &[aria-checked="true"] {
      background: ${(props) => props.theme.ControlOnBackground};

      &:after {
        background: ${(props) => props.theme.ControlOnForeground};
      }
    }

    &[aria-checked="false"] {
      background: ${(props) => props.theme.ControlOffBackground};

      &:after {
        background: ${(props) => props.theme.ControlOffForeground};
      }
    }
  }

  &[aria-readonly] {
    background: ${(props) => props.theme.ControlDisabledBackground};

    &:after {
      background: ${(props) => props.theme.ControlDisabledForeground};
    }
  }
`;

// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Switch_role
export const Switch: FC<{
  id: string;
  className?: string;
  disabled?: boolean;
  value?: boolean;
  onChange: (value: boolean) => void;
}> = ({ id, className, disabled, value, onChange }) => {
  let intl = useIntl();
  let onClick = useCallback(
    function (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      if (!disabled) {
        onChange(!value);
      }
    },
    [onChange, disabled, value]
  );

  return (
    <Button
      type="button"
      role="switch"
      className={className}
      aria-label={
        value
          ? intl.formatMessage({
              defaultMessage: "on",
              description:
                "Accessibility description for switch in 'on' position",
            })
          : intl.formatMessage({
              defaultMessage: "off",
              description:
                "Accessibility description for switch in 'off' position",
            })
      }
      aria-checked={value ? "true" : "false"}
      aria-readonly={disabled}
      id={id}
      onClick={onClick}
    ></Button>
  );
};
