import { SVGAttributes } from "react";

export const EmbedIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3.414 8l1.293 1.293a1 1 0 01-1.414 1.414l-2-2a1 1 0 010-1.414l2-2a1 1 0 011.414 1.414L3.414 8zM11.293 6.707a1 1 0 011.414-1.414l2 2a1 1 0 010 1.414l-2 2a1 1 0 01-1.414-1.414L12.586 8l-1.293-1.293zM9.051 1.684a1 1 0 011.898.632l-4 12a1 1 0 01-1.898-.632l4-12z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.316 2.051a1 1 0 00-1.265.633l-6 18a1 1 0 001.898.632l6-18a1 1 0 00-.633-1.265zM7.707 15.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L4.414 12l3.293 3.293zm8.586-6.586a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L19.586 12l-3.293-3.293z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
EmbedIcon.displayName = "EmbedIcon";
