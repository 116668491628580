import Document, { InlineAnnotation } from "@atjson/document";
import VersoSource from "@condenast/atjson-source-verso";

class Highlight extends InlineAnnotation<{ token: string }> {
  static vendorPrefix = "copilot";
  static type = "highlight";
}

class HighlightSource extends VersoSource {
  static contentType = "application/vnd.atjson+highlight";
  static schema = [
    ...VersoSource.schema,
    Highlight,
  ] as typeof VersoSource.schema;
}

VersoSource.defineConverterTo(HighlightSource, (doc) => {
  return doc;
});

export function highlight(doc: Document, query?: string) {
  query = query || "";
  if (doc.content.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
    let index = doc.content.toLowerCase().indexOf(query.toLowerCase());
    while (index !== -1 && query.length > 0) {
      doc.addAnnotations({
        type: "-copilot-highlight",
        start: index,
        end: index + query.length,
        attributes: {},
      });
      index = doc.content
        .toLowerCase()
        .indexOf(query.toLowerCase(), index + query.length + 1);
    }
  } else {
    query.split(" ").forEach((word) => {
      let index = doc.content.toLowerCase().indexOf(word.toLowerCase());
      while (index !== -1 && word.length > 0) {
        let end = index + word.length;
        doc.addAnnotations({
          type: "-copilot-highlight",
          start: index,
          end,
          attributes: {},
        });
        index = doc.content.toLowerCase().indexOf(word.toLowerCase(), end + 1);
      }
    });
  }

  return doc.convertTo(HighlightSource);
}
