import styled from "styled-components";

const Wrapper = styled.span`
  grid-area: content-type;
`;

const DefaultWrapper = styled(Wrapper)<{ $textBold: boolean }>`
  font: ${(props) => props.theme.FontSmallStatement};
  color: ${(props) => props.theme.SubheadingColor};
  ${(props) => props.$textBold && `font-weight: 700;`}
`;

const FullWrapper = styled(Wrapper)`
  justify-content: start;
  gap: var(--spacing-xxs);
  font: ${(props) => props.theme.FontLabel};
`;

export function ContentType(props: {
  contentTypeLabel?: string;
  fidelity: "full" | "default" | "compact";
  textBold?: boolean;
}) {
  const { contentTypeLabel, fidelity, textBold } = props;
  if (fidelity === "compact") {
    return <></>;
  } else if (fidelity === "default") {
    return (
      <DefaultWrapper $textBold={textBold ?? false}>
        {contentTypeLabel}
      </DefaultWrapper>
    );
  } else {
    return <FullWrapper>{contentTypeLabel}</FullWrapper>;
  }
}
ContentType.displayName = "SearchResult__ContentType";
