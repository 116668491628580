import { useCallback, useState } from "react";
import type { ComponentPropsWithoutRef, KeyboardEvent } from "react";
import { usePopper, PopperProps, StrictModifierNames } from "react-popper";
import { Menu, MenuProps } from "../Menu";
import { ThemeProvider } from "../../../contexts";
import { Button } from "../../Button";
import { modifiers as popperModifiers } from "../../../lib";

type MenuPopperProps = Pick<
  PopperProps<StrictModifierNames | string>,
  "modifiers" | "placement" | "referenceElement" | "strategy"
>;

type MenuButtonProps<T> = ComponentPropsWithoutRef<typeof Button> & {
  id: string;
  menu: Omit<MenuProps<T>, "id"> & {
    theme?: "dark" | "light" | "modal";
  };
  popper?: MenuPopperProps;
  closeWhenFocusedOutside?: boolean;
  closeAttributeIds?: string[];
};

function withPopperDefaults(popper?: MenuPopperProps) {
  let modifiers = popper?.modifiers ? [...popper.modifiers] : [];
  if (!modifiers.find((modifier) => modifier.name === "offset")) {
    modifiers.push({
      name: "offset",
      options: {
        offset: [0, 4],
      },
    });
  }

  if (!modifiers.find((modifier) => modifier.name === "preventOverflow")) {
    modifiers.push({
      name: "preventOverflow",
      options: {
        padding: 8,
      },
    });
  }

  if (!modifiers.find((modifier) => modifier.name === "maxSize")) {
    modifiers.push(popperModifiers.maxSize, popperModifiers.applyMaxSize);
  }

  return {
    ...popper,
    placement: popper?.placement ?? "bottom-end",
    modifiers,
  };
}

export function MenuButton<T>(props: MenuButtonProps<T>) {
  const {
    id,
    menu: { theme, autofocus, ...menu },
    popper,
    closeWhenFocusedOutside,
    closeAttributeIds,
    ...button
  } = props;
  const [isOpen, setOpened] = useState(false);
  const [buttonElement, setButtonElement] = useState<HTMLElement | null>(null);
  const [menuElement, setMenuElement] = useState<HTMLUListElement | null>(null);
  const [initialKeyboardCursor, setInitialKeyboardCursor] = useState<
    "start" | "end"
  >("start");
  const [isPopperPositioned, setPopperPositioned] = useState<boolean>(false);

  const onPopperFirstUpdate = useCallback(() => {
    (autofocus === undefined || autofocus) && setPopperPositioned(true);
  }, [setPopperPositioned, autofocus]);

  let { styles, attributes } = usePopper(
    popper?.referenceElement ?? buttonElement,
    menuElement,
    {
      ...withPopperDefaults(popper),
      onFirstUpdate: onPopperFirstUpdate,
    }
  );

  const onButtonClick = useCallback(
    (evt) => {
      evt.preventDefault();
      if (!isOpen) {
        setInitialKeyboardCursor("start");
      }
      setOpened(!isOpen);
    },
    [setInitialKeyboardCursor, setOpened, isOpen]
  );

  const onKeyDown = useCallback(
    (evt: KeyboardEvent<Element>) => {
      switch (evt.key) {
        case "ArrowDown":
          setInitialKeyboardCursor("start");
          setOpened(true);
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "ArrowUp":
          setInitialKeyboardCursor("end");
          setOpened(true);
          evt.preventDefault();
          evt.stopPropagation();
          break;
        case "Enter":
          evt.preventDefault();
          evt.stopPropagation();
          return;
      }
    },
    [setInitialKeyboardCursor, setOpened]
  );

  const onKeyUp = useCallback(
    (evt: KeyboardEvent<Element>) => {
      switch (evt.key) {
        case "Enter":
          setInitialKeyboardCursor("start");
          setOpened(true);
          evt.preventDefault();
          evt.stopPropagation();
          return;
      }
    },
    [setInitialKeyboardCursor, setOpened]
  );

  const onMenuClose = useCallback(
    (evt) => {
      if (evt.type.indexOf("key") === 0) {
        buttonElement?.focus();
      }
      if (
        closeWhenFocusedOutside === undefined &&
        !buttonElement?.contains(evt.target)
      ) {
        setOpened(false);
      } else if (
        /** to do custom close
        this condition satisfies when click is outside list
        or when the id of target html element is given
      **/
        closeWhenFocusedOutside !== undefined &&
        !buttonElement?.contains(evt.target) &&
        ((closeWhenFocusedOutside && !evt._reactName) ||
          (evt.target &&
            closeAttributeIds &&
            closeAttributeIds?.includes(evt.target.getAttribute("id"))))
      ) {
        setOpened(false);
      }
      if (menu.onClose) {
        menu.onClose(evt);
      }
    },
    [menu, setOpened, buttonElement, closeWhenFocusedOutside, closeAttributeIds]
  );

  return (
    <>
      <Button
        {...(button as ComponentPropsWithoutRef<typeof Button>)}
        id={id}
        ref={setButtonElement}
        aria-haspopup="true"
        aria-controls={`${id}-menu`}
        aria-expanded={isOpen ? "true" : undefined}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onClick={onButtonClick}
      />
      {isOpen ? (
        <ThemeProvider theme={theme ?? "modal"}>
          <Menu
            {...menu}
            id={`${id}-menu`}
            initialKeyboardCursor={initialKeyboardCursor}
            ref={setMenuElement}
            onClose={onMenuClose}
            style={styles.popper}
            autofocus={isPopperPositioned}
            {...attributes.popper}
            hasInputFields={menu.hasInputFields}
          >
            {menu.children}
          </Menu>
        </ThemeProvider>
      ) : (
        <ul id={`${id}-menu`} role="menu" hidden></ul>
      )}
    </>
  );
}
MenuButton.displayName = "ARIA.MenuButton";
