import { SVGAttributes } from "react";

export const DownloadIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3 15a1 1 0 110-2h10a1 1 0 110 2H3zM10.293 7.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L8 9.586l2.293-2.293z"
          fill="currentColor"
        />
        <path d="M9 10a1 1 0 11-2 0V3a1 1 0 012 0v7z" fill="currentColor" />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 12.586V3a1 1 0 10-2 0v9.586L7.707 9.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0l5-5a1 1 0 00-1.414-1.414L13 12.586zM3 19a1 1 0 100 2h18a1 1 0 100-2H3z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
DownloadIcon.displayName = "DownloadIcon";
