import { SVGAttributes } from "react";

export const USVanityFairIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={110}
      height={24}
      viewBox="0 0 110 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1944_172)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.317 5.434c0-3.894-1.335-4.75-3.135-4.75h-.528v11.223h.71c2.023 0 2.953-1.242 2.953-4.532v-1.94zM95.967 24v-.652h.992V.656h-.994V0h5.27c3.926 0 6.806 1.88 6.806 6.264 0 3.408-2.04 4.757-2.962 5.238l3.736 11.846h.675V24h-4.367l-3.42-11.41h-1.05v10.758h1.46V24h-6.146zM8.397.654V.001h3.397v.653h-.97L6.947 24H4.283L.74.654H0V.001h5.895v.653h-1.49l2.644 18.17L10.016.654H8.397zm2.045 22.692L14.279.001h2.749l3.807 23.346h.713V24H15.7v-.652h1.47l-1.042-6.395H12.3l-1.05 6.395h1.577V24H9.462v-.654h.98zm5.597-7.108L14.221 4.913l-1.804 11.325h3.622zM30.383.652V.001h3.446v.652h-1.055V24h-2.08L23.675 2.854v20.493h1.584V24h-3.135v-.654h.744V.652h-1.054V.001h4.595l5.557 16.498V.652h-1.584zm4.121 0V.001h5.682v.652h-.993v22.694h.993V24h-5.682v-.654h.995V.652h-.995zm6.406 6.552V.001h11.766v7.203h-.606L50.665.654H48.64v22.693h1.117V24h-5.929v-.652h1.117V.653H42.92l-1.405 6.551h-.605zM61.442.652V.001h3.26v.652h-.835l-2.839 12.323v10.371h1.118V24h-5.93v-.652h1.117V13.004L53.866.654h-.728V.001h5.87v.651h-1.541l3.117 10.698L63.06.652h-1.618zm6.69 0V.001h10.276v6.273h-.606L76.301.654H72.82v10.524h2.233l.762-3.042h.605v6.798h-.605l-.762-3.01H72.82v11.423h1.459V24h-6.148v-.654h.993V.652h-.993zm9.765 22.694L81.734.001h2.75l3.805 23.346h.714V24h-5.847v-.652h1.47l-1.043-6.395h-3.829l-1.05 6.395h1.577V24h-3.364v-.654h.98zm5.597-7.108L81.677 4.913l-1.805 11.325h3.622zM89.58.652V.001h5.682v.652h-.992v22.694h.992V24h-5.68v-.654h.992V.652h-.994z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1944_172">
          <path fill="#fff" d="M0 0h109.491v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
USVanityFairIcon.displayName = "USVanityFairIcon";
