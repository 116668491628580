import { SVGAttributes } from "react";

export const PreviewIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.111 7.541c.296-.587.85-1.417 1.682-2.248C4.216 3.87 5.955 3 8 3c2.044 0 3.784.87 5.207 2.293.831.83 1.386 1.66 1.682 2.248a.995.995 0 010 .917c-.296.588-.851 1.418-1.682 2.25C11.784 12.13 10.044 13 8 13c-2.045 0-3.784-.87-5.207-2.293-.831-.83-1.386-1.66-1.682-2.248a1.005 1.005 0 010-.918zm3.096-.834C5.284 5.63 6.545 5 8 5s2.716.63 3.793 1.707A8.048 8.048 0 0112.839 8l-.046.07a8.048 8.048 0 01-1 1.223C10.716 10.37 9.455 11 8 11s-2.716-.63-3.793-1.707A8.053 8.053 0 013.161 8l.046-.07a8.053 8.053 0 011-1.223zM10 8a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5C8.927 5 6.21 6.527 4.316 8.029a17.315 17.315 0 00-2.283 2.168 9.159 9.159 0 00-.658.842c-.08.119-.155.241-.215.36a1.73 1.73 0 00-.092.213A1.19 1.19 0 001 12c0 .196.055.351.08.42.033.084.071.159.104.217.067.12.15.241.236.359.176.238.418.524.708.835a19.91 19.91 0 002.389 2.15C6.448 17.461 9.167 19 12 19c2.806 0 5.515-1.388 7.466-2.819.988-.724 1.822-1.489 2.419-2.154.297-.33.55-.654.736-.949.093-.147.18-.304.246-.464.06-.147.133-.364.133-.614 0-.41-.195-.797-.337-1.044a6.989 6.989 0 00-.684-.956c-.56-.67-1.35-1.441-2.312-2.172C17.754 6.378 15.046 5 12 5zm-8.41 7.466a9.589 9.589 0 01-.428-.488c.099-.132.227-.291.383-.471.47-.542 1.159-1.233 2.014-1.911C7.289 8.223 9.573 7 12 7c2.454 0 4.746 1.123 6.458 2.422.85.644 1.528 1.311 1.985 1.859.229.275.39.505.486.673l.017.03-.017.028a5.37 5.37 0 01-.533.68c-.496.553-1.225 1.226-2.112 1.877C16.484 15.888 14.194 17 12 17c-2.167 0-4.448-1.212-6.267-2.606a17.912 17.912 0 01-2.143-1.928zm17.432-.626l-.002.007a.062.062 0 01.002-.007zM16 12a4 4 0 11-8 0 4 4 0 018 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
PreviewIcon.displayName = "PreviewIcon";
