import { useEffect } from "react";

const interactiveElements = [
  'input[type="checkbox"]',
  'input[type="email"]',
  'input[type="password"]',
  'input[type="radio"]',
  'input[type="search"]',
  'input[type="tel"]',
  'input[type="text"]',
  "a[href]",
  "button",
  "input:not([type])",
  "select",
  "textarea",
  "[contenteditable='true']",
  "[role='button']",
  "[role='tablist']",
];

const getFocusableElementsOf = (container: HTMLElement): HTMLElement[] => {
  return Array.from(
    container.querySelectorAll(interactiveElements.join(", "))
  ).filter(
    (element) =>
      element.getAttribute("tabIndex") !== "-1" &&
      !element.hasAttribute("disabled")
  ) as HTMLElement[];
};

// When trapping focus, allow focus to move into transient
// elements so that we can do things like copy their data
// to the clipboard. Elements must be explicitly marked
// with this data attribute
function isTransientElement(element: HTMLElement) {
  return element.dataset.transient === "true";
}

type FocusTrapOptions = {
  disableReturnFocus?: boolean;
};

export function useFocusTrap(
  container: HTMLElement | null,
  onClose: () => unknown,
  options?: FocusTrapOptions
) {
  const disableReturnFocus = options?.disableReturnFocus ?? false;

  useEffect(() => {
    if (container == null) {
      return;
    }
    let initialActiveElement = document.activeElement as HTMLElement;
    let focusableElements = getFocusableElementsOf(container);
    let firstFocusedElement = focusableElements.every(
      (element) => element.tagName === "BUTTON"
    )
      ? focusableElements.find(
          (element) => element.getAttribute("type") === "submit"
        ) ?? focusableElements[0]
      : focusableElements[0];
    let lastFocusedElement = firstFocusedElement;

    const onFocus = (evt: WindowEventMap["focus"]) => {
      if (
        !isTransientElement(evt.target as HTMLElement) &&
        !container?.contains(evt.target as HTMLElement)
      ) {
        evt.preventDefault();
        if (lastFocusedElement) {
          lastFocusedElement.focus();
        }
      }
    };

    const onBlur = (evt: WindowEventMap["blur"]) => {
      if (evt.target && container?.contains(evt.target as HTMLElement)) {
        lastFocusedElement = evt.target as HTMLElement;
      }
    };

    const onKeyDown = (evt: KeyboardEvent) => {
      const target = evt.target;
      if (target instanceof Element && target.closest("[data-managed-focus]")) {
        return;
      }
      if (evt.key === "Tab" && !evt.ctrlKey) {
        focusableElements = getFocusableElementsOf(container);
        let currentIndex = document.activeElement
          ? focusableElements.indexOf(document.activeElement as HTMLElement)
          : 0;
        let newIndex = currentIndex;
        if (evt.shiftKey) {
          // Tab backwards
          newIndex =
            currentIndex === 0
              ? focusableElements.length - 1
              : currentIndex - 1;
        } else {
          // Tab forwards
          newIndex = (currentIndex + 1) % focusableElements.length;
        }
        if (focusableElements[newIndex]) {
          focusableElements[newIndex].focus();
        }
        evt.preventDefault();
      }
    };

    // Focus the submit button or the first element
    if (lastFocusedElement) {
      lastFocusedElement.focus();
    }

    container.addEventListener("keydown", onKeyDown, true);
    document.addEventListener("focus", onFocus, true);
    document.addEventListener("blur", onBlur, true);

    return () => {
      // Return the focus to the initial element
      if (initialActiveElement && !disableReturnFocus) {
        initialActiveElement.focus();
      }

      container.removeEventListener("keydown", onKeyDown, true);
      document.removeEventListener("focus", onFocus, true);
      document.removeEventListener("blur", onBlur, true);
    };
  }, [container, disableReturnFocus]);

  useEffect(() => {
    if (container == null) {
      return;
    }
    const onKeyDown = (evt: KeyboardEvent) => {
      const target = evt.target;
      if (target instanceof Element && target.closest("[data-managed-focus]")) {
        return;
      }
      if (evt.key === "Escape") {
        onClose();
        evt.preventDefault();
        evt.stopPropagation();
      }
      return () => {
        container.removeEventListener("keydown", onKeyDown, true);
      };
    };
    container.addEventListener("keydown", onKeyDown, true);
  }, [container, onClose]);
}
