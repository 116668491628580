import { EllipsisIcon } from "../__generated__";
import styled, { keyframes } from "styled-components";

const hop = keyframes`
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(3px);
  }

  40% {
    transform: translateY(0);
  }
`;

export const AnimatedEllipsisIcon = styled(EllipsisIcon)`
  rect {
    animation: 1s ${hop} ease infinite;

    &:nth-of-type(1) {
      animation-delay: 0.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
  }
`;

export const AnimatedEllipsisCircleIcon = styled(EllipsisIcon)`
  circle {
    animation: 1s ${hop} ease infinite;

    &:nth-of-type(1) {
      animation-delay: 0.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
  }
`;
