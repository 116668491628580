import { SVGAttributes } from "react";

export const HorizontalRuleIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={2} y={11} width={20} height={2} rx={1} fill="currentColor" />
    </svg>
  );
};
HorizontalRuleIcon.displayName = "HorizontalRuleIcon";
