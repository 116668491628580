import { SVGAttributes } from "react";

export const ContentTypeAirportIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_72)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0A2 2 0 00.077 2.55l.571 2c.089.31.248.585.457.81A1.998 1.998 0 001 6v8a2 2 0 002 2h2a1.99 1.99 0 001-.268c.294.17.636.268 1 .268h7a2 2 0 002-2V9a2 2 0 00-2-2H7V6c0-.224-.037-.44-.105-.64.21-.225.368-.5.457-.81l.571-2A2 2 0 006 0H2zm11 14h1V9H7v5h1v-1a2 2 0 012-2h1a2 2 0 012 2v1zm-3 0h1v-1h-1v1zM5 9v5H3V6h2v3zm0-5h.429L6 2H2l.571 2H5z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_72">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_73)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0A2 2 0 00.06 2.485l1 4c.136.547.49.993.957 1.257A2.02 2.02 0 002 8v13a2 2 0 002 2h3a1.99 1.99 0 001-.268c.294.17.636.268 1 .268h13a2 2 0 002-2v-8a2 2 0 00-2-2H9V8c0-.088-.006-.174-.017-.258.466-.264.82-.71.957-1.257l1-4A2 2 0 009 0H2zm18 21h2v-2h-2v2zm2-4v-4H9v4h2a2 2 0 012-2h5a2 2 0 012 2h2zM9 21v-2h2v2H9zm4 0h5v-4h-5v4zM7 8H4v4.172l3-3V8zm0 4l-3 3v3.172l3-3V12zm0 9H4l3-3v3zM7 6h1l1-4H2l1 4h4zM4 4a1 1 0 011-1h1a1 1 0 010 2H5a1 1 0 01-1-1z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_73">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
ContentTypeAirportIcon.displayName = "ContentTypeAirportIcon";
