import styled from "styled-components";
import { Toast } from "../Toast";
import { useCallback, MouseEvent as ReactMouseEvent } from "react";
import { FormattedMessage } from "react-intl";

const ValidationList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;

  li {
    display: list-item;
  }

  a {
    color: ${(props) => props.theme.Color};
    border-bottom: none;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ValidationSummary = ({
  errors,
  onClick,
}: {
  errors: { path: ReadonlyArray<string>; message: string }[];
  onClick?: (error: { path: ReadonlyArray<string>; message: string }) => void;
}) => {
  const onClickCapture = useCallback(
    (error: { path: ReadonlyArray<string>; message: string }) =>
      (evt: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (onClick) {
          evt.preventDefault();
          onClick(error);
        }
      },
    [onClick]
  );
  return (
    <Toast
      type="error"
      details={
        <ValidationList>
          {errors.map((error) => {
            const { path, message } = error;
            const joinedPath = `#${path.join(".")}`;
            return (
              <li key={joinedPath}>
                <a href={joinedPath} onClickCapture={onClickCapture(error)}>
                  <span>{message}</span>
                </a>
              </li>
            );
          })}
        </ValidationList>
      }
    >
      <FormattedMessage
        defaultMessage="Please correct the following errors:"
        description="This appears at the beginning of a list of validation errors"
      />
    </Toast>
  );
};
ValidationSummary.displayName = "ValidationSummary";
