import React from "react";
import styled from "styled-components";
import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";
import { Asset, ContentType, Filename, Title } from "../-private";
import { Byline, ActionsMenu, ResultIndex } from "./SubComponents";
import { EditIcon, CheckIcon, UtilitiesIcon } from "../../../icons";
import {
  PublishStatusIndicator,
  withTooltip,
  RoutableLink,
  Metadata,
} from "../../index";
import { getMetaData } from "../helper";
import type { Props } from "../types";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

//These should be refactored out as well
const MEDIA_TYPES = ["cartoon", "clip", "photo", "getty", "gettyvideo"];

const StyledFilename = styled(Filename)`
  font: ${(props) => props.theme.FontAssetTitle};
`;

export const CheckIconWrapper = styled.div`
  background: ${(props) => props.theme.PrimaryBackground};
  border-bottom-right-radius: ${(props) => props.theme.CornerRadius};
  position: absolute;
  padding: var(--spacing-xxs);
  top: 0;
  left: 0;
  visibility: hidden;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${(props) => props.theme.PrimaryColor};
`;

export const SearchResultSlatContainer = styled.div<{
  $isArchived: boolean;
  $hasByline: boolean;
  $hasResultIndex: boolean;
  $hasActionsMenu: boolean;
  $selectionInitiated: boolean;
  $hasContextualOverrides: boolean;
  blur: boolean;
}>`
  display: grid;
  grid-area: search-result;
  gap: var(--spacing-xs);
  grid-row-gap: var(--spacing-xs);
  grid-column-gap: var(--spacing-xs);
  user-select: none;
  align-items: start;
  padding: var(--spacing-xs);
  grid-template-areas:
    "image . title-bar status-info actions-menu ."
    "image . asset-info asset-info actions-menu .";
  grid-template-rows: auto auto;
  grid-template-columns: auto auto 1fr auto auto auto;

  border-top: 1px solid ${(props) => props.theme.BorderlessActiveBackground};

  &[aria-selected="true"] {
    color: ${(props) => props.theme.SecondaryActiveColor};
    background: ${(props) => props.theme.SecondaryActiveBackground};
  }

  background: ${(props) => props.theme.Background};
  color: ${(props) => props.theme.Color};
  position: relative;
  cursor: pointer;
  opacity: ${(props) => (props.blur ? 0.5 : 1)};

  &:hover {
    background: ${(props) => props.theme.BorderlessHoverBackground};
  }

  ${({ $selectionInitiated, theme }) =>
    $selectionInitiated &&
    `
      &:hover {
        ${CheckIconWrapper} {
          visibility: visible;
          background: ${theme.BorderlessHoverBackground};
          ${StyledCheckIcon} {
            color: ${theme.SupportColor};
          }
        }
      }
      &[aria-selected="true"] {
        color: ${theme.SecondaryActiveColor};
        background: ${theme.SecondaryActiveBackground};
        border: 1px solid ${theme.PrimaryBackground};
      }

      &[aria-selected="true"] ${CheckIconWrapper} {
        background: ${theme.PrimaryBackground};
        visibility: visible;
      }

      &[aria-selected="true"] ${CheckIconWrapper} ${StyledCheckIcon} {
        color: ${theme.PrimaryColor};
      }
    `}

  ${({ $isArchived, theme }) =>
    $isArchived &&
    `
      background: ${theme.PrimaryDisabledBackground}
    `}

  ${({ $hasByline, $hasResultIndex }) => {
    if ($hasByline && $hasResultIndex) {
      return `
        grid-template-areas:
          "result-index image title-bar status-info actions-menu ."
          "result-index image byline byline actions-menu ."
          "result-index image asset-info asset-info actions-menu .";
        grid-template-rows: auto auto auto;
        grid-template-columns: auto auto 1fr auto auto auto;
      `;
    } else if ($hasResultIndex) {
      return `
        grid-template-areas:
          "result-index image title-bar status-info actions-menu ."
          "result-index image asset-info asset-info actions-menu .";
        grid-template-rows: auto auto;
        grid-template-columns: auto auto 1fr auto auto auto;
      `;
    } else {
      return ``;
    }
  }}
`;

const SearchResultLink = styled(RoutableLink)`
  /** Reset */
  appearance: none;
  text-decoration: none;
  color: var(--color-gray-1);
`;

const AssetUpdateDate = styled.div`
  font: ${(props) => props.theme.FontSmallStatement};
  color: var(--color-gray-3);
`;

const AssetInfo = styled.div`
  grid-area: asset-info;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
`;

const StatusInfo = styled.div`
  grid-area: status-info;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
`;

const AssetTitleBar = styled.div`
  grid-area: title-bar;
  display: flex;
  align-items: center;
  align-self: end;
  gap: var(--spacing-xxs);

  > :first-child {
    flex: auto;
  }
`;

const IconWrapper = withTooltip(styled.div`
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  background-color: var(--color-gray-6);
  justify-content: center;
  align-items: center;
  cursor: default;

  > span {
    display: flex;
  }
`);

const ContextualOverridesIconWrapper = styled(IconWrapper)`
  background-color: var(--color-blue-70);
`;

function SearchResultSlat<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  const {
    result,
    cdnHost,
    query,
    children,
    to,
    selectionInitiated,
    hasContextualOverrides,
    ...forwardProps
  } = props;

  const modifiedDate =
    result.revisionInfo?.createdAt && new Date(result.revisionInfo?.createdAt);

  let updatedOn;

  if (modifiedDate && !isNaN(modifiedDate.getTime())) {
    const now = new Date();
    const timeDifference = {
      minutes: differenceInMinutes(now, modifiedDate),
      hours: differenceInHours(now, modifiedDate),
      days: differenceInDays(now, modifiedDate),
      months: differenceInMonths(now, modifiedDate),
      years: differenceInYears(now, modifiedDate),
    };

    if (timeDifference.minutes < 60) {
      updatedOn =
        timeDifference.minutes === 1
          ? "1 min ago"
          : `${timeDifference.minutes} mins ago`;
    } else if (timeDifference.hours < 24) {
      updatedOn =
        timeDifference.hours === 1
          ? "1 hour ago"
          : `${timeDifference.hours} hours ago`;
    } else if (timeDifference.days < 30) {
      updatedOn =
        timeDifference.days === 1
          ? "1 day ago"
          : `${timeDifference.days} days ago`;
    } else if (timeDifference.months < 12) {
      updatedOn =
        timeDifference.months === 1
          ? "1 month ago"
          : `${timeDifference.months} months ago`;
    } else {
      updatedOn =
        timeDifference.years === 1
          ? "1 year ago"
          : `${timeDifference.years} years ago`;
    }
  }

  const revisionAuthor = result.revisionInfo?.authorName;
  const isMedia = MEDIA_TYPES.includes(result.contentType);
  const Byline = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) &&
      child.type === SlatResult.Byline &&
      child.props.contributorsLabel.length
  );

  const ResultIndex = React.Children.toArray(children).find(
    (child) =>
      React.isValidElement(child) && child.type === SlatResult.ResultIndex
  );
  const ActionsMenu = React.Children.toArray(props.children).find(
    (child) =>
      React.isValidElement(child) && child.type === SlatResult.ActionsMenu
  );
  return (
    <SearchResultSlatContainer
      $isArchived={result.archived}
      $hasByline={!!Byline}
      $hasResultIndex={!!ResultIndex}
      $hasActionsMenu={!!ActionsMenu}
      $selectionInitiated={selectionInitiated}
      $hasContextualOverrides={hasContextualOverrides}
      {...forwardProps}
    >
      <CheckIconWrapper>
        <StyledCheckIcon size="small" />
      </CheckIconWrapper>
      {ResultIndex}
      <Asset
        cdnHost={cdnHost}
        asset={result.asset || undefined}
        contentType={result.contentType}
        assetAspectRatio="3:4"
        modifications={{ width: 48, height: 64 }}
      />
      <AssetTitleBar>
        {isMedia ? (
          <StyledFilename title={result.title} query={query} fidelity="full" />
        ) : (
          <Title
            title={result.title}
            query={query}
            fidelity="full"
            clamp={props.clampTitle}
          />
        )}
      </AssetTitleBar>
      <StatusInfo>
        {result.publishable ? (
          result.publishInfo && result.revisionInfo ? (
            <PublishStatusIndicator
              publishInfo={result.publishInfo}
              latestRevision={result.revisionInfo.version}
              publishQueue={result.publishQueue}
              size="small"
            />
          ) : (
            <IconWrapper aria-label="Draft">
              <EditIcon size="small" />
            </IconWrapper>
          )
        ) : null}
        {hasContextualOverrides ? (
          <ContextualOverridesIconWrapper aria-label="Item has Overrides">
            <UtilitiesIcon size="small" />
          </ContextualOverridesIconWrapper>
        ) : null}
      </StatusInfo>
      {Byline}
      <AssetInfo>
        <ContentType
          contentTypeLabel={result.contentTypeLabel}
          fidelity="default"
          textBold={true}
        />
        {result.metadata && (
          <Metadata metadata={getMetaData(result.metadata)} />
        )}
        {updatedOn && (
          <AssetUpdateDate>
            <FormattedMessage
              defaultMessage={"Updated {updatedOn} by {author}"}
              description="shows the date on which a piece of content was last edited"
              values={{
                updatedOn: updatedOn,
                author: revisionAuthor,
              }}
            />
          </AssetUpdateDate>
        )}
      </AssetInfo>
      {ActionsMenu}
    </SearchResultSlatContainer>
  );
}

export function SlatResult<
  C extends keyof JSX.IntrinsicElements | ComponentType<unknown> | undefined
>(props: Props<C>) {
  return (
    <>
      {props.to && !props.selectionInitiated ? (
        <SearchResultLink to={props.to} onClick={props.trackNavigation}>
          <SearchResultSlat
            $selectionInitiated={props.selectionInitiated}
            {...props}
          />
        </SearchResultLink>
      ) : (
        <SearchResultSlat
          $selectionInitiated={props.selectionInitiated}
          {...props}
        />
      )}
    </>
  );
}

SlatResult.Byline = Byline;
SlatResult.ActionsMenu = ActionsMenu;
SlatResult.ResultIndex = ResultIndex;
