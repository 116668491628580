import { useState, MouseEventHandler, RefObject } from "react";

type DragParameters = {
  /**
   * The height in pixels of the regions that trigger the scrolling animation
   * during a drag operation
   */
  dragZoneSize: number;

  /**
   * The number of pixels the drag animation will attempt to scroll on each animation frame.
   * in practice the animation scrolls fewer pixels than this, depending on what the browser
   * is doing simultaneously.
   */
  distancePerFrame: number;

  /**
   * How many milliseconds to wait between animation frames. This is a lower bound; the actual
   * interval is determined by the event queue. Accepts values above 10:
   * all other values are treated as if they were 10.
   */
  msPerFrame: number;

  /**
   * Behavior to pass to `window.scrollTo`
   */
  scrollBehavior?: "auto" | "smooth";

  /**
   * How many milliseconds to keep animating after the most recent dragOver event.
   * dragOver events fire "every few hundred milliseconds", somewhat arbitrarily.
   * However, there can be a noticeable delay at the end of a drag operation between
   * the final dragOver event and the corresponding dragEnd event: during this period,
   * we need to stop scrolling at some point. This number is the cutoff point.
   */
  eventDelayCutoff?: number;
};

export function useScrollWhenDragging(
  {
    dragZoneSize,
    distancePerFrame,
    msPerFrame,
    scrollBehavior = "auto",
    eventDelayCutoff = 100,
  }: DragParameters,
  containerRef?: RefObject<HTMLElement>
) {
  let scrolling: -1 | 0 | 1 = 0;
  let viewport = window.visualViewport;
  let intervalWorker: number | null = null;
  let lastAnimationTimestamp = 0;
  let lastDragoverEventTimestamp = 0;

  function reset() {
    scrolling = 0;
    if (intervalWorker) {
      window.clearInterval(intervalWorker);
      intervalWorker = null;
    }
  }

  function animate(ts: DOMHighResTimeStamp) {
    let top = containerRef
      ? containerRef?.current?.scrollTop
      : viewport?.pageTop || 0;

    if (typeof top !== "number") {
      return;
    }
    let targetY = scrolling * distancePerFrame + top;
    let isTimeForNewFrame = ts - lastAnimationTimestamp >= msPerFrame;
    let isTooLongSinceLastEvent =
      ts - lastDragoverEventTimestamp >= eventDelayCutoff;

    if (isTimeForNewFrame && !isTooLongSinceLastEvent) {
      if (containerRef && containerRef.current) {
        containerRef.current.scrollTo({
          top: targetY,
          behavior: scrollBehavior,
        });
      } else {
        window.scrollTo({ top: targetY, behavior: scrollBehavior });
      }
      lastAnimationTimestamp = ts;
    }
  }

  const [eventHandlers] = useState<{
    onDragOver: MouseEventHandler;
    onDragEnd: MouseEventHandler;
    onMouseLeave: MouseEventHandler;
  }>({
    onDragOver(event) {
      event.preventDefault();
      lastDragoverEventTimestamp = event.timeStamp;

      let { clientY } = event;
      if (clientY <= dragZoneSize) {
        scrolling = -1;
      } else if (viewport && viewport.height - clientY <= dragZoneSize) {
        scrolling = 1;
      } else {
        reset();
      }

      if (scrolling && !intervalWorker) {
        intervalWorker = window.setInterval(
          () => window.requestAnimationFrame(animate),
          10
        );
      }
    },
    onDragEnd: reset,
    onMouseLeave: reset,
  });

  return eventHandlers;
}
