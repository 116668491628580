import { SVGAttributes } from "react";

export const UnlockIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11 9H5v4h6V9zM6 7h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1V4a3 3 0 013-3h2a3 3 0 013 3v1h-2V4a1 1 0 00-1-1H7a1 1 0 00-1 1v3z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M16.5 13.5h-9v6h9v-6zm-7.5-3h9a1.5 1.5 0 011.5 1.5v9a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 21v-9A1.5 1.5 0 016 10.5V6a4.5 4.5 0 014.5-4.5h3A4.5 4.5 0 0118 6v1.5h-3V6a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 6v4.5z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
UnlockIcon.displayName = "UnlockIcon";
