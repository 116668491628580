import { CloseIcon, AnimatedEllipsisIcon, SmallIcons } from "../../../icons";
import { Button, Chip, Label } from "../../index";
import {
  forwardRef,
  useCallback,
  ChangeEvent,
  useState,
  ComponentPropsWithoutRef,
  Ref,
} from "react";
import styled from "styled-components";
import type { ContributorSelection } from "../types";
import { useUniqueId } from "../../../hooks";
import { propagateRef } from "../../../lib";

const LabelGroup = styled.div`
  grid-area: label;
  display: none;
`;

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.ToastBackground};
  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(data-ph);
    color: ${(props) => props.theme.PlaceholderColor};
  }
`;

const SelectorWrapper = styled.div`
  position: relative;
  grid-area: input;
  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  &:hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};
  }
  &:focus-within {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
  }
`;

const HostnameChip = styled(Chip)`
  color: var(--color-gray-1);
  background-color: var(--color-gray-5);
  margin: var(--spacing-xs) var(--spacing-sm);
  margin-right: var(--spacing-xxs);
  margin-left: var(--spacing-xxs);
  font-weight: bold;
  float: left;

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectorInput = styled.span`
  vertical-align: middle;
  outline: 0px solid transparent;
  line-height: var(--spacing-md);
  padding: var(--spacing-xs);
  display: inline-block;
  min-width: 200px;
  &:focus {
    outline: none;
  }
  &:focus-within {
    outline: none;
  }
`;

const ChipSelection = styled.span``;
const SelectionOptions = styled.span`
  padding-right: var(--spacing-xs);
  font: ${(props) => props.theme.FontLabel};
`;

export type SearchInputProps = ComponentPropsWithoutRef<
  typeof SelectorInput
> & {
  loading?: boolean;
  icon?: SmallIcons;
  onChange: (value: string) => void;
  onSelectionChange: (selection: ContributorSelection | undefined) => void;
  selections: ContributorSelection[];
  setInputClick: (value: boolean) => void;
  label?: string;
};
export const SearchInput = forwardRef(function (
  props: SearchInputProps,
  forwardedRef: Ref<HTMLInputElement>
) {
  const {
    loading,
    value,
    onChange,
    icon: Icon,
    selections,
    setInputClick,
    onSelectionChange,
    label,
    ...forwardProps
  } = props;
  const [placeHolderSpan] = useState("Type a name to begin");
  const id = useUniqueId();

  const change = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(evt.target.innerHTML);
    },
    [onChange]
  );

  const remove = useCallback(
    (contributorSelection: ContributorSelection) => {
      let updatedSelections = selections.find(
        (selection) =>
          selection.id === contributorSelection.id &&
          selection.role === contributorSelection.role
      );
      onSelectionChange(updatedSelections);
    },
    [onSelectionChange, selections]
  );

  return (
    <Wrapper>
      <LabelGroup>
        <Label htmlFor={`SelectorField-${id}`}>{label}</Label>
      </LabelGroup>
      <SelectorWrapper>
        {Icon &&
          (loading ? (
            <AnimatedEllipsisIcon
              size="small"
              className="inputIcon loading"
              style={{
                left: "var(--spacing-sm)",
                position: "absolute",
                top: "10px",
              }}
            />
          ) : (
            <Icon
              size="small"
              className="inputIcon"
              style={{
                left: "13px",
                position: "absolute",
                top: "13px",
              }}
            />
          ))}
        <div style={{ paddingLeft: "35px", display: "inline-block" }}>
          {selections &&
            selections.map((item: ContributorSelection) => (
              <HostnameChip
                size="small"
                key={`${item.role}:${item.id}`}
                style={{
                  color: "#ffffff",
                  backgroundColor: "#191919",
                  fontWeight: "normal",
                }}
              >
                <ChipSelection>
                  <SelectionOptions>{item.roleLabel}</SelectionOptions>
                  {item.name}
                </ChipSelection>
                <Button
                  aria-label="Remove"
                  style={{
                    padding: "0 0 0 8px",
                    background: "transparent",
                    boxShadow: "none",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    if (selections) {
                      let contributorSelection = selections.find(
                        (selection: ContributorSelection) => selection == item
                      );

                      contributorSelection && remove(item);
                    }
                  }}
                >
                  <CloseIcon
                    size="small"
                    style={{ left: 0, width: "12px", height: "12px" }}
                  />
                </Button>
              </HostnameChip>
            ))}

          <SelectorInput
            ref={(el) => {
              if (forwardedRef) {
                propagateRef(forwardedRef, el);
              }
            }}
            id={`SelectorField-${id}`}
            contentEditable
            suppressContentEditableWarning
            onInput={(el: ChangeEvent<HTMLInputElement>) => {
              change(el);
            }}
            onFocus={() => {
              setInputClick(true);
            }}
            onBlur={(el) => {
              setInputClick(false);
              el.target.innerHTML = "";
            }}
            data-ph={placeHolderSpan}
            {...forwardProps}
          ></SelectorInput>
        </div>
      </SelectorWrapper>
    </Wrapper>
  );
});
SearchInput.displayName = "SearchInput";
