import { useCallback, useState, useEffect, FC } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import type { Placement } from "@popperjs/core";

const Arrow = styled.div`
  position: absolute;
  width: 18px;
  height: 14px;
  overflow: hidden;
  pointer-events: none;

  &:before {
    position: absolute;
    transform: rotate(45deg);
    content: "";
    background: ${(props) => props.theme.TooltipBackground};
    box-shadow: ${(props) => props.theme.TooltipShadow};
    width: 14px;
    height: 14px;
  }
`;

const TooltipPopover = styled.div`
  background: ${(props) => props.theme.TooltipBackground};
  color: ${(props) => props.theme.TooltipColor};
  border: none;
  text-align: center;
  border-radius: ${(props) => props.theme.CornerRadius};
  font: ${(props) => props.theme.FontSmallStatement};
  width: auto;
  position: relative;
  z-index: ${(props) => props.theme.ElevationMenu + 1};
  padding: var(--spacing-xxs) var(--spacing-xs);
  white-space: nowrap;
  box-shadow: ${(props) => props.theme.TooltipShadow};

  &[data-popper-placement="top"],
  &[data-popper-placement="bottom"] {
    ${Arrow} {
      width: 18px;
      height: 14px;
      &:before {
        left: 2px;
      }
    }
  }

  &[data-popper-placement="bottom"] ${Arrow} {
    top: -14px;
    &:before {
      top: 9px;
    }
  }
  &[data-popper-placement="top"] ${Arrow} {
    bottom: -14px;
    &:before {
      top: -9px;
    }
  }

  &[data-popper-placement="left"],
  &[data-popper-placement="right"] {
    ${Arrow} {
      width: 14px;
      height: 18px;
      &:before {
        top: 2px;
      }
    }
  }
  &[data-popper-placement="right"] ${Arrow} {
    left: -14px;
    &:before {
      left: 9px;
    }
  }
  &[data-popper-placement="left"] ${Arrow} {
    right: -14px;
    &:before {
      left: -9px;
    }
  }
  &[hidden] {
    display: none;
  }
`;

export const Tooltip: FC<{
  target: HTMLElement | null;
  hidden?: boolean;
  tooltipPlacement?: Placement;
}> = ({ target, hidden, children, tooltipPlacement }) => {
  let [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  let [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  let [active, setActive] = useState(false);
  let show = useCallback(() => setActive(true), [setActive]);
  let hide = useCallback(() => setActive(false), [setActive]);

  useEffect(() => {
    if (target) {
      target.addEventListener("mouseenter", show);
      target.addEventListener("focus", show);
      target.addEventListener("mouseleave", hide);
      target.addEventListener("blur", hide);
    }

    return () => {
      if (target) {
        target.removeEventListener("mouseenter", show);
        target.removeEventListener("focus", show);
        target.removeEventListener("mouseleave", hide);
        target.removeEventListener("blur", hide);
      }
    };
  }, [target, show, hide]);

  const { styles, attributes } = usePopper(
    target,
    hidden !== true && active ? popperElement : null,
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        { name: "preventOverflow", options: { padding: 8 } },
        { name: "offset", options: { offset: [0, 8] } },
      ],
      placement: tooltipPlacement ?? "top",
    }
  );

  return (
    <TooltipPopover
      ref={setPopperElement}
      className="popper"
      hidden={hidden || !active}
      role="presentation"
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
      <Arrow
        ref={setArrowElement}
        className="arrow"
        style={styles.arrow}
      ></Arrow>
    </TooltipPopover>
  );
};
