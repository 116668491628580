import styled from "styled-components";
import {
  PublishedIcon,
  ScheduledIcon,
  PublishedWithChangesIcon,
  ScheduledWithChangesIcon,
} from "../../icons";
import moment from "moment";
import { withTooltip } from "../withTooltip";
import { PublishInfo } from "../SearchResult/types";
import {
  differenceInCalendarDays,
  endOfYesterday,
  startOfTomorrow,
} from "date-fns";

const Wrapper = styled.div<{ $infoLength: number }>`
  display: flex;
  gap: var(--spacing-xxs);
  align-items: center;

  & .popper {
    text-wrap: wrap;
    width: ${(props) => props.$infoLength}px;
  }
`;

const RePublishIcon = styled.div`
  display: flex;
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-xxs);
  background: var(--color-green-90);
`;

export const StatusIndicator = withTooltip(
  styled.div<{ $isLive: boolean; $hasEdits: boolean }>`
    display: flex;
    border-radius: var(--spacing-xxs);
    padding: var(--spacing-xxs);

    background: ${(props) =>
      props.$isLive ? `var(--color-green-90)` : `var(--color-yellow-90)`};
    circle {
      fill: ${(props) => (props.$hasEdits ? `var(--color-red-50)` : `default`)};
    }
    white-space: nowrap;
  `
);

// TODO: extract smaller tag CSS var to theme token
const DisplayTime = styled.label`
  font: var(--font-smaller-tag);
  display: flex;
  padding-left: 4px;
`;

export function PublishStatusIndicator(props: {
  publishInfo: PublishInfo;
  publishQueue: PublishInfo | null | undefined;
  size: "small";
  latestRevision: number;
}) {
  let { publishInfo, latestRevision, size, publishQueue, ...forwardProps } =
    props;
  let hasEdits = publishInfo.version < latestRevision;
  let pInfoDate = moment(publishInfo?.pubDate).utc().toDate();
  let isLive = pInfoDate.getTime() < new Date().getTime();
  let Icon;
  let info;
  let infoLength;
  let isRepublish = false;
  let localizedDisplayDate;
  let localizedPubDate = moment(pInfoDate).format("MMM D, YYYY [at] h:mm A");
  const getDifferenceInDays = (pDate: Date) => {
    if (pDate > new Date()) {
      return differenceInCalendarDays(pDate, new Date());
    } else {
      return differenceInCalendarDays(new Date(), pDate);
    }
  };

  const getlocalizedDisplayDate = (differenceInDays: number, pubDate: Date) => {
    if (differenceInDays <= 1 && pubDate < endOfYesterday())
      return (localizedDisplayDate = "Yesterday");
    else if (differenceInDays <= 1 && pubDate > startOfTomorrow())
      return (localizedDisplayDate = "Tomorrow");
    else if (differenceInDays > 1) {
      return (localizedDisplayDate = moment(pubDate).format("D MMM YYYY"));
    } else {
      return (localizedDisplayDate = moment(pubDate).format("h:mm A"));
    }
  };

  localizedDisplayDate = getlocalizedDisplayDate(
    getDifferenceInDays(pInfoDate),
    pInfoDate
  );

  if (isLive) {
    if (hasEdits) {
      Icon = PublishedWithChangesIcon;
      info = "Published on " + localizedPubDate + " (with unpublished edits)";
      infoLength = 245;
    } else {
      infoLength = 195;
      Icon = PublishedIcon;
      info = "Published on " + localizedPubDate;
    }
  } else {
    if (hasEdits) {
      infoLength = 245;
      Icon = ScheduledWithChangesIcon;
      info = "Scheduled for " + localizedPubDate + " (with unpublished edits)";
    } else {
      infoLength = 195;
      Icon = ScheduledIcon;
      info = "Scheduled for " + localizedPubDate;
    }
  }

  if (publishQueue && publishQueue.version > publishInfo.version) {
    isRepublish = true;
    const pubQueDate = moment(publishQueue?.pubDate).utc().toDate();
    localizedDisplayDate = getlocalizedDisplayDate(
      getDifferenceInDays(pubQueDate),
      pubQueDate
    );
    localizedPubDate = moment(pubQueDate).format("MMM D, YYYY [at] h:mm A");
    if (latestRevision > publishQueue?.version) {
      Icon = ScheduledWithChangesIcon;
      hasEdits = true;
      isLive = false;
      infoLength = 305;
      info =
        "Scheduled to be republished on " +
        localizedPubDate +
        " (with unpublished edits)";
    } else {
      Icon = ScheduledIcon;
      isLive = false;
      hasEdits = false;
      info = "Scheduled to be republished on " + localizedPubDate;
      infoLength = 215;
    }
  }

  return (
    <Wrapper $infoLength={infoLength}>
      {isRepublish && (
        <RePublishIcon>
          <PublishedIcon size={props.size} {...forwardProps} />
        </RePublishIcon>
      )}
      <StatusIndicator $isLive={isLive} $hasEdits={hasEdits} aria-label={info}>
        <Icon size={props.size} {...forwardProps} />
        <DisplayTime>{localizedDisplayDate}</DisplayTime>
      </StatusIndicator>
    </Wrapper>
  );
}

PublishStatusIndicator.displayName = "PublishStatusIndicator";
