import { SVGAttributes } from "react";

export const VersoTextBelowIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1h14c1.105 0 2 .84 2 1.875v11.25C21 15.161 20.105 16 19 16H5c-1.105 0-2-.84-2-1.875V2.875C3 1.839 3.895 1 5 1zm0 1.875v11.25h14V2.875H5zM3 19a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm0 3a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1z"
        fill="currentColor"
      />
    </svg>
  );
};
VersoTextBelowIcon.displayName = "VersoTextBelowIcon";
