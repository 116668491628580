import { ComponentPropsWithoutRef } from "react";
import { Field, Switch } from "../index";
import styled from "styled-components";

const Wrapper = styled(Field)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-gap: 0 var(--spacing-xxs);
  grid-template-areas:
    "label control"
    "message message";
`;

export const Toggle = (
  props: ComponentPropsWithoutRef<typeof Switch> &
    Omit<ComponentPropsWithoutRef<typeof Field>, "children">
) => {
  let { message, label, help, errors, className, ...forwardProps } = props;
  return (
    <Wrapper
      id={props.id}
      label={label}
      help={help}
      message={message}
      errors={errors}
      className={className}
    >
      <Switch {...forwardProps} aria-invalid={!!errors?.length} />
    </Wrapper>
  );
};
