import { SVGAttributes } from "react";

export const ContentTypeRunwaymomentIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.436 2.368A2 2 0 017.333 1h1.334a2 2 0 011.897 1.368l.333 1A2 2 0 0111 4h3a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2V6a2 2 0 011-1.732V4a1 1 0 011-1h1a1 1 0 011 1h1c0-.212.034-.426.103-.632l.333-1zM3 6H2v8h12V6H3zm5 0a4 4 0 100 8 4 4 0 000-8zm.667-3L9 4H7l.333-1h1.334zM10 10a2 2 0 11-4 0 2 2 0 014 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.714 4.566A2 2 0 0110.667 3h2.666a2 2 0 011.953 1.566l.666 3c.032.144.048.29.048.434h6a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2V10a2 2 0 012-2V7a1 1 0 011-1h2a1 1 0 011 1v1h2a2 2 0 01.048-.434l.666-3zm4.62.434L14 8h-4l.667-3h2.666zM2 20V10h10a5 5 0 000 10H2zm10 0a5 5 0 000-10h10v10H12zm3-5a3 3 0 11-6 0 3 3 0 016 0z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeRunwaymomentIcon.displayName = "ContentTypeRunwaymomentIcon";
