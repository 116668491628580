import { SVGAttributes } from "react";

export const DangerIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8zm2 0c0 3.309 2.691 6 6 6s6-2.691 6-6-2.691-6-6-6-6 2.691-6 6z"
        fill="currentColor"
      />
      <path
        d="M4 13.414L2.586 12 12 2.586 13.414 4 4 13.414z"
        fill="currentColor"
      />
    </svg>
  );
};
DangerIcon.displayName = "DangerIcon";
