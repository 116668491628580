import { SVGAttributes } from "react";

export const RemoveCircleIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" }
) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8a8 8 0 1016 0A8 8 0 000 8zm14 0A6 6 0 112 8a6 6 0 0112 0z"
        fill="currentColor"
      />
      <path d="M11 7a1 1 0 110 2H5a1 1 0 010-2h6z" fill="currentColor" />
    </svg>
  );
};
RemoveCircleIcon.displayName = "RemoveCircleIcon";
