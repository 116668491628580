export const base = {
  _tint: "blue",
  CornerRadius: "var(--spacing-xxs)",
  CornerInsetRadius: "calc(var(--spacing-xxs) + 1px)",

  FontPageHeading: "var(--font-page-heading)",
  FontSectionHeading: "var(--font-section-heading)",
  FontSubSectionHeading: "var(--font-subhed)",
  FontSmallerHeading: "var(--font-smaller-heading)",
  FontLabel: "var(--font-label)",
  FontBody: "var(--font-body)",
  FontCode: "var(--font-code)",
  FontAssetTitle: "var(--font-asset-title)",
  FontMediaAssetTitle: "var(--font-asset-title-media)",
  FontStatement: "var(--font-statements)",
  FontSmallStatement: "var(--font-small-statements)",
  FontInteractive: "var(--font-button)",
  FontSmallInteractive: "var(--font-label)",
  SecondaryPadding: "var(--spacing-xs) var(--spacing-sm)",
  SecondaryTightPadding: "var(--spacing-xxs) var(--spacing-sm)",
  SecondaryMediumPadding: "var(--spacing-xxs) var(--spacing-xs)",
  SecondarySmallPadding: "var(--spacing-xxs) var(--spacing-xxs)",
  SecondaryPaddingWithLeftIcon:
    "var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) var(--spacing-xl)",
  SecondaryPaddingWithRightIcon:
    "var(--spacing-xs) var(--spacing-xl) var(--spacing-xs) var(--spacing-sm)",

  DebuggingBackground: "rgba(255, 0, 0, 0.14);",
  DebuggingColor: "rgba(255, 0, 0, 0.4);",

  FieldHoverRing: "inset 0 0 0 1px var(--color-blue-50)",
  FieldFocusRing: "inset 0 0 0 2px var(--color-blue-50)",
  FieldActiveRing: "inset 0 0 0 1px var(--color-blue-50)",
  FieldDisabledColor: "var(--color-gray-4)",
  FieldDisabledRing: "inset 0 0 0 1px var(--color-gray-5)",

  ElevationCard: 10,
  ElevationMenu: 20,
  ElevationModal: 30,
  ElevationAboveModal: 40,

  CardSize: "calc(var(--spacing-xl) * 25)",
  NarrowCardSize: "calc(var(--spacing-xl) * 16)",

  DialogSize: 800,
  NarrowDialogSize: 480,
  LargeDialogSize: 1180,
};
