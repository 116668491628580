import { SVGAttributes } from "react";

export const ContentTypeHotelIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_80)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 0h10a3 3 0 013 3v10a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm11 13a1 1 0 01-1 1v-1a3 3 0 00-5-2.236A3 3 0 003 13v1a1 1 0 01-1-1V9h12v4zm-5 1v-1a1 1 0 112 0v1H9zm-2-1v1H5v-1a1 1 0 112 0zM3 2a1 1 0 00-1 1v4h12V3a1 1 0 00-1-1H3zm2 1a1 1 0 00-1 1v1a1 1 0 002 0V4a1 1 0 00-1-1zm2 1a1 1 0 012 0v1a1 1 0 01-2 0V4zm4-1a1 1 0 00-1 1v1a1 1 0 102 0V4a1 1 0 00-1-1z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_80">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_79)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zM11 18.5V22H6v-3.5a2.5 2.5 0 015 0zM8.5 14c1.414 0 2.675.652 3.5 1.671a4.5 4.5 0 018 2.829V22h1a1 1 0 001-1v-9H2v9a1 1 0 001 1h1v-3.5A4.5 4.5 0 018.5 14zM2 10h20V3a1 1 0 00-1-1H3a1 1 0 00-1 1v7zm16 8.5V22h-5v-3.5a2.5 2.5 0 015 0zM5 5a1 1 0 012 0v2a1 1 0 01-2 0V5zm5-1a1 1 0 00-1 1v2a1 1 0 002 0V5a1 1 0 00-1-1zm3 1a1 1 0 112 0v2a1 1 0 11-2 0V5zm5-1a1 1 0 00-1 1v2a1 1 0 102 0V5a1 1 0 00-1-1z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_79">
            <path fill="#fff" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
};
ContentTypeHotelIcon.displayName = "ContentTypeHotelIcon";
