import { useMemo, useState, useCallback, ReactNode } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { ARIA, Chip, Button } from "../../index";
import { ChevronDownIcon, CloseIcon } from "../../../icons";
import { useIntl } from "react-intl";
import type { SmallIcons, RegularIcons } from "../../../icons/types";
import { useClickOutside } from "../../../hooks";

const Container = styled.div`
  position: relative;
  grid-area: control;
  color: ${(props) => props.theme.Color};

  svg.select-chevron {
    pointer-events: none;
    position: absolute;
    top: calc(var(--spacing-md) / 2);
    right: calc(var(--spacing-md) / 2);
  }

  svg.input-icon {
    height: 16px;
    width: 16px;
    pointer-events: none;
    position: absolute;
    top: calc(var(--spacing-md) / 2);
    left: calc(var(--spacing-md) / 2);
  }

  ul[role="listbox"] {
    z-index: ${(props) => props.theme.ElevationMenu};
  }
`;

const SelectButton = styled.button<{
  $placeholder: boolean;
  $customIcon: boolean;
}>`
  /* Resets */
  appearance: none;
  border: 0;
  margin: 0;
  font: ${(props) => props.theme.FontBody};
  &::-moz-focus-inner {
    border: 0;
  }

  &[disabled] + svg {
    display: none;
  }

  &[aria-expanded] + svg {
    transform: rotate(180deg);
  }

  &[aria-expanded] + svg + ul {
    display: block;
  }

  width: 100%;
  min-height: var(--spacing-xl);

  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: ${(props) =>
    props.disabled
      ? props.theme.ControlDisabledBackground
      : props.theme.FieldBackground};

  border-radius: ${(props) => props.theme.CornerRadius};
  box-shadow: ${(props) => props.theme.FieldRing};
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.ControlDisabledColor;
    } else if (props.$placeholder) {
      return props.theme.SupportColor;
    } else {
      return props.theme.Color;
    }
  }};
  padding: ${(props) => props.theme.SecondaryPadding};
  padding-right: var(--spacing-xl);
  padding-left: ${(props) =>
    props.$customIcon ? "var(--spacing-xl)" : "props.theme.SecondaryPadding"};

  &:not([disabled]):hover {
    box-shadow: ${(props) => props.theme.FieldHoverRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorHoverRing};
    }
  }

  &[aria-invalid="true"] {
    box-shadow: ${(props) => props.theme.ErrorRing};
  }

  &:not([disabled]):active {
    box-shadow: ${(props) => props.theme.FieldActiveRing};

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorRing};
    }
  }

  &:not([disabled]):focus,
  &:not([disabled])[aria-expanded="true"] {
    box-shadow: ${(props) => props.theme.FieldFocusRing},
      ${(props) => props.theme.FocusRing};
    outline: none;

    &[aria-invalid="true"] {
      box-shadow: ${(props) => props.theme.ErrorFocusRing};
    }
  }
`;

const Match = styled.em`
  font-style: normal;
  text-decoration: underline;
`;

const ChipList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: var(--spacing-sm) 0;

  &.selection-list--empty {
    padding: 0;
  }
`;

const Selection = styled(Chip)`
  padding-right: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
  background-color: ${(props) => props.theme.MedallionBackground};

  button {
    background: transparent;
    &:not([disabled]):hover {
      background: transparent;
    }
  }
`;

const SelectionLabel = styled.span`
  margin-right: var(--spacing-xxs);
`;

type OptionT = { label: string | null; value: string; disabled?: boolean };

export const PrettySelect = (
  props: {
    id: string;
    className?: string;
    disabled?: boolean;
    options: OptionT[];
    "aria-invalid"?: boolean;
    "aria-label"?: string;
    icon?: SmallIcons;
    regularIcon?: RegularIcons;
    alwaysUsePlaceholder?: boolean;
  } & (
    | {
        multiple: true;
        placeholder: string | ReactNode;
        value: string[];
        onChange: (values: string[]) => void;
      }
    | {
        multiple?: false;
        placeholder?: string | ReactNode;
        value?: string;
        onChange: (value: string | undefined) => void;
      }
  )
) => {
  const { icon: Icon, regularIcon: RegularIcon } = props;
  let intl = useIntl();
  let [chipListEl, setChipListEl] = useState<HTMLUListElement | null>(null);
  let [popperTarget, setPopperTarget] = useState<HTMLButtonElement | null>(
    null
  );
  let [popoverElement, setPopoverElement] = useState<HTMLUListElement | null>(
    null
  );
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(
    null
  );
  let { styles, attributes } = usePopper(popperTarget, popoverElement, {
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  let selectedOption = useMemo(
    () => props.options.find((option) => option.value === props.value),
    [props.options, props.value]
  );
  let options = useMemo(
    () => props.options.filter((option) => !option.disabled),
    [props.options]
  );
  let [isExpanded, setExpanded] = useState(false);

  let doClose = useCallback(() => {
    if (document.activeElement === popoverElement && popperTarget) {
      popperTarget.focus();
    }
    setExpanded(false);
  }, [popoverElement, popperTarget]);

  useClickOutside(wrapperElement, doClose);

  return (
    <Container className={props.className} ref={setWrapperElement}>
      {Icon ? (
        <Icon size="small" className="input-icon" />
      ) : (
        RegularIcon && <RegularIcon size="regular" className="input-icon" />
      )}
      <SelectButton
        ref={setPopperTarget}
        id={props.id}
        type="button"
        disabled={props.disabled}
        tabIndex={isExpanded ? -1 : 0}
        $placeholder={
          props.multiple || props.alwaysUsePlaceholder || !props.value
        }
        $customIcon={Icon || RegularIcon ? true : false}
        aria-haspopup="listbox"
        aria-controls={`options-${props.id}`}
        aria-expanded={isExpanded ? "true" : undefined}
        aria-invalid={props["aria-invalid"]}
        aria-label={props["aria-label"]}
        onClick={(evt) => {
          if (props.disabled) {
            evt.preventDefault();
            return;
          }

          setExpanded(!isExpanded);
          if (isExpanded && popperTarget) {
            popperTarget.focus();
          }
          evt.preventDefault();
        }}
      >
        <label htmlFor={props.id}>
          {props.multiple || props.alwaysUsePlaceholder || !selectedOption
            ? props.placeholder
            : selectedOption?.label}
        </label>
      </SelectButton>
      <ChevronDownIcon className="select-chevron" size="small" />
      {isExpanded ? (
        <ARIA.Listbox<OptionT>
          ref={setPopoverElement}
          id={`options-${props.id}`}
          aria-readonly={props.disabled ? "true" : undefined}
          options={options}
          onClose={doClose}
          onSearch={(options, query) =>
            options.find((option) =>
              option.label?.toLocaleLowerCase().startsWith(query.trim())
            )
          }
          style={styles.popper}
          aria-labelledby={props.id}
          aria-invalid={props["aria-invalid"]}
          {...attributes.popper}
          {...(props.multiple
            ? {
                multiple: true,
                onChange(options) {
                  props.onChange(options.map((o: OptionT) => o.value));
                },
                value: props.value.map(
                  (val) =>
                    props.options.find((o) => o.value === val) ?? {
                      label: val,
                      value: val,
                    }
                ),
              }
            : {
                multiple: false,
                onChange(option) {
                  props.onChange(option?.value);
                  doClose();
                },
                value: props.options.find((o) => o.value === props.value),
              })}
        >
          {(option, query) => {
            if (query) {
              return (
                <>
                  <Match>{option.label?.slice(0, query.length)}</Match>
                  {option.label?.slice(query.length)}
                </>
              );
            } else {
              return option.label;
            }
          }}
        </ARIA.Listbox>
      ) : (
        <ul
          role="listbox"
          id={`options-${props.id}`}
          aria-labelledby={props.id}
          aria-hidden="true"
          aria-multiselectable={props.multiple}
        />
      )}

      {props.multiple && (
        <ChipList
          aria-live="polite"
          className={props.value.length ? "" : "selection-list--empty"}
          ref={setChipListEl}
          id={`selection-chips-${props.id}`}
        >
          {props.value.map((value, index) => {
            let option = props.options.find((o) => o.value === value);
            return (
              <Selection forwardedAs="li" key={value} size="small">
                <SelectionLabel>{option?.label || value}</SelectionLabel>
                {!props.disabled && !option?.disabled && (
                  <Button
                    type="button"
                    size="small"
                    aria-label={intl.formatMessage(
                      {
                        defaultMessage: "Remove {item}",
                        description:
                          "button to remove an item from a list of selected items",
                      },
                      { item: option?.label || value }
                    )}
                    onClick={() => {
                      // remove the current selection from `value`
                      let newValue = props.value.filter(
                        (selection) => selection !== value
                      );
                      props.onChange(newValue);

                      // focus the next (or previous) button in the list
                      if (newValue.length && chipListEl) {
                        let buttons = chipListEl.querySelectorAll("button");

                        if (index === newValue.length) {
                          buttons[index - 1].focus();
                        } else {
                          buttons[index + 1].focus();
                        }
                      }
                    }}
                  >
                    <CloseIcon size="small" />
                  </Button>
                )}
              </Selection>
            );
          })}
        </ChipList>
      )}
    </Container>
  );
};
