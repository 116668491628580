import { SVGAttributes } from "react";

export const EditIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.414 2A1 1 0 0010 2L7.88 4.121 6.464 5.536 2.93 9.07a1 1 0 00-.26.454l-1 3.829a1 1 0 001.22 1.22l3.828-1a1 1 0 00.455-.26l7.07-7.071a1 1 0 000-1.415L11.415 2zM7.88 6.95l-3.344 3.343-.5 1.915 1.914-.5 3.344-3.344L7.879 6.95zm2.828 0l1.414-1.414-1.414-1.415-1.414 1.415 1.414 1.414z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.437 18.544a.995.995 0 01-.456.27l-3.89 1.06a1 1 0 01-1.227-1.227l1.06-3.89a.995.995 0 01.27-.456l9.887-9.887a2 2 0 012.829 0l1.414 1.415a2 2 0 010 2.828l-9.887 9.887zm5.645-11.301l-8.486 8.485-.353 1.768 1.767-.354 8.486-8.485-1.414-1.414z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
EditIcon.displayName = "EditIcon";
