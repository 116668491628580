import { SVGAttributes } from "react";

export const USGqIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg
      width={46}
      height={24}
      viewBox="0 0 46 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.67 13.616l-.627-.851h-7.49l3.046 4.397c-3.168.62-6.159-2.03-6.118-5.112.045-3.15 2.586-5.509 5.627-5.486 3.337.018 6.665 3.615 5.563 7.052zm4.494 5.404s2.222-2.9 2.23-6.674C44.414 4.93 39.22.113 31.972.1a12.148 12.148 0 00-9.924 5.04c.897 1.274 1.42 2.503 1.634 3.318h-3.318c-.169.524-.3 1.06-.396 1.612h4.097c.095.605.145 1.224.145 1.857 0 2.54-.796 4.894-2.153 6.833 2.112 3.037 5.613 5.24 9.956 5.24 2.882 0 4.917-1.23 5.285-1.425l.765 1.006h7.375l-3.273-4.561z"
        fill="#C6A348"
      />
      <path
        d="M13.002 15.697h3.05c0 .136-1.562 1.657-3.91 1.657-2.582 0-5.505-2.04-5.564-5.304-.059-3.382 2.864-5.6 5.563-5.504 2.19.078 3.023.706 4.248 1.908 0 0 1.766 0 3.56.004h3.728c-.214-.814-.737-2.044-1.634-3.318-.078-.105-.15-.214-.232-.319C20.017 2.431 16.899.009 12.05 0 6.354-.01 1.961 3.801.513 8.458a2.36 2.36 0 00-.05.169c0 .004 0 .004-.004.009A12.002 12.002 0 000 11.914a12.6 12.6 0 00.41 3.114c.915 3.4 3.291 6.205 6.428 7.711l.077.037c.014.009.032.013.05.023 1.58.719 3.355 1.12 5.245 1.133a12.113 12.113 0 009.714-4.985c.046-.064.087-.127.132-.191a11.877 11.877 0 002.154-6.833c0-.633-.05-1.252-.146-1.858H13.002v5.632z"
        fill="currentColor"
      />
    </svg>
  );
};
USGqIcon.displayName = "USGqIcon";
