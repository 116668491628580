import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Button, Card, Link } from "../index";
import { ThemeProvider } from "../../contexts";
import {
  CautionIcon,
  CloseIcon,
  CheckCircleIcon,
  DangerIcon,
  InfoIcon,
  SmallIcons,
} from "../../icons";
import { useIntl } from "react-intl";

const BaseToast = styled.div<{
  $type: string;
  $circleColor: string | null;
  $iconColor: string | null;
}>`
  font: ${(props) => props.theme.FontLabel};
  background: ${(props) => props.theme.ToastTintedBackground};
  padding: var(--spacing-xs) 0;
  color: ${(props) => props.theme.Color};
  border-radius: ${(props) => props.theme.CornerRadius};
  display: grid;
  row-gap: var(--spacing-xs);
  & > svg {
    grid-area: icon;
  }

  column-gap: var(--spacing-sm);
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas:
    ". .    .       ."
    ". icon message ."
    ". .    .       .";

  border-left: var(--spacing-xxs) solid
    ${(props) => props.theme.ToastBorderColor};
  & > svg {
    color: ${(props) =>
      props.$iconColor === "grey-1"
        ? "var(--color-gray-1)"
        : props.theme.ToastIconColor};
    > circle {
      color: ${(props) => props.$circleColor ?? props.theme.ToastIconColor};
    }
  }

  box-shadow: ${(props) => props.theme.CardShadow};

  ${Card} &.${(props) => props.$type} {
    box-shadow: none;
  }

  &.with-close {
    column-gap: var(--spacing-xs);
    grid-template-columns: auto auto auto auto 1fr auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       close ."
      ". . icon . message close ."
      ". . .    . .       .     .";
  }

  &.with-action {
    column-gap: var(--spacing-xs);
    grid-template-columns: auto auto auto auto 1fr auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       . ."
      ". . icon . message . ."
      ". . .    . action  . ."
      ". . .    . .       . .";
  }

  &.with-details {
    grid-template-columns: auto auto 1fr auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". .    .       ."
      ". icon message ."
      ". .    details ."
      ". .    .       .";
  }

  &.with-details.with-action {
    grid-template-columns: auto auto auto auto 1fr auto auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       .       . ."
      ". . icon . message message . ."
      ". . .    . details details . ."
      ". . .    . action  action  . ."
      ". . .    . .       .       . .";
  }

  &.with-close.with-details {
    grid-template-columns: auto auto auto auto 1fr auto auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       close   close ."
      ". . icon . message close   close ."
      ". . .    . details details .     ."
      ". . .    . .       .       .     .";
  }

  &.with-close.with-action {
    grid-template-columns: auto auto auto auto 1fr auto auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       close   close ."
      ". . icon . message close   close ."
      ". . .    . action  action .     ."
      ". . .    . .       .       .     .";
  }

  &.with-close.with-details.with-action {
    grid-template-columns: auto auto auto auto 1fr auto auto auto;
    grid-template-rows: auto;
    grid-template-areas:
      ". . .    . .       close   close ."
      ". . icon . message close   close ."
      ". . .    . details details .     ."
      ". . .    . action  action  .     ."
      ". . .    . .       .       .     .";
  }
`;

const Message = styled.strong`
  grid-area: message;
  font: ${(props) => props.theme.FontLabel};
`;

const Details = styled.div`
  grid-area: details;
  font: ${(props) => props.theme.FontBody};
`;

const CloseButton = styled(Button)`
  grid-area: close;
`;

const Action = styled.div`
  grid-area: action;
  ${Link} {
    font: ${(props) => props.theme.FontSmallInteractive};
  }
`;

const Tints = {
  success: "green",
  informational: "blue",
  warning: "yellow",
  error: "red",
} as const;

const Icons = {
  success: CheckCircleIcon,
  informational: InfoIcon,
  warning: CautionIcon,
  error: DangerIcon,
} as const;

export const Toast: FC<{
  type: "success" | "informational" | "warning" | "error";
  details?: ReactNode;
  action?: ReactNode;
  className?: string;
  onClose?: () => void;
  icon?: SmallIcons;
  circleColor?: "red";
  iconColor?: "grey-1";
  unpublished?: boolean;
}> = (props) => {
  let intl = useIntl();
  let Icon = props.icon ?? Icons[props.type];
  let classNames = [];
  if (props.onClose) {
    classNames.push("with-close");
  }
  if (props.details) {
    classNames.push("with-details");
  }
  if (props.action) {
    classNames.push("with-action");
  }

  return (
    <ThemeProvider tint={Tints[props.type]}>
      <BaseToast
        role="alert"
        aria-atomic="true"
        $type={props.type}
        $circleColor={props.circleColor ?? null}
        $iconColor={props.iconColor ?? null}
        className={[...classNames, props.className, props.type].join(" ")}
      >
        <Icon size="small" />
        <Message>{props.children}</Message>
        {props.details && <Details>{props.details}</Details>}
        {props.action && (
          <ThemeProvider tint="blue">
            <Action>{props.action}</Action>
          </ThemeProvider>
        )}
        {props.onClose && (
          <CloseButton
            size="small"
            aria-label={intl.formatMessage({
              defaultMessage: "Close",
              description: "Tooltip to clear toast",
            })}
            onClick={props.onClose}
          >
            <CloseIcon size="small" />
          </CloseButton>
        )}
      </BaseToast>
    </ThemeProvider>
  );
};
