import { SVGAttributes } from "react";

export const NoAssetIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.293 2.293a1 1 0 011.414 0l18 18a1 1 0 01-1.414 1.414l-18-18a1 1 0 010-1.414z"
        fill="currentColor"
      />
      <path
        d="M4.682 4.096A2.988 2.988 0 004 6v12a3 3 0 003 3h10a3 3 0 002.761-1.825L18 17.415V18a1 1 0 01-1 1H7a1 1 0 01-1-1V6c0-.17.042-.33.117-.47L4.682 4.097zM18 14.586V6a1 1 0 00-1-1H8.414L6.462 3.048C6.637 3.016 6.816 3 7 3h10a3 3 0 013 3v10.586l-2-2z"
        fill="currentColor"
      />
    </svg>
  );
};
NoAssetIcon.displayName = "NoAssetIcon";
