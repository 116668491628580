import styled from "styled-components";
import { HighlightedText } from "../../HighlightedText";

const Result = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Name = styled(HighlightedText)`
  font: ${(props) => props.theme.FontAssetTitle};
`;
const Description = styled(HighlightedText)`
  font: ${(props) => props.theme.FontBody};
  overflow: scroll;
`;
const Highlight = styled.span`
  font-weight: bolder;
`;
export const SearchResult = (props: {
  name: string;
  description: string;
  query?: string;
  showDescription?: boolean;
}) => (
  <Result>
    <Name text={props.name} query={props.query} components={{ Highlight }} />
    {props.showDescription ? (
      <Description
        text={props.description}
        query={props.query}
        components={{ Highlight }}
      />
    ) : (
      <></>
    )}
  </Result>
);
