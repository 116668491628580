import { FC } from "react";
import styled from "styled-components";

type ResultIndexProps = {
  index: number;
};
const StyledResultIndex = styled.div`
  grid-area: result-index;
  align-self: start;
  font: var(--font-smaller-heading);
`;

export const ResultIndex: FC<ResultIndexProps> = ({ index }) => {
  return <StyledResultIndex>{index}</StyledResultIndex>;
};
