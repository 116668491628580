import { SVGAttributes } from "react";

export const LinkIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.016 8.504c.385.374 1.017.38 1.41.014l1.356-1.26c.167-.155.387-.405.604-.742.9-1.393.9-2.994-.604-4.392-1.493-1.387-3.185-1.387-4.668-.56a4.2 4.2 0 00-.796.56l-1.355 1.26c-.186.173-.442.45-.699.808-.46.642-.745 1.305-.744 1.997 0 .634.254 1.223.765 1.7.394.365 1.026.36 1.411-.015a.914.914 0 00-.014-1.34c-.124-.114-.166-.214-.167-.346 0-.24.144-.573.4-.93.171-.24.345-.427.444-.52l1.355-1.26c.06-.055.205-.166.41-.28.776-.433 1.495-.433 2.262.28.755.702.755 1.341.299 2.048-.122.188-.24.323-.3.378l-1.355 1.26a.914.914 0 00-.014 1.34zM4.984 7.496a1.036 1.036 0 00-1.41-.014l-1.356 1.26a3.98 3.98 0 00-.604.742c-.9 1.393-.9 2.994.604 4.392 1.493 1.387 3.185 1.387 4.668.56.36-.2.629-.405.796-.56l1.355-1.26c.186-.173.442-.45.699-.808.46-.642.745-1.305.744-1.997 0-.634-.254-1.223-.765-1.7a1.036 1.036 0 00-1.411.015.914.914 0 00.014 1.34c.124.114.166.214.166.346 0 .24-.143.573-.398.93-.172.24-.346.427-.445.52l-1.355 1.26a2.339 2.339 0 01-.41.28c-.776.433-1.495.433-2.262-.28-.755-.702-.755-1.341-.299-2.048.122-.188.24-.323.3-.378l1.355-1.26a.914.914 0 00.014-1.34z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.485 12.243l2.122-2.122a3 3 0 10-4.243-4.242l-2.829 2.828a3 3 0 000 4.243 1 1 0 11-1.414 1.414 5 5 0 010-7.071l2.829-2.829a5 5 0 017.07 7.072l-2.12 2.12a1 1 0 01-1.415-1.413zm-8.485 0l-2.121 2.121a3 3 0 104.242 4.243l2.829-2.829a3 3 0 000-4.242 1 1 0 011.414-1.415 5 5 0 010 7.071l-2.829 2.829a5 5 0 11-7.07-7.071l2.12-2.122A1 1 0 018 12.243z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
LinkIcon.displayName = "LinkIcon";
