import { SVGAttributes } from "react";

export const ContentTypeRcalistIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_1871_84)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 8A6 6 0 112 8a6 6 0 0112 0zm2 0A8 8 0 110 8a8 8 0 0116 0zM5.438 10.669V12h5.346v-1.331H9.156V4.322h-2.53L4.79 6.742l1.056.825 1.562-2.046h.1v5.148H5.437z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_1871_84">
            <path fill="#fff" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 12a9 9 0 11-18 0 9 9 0 0118 0zm2 0c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zM8.921 15.306V17h6.805v-1.694h-2.073V7.228h-3.22l-2.338 3.08 1.344 1.05 1.989-2.604h.125v6.552H8.921z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeRcalistIcon.displayName = "ContentTypeRcalistIcon";
