import { SVGAttributes } from "react";

export const LockIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6 7h4V5a1 1 0 00-1-1H7a1 1 0 00-1 1v2zm6 0a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1V5a3 3 0 013-3h2a3 3 0 013 3v2z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9 10.5h6v-3A1.5 1.5 0 0013.5 6h-3A1.5 1.5 0 009 7.5v3zm9 0a1.5 1.5 0 011.5 1.5v9a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 21v-9A1.5 1.5 0 016 10.5v-3A4.5 4.5 0 0110.5 3h3A4.5 4.5 0 0118 7.5v3z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
LockIcon.displayName = "LockIcon";
