import { SVGAttributes } from "react";

export const VersoTextAboveIcon = (
  props: SVGAttributes<SVGElement> & { size: "regular" }
) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm0 3a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1zm2 3h14c1.105 0 2 .84 2 1.875v11.25C21 22.16 20.105 23 19 23H5c-1.105 0-2-.84-2-1.875V9.875C3 8.839 3.895 8 5 8zm0 1.875v11.25h14V9.875H5z"
        fill="currentColor"
      />
    </svg>
  );
};
VersoTextAboveIcon.displayName = "VersoTextAboveIcon";
