import { SVGAttributes } from "react";

export const ContentTypeVenueIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0h6a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V3a3 3 0 013-3zm7 13a1 1 0 01-1 1v-2a3 3 0 00-3-3h1a2 2 0 002-2V3H5v4a2 2 0 002 2h1a3 3 0 00-3 3v2a1 1 0 01-1-1V3a1 1 0 011-1h6a1 1 0 011 1v10zm-4-2a1 1 0 011 1v2H7v-2a1 1 0 011-1zM7 5h2v2H7V5z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={3}
          y={1}
          width={18}
          height={22}
          rx={2}
          stroke="currentColor"
          strokeWidth={2}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0h14a3 3 0 013 3v18a3 3 0 01-3 3H5a3 3 0 01-3-3V3a3 3 0 013-3zm14 22h-3v-2a4 4 0 00-8 0v2H5a1 1 0 01-1-1V3a1 1 0 011-1h14a1 1 0 011 1v18a1 1 0 01-1 1zm-7-4a2 2 0 012 2v2h-4v-2a2 2 0 012-2zm-4-5v-2h8v2H8zm5-4h3V5h-3v4zm0-6h5v10a2 2 0 01-2 2H8a2 2 0 01-2-2V3h7zm-2 2H8v4h3V5z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
ContentTypeVenueIcon.displayName = "ContentTypeVenueIcon";
