import { SVGAttributes } from "react";

export const BulletedListIcon = (
  props: SVGAttributes<SVGElement> & { size: "small" | "regular" }
) => {
  if (props.size === "small") {
    return (
      <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8 9a1 1 0 010-2h5a1 1 0 110 2H8zM8 14a1 1 0 110-2h5a1 1 0 110 2H8zM8 4a1 1 0 010-2h5a1 1 0 110 2H8z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3a1 1 0 11-2 0 1 1 0 012 0zM4 13a1 1 0 11-2 0 1 1 0 012 0zM4 8a1 1 0 11-2 0 1 1 0 012 0z"
          fill="currentColor"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 6a1 1 0 100-2 1 1 0 000 2zm0 14a1 1 0 100-2 1 1 0 000 2zm1-8a1 1 0 11-2 0 1 1 0 012 0zm5-8a1 1 0 100 2h9a1 1 0 100-2h-9zm-1 8a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zm1 6a1 1 0 100 2h9a1 1 0 100-2h-9z"
          fill="currentColor"
        />
      </svg>
    );
  }
};
BulletedListIcon.displayName = "BulletedListIcon";
